<template>
    <div id="sms-conversation" class="container-fluid main-content d-flex">
        <div id="conversation-box">
            <div id="conversation-list" class="messages-box m-2 p-0">
                <div id="active-sms" :class="{'border-white' : this.user.attributes['is-admin']}" v-scroll="infiniteScroll">
                    <p v-if="!this.user.attributes['is-admin']" class="active-conversation-title">{{ $t('my-active-conversation') }}</p>
                    <div class="loading-active-conversation-box" v-if="currentUserLoading">
                        <span class="spinner spinner-border text-primary"></span>
                    </div>
                    <div :class="{'d-flex': currentUserConversations.conversations.length === 0}" class="h-100" v-else>
                        <template v-if="currentUserConversations.conversations.length">
                            <div class="active-box">
                                <sms-conversation-box
                                    v-for="conversation in currentUserConversations.conversations"
                                    v-bind:key="conversation.id"
                                    v-bind:conversationId="conversation.id"
                                    v-bind:contactId="conversation.relationships.contact.data.id"
                                    v-bind:usersIds="conversation.attributes['active-users']"
                                    v-bind:conversationMessages="conversation.relationships.messages.data"
                                    v-on:openConversation="openConversation($event, {})"
                                ></sms-conversation-box>
                                <span
                                    id="load-more-conversations"
                                    class="spinner spinner-border text-secondary loading-spinner align-self-center"
                                    role="status"
                                    v-if="loadMoreCurrentUserLoading">
                                </span>
                            </div>
                        </template>
                        <div v-else class="d-flex align-self-center justify-content-center w-100 no-active-text">{{ $t('you-no-active-conversation') }}</div>
                    </div>
                    <div class="next-conversations" v-if="currentUserConversations.meta.page['current-page'] < currentUserConversations.meta.page['last-page']">
                        <span>{{ $t('next-messages') }}</span><font-awesome-icon :icon="['fa', 'angles-down']" />
                    </div>
                </div>
                <div v-if="!this.user.attributes['is-admin']" id="unactive-conversation" class="white-box sms-list p-0" :class="{'empty':!contactHasConversations, 'full':contactHasConversations}">
                    <div class="order-sms d-flex justify-content-between px-4 py-2">
                        <p class="text-grey-9 text-left mt-3 m-0"><small>{{ $t('sms-in-waiting-for-replay') }}</small></p>
                        <font-awesome-icon class="icon mt-3 text-primary" :icon="['fas', 'caret-down']" />
                    </div>
                    <div class="loading-active-conversation-box" v-if="noUsersLoading">
                        <span class="spinner spinner-border text-primary"></span>
                    </div>
                    <div id="no-users-conversation" class="message-list-box" v-else v-scroll="infiniteScroll">
                        <template v-if="noUsersConversations.conversations.length">
                            <sms-conversation-box
                                v-for="conversation in noUsersConversations.conversations"
                                v-bind:key="conversation.id"
                                v-bind:conversationId="conversation.id"
                                v-bind:contactId="conversation.relationships.contact.data.id"
                                v-bind:usersIds="conversation.attributes['active-users']"
                                v-bind:conversationMessages="conversation.relationships.messages.data"
                                v-on:openConversation="openConversation($event, {})"
                            ></sms-conversation-box>
                            <span
                                id="load-more-conversations"
                                class="spinner spinner-border text-secondary loading-spinner align-self-center"
                                role="status"
                                v-if="loadMoreNoUsersLoading">
                            </span>
                        </template>
                        <div v-else class="mt-5">{{ $t('you-no-message') }}</div>
                    </div>
                    <div class="next-conversations" v-if="noUsersConversations.meta.page['current-page'] < noUsersConversations.meta.page['last-page']">
                        <span>{{ $t('next-messages') }}</span><font-awesome-icon :icon="['fa', 'angles-down']" />
                    </div>
                    <div class="empty-box-footer d-flex py-2">
                    </div>
                </div>
            </div>
        </div>
        <div id="contact-sms" class="w-100">
            <div v-if="!this.user.attributes['is-admin']" class="col-sm text-left mb-3">
                <h3 class="section-title mt-3">{{ $t('sms-conversation') }}</h3>
                <h6>{{ smsNumber }} <span class="font-italic" v-if="numberToActivate && smsNumber">{{ $t('number-not-active-yet') }}</span></h6>
            </div>
            <div id="chat-info" class="white-box">
                <div class="loading-box" v-if="openingConversations">
                    <span class="spinner spinner-border text-primary loading-spinner" role="status"></span>
                </div>
                <div class="row" v-else-if="(shownConversation && !currentUserLoading && !noUsersLoading)">
                    <div class="col-7" id="chat">
                        <sms-chat
                            :loadTerminatedConversations="false"
                            v-on:conversationUpdated="updateConversations()"
                            v-on:conversationTerminated="terminateConversation()"
                        >
                        </sms-chat>
                    </div>
                    <div class="col info-contact-block responsive-bloc-width" id="info">
                        <sms-contact-info></sms-contact-info>
                    </div>
                </div>
                <div v-else>
                    <p class='no-message'>{{ $t('no-messages-yet') }}</p>
                    <div class='d-flex justify-content-center psitt'>
                        <div class="pr-3">
                            <img src="/images/psitt.png" />
                        </div>
                        <div class='text-left pt-4' v-if="!contactHasConversations">
                            {{ $t('test-connection') }}<br>{{ $t('simply-text-yourself') }}
                        </div>
                        <div class='text-left pt-4' v-if="contactHasConversations">
                            <p class="d-flex align-self-center justify-content-center w-100">{{ $t('you-no-active-conversation') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Components
import SmsChat            from '../components/SmsChat.vue';
import SmsContactInfo     from '../components/SmsContactInfo.vue';
import SmsConversationBox from '../components/SmsConversationBox.vue';

// Import Mixins
import myConversationMixin from '@/core/mixins/myConversationMixin';
import InfiniteScrollMixin from '@/core/mixins/InfiniteScrollMixin.js';

export default {
    components: {
        SmsChat,
        SmsContactInfo,
        SmsConversationBox
    },

    mixins: [
        myConversationMixin,
        InfiniteScrollMixin
    ]
};
</script>
