 <template>
    <div id="fb-top-sidebar" class="white-box stats mb-4">
        <ul class="nav fa-ul flex-column py-3">
            <li class="nav-item menu-item text-left" v-for="interaction in interactions" :key="interaction.name">
                <div v-if="interaction.type === 'font'">
                    <font-awesome-icon class="fa-li text-grey" :icon="interaction.font" /><p class="mb-0">{{ pageStats !== null ? pageStats[interaction.name] : 0 }} {{ $tc(interaction.name, pageStats !== null ? pageStats[interaction.name] : 1 ) }}</p>
                </div>
                <div class="d-flex" v-else>
                    <img class="icon-stats ml-2 mt-0" :src='interaction.font' height="24" width="17"><p class="mb-0">{{ pageStats !== null ? pageStats[interaction.name] : 0 }} {{ $tc(interaction.name, pageStats !== null ? pageStats[interaction.name] : 1 ) }}</p>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>

// Internal services js
import { RepositoryFactory } from '../../../repositories/repositoryFactory.js';

const FacebookPages = RepositoryFactory.get('FacebookPages');

// Import external libraries
import { mapState } from 'vuex';

export default {
    mounted () {
        if (!this.hasActivePage) {
            return;
        }

        // Get the user`s active pages
        FacebookPages.index()
            .then(request => {
                this.getPageStats(request);
            });
    },

    data () {
        return {
            pageStats:    null,
            interactions: {
                comment: { name: 'comment', font: ['fas', 'comment-dots'],    type: 'font' },
                like:    { name: 'like',    font: ['fas', 'thumbs-up'],       type: 'font' },
                love:    { name: 'love',    font: ['fas', 'heart'],           type: 'font' },
                care:    { name: 'care',    font: '/images/soutien_gris.png', type: 'svg'  },
                haha:    { name: 'haha',    font: ['fas', 'grin-beam'],       type: 'font' },
                wow:     { name: 'wow',     font: ['fas', 'grin-hearts'],     type: 'font' },
                sad:     { name: 'sad',     font: ['fas', 'sad-tear'],        type: 'font' },
                angry:   { name: 'angry',   font: ['fas', 'angry'],           type: 'font' }
            }
        };
    },

    computed: {
        ...mapState('core/facebook', [
            'hasActivePage'
        ])
    },

    methods: {
        /**
         * Get stats of the active page
         *
         * @param   {object}    request     the api response
         */
        getPageStats (request) {
            if (request.data.data[0] === void 0) {
                return;
            }

            FacebookPages.getStats(request.data.data[0].id)
                .then((request) => {
                    this.pageStats = request.data.data;
                });
        }
    }
};
</script>
