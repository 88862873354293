<template>
    <b-modal modal-class="modal right fade sidebar-modal font-nunito" id="flash-send-sidebar" no-close-on-backdrop hide-footer hide-header>
        <div class="border-0 d-flex justify-content-between mt-2" v-if="!displayMessageDetail">
            <button type="button" class="btn text-btn style-btn fs-14" ref="closeButton" @click="clearFlashSend()">{{ $t('close') }}</button>
        </div>
        <div class="modal-header justify-content-center border-0 modal-header-sms mt-5 mb-4" v-if="displayMessageDetail">
            <h3 class="fs-18 text-black font-nunito">Détails du message éclair envoyé</h3>
        </div>
        <div class="modal-header justify-content-center border-0 modal-header-sms mt-2" v-else>
            <h3 class="fs-18 text-black font-nunito">{{ $t('create-flash-send') }}</h3>
            <p class="text-left fs-13 text-black descrip-modal">{{ $t('send-multi-sms') }} </p>
        </div>
        <div class="modal-body pt-0 p-2">
            <div class="form-group text-left">
                <label for="recipient" class="col-form-label fs-14">
                    {{ $tc('recipient', this.to.length) }}
                    <strong class="obligatory" v-if="!displayMessageDetail">*</strong>
                </label>
                    <search-input :defaultList="defaultList" v-on:listUpdated="updateContactList($event)" :disabled="displayMessageDetail"></search-input>
                    <div class="invalid-feedback" v-if="submitted  && errors.has('recipient')">
                        {{ errors.first('recipient') }}
                    </div>
            </div>
            <div class="form-group text-left" v-if="!displayMessageDetail">
                <label for="template" class="col-form-label fs-14">{{ $t('template') }}</label>
                <select-dropdown
                    :options="options"
                    :selected="selectedTemplate"
                    v-on:selectTemplate="chooseTemplate($event)"
                    :showLabel="false"
                    :type="'template'"
                    :isDisabled="isFormDisabled"
                >
                </select-dropdown>
                <div class="invalid-feedback" v-if="submitted && errors.has('template')">
                    {{ errors.first('template') }}
                </div>
            </div>
            <div class="form-group text-left">
                <label for="message" class="col-form-label fs-14">
                    {{ $t('message') }}
                    <strong class="obligatory" v-if="!displayMessageDetail">*</strong>
                </label>
                <div v-if="displayMessageDetail">
                    <p class="fs-13">{{ message }}</p>
                </div>
                <div v-else>
                    <textarea
                        id="message"
                        name="message"
                        type="text"
                        class="form-control fs-13"
                        rows="6"
                        :placeholder="isFormDisabled ? chatPlaceholder : $t('template-text-placeholder')"
                        v-model="message"
                        v-validate="'required'"
                        :class="{ 'is-invalid': submitted && errors.has('message'), 'disabled-textarea': isFormDisabled}"
                        :disabled="isFormDisabled"
                    ></textarea>
                    <sms-counter :sms="message" v-on:limitExceeded="smsLimitExceeded = $event"></sms-counter>
                    <div class="invalid-feedback" v-if="submitted && errors.has('message')">
                        {{ errors.first('message') }}
                    </div>
                </div>
            </div>
            <div class="name-content d-flex flex-column mb-5 mt-5" v-if="displayMessageDetail">
                <h6 class="font-nunito text-14">{{ $t('time-date-to-send') }}</h6>
                <div class="d-flex d-inline-flex">
                    <span class="text-dark-brown text-left mr-5 font-nunito fs-13">
                        {{ toUtc(selectedFlashMessage.sentAt).date }} {{ toUtc(selectedFlashMessage.sentAt).time }} <utc>UTC</utc>
                    </span>
                </div>
            </div>
            <div class="mb-4 send-time" v-else>
                <router-link :to="{ name: '' }" type="button" class="text-btn style-btn fs-14" @click.native="showTimeSection()">
                    {{  $t('plan-flash-message') }}
                    <font-awesome-icon :icon="['fas', 'angle-up']" v-if="shownSendTime"/>
                    <font-awesome-icon :icon="['fas', 'angle-down']" v-else/>
                </router-link>
                <div class="date-time-to-send-section form-group mb-5 p-2" v-if="shownSendTime">
                    <div class="d-flex flex-column">
                        <label class="font-nunito text-14 text-dark mb-0" for="date-time">
                            {{ $t('time-date-to-send') }}
                        </label>
                        <small class="text-left text-black font-nunito">
                            {{ $t('schedule-time-to-send') }}
                        </small>
                        <small class="btn-link text-decoration">
                            <utc>{{ $t('learn-about-utc') }}</utc>
                        </small>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label for="planned-date" class="font-nunito text-12 text-darker-grey mb-0">
                                {{ $t('Date') }}
                            </label>
                            <input type="date" class="form-border"
                                name="planned-date"
                                v-validate="`date_format:yyyy-MM-dd|after:${minDate}${dateRules}`"
                                v-model="flashMessage['planned-date']"
                            >
                            <div class="invalid-feedback" v-if="submitted && errors.has('planned-date')">
                                {{ errors.first('planned-date') }}
                            </div>
                        </div>
                        <div class="col">
                            <label for="planned-time" class="font-nunito text-12 text-darker-grey mb-0">
                                {{ $t('utc-time') }}
                            </label>
                            <input type="time" class="form-border"
                                name="planned-time"
                                v-validate="timeRules"
                                v-model="flashMessage['planned-time']"
                            >
                            <div class="invalid-feedback" v-if="submitted && errors.has('planned-time')">
                                {{ errors.first('planned-time') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer mb-2 d-flex justify-content-end align-self-end" v-if="displayMessageDetail">
            <button class="btn btn-primary rounded-pill px-5 py-1 mt-auto font-nunito"
                @click="$bvModal.hide('flash-send-sidebar')">{{ $t('close') }}
            </button>
        </div>
        <div class="modal-footer border-0 d-flex justify-content-between" v-else>
            <button type="button" class="btn text-btn style-btn fs-14" ref="closeButton" @click="clearFlashSend()">{{ $t('cancel') }}</button>
            <button
                type="button"
                class="btn btn-primary rounded-pill invite-btn style-btn fs-14"
                :disabled="isFormDisabled || disabledSubmit || loading"
                @click="editMessage ? editFlash() : createFlash()"
            >
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                {{ shownSendTime ? $t('send-at-scheduled-time') : $t('send-now') }}
            </button>
        </div>
    </b-modal>
</template>
<script>
import Vue from 'vue';

// Components
import SelectDropdown from '@/core/components/SelectDropdown.vue';
import SearchInput    from '@/connectors/sms/components/SearchInput.vue';
import SmsCounter     from '@/connectors/sms/components/SmsCounter.vue';
import Utc            from '@/core/components/UTC.vue';

// Internal services js
import {
    mapActions,
    mapState,
    mapGetters
} from 'vuex';
import Alert        from '@/helpers/Alert.js';
import { EventBus } from '@/helpers/EventBus.js';
import moment       from 'moment';
import Format       from '@/helpers/Format.js';

// Import Really Fast Deep Copy
const clone = require('rfdc')();

export default {
    props: ['editMessage', 'displayMessageDetail'],

    components: {
        Utc,
        SelectDropdown,
        SearchInput,
        SmsCounter
    },

    data () {
        const FLASH_CATEGORY = 'flash';

        return {
            // Template
            selectedTemplate: this.$i18n.t('choose-template'),
            options:          [],
            smsLimitExceeded: false,
            shownSendTime:    false,
            defaultList:      [],

            // Scheduled Flash details
            to:           [],
            message:      '',
            flashMessage: {
                name:           'flash name',
                category:       FLASH_CATEGORY,
                content:        '',
                audiences:      [],
                'planned-date': '',
                'planned-time': ''
            },

            submitted: false,
            loading:   false
        };
    },

    computed: {
        ...mapState('core/scheduled-messages', {
            flashMessages:        'plannedScheduledMessages',
            selectedFlashMessage: 'selectedScheduledMessage'
        }),

        ...mapState('core/templates', [
            'templates'
        ]),

        // Getter for the selected phone number
        ...mapGetters('core/number', { activePhoneNumber: 'smsPhoneNumber', numberToActivate: 'numberToActivate' }),

        isFormDisabled () {
            if (!this.activePhoneNumber || this.numberToActivate) {
                return true;
            }

            return false;
        },

        /**
         * Get the Chat placeholder
         *
         * @return {string}
         */
        chatPlaceholder () {
            if (this.numberToActivate) {
                // Phone waiting to be approved
                return this.$i18n.t('phone-not-activated-yet');
            }

            // No active phone number
            return this.$i18n.t('no-phone-number-configured');
        },

        disabledSubmit () {
            if (this.to.length !== 0 && this.message && !this.smsLimitExceeded) {
                return false;
            }

            return true;
        },

        /**
         * Get the min date (yesterday)
         */
        minDate () {
            return moment().subtract(1, 'days')
                .format('yyyy-MM-DD')
                .toString();
        },

        /**
         * Validates the planned date
         *
         * @return boolean
         */
        dateRules () {
            return this.flashMessage['planned-time'] ? '|required' : '';
        },

        /**
         * Validates the planned time
         *
         * @return boolean
         */
        timeRules () {
            return this.flashMessage['planned-date'] ? '|required' : '';
        },
    },

    mounted () {
        this.getTemplates();
    },

    watch: {
        /**
         * Selected flash message watcher
         *
         * @param   {Object}  value   The value
         */
        selectedFlashMessage (value) {
            this.flashMessage.audiences       = clone(value.audiences);
            this.flashMessage.content         = clone(value.content);
            this.flashMessage['planned-date'] = clone(value.plannedDate);
            this.flashMessage['planned-time'] = clone(value.plannedTime);

            this.message     = clone(value.content);
            this.defaultList = clone(value.audiences);

            if (this.flashMessage['planned-date'] && this.flashMessage['planned-time']) {
                this.shownSendTime = true;
            } else {
                this.shownSendTime = false;
            }
        }
    },

    methods: {
        /**
         * Load templates
         */
        ...mapActions('core/templates', { getTemplate: 'index' }),

        ...mapActions('core/scheduled-messages', {
            create:                    'create',
            update:                    'update',
            send:                      'send',
            resetSelectedFlashMessage: 'resetSelectedScheduledMessage'
        }),

        ...mapActions('core/contacts', {
            getContacts: 'index'
        }),

        /**
         * Helper method to format date to use in template
         */
        toUtc: Format.toUtc,

        /**
         * Get the list of available templates
         */
        getTemplates () {
            this.getTemplate().then(() => {
                this.options.push({
                    'id':      null,
                    'name':    this.$i18n.t('choose-template'),
                    'content': ''
                });
                for (const templateId in this.templates) {
                    this.options.push({
                        'id':      templateId,
                        'name':    this.templates[templateId].attributes.name,
                        'content': this.templates[templateId].attributes.content
                    });
                }
            });
        },

        /**
         * Update selected option
         *
         * @return    void
         */
        chooseTemplate (option) {
            if (this.message === '') {
                this.selectedTemplate = option.name;
                this.message          = option.content;
            } else {
                Alert.confirmation(this.$i18n.t('this-will-replace-message'), this.$i18n.t('replace'))
                    .then((response) => {
                        if (response.isConfirmed) {
                            this.selectedTemplate = option.name;
                            this.message = option.content;
                        }
                    });
            }
        },

        /**
         * When the liste of contacts is updated, update the TO list
         *
         * @param {object}  event   the event
         */
        updateContactList (event) {
            this.to = event;
        },

        /**
         * Create a flash scheduled message to one or multiple contacts
         */
        createFlash () {
            if (this.isFormDisabled || this.disabledSubmit) {
                return;
            }

            Vue.prototype.$validation = this.$validator;

            this.loading   = true;
            this.submitted = true;

            this.$validator.validate().then((valid) => {
                if (!valid) {
                    this.loading = false;
                    return;
                }

                // Set the new flash content and audiences
                this.flashMessage.content = this.message;
                this.to.forEach(element => {
                    this.flashMessage.audiences.push(element.phoneNumber);
                });

                this.create({ attributes: this.flashMessage })
                    .then((response) => {
                        if (this.shownSendTime === false) {
                            this.sendFlash(response.data.data.id);
                        } else {
                            this.loading   = false;
                            this.submitted = false;

                            this.resetSelectedFlashMessage();
                            this.$emit('flashListUpdated');
                            Alert.success(this.$i18n.t('flash-send-created'));
                            this.clearFlashSend();
                        }
                    })
                    .catch(() => {
                        this.loading   = false;
                        this.submitted = false;
                    });
            });
        },

        /**
         * Edit the selected flash message
         */
        editFlash () {
            if (this.isFormDisabled || this.disabledSubmit) {
                return;
            }

            Vue.prototype.$validation = this.$validator;

            this.loading   = true;
            this.submitted = true;

            this.$validator.validate().then((valid) => {
                if (!valid) {
                    this.loading = false;
                    return;
                }

                // Set the updated flash content and audiences
                this.flashMessage.content   = this.message;
                this.flashMessage.audiences = [];
                this.to.forEach(element => {
                    this.flashMessage.audiences.push(element.phoneNumber);
                });

                this.update({
                    id:         this.selectedFlashMessage.id,
                    attributes: this.flashMessage
                })
                    .then(() => {
                        if (this.shownSendTime === false) {
                            this.sendFlash(this.selectedFlashMessage.id);
                        } else {
                            this.loading   = false;
                            this.submitted = false;

                            this.resetSelectedFlashMessage();
                            this.$emit('flashListUpdated');
                            Alert.success(this.$i18n.t('flash-send-updated'));
                            this.clearFlashSend();
                        }
                    })
                    .catch(() => {
                        this.loading   = false;
                        this.submitted = false;
                    });
            });
        },

        /**
         * Send the scheduled message now
         *
         * @param   {string}  id   the scheduled messages id
         */
        sendFlash (id) {
            Alert.scheduledMessageSendConfirmation('title-flash-message-send', 'confirm-scheduled-send')
                .then((response) => {
                    if (!response.isConfirmed) {
                        this.loading = false;
                        this.$emit('flashListUpdated');
                        this.clearFlashSend();
                        return;
                    }

                    this.send({ id })
                        .then(() => {
                            this.loading   = false;
                            this.submitted = false;

                            this.resetSelectedFlashMessage();
                            Alert.success(this.$i18n.t('scheduled-send-success'));
                            this.clearFlashSend();
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                });
        },

        /**
         * Show the date and time section
         */
        showTimeSection () {
            this.shownSendTime = !this.shownSendTime;

            if (!this.shownSendTime) {
                this.flashMessage['planned-date'] = '';
                this.flashMessage['planned-time'] = '';
            }
        },

        /**
         * Clear all form inputs
         */
        clearFlashSend () {
            this.to               = [];
            this.message          = '';
            this.selectedTemplate = this.$i18n.t('choose-template');
            EventBus.$emit('clear-contact-list');
            this.resetSelectedFlashMessage();
            this.$bvModal.hide('flash-send-sidebar');
        }
    }
};
</script>
