<template>
    <div class="d-flex align-items-stretch">
        <navigation></navigation>
        <div id="config-folio-sms" class="container-fluid main-content">
            <div class="row pr-3 pl-2 mb-4">
                <div class="col-sm text-left">
                    <h3 class="section-title ">{{ $t("config-telnyx-connector") }}</h3>
                </div>
            </div>
            <SmsNumberToActivate></SmsNumberToActivate>
        </div>
    </div>
</template>

<script>

// Components
import SmsNumberToActivate from '@/connectors/sms/components/telnyx/SmsNumberToActivate';

export default {
    name:       'SmsConfigTelnyx',
    components: { SmsNumberToActivate }
};
</script>
