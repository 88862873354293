<template>
 <!-- v-if="visible" -->
<div class="modal" id="modal-result-config">
    <div class="modal-dialog modal-lg">
        <div class="modal-content px-5 py-3">
            <div class="modal-body body-result-config">
                <div id="content-pageResult-setup-connector" class="row">
                    <div class="text-right"
                        :class="{
                            'col-4 ml-3 ': result.status === '200',
                            'col-3': result.status !== '200'
                        }">
                        <img :class="{'img-oups': result.status !== '200'}"
                        :src="getImgUrl(result.status !== '200' ? 'oups-pink' : 'celebration')" />
                    </div>
                    <div :class="{
                            'col-7 ml-3': result.status === '200',
                            'col-9': result.status !== '200',
                        }">
                        <div class="paragr-result-of-config"
                            :class="{
                                'paragr-result-success': result.status === '200',
                                'paragr-result-fail': result.status !== '200'
                            }">
                            {{ result.status=== '200' ? $i18n.t('Congrats') : $i18n.t('fail') }}
                            <br/>
                            {{getMsgResultConfig()}}
                            <p v-if="result.status !== '200'" class="msg-error">
                            {{
                                result.code === '403'
                                ? $i18n.t("error-api-key")
                                : $i18n.t("error-config-sms")
                            }}
                            </p>
                        </div>
                        <div class="bloc-btn-page-result pt-2t text-left"
                            data-dismiss="modal"
                            :class="{
                                'pt-3': result.status === '200'
                            }">
                            <button class="btn btn-primary rounded-pill btn-close-afterConfig"
                                :class="{
                                    'ml-5': result.status !== '200'
                                }"
                                @click.prevent="onClickBtnResult">
                                {{ getNameBtn()}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        result:  Object,
        visible: Boolean
    },
    emits:   ['resultSetupEmit'],
    methods: {
        /**
         * Get the path of pic
         *
         * @param  {string}  pic  name pic
         * @param  {string}  extension  extension pic
         * @return {string}  path img
         */
        getImgUrl (pic, extension = 'png') {
            return require(`@/core/assets/images/${pic}.${extension}`);
        },

        /**
         * Get the button text
         *
         * @return {string}  name btn
         */
        getNameBtn () {
            switch (this.result.status) {
                case '403':
                    return `${this.$i18n.t('jump-to-step')} 3`;
                case '401':
                    return `${this.$i18n.t('jump-to-step')} 1`;
                default:
                    return this.$i18n.t('finish');
            }
        },

        /**
         * Emit event to parent
         *
         * @return {string}  name btn
         */
        onClickBtnResult () {
            this.$bvModal.hide('modal-result-config');
            this.$emit('resultSetupEmit');
        },

        /**
         * Get Msg to inform the user of the config result
         * @return {string}  paragraph of result
         */
        getMsgResultConfig () {
            switch (this.result.status) {
                case '200':
                    return `${this.$i18n.t('Account')} ${this.result.account} ${this.$i18n.t('valid')}.`;
                case '403':
                    return `${this.$i18n.t('cannot-communicate-API')}`;
                case '401':
                    return `${this.$i18n.t('incSms-config-incorrectly')}`;
                default:
                    if (this.result.errors) {
                        return this.result.errors[0].title;
                    }
            }
        }
    }
};
</script>
