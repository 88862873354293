<template>
    <div class="contact-card">
        <div class="modal" id="edit-user" v-observe-visibility="visibilityChanged">
            <div class="modal-dialog modal-lg">
                <div class="modal-content px-4 pb-4">
                    <div class="modal-header justify-content-center border-0 pb-0">
                        <h3 class="modal-title">{{ $t('profile') }}</h3>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="menu-tab mb-3">
                                <ul class="nav ml-4">
                                    <li class="nav-item information-tab" v-bind:class="{ 'selected': selectedProfile == 'information' }" @click="switchTab('information')">
                                        <a class="nav-link" data-toggle="pill">{{ $t('information') }}</a>
                                    </li>
                                    <li class="nav-item contact-info-tab" v-bind:class="{ selected: selectedProfile == 'contact' }" @click="switchTab('contact')">
                                        <a class="nav-link" data-toggle="pill">{{ $t('contact-info') }}</a>
                                    </li>
                                    <li class="nav-item password-tab" v-bind:class="{ selected: selectedProfile == 'password' }" @click="switchTab('password')">
                                        <a class="nav-link" data-toggle="pill">{{ $t('change-password') }}</a>
                                    </li>
                                    <li class="nav-item security-tab" v-bind:class="{ selected: selectedProfile == 'security' }" @click="switchTab('security')">
                                        <a class="nav-link text-btn" data-toggle="pill">{{ $t('security') }}</a>
                                    </li>
                                    <hr class="menu-line" :lang="`${$i18n.locale}`"/>
                                </ul>
                            </div>
                        </div>
                        <popover name="rules" id="rules">
                            <div class="alert alert-warning show-rules" role="alert">
                                <password-security-rules></password-security-rules>
                            </div>
                        </popover>
                        <!-- Information tab -->
                        <form class="user-information" v-if="selectedProfile === 'information'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="avatar form-group text-left d-flex">
                                            <label class="mb-0">
                                                <model-short-name class="text-center" v-if="profilePicture === null && previewPicture === null" v-bind:model="user"></model-short-name>
                                                <img class="contact-img" :src="previewPicture" alt="" v-if="previewPicture !== null">
                                                <img class="contact-img" :src="profilePicture" alt="" v-if="profilePicture !== null && previewPicture === null">
                                                <font-awesome-icon class="delete-icon" :icon="['fas', 'times-circle']" v-if="profilePicture !== null && previewPicture === null !== null" @click="deletePreviewPicture()"/>
                                            </label>
                                            <div class="form-group ml-4 mb-0 align-self-center">
                                                <label class="label-avatar mb-0" for="userAvatar">
                                                    <h6 class="text-primary mb-0">
                                                        {{ (profilePicture === null && previewPicture === null) ? $t('add-picture') : $t('change-picture') }}
                                                    </h6>
                                                </label>
                                                <input type="file" class="form-control-file" accept="image/*" ref="file" @change="previewImage()" id="userAvatar" hidden><br>
                                                <label class="label-avatar" @click="deletePreviewPicture()" v-if="profilePicture !== null && previewPicture != null"><small class="delete-avatar">{{ $t('cancel-change-picture') }}</small></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <input
                                            id="edit-first-name"
                                            name="first-name"
                                            type="text"
                                            v-validate="'required'"
                                            class="form-control"
                                            :class="{ 'is-invalid': submitted && errors.has('first-name') }"
                                            :placeholder="submitted && errors.has('first-name') ? errors.first('first-name') : ''"
                                            :data-vv-as="$t('first-name')"
                                            v-model="editedUser.attributes['first-name']"
                                            @keyup="checkForChanges()"
                                        >
                                        <p class="text-primary text-small text-left">{{ $t('first-name') }}</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input
                                            id="edit-last-name"
                                            name="last-name"
                                            type="text" v-validate="'required'"
                                            class="form-control"
                                            :class="{ 'is-invalid': submitted && errors.has('last-name') }"
                                            :placeholder="submitted && errors.has('last-name') ? errors.first('last-name') : ''"
                                            :data-vv-as="$t('last-name')"
                                            v-model="editedUser.attributes['last-name']"
                                            @keyup="checkForChanges()"
                                            >
                                        <p class="text-primary text-small text-left">{{ $t('last-name') }}</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group text-left mb-0">
                                            <input type="email" disabled class="form-control" v-model="editedUser.attributes.email">
                                        </div>
                                        <p class="text-primary text-small text-left mb-0">{{ $t('email') }}</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group text-left mb-0">
                                            <select-dropdown
                                                :options="languages"
                                                :selected="selectedLang.name"
                                                :showLabel="false"
                                                v-on:selectLanguage="changeLanguage($event)"
                                                :type="'language'">
                                            </select-dropdown>
                                            <p class="mb-0">{{ languages.name }}</p>
                                        </div>
                                        <p class="text-primary text-small text-left mb-0">{{ $t('language') }}</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- coordonee tab -->
                        <form class="user-information" v-if="selectedProfile === 'contact'" :key="componentKey">
                            <div class="container">
                                <div class="row">
                                    <div class="col-sm-6 mb-3">
                                        <input
                                            type="text"
                                            name="addressLine1"
                                            class="form-control"
                                            v-model="editedUser.attributes['address-line-1']"
                                            @keyup="checkForChanges()">
                                        <p class="text-primary text-small text-left">{{ $t('address-line-1') }}</p>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <input
                                            type="text"
                                            name="addressLine2"
                                            class="form-control"
                                            v-model="editedUser.attributes['address-line-2']"
                                            @keyup="checkForChanges()">
                                        <p class="text-primary text-small text-left">{{ $t('address-line-2') }}</p>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <input
                                            type="text"
                                            name="city"
                                            class="form-control"
                                            v-model="editedUser.attributes.city"
                                            @keyup="checkForChanges()">
                                        <p class="text-primary text-small text-left">{{ $t('city') }}</p>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <input
                                            type="text"
                                            name="province"
                                            class="form-control"
                                            v-model="editedUser.attributes['province-state']"
                                            @keyup="checkForChanges()">
                                        <p class="text-primary text-small text-left">{{ $t('state-province') }}</p>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <input
                                            type="text"
                                            name="country"
                                            class="form-control"
                                            v-model="editedUser.attributes.country"
                                            @keyup="checkForChanges()">
                                        <p class="text-primary text-small text-left">{{ $t('country') }}</p>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <input
                                            type="text"
                                            name="postalCode"
                                            class="form-control"
                                            v-model="editedUser.attributes['postal-code']"
                                            @keyup="checkForChanges()">
                                        <p class="text-primary text-small text-left">{{ $t('zip-code') }}</p>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <input
                                            type="text"
                                            name="company"
                                            class="form-control"
                                            v-model="editedUser.attributes.company"
                                            @keyup="checkForChanges()">
                                        <p class="text-primary text-small text-left">{{ $t('company') }}</p>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                    </div>
                                    <div class="col-sm-6">
                                        <input
                                            type="text"
                                            name="phone"
                                            class="form-control"
                                            v-model="editedUser.attributes['phone-number']"
                                            @keyup="checkForChanges()">
                                        <p class="text-primary text-small text-left mb-0">{{ $t('phone') }}</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input
                                            type="text"
                                            name="cellphone"
                                            class="form-control"
                                            v-model="editedUser.attributes['cell-phone']"
                                            @keyup="checkForChanges()">
                                        <p class="text-primary text-small text-left mb-0">{{ $t('cellphone') }}</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Password tab -->
                        <form class="user-password" v-if="selectedProfile === 'password'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <transition name="fade">
                                            <div class="alert alert-danger alert-profile alert-dismissible text-left fade show" role="alert" v-show="submitted && errors.has('new-password')" >
                                                <password-security-rules></password-security-rules>
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="password-info">
                                            <div class="form-group text-left">
                                                <div>
                                                    <label for="old-password" class="info-label w-100">
                                                        <input class="input-password form-control"
                                                            name="old-password"
                                                            v-validate="'required'"
                                                            :class="{ 'is-invalid': submitted && errors.has('old-password') }"
                                                            id="old-password" type="password"
                                                            :data-vv-as="$t('active-password')"
                                                            v-model="oldPassword"
                                                            :disabled="user.attributes['is-saml']"
                                                        >
                                                        <div class="invalid-feedback" v-if="submitted">
                                                            {{ errors.first('old-password') }}
                                                        </div>
                                                    </label>
                                                </div>
                                                <p class="text-primary text-small">{{ $t('active-password') }} <strong class="obligatory">*</strong></p>
                                            </div>
                                             <div class="form-group text-left">
                                                <div>
                                                    <label for="new-password" class="info-label w-100">
                                                        <input class="input-password form-control"
                                                            name="new-password"
                                                            ref="password"
                                                            v-validate= "'required|verify_password'"
                                                            :class="{ 'is-invalid': submitted && errors.has('new-password'), 'is-valid': submitted && !errors.has('new-password') }"
                                                            id="new-password"
                                                            type="password"
                                                            :data-vv-as="$t('new-password')"
                                                            v-model="newPassword"
                                                            :disabled="user.attributes['is-saml']"
                                                        >
                                                        <div class="invalid-feedback" v-if="submitted">
                                                            {{ errors.first('new-password') }}
                                                        </div>
                                                    </label>
                                                </div>
                                                <div class="text-primary text-small d-flex">
                                                    {{ $t('new-password') }} <strong class="obligatory">*</strong>
                                                    <div v-popover.right="{name:'rules'}" type="button" ref="rules" class="ml-3">
                                                        <font-awesome-icon class="text-primary info-circle" :icon="['fa', 'info-circle']" />
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="form-group text-left mb-0">
                                                <div>
                                                    <label for="password-confirm" class="info-label w-100">
                                                        <input class="input-password form-control"
                                                            name="confirm-password"
                                                            v-validate="'required|confirmed:password'"
                                                            :class="{ 'is-invalid': submitted && errors.has('confirm-password'), 'is-valid': submitted && !errors.has('confirm-password') && !errors.has('new-password') }"
                                                            id="confirm-password"
                                                            type="password"
                                                            :data-vv-as="$t('confirm-password')"
                                                            v-model="confirmPassword"
                                                            @paste.prevent
                                                            :disabled="user.attributes['is-saml']"
                                                        >
                                                        <div class="invalid-feedback" v-if="submitted">
                                                            {{ errors.first('confirm-password') }}
                                                        </div>
                                                    </label>
                                                </div>
                                                <p class="text-primary text-small mb-0">{{ $t('confirm-password') }} <strong class="obligatory">*</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Security tab -->
                        <div class="user-security" v-if="selectedProfile === 'security'">
                            <div class="container d-flex justify-content-center">
                                <div class="row p-4" v-if="!this.user.attributes['is-two-factor-enabled']">
                                    <h4 class="text-left text-black">{{ $t('security-title') }}</h4>
                                    <p class="text-left text-black fs-14">{{ $t('security-description') }}</p>
                                    <button
                                        class="btn btn-primary px-4 rounded-pill"
                                        data-toggle="modal"
                                        data-target="#activate-security"
                                        :disabled="user.attributes['is-saml']"
                                        @click="showTwoFactorsModal()"
                                    >
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loadingQrCode"></span>
                                        {{ $t('activate') }}
                                    </button>
                                </div>
                                <div class="d-flex justify-content-start flex-wrap mt-3" v-if="this.user.attributes['is-two-factor-enabled']">
                                    <p class="col-12 text-left">
                                        <font-awesome-icon class="text-primary font-check-circle mr-2" :icon="['fas', 'check-circle']" />
                                        <strong class="text-primary">{{ $t('security-active') }}</strong>
                                    </p>
                                    <p class="text-left col-12">{{ $t('security-active-description') }}</p>
                                    <br>
                                    <div class="col-12 d-flex justify-content-start">
                                        <button type="button" class="btn btn-danger rounded-pillv mt-4" @click="deactivateSecurity()">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                                            {{ $t('deactivate') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-0 justify-content-between">
                        <template   v-if="selectedProfile !== 'security'">
                            <button type="button" class="btn text-btn" data-dismiss="modal" id="close" ref="closeButton" @click="cancelChanges()">{{ $t('close') }}</button>
                            <button type="button" class="btn btn-primary rounded-pill" :disabled="!modified" @click="submitChanges()">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                                {{ $t('save') }}
                            </button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <activate-security-user :loadingQrCode="loadingQrCode" ></activate-security-user>
    </div>
</template>
<script>
import Vue from 'vue';

// Components
import ActivateSecurityUser   from './ActivateSecurityUser.vue';
import PasswordSecurityRules  from '../password/PasswordSecurityRules.vue';
import ModelShortName         from '../../ModelShortName.vue';
import SelectDropdown         from '../../../components/SelectDropdown.vue';

// Internal services js
import Alert        from '../../../../helpers/Alert.js';
import { EventBus } from '../../../../helpers/EventBus.js';

// External libraries
import HttpStatus               from 'http-status-codes';
import { mapState, mapActions } from 'vuex';
const clone = require('rfdc')();

export default {
    components: {
        PasswordSecurityRules,
        ActivateSecurityUser,
        ModelShortName,
        SelectDropdown
    },

    data () {
        return {
            securityStep:    0,
            securitycode:    '',
            id:              '',
            previewPicture:  null,
            profilePicture:  null,
            oldPassword:     '',
            newPassword:     '',
            confirmPassword: '',
            loading:         false,
            loadingQrCode:   false,
            submitted:       false,
            modified:        false,
            selectedProfile: 'information',
            selectedLang:    {
                locale: '',
                name:   ''
            },
            languages: [
                {
                    locale: 'fr',
                    name:   'Français'
                },
                {
                    locale: 'en',
                    name:   'English'
                }
            ],
            editedUser:   null,
            componentKey: 0
        };
    },

    computed: {
        ...mapState('core/auth', [
            'user'
        ])
    },

    watch: {
        user () {
            this.languageIdentifier();
        },
        oldPassword () {
            if (this.oldPassword !== '') {
                this.modified = true;
            } else {
                this.checkForChanges();
            }
        },
        newPassword () {
            if (this.newPassword !== '') {
                this.modified = true;
            } else {
                this.checkForChanges();
            }
        },
        confirmPassword () {
            if (this.confirmPassword !== '') {
                this.modified = true;
            } else {
                this.checkForChanges();
            }
        },
        selectedLang () {
            if (this.selectedLang.locale !== this.user.attributes.language) {
                this.modified = true;
            } else {
                this.checkForChanges();
            }
        }
    },

    created () {
        this.editedUser = clone(this.user);
    },

    mounted () {
        if (this.user) {
            this.id = this.user.id;

            // If user have a profile picture
            if (this.user.attributes['profile-picture']) {
                // Use timestamp (Date.now()) show the new image.
                this.profilePicture = `${this.user.attributes['profile-picture']}?${Date.now()}`;
            }
            this.languageIdentifier();
            this.informationUser();
        }
    },
    methods: {
        ...mapActions('core/session', [
            'setSessionUser'
        ]),

        ...mapActions('core/auth', [
            'enableTwoFactorAuthentication',
            'getAuthenticatedUser',
            'disableTwoFactorAuthentication'
        ]),

        ...mapActions('core/users', [
            'updloadProfilePicture',
            'deleteProfilePicture',
            'patch'
        ]),

        /**
         * Compare User and editedUser to find if something has changed to activate the submit button
         */
        checkForChanges () {
            if (JSON.stringify(this.user.attributes) === JSON.stringify(this.editedUser.attributes)) {
                this.modified = false;
            } else {
                this.modified = true;
            }
        },

        /**
         * Change language in dropdown select.
         */
        changeLanguage (languages) {
            this.selectedLang = languages;
        },

        /**
         * Preview an image before it is uploaded
         */
        previewImage () {
            if (this.$refs.file.files[0]) {
                this.previewPicture = URL.createObjectURL(this.$refs.file.files[0]);
                this.modified = true;
            }
        },

        /**
         * Delete the preview picture if exist.
         */
        deletePreviewPicture () {
            if (this.$refs.file.files[0]) {
                this.previewPicture = null;
                this.$refs.file.value = '';
                return;
            }

            this.deleteAvatar();
        },

        /**
         * Set editedUser (user clone) information in method.
         */
        informationUser () {
            this.editedUser.attributes.email;
            this.editedUser.attributes['first-name'];
            this.editedUser.attributes['last-name'];
            this.editedUser.attributes['address-line-1'];
            this.editedUser.attributes['address-line-2'];
            this.editedUser.attributes.city;
            this.editedUser.attributes['province-state'];
            this.editedUser.attributes.country;
            this.editedUser.attributes['postal-code'];
            this.editedUser.attributes.company;
            this.editedUser.attributes['phone-number'];
            this.editedUser.attributes['cell-phone'];
        },

        /**
         * Cancel all changes
         */
        cancelChanges () {
            if (this.user.attributes['profile-picture']) {
                // Use timestamp (Date.now()) show the new image.
                this.profilePicture = `${this.user.attributes['profile-picture']}?${Date.now()}`;
            }

            this.informationUser();
            this.oldPassword         = '';
            this.newPassword         = '';
            this.confirmPassword     = '';
            this.selectedLang.locale = this.user.attributes.language;
            this.languageIdentifier();
            this.securityStep = 0;
            this.selectedProfile = 'information';
        },

        /**
         * Reset form with initial user informations if modal is closed without saving changes.
         * This function is triggered when the modal is shown or hidden.
         * isVisible is used to tell if the modal is visible or not.
         */
        visibilityChanged (isVisible) {
            if (!isVisible) {
                this.oldPassword         = '';
                this.newPassword         = '';
                this.confirmPassword     = '';
                this.editedUser          = clone(this.user);
                this.resetValidation();
                this.securityStep = 0;
                this.selectedProfile = 'information';
            }
        },

        /**
         * Reset validation so thet we don't see errors after closing modal and reopening it
         */
        resetValidation () {
            this.$validator.reset();
            this.errors.clear();
            this.submitted = false;
            this.componentKey++;
        },

        /**
         * Switch tab according to link clicked
         */
        switchTab (tab) {
            this.selectedProfile = tab;
            this.resetValidation();
        },

        /**
         * Upload image for profile
         */
        uploadImage () {
            Vue.prototype.$validation = this.$validator;
            this.submitted = true;
            this.loading = true;

            if (!this.$refs.file.files[0]) {
                this.validateInformationForm();
                return;
            }

            // Upload the image selected by user.
            this.updloadProfilePicture({
                id:    this.id,
                image: this.$refs.file.files[0]
            })
                .then(() => {
                    // Use timestamp (Date.now()) to show the new image.
                    this.profilePicture = `${this.user.attributes['profile-picture']}?${Date.now()}`;
                    this.previewPicture = null;
                    this.setSessionUser({ user: this.user });

                    this.validateInformationForm();
                })
                .catch((error) => {
                    if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                        Alert.fail(this.$i18n.t('must-be-image'));
                        this.submitted = false;
                        this.loading   = false;
                        this.deletePreviewPicture();
                    }
                });
        },

        /**
         * Delete user picture
         */
        deleteAvatar () {
            Alert.deleteConfirmation(this.$i18n.t('confirm-delete-picture'))
                .then((response) => {
                    if (!response.value) {
                        return;
                    }
                    this.loading = true;
                    this.profilePicture = null;

                    this.deleteProfilePicture({ id: this.id })
                        .then(() => {
                            this.setSessionUser({ user: this.user });
                            this.loading = false;
                            this.submitted = false;
                            EventBus.$emit('informationsUpdated');
                        });
                });
        },

        /**
         * Validate information form
         * Save new information in attributes object variable
         * Send attribute variable with contact information to API
         *
         * Basic profile information
         * Information tab
         */
        validateInformationForm () {
            this.loading = true;
            this.$validator.validate().then(valid => {
                if (!valid) {
                    this.loading = false;
                    return;
                }

                // Set use's new informations to an object attributes
                const attributes = {
                    'first-name':      this.editedUser.attributes['first-name'],
                    'last-name':       this.editedUser.attributes['last-name'],
                    'profile-picture': this.profilePicture,
                    'language':        this.selectedLang.locale
                };

                // Change the i18n locale
                this.$i18n.locale = attributes.language;

                // Go to the translated route
                if (this.$route.meta.locale !== 'undefined' && this.$route.meta.locale !== attributes.language) {
                    // Change page URL
                    this.$router.push({ name: this.$route.name.replace(this.$route.meta.local, attributes.language) });
                }

                this.sendModificationRequestToApi(attributes);
            });
        },

        /**
         * Validate contact information form
         * Save new information in attributes object variable
         * Send attribute variable with contact information to API
         *
         * Contact information
         * Contct information tab
         */
        validateContacts () {
            this.loading = true;
            this.$validator.validate().then(valid => {
                if (!valid) {
                    this.loading = false;
                    return;
                }

                // Set use's new informations to an object attributes
                const attributes = {
                    'address-line-1': this.editedUser.attributes['address-line-1'],
                    'address-line-2': this.editedUser.attributes['address-line-2'],
                    'city':           this.editedUser.attributes.city,
                    'company':        this.editedUser.attributes.company,
                    'country':        this.editedUser.attributes.country,
                    'phone-number':   this.editedUser.attributes['phone-number'],
                    'cell-phone':     this.editedUser.attributes['cell-phone'],
                    'postal-code':    this.editedUser.attributes['postal-code'],
                    'province-state': this.editedUser.attributes['province-state']
                };
                this.sendModificationRequestToApi(attributes);
            });
        },

        /**
         * Validate change password form
         * Save new information in attribute object variable
         * Send attribute variable with contact information to API
         *
         * Old password confirmation
         * New password information
         * change password tab
         */
        validatePassword () {
            if (this.user.attributes['is-saml']) {
                return;
            }

            this.submitted = true;
            this.loading = true;
            this.$validator.validate().then(valid => {
                if (!valid) {
                    this.loading = false;
                    return;
                }

                // If user want to change the password, we add old, new and confirm password to attributes
                const attributes = {
                    'old-password':          this.oldPassword,
                    'password':              this.newPassword,
                    'password-confirmation': this.confirmPassword
                };
                this.sendModificationRequestToApi(attributes);
            });
        },

        /**
         * Send request with user id and attributes to the api
         *
         * @param    {object}    attributes    The new user informations
         */
        sendModificationRequestToApi (attributes) {
            this.patch({
                id: this.user.id,
                attributes
            })
                .then(() => {
                    this.successEdit();

                    // When getting the form saved, we sould always empty passwords fields
                    this.oldPassword = '';
                    this.newPassword = '';
                    this.confirmPassword = '';
                })
                .catch((errors) => {
                    if (errors.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                        this.loading = false;
                        this.submitted = false;
                        Alert.fail(this.$i18n.t('error-change-password'));
                    }
                });
        },

        /**
         * The success callback of the edit method
         *
         * @param    {object}    $request    The request sent
         *
         * @return    void
         */
        successEdit () {
            this.loading   = false;
            this.submitted = false;
            this.modified = false;
            Alert.success(this.$i18n.t('information-updated'));
            EventBus.$emit('informationsUpdated');
        },

        /**
         * Select contact info to update
         */
        submitChanges () {
            if (this.selectedProfile === 'information') {
                this.uploadImage();
            } else if (this.selectedProfile === 'contact') {
                this.validateContacts();
            } else {
                this.validatePassword();
            }
        },

        /**
         * Identify languange at login
         */
        languageIdentifier () {
            if (this.user.attributes.language === 'fr') {
                this.selectedLang = this.languages[0];
            }
            else {
                this.selectedLang = this.languages[1];
            }
        },

        /**
         * Deactivate the security
         */
        deactivateSecurity () {
            Alert.confirmation(this.$i18n.t('security-confirmation-desc'), this.$i18n.t('deactivate'))
                .then((result) => {
                    if (result.isConfirmed) {
                        this.loading = true;

                        this.disableTwoFactorAuthentication()
                            .then(() => {
                                this.getAuthenticatedUser()
                                    .then(() => {
                                        this.loading = false;
                                    });
                            });
                    }
                });
        },

        /**
         * Enable 2FA and show the modal
         */
        showTwoFactorsModal () {
            if (this.user.attributes['is-saml']) {
                return;
            }

            this.loadingQrCode = true;
            this.enableTwoFactorAuthentication()
                .then(() => {
                    this.loadingQrCode = false;
                });
        }
    }

};
</script>
