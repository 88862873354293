import Api       from '@/helpers/Api.js';
import normalize from 'json-api-normalizer';
import qs        from 'qs';
import _         from 'underscore';
import Vue       from 'vue';

// Import mutations
import {
    SET_SELECTED_TENANT,
    SET_USER_TENANTS,
    UPDATE_TENANT_PICTURE,
    DELETE_TENANT_PICTURE,
    UPDATE_TENANT
} from './mutation-types.js';

const resource = '/internal/v1/tenants';

// Initial state
const state = () => ({
    selectedTenant: null,
    userTenants:    [],
});

// Getters
const getters = {};

// Actions
const actions = {
    /**
     * Get list of tenants (workspaces) for the current user.
     *
     * @param      {Object}  context         The context
     *
     * @return     {Promise}
     */
    tenants: (context, payload) => {
        const urlParams = {};

        if (payload) {
            urlParams.sort   = payload.sort ? payload.sort : void 0;
            urlParams.filter = payload.filters ? payload.filters : void 0;
        }

        return new Promise((resolve, reject) => {
            Api.get(`${context.rootState.core.auth.userLink}/tenants`, {
                params:           urlParams,
                paramsSerializer: params => qs.stringify(params, { encode: false })
            })
                .then(response => {
                    const normalizedData = (response.data !== null && response.data.data.length !== 0) ? normalize(response.data, { camelizeKeys: false }).tenants : null;
                    context.commit(SET_USER_TENANTS, normalizedData);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Select tenant from the list of the tenants
     * linked to the user.
     *
     * @param      {Object}  context     The context
     * @param      {Object}  payload.id  The tenant identifier
     *
     * @return     {Promise}
     */
    selectUserTenant: (context, { id }) => {
        const selectedTenant = _.find(context.state.userTenants, (tenant) => {
            return tenant.id === id;
        });

        return new Promise((resolve, reject) => {
            Api.get(`${resource}/${id}/select`)
                .then(() => {
                    context.commit(SET_SELECTED_TENANT, selectedTenant);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Upload image for the tenant.
     *
     * @param      {Object}  context        The context
     * @param      {string}  payload.id     The tenant identifier
     * @param      {string}  payload.image  The image
     *
     * @return     {Promise}
     */
    updloadTenantPicture: (context, { id, image }) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('profile-picture', image);

            Api.post(`${resource}/${id}/picture`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    context.commit(UPDATE_TENANT_PICTURE, { id, ...response.data.data });
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Delete image for the tenant.
     *
     * @param      {Object}  context     The context
     * @param      {string}  payload.id  The tenant identifier
     *
     * @return     {Promise}
     */
    deleteTenantPicture: (context, { id }) => {
        return new Promise((resolve, reject) => {
            Api.delete(`${resource}/${id}/picture`)
                .then(() => {
                    context.commit(DELETE_TENANT_PICTURE, { id, ...{ 'profile-picture': null } });
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Update workspace
     *
     * @param      {Object}  context      The context
     * @param      {string}  payload.id   The tenant identifier
     * @param      {string}  payload.name name
     *
     * @return     {Promise}
     */
    update: (context, { id, name }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/${id}`, {
                'data': {
                    'type':       'tenants',
                    'id':         id,
                    'attributes': {
                        'name': name
                    }
                }
            })
                .then((response) => {
                    context.commit(UPDATE_TENANT, response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Select tenant from the list of the tenants
     * linked to the user.
     *
     * @param      {Object}  context     The context
     * @param      {Object}  payload.id  The tenant identifier
     *
     * @return     {Promise}
     */
    selectSamlUserTenant: (context, { id }) => {
        return new Promise((resolve) => {
            const selectedTenant = _.find(context.state.userTenants, (tenant) => {
                return tenant.id === id;
            });

            context.commit(SET_SELECTED_TENANT, selectedTenant);
            resolve();
        });
    },
};

// Mutations
const mutations = {
    [SET_SELECTED_TENANT]: (state, payload) => {
        state.selectedTenant = payload;
    },
    [SET_USER_TENANTS]: (state, payload) => {
        state.userTenants = payload;
    },
    [UPDATE_TENANT_PICTURE]: (state, payload) => {
        // Vue set to update an object, Vue.set(object, key, newValue)
        Vue.set(state.selectedTenant.attributes, 'profile-picture', payload.links['profile-picture']);
        Vue.set(state.userTenants[payload.id].attributes, 'profile-picture', payload.links['profile-picture']);
    },
    [DELETE_TENANT_PICTURE]: (state, payload) => {
        state.selectedTenant.attributes['profile-picture'] = null;
        state.userTenants[payload.id].attributes['profile-picture'] = null;
    },
    [UPDATE_TENANT]: (state, payload) => {
        state.selectedTenant = payload;
        Vue.set(state.userTenants, payload.id, payload);
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
