/**
 * Translated routes file
 * Locale: fr
 */

// Core routes
import AllContacts             from '@/core/components/contacts/AllContacts.vue';
import AllMessages             from '@/core/components/AllMessages.vue';
import ConnectorsPage          from '@/core/components/ConnectorsPage.vue';
import ForgotPassword          from '@/core/components/security/password/ForgotPassword.vue';
import HomePage                from '@/core/components/HomePage.vue';
import HelpPage                from '@/core/components/HelpPage.vue';
import ResetPassword           from '@/core/components/security/password/ResetPassword.vue';
import SingleContact           from '@/core/components/contacts/ContactPage.vue';
import Tags                    from '@/core/components/contacts/Tags.vue';
import Reports                 from '@/core/components/Reports.vue';
import AutoResponse            from '@/core/components/AutoResponse.vue';
import MessageTemplate         from '@/core/components/MessageTemplate.vue';
import Tenants                 from '@/core/components/Tenants.vue';
import TenantUsers             from '@/core/components/TenantUsers.vue';
import WorkspaceSettings       from '@/core/components/WorkspaceSettings.vue';
import SamlLogin               from '@/core/components/security/user/saml/SamlLogin.vue';
import twoFactorsLogin         from '@/core/components/security/user/twoFactors/loginForm.vue';

// Facebook connector routes
import FacebookConfig          from '@/connectors/facebook/components/FacebookConfiguration.vue';
import FacebookDataDeletion    from '@/connectors/facebook/components/FacebookDataDeletion.vue';
import FacebookConnector       from '@/connectors/facebook/components/FacebookConnector.vue';
import FacebookPage            from '@/connectors/facebook/components/FacebookRemotePage.vue';

// SMS connector routes
import SmsConnector            from '@/connectors/sms/components/SmsConnector.vue';
import SmsConfigConnectors     from '@/connectors/sms/components/SmsConfigConnectors.vue';
import SmsConfigFolio          from '@/connectors/sms/components/folio/SmsConfigFolio.vue';
import SmsConfigVoip           from '@/connectors/sms/components/voip/SmsConfigVoip.vue';
import SmsConfigTelnyx         from '@/connectors/sms/components/telnyx/SmsConfigTelnyx.vue';
import SmsConfigurationFolio   from '@/connectors/sms/components/folio/ConfigFolio.vue';
import SmsConfigurationVoip    from '@/connectors/sms/components/voip/ConfigVoip.vue';
import SmsConfigurationTelnyx  from '@/connectors/sms/components/telnyx/ConfigTelnyx.vue';
import SmsConfigTwilio         from '@/connectors/sms/components/twilio/SmsConfigTwilio.vue';
import ScheduledMessages       from '@/connectors/sms/messages/scheduled/MainPage.vue';

// Vue-i18n
import i18n from '../libraries/I18n.js';

export default [
    {
        path:      '/saml-connexion',
        name:      'saml-login:fr',
        component: SamlLogin,
        meta:      {
            title:  i18n.t('saml-login', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/2fa-connexion',
        name:      '2fa-login:fr',
        component: twoFactorsLogin,
        meta:      {
            title:  i18n.t('2fa-login', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/mot-de-passe-oublie',
        name:      'forgot:fr',
        component: ForgotPassword,
        meta:      {
            title:  i18n.t('password-forgot', 'fr'),
            locale: 'fr'
        }
    },
    {
        path: 'https://www.cocoapp.com/forfaits/',
        name: 'cocoapp-wordpress-packages:fr'
    },
    {
        path: 'https://www.cocoapp.com/',
        name: 'cocoapp-website:fr'
    },
    {
        path:      '/facebook',
        name:      'facebook-connector:fr',
        component: FacebookConnector,
        meta:      {
            title:  i18n.t('facebook', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/messages',
        name:      'all-messages:fr',
        component: AllMessages,
        meta:      {
            title:  i18n.t('all-message', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/contacts',
        name:      'contact-list:fr',
        component: AllContacts,
        meta:      {
            title:  i18n.t('contact-list', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/etiquettes',
        name:      'tags:fr',
        component: Tags,
        meta:      {
            title:  i18n.t('tags', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/rapports',
        name:      'reports:fr',
        component: Reports,
        meta:      {
            title:  i18n.t('reports', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/autoreponse',
        name:      'autoResponse:fr',
        component: AutoResponse,
        meta:      {
            title:  i18n.t('auto-response', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/contact',
        name:      'contact:fr',
        component: SingleContact,
        meta:      {
            title:  i18n.t('contact', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/utilisateurs',
        name:      'user:fr',
        component: TenantUsers,
        meta:      {
            title:  i18n.t('user', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/accueil',
        name:      'home:fr',
        component: HomePage,
        meta:      {
            title:  i18n.t('home', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/aide',
        name:      'help:fr',
        component: HelpPage,
        meta:      {
            title:  i18n.t('help', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/reinitialiser-mot-de-passe',
        name:      'reset-password:fr',
        component: ResetPassword,
        meta:      {
            title:  i18n.t('reset-password', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/enregistrer',
        name:      'register-password:fr',
        component: ResetPassword,
        meta:      {
            title:  i18n.t('register-password', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/facebook/pages',
        name:      'facebook-pages:fr',
        component: FacebookPage,
        meta:      {
            title:  i18n.t('connect-page', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/sms/configuration',
        name:      'config-sms-connectors:fr',
        component: SmsConfigConnectors,
        meta:      {
            title:  i18n.t('config-sms-connectors', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/facebook/configuration',
        name:      'facebook-first-config:fr',
        component: FacebookConfig,
        meta:      {
            title:  i18n.t('facebook-configuration', 'fr'),
            locale: 'fr',
            first:  true
        }
    },
    {
        path:      '/facebook/configuration',
        name:      'facebook-config:fr',
        component: FacebookConfig,
        meta:      {
            title:  i18n.t('facebook-configuration', 'fr'),
            locale: 'fr',
            first:  false
        }
    },
    {
        path:      '/espaces-de-travail',
        name:      'tenant-list:fr',
        component: Tenants,
        meta:      {
            title:  i18n.t('tenants', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/connecteurs',
        name:      'connectors-page:fr',
        component: ConnectorsPage,
        meta:      {
            title:  i18n.t('connectors-title', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/parametres',
        name:      'workspace-settings:fr',
        component: WorkspaceSettings,
        meta:      {
            title:  i18n.t('workspace-settings', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/sms',
        name:      'sms-connector:fr',
        component: SmsConnector,
        meta:      {
            title:  i18n.t('conversations', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/gabarits',
        name:      'template:fr',
        component: MessageTemplate,
        meta:      {
            title:  i18n.t('template', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/sms/configuration/foliomedian',
        name:      'foliomedian-activated:fr',
        component: SmsConfigFolio,
        meta:      {
            title:  i18n.t('sms-configuration', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/sms/configuration/voip',
        name:      'voip-activated:fr',
        component: SmsConfigVoip,
        meta:      {
            title:  i18n.t('sms-configuration', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/sms/configuration/telnyx',
        name:      'telnyx-activated:fr',
        component: SmsConfigTelnyx,
        meta:      {
            title:  i18n.t('sms-configuration', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/data-suppression',
        name:      'data-deletion:fr',
        component: FacebookDataDeletion,
        meta:      {
            title:  i18n.t('data-deletion', 'fr'),
            locale: 'fr',
            auth:   false
        }
    },
    {
        path:      '/sms/configuration/twilio',
        name:      'twilio-activated:fr',
        component: SmsConfigTwilio,
        meta:      {
            title:  i18n.t('sms-configuration', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/sms/configuration/folio',
        name:      'foliomedian-config:fr',
        component: SmsConfigurationFolio,
        meta:      {
            title:  i18n.t('sms-configuration', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/sms/configuration/telnyx',
        name:      'telnyx-config:fr',
        component: SmsConfigurationTelnyx,
        meta:      {
            title:  i18n.t('sms-configuration', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/sms/configuration/voipms',
        name:      'voip-config:fr',
        component: SmsConfigurationVoip,
        meta:      {
            title:  i18n.t('sms-configuration', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/sms/configuration/twilio',
        name:      'sms-config:fr',
        component: SmsConfigTwilio,
        meta:      {
            title:  i18n.t('sms-configuration', 'fr'),
            locale: 'fr'
        }
    },
    {
        path:      '/sms/envoi-massif',
        name:      'massive-send:fr',
        component: ScheduledMessages,
        meta:      {
            title:    i18n.t('massive-send', 'fr'),
            locale:   'fr',
            category: 'mass'
        }
    },
    {
        path:      '/sms/envoi-eclair',
        name:      'flash-send:fr',
        component: ScheduledMessages,
        meta:      {
            title:    i18n.t('flash-send', 'fr'),
            locale:   'fr',
            category: 'flash'
        }
    }
];
