import Api       from '@/helpers/Api.js';
import normalize from 'json-api-normalizer';
import qs        from 'qs';

// Import mutations
import { SET_MESSAGES } from './mutation-types.js';

const resource  = '/connectors/sms/v1/messages';

// Initial state
const state = () => ({
    messages:        [],
    smsConversation: null
});

// Getters
const getters = {
    /**
     * Get messages
     *
     * @param {Object} state The state
     *
     * @return {Object}
     */
    messageList: (state) => {
        return state.messages;
    },

    /**
     * Get conversation
     *
     * @param {Object} state The state
     *
     * @return {Object}
     */
    smsConverstation: (state) => {
        return state.smsConversation;
    }
};

// Actions
const actions = {
    create: (context,  { phoneNumber, sms }) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`, {
                'data': {
                    'type':       'messages',
                    'attributes': {
                        'to':      phoneNumber,
                        'content': sms
                    }
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * List SMS received to number
     *
     * @param   {Object}  context  The context
     * @param   {String}  phoneNumber active sms phone number
     *
     * @return  {Promise}
     */
    index: (context, payload) => {
        const urlParams = {};
        if (payload) {
            urlParams.sort   = payload.sort ? payload.sort : void 0;
            urlParams.filter = payload.filters ? payload.filters : void 0;
        }
        return new Promise((resolve, reject) => {
            Api.get(`${resource}`,{
                params:           urlParams,
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                }),
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_MESSAGES, normalizedData.messages);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {
    [SET_MESSAGES]: (state, payload) => {
        if (payload != void 0) {
            state.messages = payload;
        } else {
            state.messages = [];
        }
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
