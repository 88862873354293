<template>
    <div id="app" :class="background">
        <router-view></router-view>
    </div>
</template>

<script>

import moment from 'moment';

export default {
    name:       'app',
    components: {},

    computed: {
        background () {
            return this.$router.currentRoute.name.includes('not-found') ? 'not-found-background' : 'coco-background';
        }
    },

    /**
     * Function to add link in the html head
     *
     * @return     {Object}
     */
    head () {
        let image = 'favicon.ico';
        if (process.env.VUE_APP_ENV === 'staging') {
            image = '/images/staging-badge.png';
        } else if (process.env.VUE_APP_ENV === 'local') {
            image = '/images/local-badge.png';
        }
        return {
            link: [{
                rel:  'icon', href: image, size: '32x32', type: 'image/png'
            }]
        };
    },

    /**
     * Before the component is created
     */
    beforeCreate () {
        /**
         * If the route has a specific locale, use it
         * Will get overridden by the User's locale, if logged in
         */
        if (this.$route.meta.locale !== 'undefined') {
            this.$i18n.locale = this.$route.meta.locale;
            moment.locale(this.$route.meta.locale);
        }
    }
};
</script>

<style>
    @import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
</style>
