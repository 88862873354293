<template>
    <div id="single-contact" class="d-flex align-items-stretch">
        <navigation></navigation>
        <contactcontent
            v-on:hideSidebar="collapseSidebar = false"
            v-on:showSidebar="collapseSidebar = true"
            v-bind:collapseSidebar="collapseSidebar"
            v-bind:activePageId="pageId"
            v-bind:contactInteractions="interactionsFilterBy"
            v-bind:loading="loading"
            v-bind:singleContactAllInteractions="contactAllInteractions"
        ></contactcontent>
        <contactsidebar
            v-bind:collapseSidebar="collapseSidebar"
            v-bind:contactTags="contactTags"
            v-bind:contact="contact"
        ></contactsidebar>
    </div>
</template>
<script>

// Components
import ContactSidebar from './SingleContactSidebar.vue';
import ContactContent from './ContactContent.vue';

// Internal services js
import { RepositoryFactory } from '../../../repositories/repositoryFactory.js';

// External libraries
import HttpStatus   from 'http-status-codes';
import { mapState, mapActions } from 'vuex';

const FacebookContacts  = RepositoryFactory.get('FacebookContacts');
const FacebookPages = RepositoryFactory.get('FacebookPages');

// Import Really Fast Deep Copy
const clone = require('rfdc')();

export default {
    components: {
        'contactsidebar': ContactSidebar,
        'contactcontent': ContactContent
    },
    data () {
        return {
            loading:                true,
            collapseSidebar:        true,
            contactTags:            [],
            interactions:           [],
            allInteractions:        [],
            primaryPosts:           [],
            interactionsFilterBy:   [],
            filterById:             '',
            replyComments:          [],
            authorsParent:          [],
            contactId:              '',
            pageId:                 '',
            contactAllInteractions: []
        };
    },

    computed: {
        ...mapState('core/connectors', [
            'facebook'
        ]),

        ...mapState('core/contacts', {
            contact:                      'single',
            singleContactAllInteractions: 'singleContactAllInteractions'
        })
    },

    mounted () {
        // Get contact id from session after set it by AllContacts page.
        if (!this.contact) {
            this.$router.replace({ name: `contact-list:${this.$i18n.locale}` });
            return;
        }

        // Get all interactions
        this.getSingleAllInteractions({ id: this.contact.id }).then(() => {
            this.setAllInteractions(this.singleContactAllInteractions);

            // Get the contact interactions
            this.getSingleContactInteractions();
        });

        // Get the active pages
        if (this.facebook.isConnected) {
            FacebookPages.index()
                .then(request => this.setPage(request));
        }
    },

    methods: {
        ...mapActions('core/contacts', { getSingleAllInteractions: 'getSingleAllInteractions' }),

        /**
         * Show the active page
         */
        setPage (request) {
            this.pageId = request.data && request.data.data.length > 0 ? request.data.data[0].id ?? '' : '';
        },

        /**
         * Get the single contact interactions
         */
        getSingleContactInteractions () {
            if (this.facebook.isConnected) {
                FacebookContacts.getInteractions(this.contact.id)
                    .then(request => this.setInteractions(request))
                    .catch((error) => {
                        if (error.response.status === HttpStatus.NOT_FOUND) {
                            this.loading = false;
                        }
                    });
            }
        },

        /**
         * Set interactions of the active page in interactions table
         * Set posts of interaction in primaryPosts table
         *
         * @param {object}  request    The api request
         *
         */
        setInteractions (request) {
            if (!request.data.data.length) {
                this.loading = false;
                return;
            }
            this.interactions = request.data.data;
            for (let i = 0; i < request.data.included.length; i++) {
                if (request.data.included[i].type === 'contacts') {
                    this.authorsParent.push(request.data.included[i]);
                } else if (request.data.included[i].type === 'posts') {
                    this.primaryPosts.push(request.data.included[i]);
                } else {
                    this.replyComments.push(request.data.included[i]);
                }
            }
            this.cuttingInteractions(this.interactions);
        },

        /**
         * Set Facebook interactions of the active page in interactions table
         * Set posts of interaction in primaryPosts table
         *
         * @param {object}  request    The api request
         *
         */
        setAllInteractions (request) {
            if (!request.data.data.length) {
                this.loading = false;
                return;
            }
            this.allInteractions = request.data.data;
            if (request.data.included) {
                for (let i = 0; i < request.data.included.length; i++) {
                    if (request.data.included[i].attributes.connector === 'facebook') {
                        if (request.data.included[i].type === 'contacts') {
                            this.authorsParent.push(request.data.included[i]);
                        } else if (request.data.included[i].type === 'posts') {
                            this.primaryPosts.push(request.data.included[i]);
                        } else {
                            this.replyComments.push(request.data.included[i]);
                        }
                    }
                }
            }
            this.cuttingAllInteractions(this.allInteractions);
        },

        /**
         * Cutting the interactions
         * Set the single contact like author in the interactions
         * Set the primary post to the interactions
         *
         * @param   {array}    interactions     the contact interactions
         */
        cuttingInteractions (interactions) {
            for (let i = 0; i < interactions.length; i++) {
                this.$set(interactions[i], 'author', this.contact);

                interactions[i].post = this.primaryPosts.find(post => post.id === interactions[i].relationships.post.data.id);

                if (interactions[i].relationships.parent.data !== null) {
                    interactions[i].parent = this.replyComments.find(parent => parent.id === interactions[i].relationships.parent.data.id);

                    interactions[i].authorParent = this.authorsParent.find(authorParent => authorParent.id === interactions[i].parent.relationships.author.data.id);
                }

                if (interactions[i].relationships.reply.data !== null) {
                    interactions[i].reply = this.replyComments.find(reply => reply.id === interactions[i].relationships.reply.data.id);
                }
            }

            // Finish loading and send the full interactions to the next component to show it
            this.loading = false;
            this.interactionsFilterBy = interactions;
        },

        /**
         * Cutting the Facebook interactions
         * Set the single contact like author in the interactions
         * Set the primary post to the interactions
         *
         * @param   {array}    interactions     the contact interactions
         */
        cuttingAllInteractions (stateInteractions) {
            let interactions = clone(stateInteractions);
            for (let i = 0; i < interactions.length; i++) {
                if (interactions[i].attributes.connector == 'facebook') {
                    this.$set(interactions[i], 'author', this.contact);

                    interactions[i].post = this.primaryPosts.find(post => post.id === interactions[i].relationships.post.data.id);

                    if (interactions[i].relationships.parent.data !== null) {
                        interactions[i].parent = this.replyComments.find(parent => parent.id === interactions[i].relationships.parent.data.id);

                        interactions[i].authorParent = this.authorsParent.find(authorParent => authorParent.id === interactions[i].parent.relationships.author.data.id);
                    }

                    if (interactions[i].relationships.reply.data !== null) {
                        interactions[i].reply = this.replyComments.find(reply => reply.id === interactions[i].relationships.reply.data.id);
                    }
                }
            }

            // Finish loading and send the full interactions to the next component to show it
            this.loading = false;
            this.contactAllInteractions = interactions;
        },

        /**
         * If the select option change we change the showing interactions
         * event.target.value is the option value.
         *
         * @param   {string}    option    The selected option
         */
        changeFilterBy (option) {
            this.loading = true;
            this.filterById = option;

            if (this.filterById === 'all') {
                this.interactionsFilterBy = this.interactions;
                this.loading = false;
            } else if (this.facebook.isConnected) {
                FacebookContacts.getInteractions(this.contactId, { category: this.filterById })
                    .then((request) => this.cuttingInteractions(request.data.data));
            }
        }
    }
};
</script>
