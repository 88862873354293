export default {
    // All connectors logos.
    connectorsLogo: {
        facebook:    '/images/facebook-logo.png',
        sms:         '/images/twilio-logo.png',
        twilio:      '/images/twilio-logo.png',
        foliomedian: '/images/foliomedian-logo.png',
        voip:        '/images/voipms-logo.png',
        telnyx:      '/images/telnyx-logo.png',
    }
};
