<template>
    <div id="tenants-avatar">
        <label class="label-tenant" :style="'--tenant-color:' + tenantColor" v-if="tenantImage === null">
            <model-short-name v-bind:model="tenant"></model-short-name>
        </label>
        <img class="tenant-img img-fluid" v-else :src="tenantImage" alt="">
    </div>
</template>
<script>

// Internal components
import ModelShortName from './ModelShortName.vue';

// Helpers
import { EventBus } from '@/helpers/EventBus.js';

export default {
    props: ['tenant'],

    components: {
        ModelShortName
    },

    data () {
        return {
            tenantColor: '',
            tenantImage: null
        };
    },

    mounted () {
        // Set color for tenant when no image represent the tenant avatar
        this.tenantColor = this.tenant.attributes['default-color'] || '#FFDEEB';

        this.tenantImage = this.tenant.attributes['profile-picture'];

        EventBus.$on('tenant-image-updated', () => {
            this.tenantImage = this.tenant.attributes['profile-picture'] !== null ? `${this.tenant.attributes['profile-picture']}?${Date.now()}` : null;
        });
    }
};
</script>
