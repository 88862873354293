<template>
  <div class="d-flex align-items-stretch">
    <navigation></navigation>
    <div id="config-voip-sms" class="container-fluid main-content">
      <div class="row pr-3 pl-2 mb-4">
        <div class="col-sm text-left">
          <h3 class="section-title ">{{ $t("config-voip-connector") }}</h3>
        </div>
      </div>
      <div id="section-manage-connector">
          <button id="btn-open-modal-result" v-show="false"
            data-toggle="modal" data-target="#modal-result-config">
          </button>
          <ResultConfigVoip
            :visible="submitted" :result="result"
            @resultSetupEmit="bnClicktResultSetup">
          </ResultConfigVoip>

          <StepsToAccomplish
            :stepCurrent="stepActivated"
            :nbrOfSteps="nbrOfSteps"
            :detailsStep="getDetailsStepActivated()"
            :showBtnNext="true"
            :showOngletsSteps="true"
            @nextStepTriggered="onClicknextStep"
            @submitconfigConnector="onClicksubmitconfig">
          </StepsToAccomplish>
      </div>
    </div>
  </div>
</template>

<script>

// Components
import StepsToAccomplish from '../voip/StepsToAccomplish.vue';
import ResultConfigVoip  from '../voip/ResultConfigVoip.vue';

export default {
    name:       'SmsConfigFolio',
    components: {
        StepsToAccomplish,
        ResultConfigVoip
    },
    data () {
        return {
            stepToAccomplish: [
                {
                    id:          1,
                    name:        'Config-incoming-SMS',
                    description: 'voip-step1-description',
                    details:     [
                        {
                            img:         'step1-Voip',
                            actionImg:   'pic-enlarge',
                            extension:   'png',
                            title:       'choose-nbr-click-edit-btn',
                            imgTitle:    'edit-icon',
                            description: '',
                            fields:      [
                                {
                                    model:       '',
                                    description: 'check-field-sms',
                                    ref:         '',
                                    indiceInput: '',
                                    action:      '',
                                    readOnly:    true,
                                    type:        'input',
                                    content:     '',
                                    show:        false
                                },
                                {

                                    description: 'copy-url-and-paste',
                                    ref:         'lien',
                                    indiceInput: 'link-url-cocoapp',
                                    action:      'copy',
                                    type:        'input',
                                    readOnly:    true,
                                    show:        true,
                                    content:     'https://api.cocoapp.com/api/connectors/sms/v1/voip/webhook?To={TO}&From={FROM}&Body={MESSAGE}'
                                }
                            ]
                        }
                    ]
                },
                {
                    id:          2,
                    name:        'Config-outcoming-SMS',
                    description: 'voip-step2-description',
                    details:     [
                        {
                            img:         'step2-Voip',
                            imgTitle:    '',
                            actionImg:   'pic-enlarge',
                            extension:   'png',
                            title:       'must-create-passwd',
                            description: '',
                            fields:      [
                                {
                                    model:       '',
                                    description: 'copy-username-infield',
                                    ref:         'usernameApi',
                                    indiceInput: 'username-api',
                                    action:      '',
                                    type:        'input',
                                    content:     '',
                                    readOnly:    false,
                                    show:        true,
                                    required:    true
                                },
                                {
                                    model:       '',
                                    description: 'copy-passwd-infield',
                                    ref:         'passwordApi',
                                    indiceInput: 'password-api',
                                    action:      '',
                                    type:        'input',
                                    content:     '',
                                    readOnly:    false,
                                    show:        true,
                                    required:    true
                                },
                                {
                                    model:       '',
                                    ref:         'nbrPhone',
                                    name:        '',
                                    description: 'enter-nbr-toActivate',
                                    placeholder: 'ex: 555-555-1234',
                                    indiceInput: 'phone-number',
                                    type:        'input',
                                    content:     '',
                                    readOnly:    false,
                                    show:        true,
                                    required:    true
                                }
                            ]
                        }
                    ]
                }
            ],
            stepActivated: 1,
            submitted:     false,
            visibleModal:  false,
            result:        {
                data: {
                    type:       'voip',
                    attributes: { account: '1 514 123-4567' }
                },
                status: '200'
            }
        };
    },
    computed: {
        nbrOfSteps () {
            return this.stepToAccomplish.length;
        }
    },
    methods: {
        /**
         * Get details of the step activated
         *
         * @return array of details step
         */
        getDetailsStepActivated () {
            return this.stepToAccomplish.filter((_x) => _x.id === this.stepActivated)
                ? this.stepToAccomplish.filter((_x) => _x.id === this.stepActivated)[0]
                : [];
        },

        /**
         * Click on the btn submit : open the modal result
         */
        onClicksubmitconfig (payload) {
            if (payload) {
                this.result = payload;
            }
            this.submitted = true;
            document.getElementById('btn-open-modal-result').click();
        },

        /**
         * Update the value of param with the step activated
         * @param {integer} step
         */
        onClicknextStep (step) {
            this.stepActivated = step;
        },

        /**
         * Click on the btn of modal (if result success: redirection / else jumb to step badly configured)
         */
        bnClicktResultSetup () {
            if (this.result.status === '200') {
                this.$router.push({ name: `voip-activated:${this.$i18n.locale}` });
            } else {
                this.submitted = false;
                if (this.result.code === '403' || this.result.status === '422') {
                    this.stepActivated = 2;
                } else {
                    this.stepActivated = 1;
                }
            }
        }
    }
};
</script>
