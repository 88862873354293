<template>
    <div id="content-page-setup-connector" class="p-2">
        <SmsBlocTabsSteps
            :showOngletsSteps="showOngletsSteps"
            :nbrOfSteps="nbrOfSteps"
            :stepCurrent="stepCurrent"
            @nextStepTriggered="onClickStepNbr">
        </SmsBlocTabsSteps>
        <div class="step-content mr-2">
            <div class="row justify-content-center" id="bloc-steps-config">
                <div class="col-12">
                    <h2 class="title-step my-4">{{$t(detailsStep.name)}}</h2>
                    <p class="descrp-step" v-html="$t(detailsStep.description)" ></p>
                </div>
            </div>
            <div class="row bloc-steps-config-fields"
                v-for="details in detailsStep.details" v-bind:key="details.img">
                <div v-if="details.img!=='' " class="bloc-img-step col-md-6 col-12"
                    :style="{order:  1}">
                    <template v-if="details.actionImg!=='' && showLightBoxPic">
                            <VueEasyLightbox
                                @hide="handleHide"
                                scrollDisabled escDisabled moveDisabled
                                :index="0"
                                :visible="showLightBoxPic"
                                :ref="details.img"
                                :class="{
                                    'svg-screenshot-expl-step': details.extension === 'svg',
                                    'img-screenshot-expl-step': details.extension === 'png'
                                }"
                                :imgs="[ getImgUrl(details.img, details.extension)]">
                            </VueEasyLightbox>
                    </template>
                    <img :class="{'svg-screenshot-expl-step': details.extension ==='svg', 'img-screenshot-expl-step': details.extension ==='png'}"
                                :src="getImgUrl(details.img, details.extension)"
                                :alt="details.img"/>
                    <div class="text-right" v-if="details.actionImg!== ''">
                        <button class="action-img-step" @click="onclickPicBigger()">
                            {{$t(details.actionImg)}}
                        </button>
                    </div>
                </div>
                <div class="text-left align-self-center bloc-explain-step"
                    :style="{order:  2}"
                    :class="{'col-md-5 col-12': details.img !== ''}">
                    <h5 v-if="details.title!== ''"
                         class="title-explain-step mb-3">
                        {{$i18n.t(details.title)}}
                        <img v-if="details.imgTitle !== ''" class="img-title"
                            :src="require(`@/core/assets/images/${details.imgTitle}.png`)"/>
                    </h5>
                    <span v-if="details.description!== ''" class="paragraph-explain-step"
                          v-html="$t(details.description)">
                    </span>
                        <template v-if="details.fields.length > 0">
                        <div class="bloc-inputs mb-4" v-for="field in details.fields"
                                v-bind:key="field.name">
                                <p class="title-field" v-if="field.name!== ''">{{$i18n.t(field.name)}}</p>
                                <p class="descrip-field" v-if="field.description!== ''" v-html="$i18n.t(field.description)" :style="{marginBottom: '0px'}"></p>
                                <div v-if="field.type === 'input' && field.show" class="bloc-input-config inputs-voip">
                                    <input v-if="field.required"
                                        :ref="field.ref"
                                        :readonly="field.readOnly"
                                        v-bind:placeholder="$i18n.t(field.placeholder)"
                                        v-validate="field.required? 'required': ''"
                                        :name="field.ref"
                                        v-model="field.model"
                                        class="input-step-config form-control"
                                        :class="{ 'is-invalid': submitted && errors.has(field.ref) }"
                                        />
                                    <input v-else class="input-step-config"
                                        :value="field.content"
                                        :ref="field.ref"
                                        :readonly="field.readOnly"
                                        v-bind:placeholder="$t(field.placeholder)"
                                        :name="field.ref"
                                        />
                                    <button class="btn-copy-input ml-2" v-if="field.action === 'copy'"
                                            :title="$t('copy')"
                                            @click.prevent="onClickCopyContent(field.ref)">
                                        <img class="svg-copy-input"
                                            :title="$t('copy')"
                                            src="@/core/assets/images/copy.svg" :alt="'copy'"/>
                                    </button>
                                </div>
                                <span class="indice-input" v-if="field.indiceInput!== ''">
                                    {{$t(field.indiceInput)}}
                                </span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="bloc-end-step text-right pt-4 pb-4 pr-4" v-if="showBtnNext">
                <button class="btn-next-step" @click.prevent="onClickNextStep">
                    {{getStepInBtn()}}
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

// Import external libraries
import VueEasyLightbox from 'vue-easy-lightbox';
import SmsBlocTabsSteps from '../SmsBlocTabsSteps.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name:       'StepsToAccomplish',
    components: {
        VueEasyLightbox,
        SmsBlocTabsSteps
    },
    emits: ['submitconfigConnector', 'nextStepTriggered'],
    data () {
        return {
            stepCurr:        1,
            submitted:       false,
            visible:         false,
            showLightBoxPic: false,
            loading:         false
        };
    },
    computed: { ...mapGetters('core/number', ['smsPhoneNumber']) },
    props:    {
        stepCurrent:      Number,
        detailsStep:      Object,
        nbrOfSteps:       Number,
        showBtnNext:      Boolean,
        showOngletsSteps: Boolean
    },
    watch: {
        stepCurrent () {
            this.stepCurr = this.stepCurrent;
        }
    },
    beforeMount () {
        this.stepCurr = this.stepCurrent;
    },
    methods: {
        ...mapActions('core/number', { configureVoip: 'configureVoip' }),

        /**
         * Get the content of the onglet
         *
         * @param  {number}  stepNbr  nbr of step
         * @return {string}  Name of onglet
         */
        getStep (stepNbr) {
            return `${this.$i18n.t('step')} ${stepNbr}`;
        },

        /**
         * Get the content of the btn (btn steps) according to the step activated
         *
         * @return {string}  Name of btn
         */
        getStepInBtn () {
            if (this.stepCurr < this.nbrOfSteps)
            {
                return (`${this.$i18n.t('step')} ${this.stepCurr + 1} >`);
            }
            return (this.$i18n.t('finish'));
        },

        /**
         * Get the path of pic
         *
         * @param  {string}  pic  name pic
         * @param  {string}  extension  extension pic
         * @return {string}  path img
         */
        getImgUrl (pic, extension = 'png') {
            return require(`@/core/assets/images/img-steps-voip/${pic}.${extension}`);
        },

        /**
         * Increment the steps and emit the click to the parent component when the onglet is clicked
         *
         * @param  {number}  step
         * @return emit event
         */
        onClickStepNbr (step) {
            this.stepCurr = step;
            this.$emit('nextStepTriggered', this.stepCurr);
        },

        /**
         * Increment the steps and emit the click to the parent component when the btn next step is clicked
         *
         * @return emit event
         */
        onClickNextStep () {
            if (this.stepCurr < this.nbrOfSteps) {
                const beginBloc = document.getElementById('content-page-setup-connector');
                beginBloc.scrollIntoView();
                this.stepCurr++;
                this.$emit('nextStepTriggered', this.stepCurr);
                return this.stepCurr;
            }
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.configureVoip({
                        'apiUsername': this.detailsStep.details[0].fields[0].model,
                        'apiPassword': this.detailsStep.details[0].fields[1].model,
                        'phoneNumber': this.detailsStep.details[0].fields[2].model
                    }).then(() => {
                        this.submitted = true;
                        const payload = {
                            status:  '200',
                            account: Object.values(this.smsPhoneNumber)[0].attributes['phone-number']
                        };
                        this.$emit('submitconfigConnector', payload);
                    })
                        .catch((error) => {
                            const payload = {
                                errors: [
                                    {
                                        status: 'fail',
                                        title:  error.response.data.errors[0].detail
                                    }
                                ],
                                status: error.response.data.errors[0].status
                            };
                            this.$emit('submitconfigConnector', payload);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },

        /**
         * Copy the content of an input from it's reference
         *
         * @param  {string}  refInput  ref of the inputInput
         * @return value of the inputn is copied
         */
        onClickCopyContent (refInput) {
            this.$refs[refInput][0].select();
            document.execCommand('copy');
        },

        /**
         * Show the VueEasyLightbox to display img (in a bigger way)
         *
         */
        onclickPicBigger () {
            this.showLightBoxPic = true;
        },

        /**
         * Hide the VueEasyLightbox
         *
         */
        handleHide () {
            this.showLightBoxPic = false;
        }
    }
};
</script>
