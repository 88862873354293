import moment    from 'moment';
import { phone } from 'phone';

export default {
    /**
     *
     * Format date in the format YYYY-MM-DD HH:mm,
     * but if we are in the same day we put today HH:mm
     * If it is the day before, we put yesterday HH:mm
     *
     * @param  date    the date to format
     * @return void
     */
    formatDate (date) {
        let today = moment();
        let yesterday = moment().subtract(1, 'day');
        if (date.isSame(today, 'day')) {
            return this.$i18n.t('today') + date.format(' H:mm');
        } else if (moment(date).isSame(yesterday, 'day')) {
            return this.$i18n.t('yesterday') + date.format(' H:mm');
        } else {
            return date.format('YYYY-MM-DD H:mm');
        }
    },

    /**
     *
     * Format the date in the format YYYY-MM-DD HH:mm to the right UTC time.
     *
     * @param  date    the date to format
     * @return string
     */
    toUtc (date) {
        let utc = {
            date: null,
            time: null,
        };

        let formated = moment(date).utc();
        utc.date = formated.format('YYYY-MM-DD');
        utc.time = formated.format('HH:mm');

        return utc;
    },

    /**
     *
     * format phone number in the format 555-555-5555
     * @param  phoneNumber the number to format
     * @return string
     */
    formatPhoneNumber (phoneNumber) {
        let cleaned = phoneNumber.replace(/\D/g, '');
        let match = cleaned.match(/(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        return null;
    },

    /**
     * Normalize the phone number to E164 format
     *
     * @param  {string}    phoneNumber the number to format
     * @return {object}    phone
     */
    phone (phoneNumber) {
        const supportedContriesFormat = [
            'CAN', 'USA'
        ];

        let normalizedPhoneNumber = null;
        supportedContriesFormat.some(countryCode => {
            normalizedPhoneNumber = phone(phoneNumber, { country: countryCode });
            return normalizedPhoneNumber.isValid;
        });

        return normalizedPhoneNumber;
    }
};
