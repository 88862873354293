<template>
    <div id="sms-chat">
        <div class="loading-active-conversation-box content d-flex justify-content-center" v-if="loading">
            <span class="spinner spinner-border text-primary align-self-center"></span>
        </div>
        <div class="content" v-else>
            <div class="conversations-section" id="conversationsSection" :class="{'wihout-dashboard': !showDashboard}">
                <div class="conversation" v-for="conversation in contactConversations.conversations" :key="conversation.id">
                    <div v-for="(message, index) in conversation.relationships.messages.data"
                        :key="message.id"
                        class="message"
                        :class="{
                            'user-message text-right': contactConversations.messages[message.id].attributes.direction === 'mobile_terminated',
                            'contact-message text-left': contactConversations.messages[message.id].attributes.direction === 'mobile_originated'
                        }"
                    >
                        <div
                            v-if="isMms"
                            class="mb-3"
                            :class="{
                                'right-message': contactConversations.messages[message.id].attributes.direction==='mobile_terminated',
                                'left-message':  contactConversations.messages[message.id].attributes.direction==='mobile_originated'
                            }
                        ">
                            <div class="d-flex justify-content-end">
                                <p :class="{
                                        'animated':     index == contactConversations.relationships.messages.data.length - 1,
                                        'mass-message': contactConversations.messages[message.id].attributes['is-mass'],
                                        'flash-message': contactConversations.messages[message.id].attributes['is-flash'],
                                        'auto-response-message': contactConversations.messages[message.id].attributes['is-auto-response'],
                                    }">
                                    <img src="/images/mms.svg">
                                </p>

                            </div>
                            <small>
                                <span v-if="contactConversations.messages[message.id].attributes['is-mass']"> {{ $t('massive-send') }}: </span>
                                <span v-if="contactConversations.messages[message.id].attributes['is-flash']"> {{ $t('flash-message') }}: </span>
                                <span v-if="contactConversations.messages[message.id].attributes['is-auto-response']"> {{ $t('auto-response') }}: </span>
                                {{ formatDate(moment(contactConversations.messages[message.id].attributes['created-at'])) }}
                            </small>
                        </div>
                        <div v-else class="mb-3" :class="{
                                'right-message': contactConversations.messages[message.id].attributes.direction==='mobile_terminated',
                                'left-message': contactConversations.messages[message.id].attributes.direction==='mobile_originated'
                            }">
                            <div class="d-flex justify-content-end">
                                <p class="text-message" :class="{
                                        'animated': index == conversation.relationships.messages.data.length - 1,
                                        'mass-message': contactConversations.messages[message.id].attributes['is-mass'],
                                        'flash-message': contactConversations.messages[message.id].attributes['is-flash'],
                                        'auto-response-message': contactConversations.messages[message.id].attributes['is-auto-response'],
                                    }">
                                    {{ contactConversations.messages[message.id].attributes.content }}
                                </p>
                            </div>
                            <small class="message-date">
                                <span v-if="contactConversations.messages[message.id].attributes['is-mass']"> {{ $t('massive-send') }}: </span>
                                <span v-if="contactConversations.messages[message.id].attributes['is-flash']"> {{ $t('flash-message') }}: </span>
                                <span v-if="contactConversations.messages[message.id].attributes['is-auto-response']"> {{ $t('auto-response') }}: </span>
                                {{ formatDate(moment(contactConversations.messages[message.id].attributes['created-at'])) }}
                            </small>
                        </div>
                    </div>
                    <div class="conversation-end" v-if="conversation.attributes['ended-at']">
                        <p>
                            {{ $t('conversation-terminated') }} {{ formatDate(moment(conversation.attributes['updated-at'])).toLowerCase() }}
                            <span v-if="conversation.relationships.users.data[0]">
                                {{ $i18n.t('by') }}
                                {{ contactConversations.users[conversation.relationships.users.data[0].id].attributes['first-name']}}
                                {{ contactConversations.users[conversation.relationships.users.data[0].id].attributes['last-name'] }}
                            </span>
                        </p>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="dashboard" v-if="showDashboard">
                <div>
                    <form>
                        <select-dropdown
                            :options="options"
                            :selected="selectedTemplate"
                            v-on:selectTemplate="chooseTemplate($event)"
                            :showLabel="false"
                            :type="'template'"
                            :isDisabled="isFormDisabled">
                        </select-dropdown>
                        <textarea
                            class="form-control mt-2 select-template"
                            name="message"
                            id="message"
                            cols="30"
                            rows="4"
                            :placeholder="isFormDisabled ? chatPlaceholder : $t('write-your-response')"
                            v-model="smsText"
                            :disabled="isFormDisabled"
                            :class="{'disabled-textarea': isFormDisabled}"
                            @keyup="disabled = (smsText === '')"
                        >
                        </textarea>
                        <sms-counter :sms="smsText" v-on:limitExceeded="smsLimitExceeded = $event" v-if="!isFormDisabled"></sms-counter>
                    </form>
                </div>
                <div class="row mt-3">
                    <div class="col-6 text-left pl-3 pt-md-0 pt-2"
                        :key="contactActiveConversation.id"
                        v-if="contactActiveConversation && currentUserCanChatWithContact"
                        id="terminate-conversation"
                    >
                        <router-link :to="{ name: '' }" @click.native="terminateSingleContactConversation()"
                            v-if="this.numberToActivate === null"
                            class="text-left text-danger end-discussion-text">
                            {{ $t('end-the-conversation') }}
                        </router-link>
                    </div>
                    <div v-bind:class="[ (contactActiveConversation && currentUserCanChatWithContact) ? 'col-6' : 'col-12' ]" class="text-right">
                        <button
                            class="btn btn-primary btn-paper-plane style-btn style-btn"
                            @click="sendSms()"
                            :disabled="isButtonDisabled"
                        >
                            <font-awesome-icon class="paper-plane" :icon="['fas', 'paper-plane']" v-if="!sendingSms"/>
                            <span class="spinner-border spinner-border" role="status" aria-hidden="true" v-if="sendingSms"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end my-2" v-else>
                <div class="mr-5" >
                    <span class="initials fs-10 pointer" v-popover.top="{ name: 'active-user' }">
                        {{ userInitials }}
                    </span>
                    <img class="icone-message mb-4" src="@/core/assets/images/icon-message.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Components
import SelectDropdown from '../../../core/components/SelectDropdown.vue';
import SmsCounter     from './SmsCounter.vue';

// Internal services js
import {
    mapState, mapActions, mapGetters
} from 'vuex';
import Format from '@/helpers/Format.js';
import Alert  from '@/helpers/Alert.js';

// External services js
import HttpStatus from 'http-status-codes';
import moment     from 'moment';

export default {
    components: { SelectDropdown, SmsCounter },

    props: {
        loadTerminatedConversations: {
            default: true,
            type:    Boolean
        },
        showDashboard: {
            default: true,
            type:    Boolean
        }
    },

    data () {
        return {
            selectedTemplate: this.$i18n.t('choose-template'),
            smsMessage:       '',
            selectFilter:     this.$i18n.t('all'),
            smsLimitExceeded: false,
            smsFilter:        [
                'all',
                'last-7-days',
                'active-conversation'
            ],
            smsText:  '',
            disabled: true,
            isMms:    false,
            options:  [],

            // Loadings
            loading:    false,
            sendingSms: false
        };
    },

    watch: {
        smsText (newValue) {
            if (!newValue) {
                this.selectedTemplate = this.$i18n.t('choose-template');
                this.disabled         = true;
            }
        }
    },

    computed: {
        /**
         * Check if the send form is disabled
         *
         * @retun   {boolean}
         */
        isFormDisabled () {
            if (!this.isSubscribed ||
                !this.numberGetter ||
                this.numberToActivate ||
                (this.contactActiveConversation && !this.currentUserCanChatWithContact)
            ) {
                return true;
            }

            return false;
        },

        /**
         * Get the Chat placeholder
         *
         * @return {string}
         */
        chatPlaceholder () {
            if (!this.isSubscribed) {
                return this.$i18n.t('stop-request-received');
            } else if (this.numberToActivate) {
                return this.$i18n.t('phone-not-activated-yet');
            } else if (!this.numberGetter) {
                return this.$i18n.t('no-phone-number-configured');
            }

            return this.$i18n.t('conversation-assigned-to-user', {
                name: this.currentConversationUser ?
                    `${this.currentConversationUser.attributes['first-name']} ${this.currentConversationUser.attributes['last-name']}` :
                    ''
            });
        },

        /**
         * Check if the send button is disabled
         *
         * @retun   {boolean}
         */
        isButtonDisabled () {
            return (
                !this.isSubscribed ||
                !this.numberGetter ||
                this.disabled ||
                this.sendingSms ||
                this.smsLimitExceeded ||
                (this.contactActiveConversation && !this.currentUserCanChatWithContact)
            );
        },

        /**
         * State of SMS conversation
         *
         */
        ...mapState('core/conversations', {
            contactHasConversations:   'contactHasConversations',
            contactActiveConversation: 'contactActiveConversation',
            contactConversations:      'contactConversations'
        }),

        /**
         * State for current active conversation with the current contact
         * Single contact
         * Conversations
         */
        ...mapState('core/contacts', { contact: 'single' }),

        /**
         * State of tenant
         *
         * selectedTenant    State of the tenant the user has selected
         */
        ...mapState('core/tenants', { selectedTenant: 'selectedTenant' }),

        ...mapState('core/templates', [
            'templates'
        ]),

        ...mapGetters('core/conversations', [
            'isSubscribed',
            'currentUserCanChatWithContact',
            'currentConversationUser'
        ]),

        // Getter for the selected phone number
        ...mapGetters('core/number', { numberGetter: 'smsPhoneNumber', numberToActivate: 'numberToActivate' }),

        /**
         * Get the current conversation active user initials
         */
        userInitials () {
            if (this.currentConversationUser) {
                return this.currentConversationUser.attributes['first-name'].charAt(0).toUpperCase() + this.currentConversationUser.attributes['last-name'].charAt(0).toUpperCase();
            }

            return null;
        }
    },

    mounted () {
        this.getConversationsDetails();

        // Listen to the websocket event.
        if (this.loadTerminatedConversations) {
            this.update({ tenantId: this.selectedTenant.id, filters: {} });
        }

        this.getTemplate().then(() => {
            this.options.push({
                'id':      null,
                'name':    this.$i18n.t('choose-template'),
                'content': ''
            });
            for (const templateId in this.templates) {
                this.options.push({
                    'id':      templateId,
                    'name':    this.templates[templateId].attributes.name,
                    'content': this.templates[templateId].attributes.content
                });
            }
        });
    },

    methods: {
        // Send sms
        ...mapActions('core/sms-messages', { newSms: 'create' }),

        /**
         * Load templates
         */
        ...mapActions('core/templates', { getTemplate: 'index' }),

        /**
         * SetActiveContact      set the active contact
         * terminateConversation ends a conversation
         *
         */
        ...mapActions('core/conversations', [
            'getContactConversations',
            'setActiveContact',
            'terminateConversation',
            'resetSingleConversation',
            'stopListeningToWebsocket',
            'update'
        ]),

        /**
         * Define moment to use it in template
         */
        moment,

        /**
         * Fetch conversation details
         */
        getConversationsDetails () {
            this.loading = true;
            let filters  =  {};
            if (!this.loadTerminatedConversations) {
                filters = { 'is-active': 1 };
            }

            // Init the conversation board and then load new data
            this.resetSingleConversation();
            this.setActiveContact({ contactId: null });

            this.getContactConversations({
                id: this.contact.id,
                filters
            }).then(() => {
                this.setActiveContact({ contactId: this.contact.id });
                this.loading = false;
            });
        },

        /**
         * Update selected option
         *
         * @return    void
         */
        chooseTemplate (option) {
            if (this.smsText === '') {
                this.selectedTemplate = option.name;
                this.smsText          = option.content;
                this.disabled         = option.id === null;
            } else {
                Alert.confirmation(this.$i18n.t('this-will-replace-message'), this.$i18n.t('replace'))
                    .then((response) => {
                        if (response.isConfirmed) {
                            this.selectedTemplate = option.name;
                            this.smsText = option.content;
                        }
                    });
            }
        },

        /**
         * Send sms to contact
         */
        sendSms () {
            if (this.isButtonDisabled) {
                return;
            }

            this.sendingSms = true;
            this.newSms({
                phoneNumber: this.contact.attributes['phone-number'],
                sms:         this.smsText
            })
                .then(() => {
                    Alert.success(this.$i18n.t('message-sent'));
                    this.smsText = '';
                    let filters =  {};
                    this.$emit('conversationUpdated');
                    if (!this.loadTerminatedConversations) {
                        filters = { 'is-active': 1 };
                    }
                    this.getContactConversations({
                        id: this.contact.id,
                        filters
                    });
                })
                .catch((errors) => {
                    if (errors.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                        Alert.fail((errors.response.data.errors[0].detail));
                    }
                })
                .finally(() => {
                    this.sendingSms = false;
                    const container = document.querySelector('#conversationsSection');
                    container.scrollTop = container.scrollTo(0, container.scrollHeight);
                    this.disabled = true;
                });
        },

        /**
         * Terminate conversation
         */
        terminateSingleContactConversation () {
            Alert.confirmation(this.$i18n.t('end-the-conversation'), this.$i18n.t('terminate'))
                .then((result) => {
                    if (result.isConfirmed) {
                        this.loading = true;
                        this.terminateConversation({ id: this.contactActiveConversation.id })
                            .then(() => {
                                this.$emit('conversationTerminated');
                                if (this.loadTerminatedConversations) {
                                    this.getConversationsDetails();
                                }
                            });
                    }
                });
        },

        /**
         * Define formatDate method to be used in template
         */
        formatDate: Format.formatDate
    },

    destroyed () {
        this.resetSingleConversation();
        if (this.loadTerminatedConversations) {
            this.stopListeningToWebsocket(this.selectedTenant.id);
        }
    }
};
</script>
