import Api       from '@/helpers/Api.js';
import qs        from 'qs';
import normalize from 'json-api-normalizer';
import Vue       from 'vue';

const resource = '/internal/v1/auto-responses';

// Import mutations
import {
    ADD_AUTO_RESPONSE,
    REMOVE_AUTO_RESPONSE,
    SET_AUTO_RESPONSES,
    UPDATE_AUTO_RESPONSE,
    SELECT_AUTO_RESPONSE
} from './mutation-types.js';

// Initial state
const state = () => ({
    autoResponses:        [],
    selectedAutoResponse: null
});

// Getters
const getters = {
    /**
     * Get all used keywords
     *
     * @param {object} state
     *
     * @return array
     */
    usedKeywords: (state) => {
        let keywords = [];

        if (state.autoResponses.length > 0) {
            state.autoResponses.forEach(autoResponse => {
                keywords = keywords.concat(autoResponse.attributes.keywords);
            });
        }

        return keywords;
    },

    /**
     * Get all system keywords
     *
     * @param {object} state
     *
     * @return array
     */
    systemKeywords: () => {
        return [
            'start',
            'stop',
            'info',
            'help',
            'reprise',
            'arret',
            'aide'
        ];
    },
};

// Actions
const actions = {
    /**
     * Get all auto responses.
     *
     * @param      {Object}   context          The context
     * @param      {Object}   payload.filters  The filters
     *
     * @return     {Promise}
     */
    index: (context, payload) => {
        return new Promise((resolve, reject) => {
            const urlParams = {};

            if (payload && payload.filters) {
                urlParams.filter = payload.filters;
            }

            Api.get(`${resource}`, {
                params:           urlParams,
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: true });

                    // Always check if results are not empty, then call mutations.
                    if (normalizedData.autoResponses) {
                        context.commit(SET_AUTO_RESPONSES, Object.values(normalizedData.autoResponses));
                    } else {
                        context.commit(SET_AUTO_RESPONSES, []);
                    }

                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Creates a new auto responses.
     *
     * @param      {object}  context         The context
     * @param      {object}  attributes      The auto response name
     *
     * @return     {Promise}
     */
    create: (context, { attributes }) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`, {
                'data': {
                    'type': 'auto-responses',
                    attributes
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: true });

                    // Always check if results are not empty, then call mutations.
                    if (normalizedData.autoResponses) {
                        context.commit(ADD_AUTO_RESPONSE, normalizedData.autoResponses[response.data.data.id]);
                    }

                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Updates an auto response.
     *
     * @param      {Object}  context             The context
     * @param      {string}  payload.id          The tag identifier
     * @param      {Object}  payload.attributes  The tag attributes
     *
     * @return     {Promise}
     */
    update: (context, { id, attributes }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/${id}`, {
                'data': {
                    'type': 'auto-responses',
                    id:     id,
                    attributes
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: true });

                    // Always check if results are not empty, then call mutations.
                    if (normalizedData.autoResponses) {
                        context.commit(UPDATE_AUTO_RESPONSE, normalizedData.autoResponses[id]);
                    }

                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Deletes an auto response.
     *
     * @param      {Object}  context       The context
     * @param      {string}  payload.id    The auto response identifier
     *
     * @return     {Promise}
     */
    delete: (context, { id }) => {
        return new Promise((resolve, reject) => {
            Api.delete(`${resource}/${id}`)
                .then(() => {
                    context.commit(REMOVE_AUTO_RESPONSE, id);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     *
     * @param    {Object}  context        The context
     * @param    {string}  payload.id     The auto response identifier
     */
    selectAutoResponse: (context, { id }) => {
        context.commit(SELECT_AUTO_RESPONSE, context.state.autoResponses.find((autoResponse) => autoResponse.id === id));
    },

    /**
     * Unselect the selectedAutoResponse
     */
    unselectAutoResponse (context) {
        context.commit(SELECT_AUTO_RESPONSE, null);
    }
};

// Mutations
const mutations = {
    [SET_AUTO_RESPONSES]: (state, payload) => {
        state.autoResponses = payload;
    },
    [ADD_AUTO_RESPONSE]: (state, payload) => {
        state.autoResponses.push(payload);
    },
    [UPDATE_AUTO_RESPONSE]: (state, payload) => {
        const index = state.autoResponses.findIndex(autoResponse => autoResponse.id === payload.id);
        Vue.set(state.autoResponses, index, payload);
    },
    [REMOVE_AUTO_RESPONSE]: (state, id) => {
        const index = state.autoResponses.findIndex(autoResponse => autoResponse.id === id);
        state.autoResponses.splice(index, 1);
    },
    [SELECT_AUTO_RESPONSE]: (state, payload) => {
        state.selectedAutoResponse = payload;
    },
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
