<template>
    <div>
        <h6 class="sibebar-title text-left text-black">
            {{ $t('profile-tags') }}
        </h6>
        <div class="tags-wrap d-flex flex-wrap p-0">
            <div class="tags mr-1" v-for="(tag, i) in contactTags" :key="i">
                <p class="mb-2"> {{ tag }}
                    <button type="button" class="close tags-button" aria-label="Close" @click="deleteTag(i)">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </p>
            </div>
        </div>
        <form v-on:submit="createTag">
            <div class="tag-search text-left">
                <input class="form-control w-100 add-tag-form"
                    name="tags"
                    v-validate="'required'"
                    :data-vv-as="$t('tags')"
                    type="text"
                    v-model="newTag"
                    autocomplete="off"
                    list=”idOfDatalist”
                    :placeholder="$t('add-tag')"
                    @change="createTag($event)"
                    @mouseover="showDatalist()"
                    @mouseleave="hideDatalist()"
                    ref="inputTags">
                <datalist id=”idOfDatalist” >
                    <option v-for="tag in allTags" :key="tag.id">{{tag.attributes.name}}</option>
                </datalist>
                <div class="text-right mt-2">
                    <button type="submit" class="btn btn-primary rounded-pill add-note-btn">{{ $t('add') }}</button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>

// Internal services js
import Alert from '@/helpers/Alert.js';

// External libraries
const clone = require('rfdc')();
import _ from 'underscore';
import {
    mapActions,
    mapGetters,
    mapState
} from 'vuex';

export default {
    created () {
        this.getTags();
    },

    mounted () {
        this.contactTags = clone(this.singleTags);
    },

    data () {
        return {
            submitted:   false,
            newTag:      '',
            contactTags: []
        };
    },

    computed: {
        ...mapState('core/contacts', {
            contact: 'single'
        }),

        ...mapGetters('core/contacts', {
            singleTags: 'singleTags',
        }),

        ...mapGetters('core/tags', {
            'allTags': 'tags'
        })
    },

    watch: {
        /**
         * singleTags watcher
         *
         * @param      {Object}  value   The value
         */
        singleTags: function (value) {
            this.contactTags = clone(value);
        }
    },

    methods: {
        ...mapActions('core/contacts', {
            'patchContact': 'patch'
        }),

        ...mapActions('core/tags', {
            getTags: 'index'
        }),

        /**
         * Delete tags
         *
         * @param   {integer}   position    The position of tag in contact tags table.
         */
        deleteTag (position) {
            this.contactTags.splice(position, 1);
            this.patchContact({
                id:         this.contact.id,
                attributes: {
                    tags: this.contactTags
                }
            });
        },

        /**
         * Create tag
         *
         * @param {object}  event  The form submit event
         */
        createTag (event) {
            this.submitted = true;

            // Validate the input
            this.$validator.validate('tags').then(valid => {
                if (!valid) {
                    return;
                }

                // Check if the newTag exist
                const existTag = _.find(this.contactTags, (tag) => {
                    // Condition to return good tag
                    return tag === this.newTag;
                });

                this.submitted = false;
                if (typeof existTag !== 'undefined') {
                    this.newTag = '';
                    Alert.fail(this.$i18n.t('tags-create-error'));
                    return;
                }

                this.submitted = false;
                this.contactTags.push(this.newTag);
                this.newTag = '';

                this.patchContact({
                    id:         this.contact.id,
                    attributes: {
                        tags: this.contactTags
                    }
                });
            });
            event.preventDefault();
        }
    }
};
</script>
