<template>
    <b-modal modal-class="modal right fade sidebar-modal" id="auto-response-sidebar" no-close-on-backdrop hide-footer hide-header>
        <div id="auto-response" class="px-4 d-flex flex-column add-edit-form">
            <div class="title my-4 modal-header">
                <h4 class="form-title modal-title text-black font-nunito">
                    {{ !editMode ? $t('add-auto-response') : $t('edit-auto-response') }}
                </h4>
            </div>
            <div class="modal-body">
                <form>
                    <!-- Title -->
                    <div class="form-group mb-5">
                        <label class="form-title font-nunito" for="name">{{$t('auto-response-title')}}</label>
                        <input
                            type="text"
                            name="name"
                            class="form-control form-border font-nunito"
                            :placeholder="$t('template-name-placeholder')"
                            v-model.trim="autoResponse.name">
                    </div>

                    <!-- Keywords -->
                    <div class="form-group mb-4">
                        <div class="d-flex flex-column">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex">
                                    <label class="form-title mb-0 font-nunito" for="audience">{{$t('keywords')}}</label>
                                    <div class="ml-2 d-flex" v-popover.right="{ name:'auto-response-keywords' }" type="button" ref="rules">
                                        <font-awesome-icon class="align-self-center text-black info-circle" :icon="['fa', 'info-circle']" />
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <input type="checkbox" class="form-check-input mr-2 align-self-center" id="isWithoutKeywords" v-model="isWithoutKeywords">
                                    <label for="isWithoutKeywords" class="m-0 pointer text-black fs-14 font-nunito">
                                        {{ $t('no-keywords') }}
                                    </label>
                                </div>
                            </div>
                            <small class="text-left text-black font-nunito text-dark-brown" v-if="isWithoutKeywords">{{ $t('autoresp-without-keywords') }}</small>
                            <small class="text-left text-black font-nunito text-dark-brown" v-else>{{ $t('keywords-auto-response') }}</small>
                        </div>
                        <div v-if="!isWithoutKeywords">
                            <div class="d-flex d-inline-flex flex-row p-0 mr-2 mt-3 mb-2"
                                v-for="(keyword, i) in autoResponse.keywords" :key="i">
                                <div class="keyword-ball d-flex">
                                    <p>{{ keyword.toLowerCase() }}</p>
                                    <button type="button" class="close font-nunito" aria-label="Close" @click="deleteKeyword(i)">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <input class="form-control w-100 add-tag-form form-border"
                                :class="{ 'mt-3' : autoResponse.keywords === null }"
                                name="keywords"
                                type="text"
                                v-model.trim="keyword"
                                :placeholder="$t('autoResponse-key-placeholder')"
                                ref="inputKeywords"
                            >
                            <div class="d-flex text-left justify-content-end mt-2">
                                <button class="btn btn-primary btn-sm rounded rounded-pill px-4 font-nunito"
                                    :disabled="disabled = activeAddKeywordBtn"
                                    @click.prevent="addKeyword()">
                                    {{ $t('add') }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Content -->
                    <div class="form-group" :class="{ 'mb-5': !errors.has('content') }">
                        <label class="form-title font-nunito d-flex"  for="name">
                            {{ $t('autoresponse-sent')}}
                            <div class="ml-2 d-flex" v-popover.right="{ name:'create-auto-response' }" type="button" ref="rules">
                                <font-awesome-icon class="align-self-center text-black info-circle" :icon="['fa', 'info-circle']" />
                            </div>
                        </label>
                        <!-- Gabarits -->
                        <select-dropdown
                            :options="options"
                            :selected="selectedTemplate"
                            v-on:selectTemplate="chooseTemplate($event)"
                            :showLabel="false"
                            :type="'template'">
                        </select-dropdown>
                        <textarea
                            type="text"
                            name="message"
                            class="form-control form-border font-nunitor mt-2"
                            :placeholder="$t('template-text-placeholder')"
                            v-model.trim="autoResponse.content"
                            v-validate="'max:1600'"
                            data-vv-as="Message"
                            data-vv-name="content"
                            :class="{ 'is-invalid': submitted && errors.has('content') }">
                        </textarea>
                        <sms-counter :sms="autoResponse.content" v-on:limitExceeded="smsLimitExceeded = $event"></sms-counter>
                    </div>
                    <div class="invalid-feedback text-left mb-5 font-nunito"
                        v-if="submitted && errors.has('content')">
                        {{ errors.first('content') }}
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <div class="d-flex justify-content-between w-100">
                    <button class="btn btn-link form-buttons" @click="clearForm()">
                        {{ $t('cancel')}}
                    </button>
                    <button
                        class="btn btn-primary rounded rounded-pill px-4 form-buttons"
                        @click="editMode ? editAutoResponse() : createAutoResponse()"
                        :disabled="disabled = disabledSaveBtn">
                        <span v-if="editMode && !loading">{{ $t('save') }}</span>
                        <span v-if="!editMode && !loading">{{ $t('add') }}</span>
                        <span class="spinner-border text-primary" v-if="loading"></span>
                    </button>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

import Vue from 'vue';

// Import internal libraries
import Alert  from '@/helpers/Alert.js';

// Import Components
import SelectDropdown from '@/core/components/SelectDropdown.vue';
import SmsCounter     from '@/connectors/sms/components/SmsCounter.vue';

// Import external libraries
import {
    mapActions,
    mapState,
    mapGetters,
} from 'vuex';

const clone = require('rfdc')();

export default {
    props: {
        editMode: {
            type:    Boolean,
            default: false
        }
    },
    components: {
        SelectDropdown,
        SmsCounter
    },

    mounted () {
        // Get the temaplate
        this.getTemplate().then(() => {
            this.options.push({
                'id':      null,
                'name':    this.$i18n.t('choose-template'),
                'content': ''
            });
            for (const templateId in this.templates) {
                this.options.push({
                    'id':      templateId,
                    'name':    this.templates[templateId].attributes.name,
                    'content': this.templates[templateId].attributes.content
                });
            }
        });
    },

    data () {
        return {
            selectedTemplate:  this.$i18n.t('choose-template'),
            keyword:           '',
            smsLimitExceeded:  false,
            disabled:          false,
            loading:           false,
            submitted:         false,
            isWithoutKeywords: false,
            autoResponse:      {
                'name':       '',
                'content':    '',
                'is-enabled': true,
                'keywords':   [],
            },
            options: [],
        };
    },

    watch: {
        /**
         * Selected Auto Response watcher
         *
         * @param {Object}  value   The value
         */
        selectedAutoResponse (value) {
            if (!value) {
                return;
            }

            const data = ['name', 'content', 'is-enabled', 'keywords'];
            data.forEach(element => {
                this.autoResponse[element] = clone(value.attributes[element]);
            });

            this.isWithoutKeywords = this.autoResponse.keywords.length === 0;
        },

        isWithoutKeywords (isWithout) {
            if (isWithout && this.autoResponse.keywords.length > 0) {
                this.autoResponse.keywords = [];
            }
        },

        /**
         * Prevent a user from adding a whitespace in the keyword entry
         */
        keyword (newValue) {
            this.keyword = newValue.replace(' ', '');
        }
    },

    computed: {
        /**
         * Load templates
         */
        ...mapState('core/templates', { 'templates': 'templates' }),

        /**
         * Auto responses States
         */
        ...mapState('core/autoResponses', ['selectedAutoResponse']),

        /**
         * Auto responses Getters
         */
        ...mapGetters('core/autoResponses', ['usedKeywords', 'systemKeywords']),

        /**
         * Test Keyword input to activate add button
         *
         * @return boolean
         */
        activeAddKeywordBtn () {
            return this.keyword === '';
        },

        /**
         * Check if the submit button is disabled or not
         *
         * @return boolean
         */
        disabledSaveBtn () {
            if (this.loading ||
                this.autoResponse.name === '' ||
                this.autoResponse.content === '' ||
                this.smsLimitExceeded ||
                (!this.isWithoutKeywords && this.autoResponse.keywords.length === 0)
            ) {
                return true;
            }
            return false;
        },
    },

    methods: {
        /**
         * Load templates
         */
        ...mapActions('core/templates', { getTemplate: 'index' }),

        /**
         * Auto response actions
         */
        ...mapActions('core/autoResponses', ['create', 'update', 'delete', 'unselectAutoResponse']),

        /**
         * Update selected option
         *
         * @return    void
         */
        chooseTemplate (option) {
            if (this.autoResponse.content === '') {
                this.selectedTemplate     = option.name;
                this.autoResponse.content = option.content;
                this.disabled             = option.id === null;
            } else {
                Alert.confirmation(this.$i18n.t('this-will-replace-message'), this.$i18n.t('replace'))
                    .then((response) => {
                        if (response.isConfirmed) {
                            this.selectedTemplate     = option.name;
                            this.autoResponse.content = option.content;
                        }
                    });
            }
        },

        /**
         *  Add new keyword to the auto response
         */
        addKeyword () {
            if (this.keyword === '') {
                return;
            }

            var keyList = this.keyword.split(',');
            keyList.forEach(keyword => {
                // Add the keyword if not used before in other auto responses, not one of the system keywords, and not used in this current auto response
                if (this.usedKeywords.find(key => key.toLowerCase() === keyword.trim().toLowerCase())) {
                    Alert.fail(this.$i18n.t('keyword-used', { keyword: `"${keyword.trim().toUpperCase()}"` }));
                    return;
                } else if (this.systemKeywords.find(key => key.toLowerCase() === keyword.trim().toLowerCase())) {
                    Alert.fail(this.$i18n.t('system-keyword', { keyword: `"${keyword.trim().toUpperCase()}"` }));
                    return;
                } else if (this.autoResponse.keywords.find(key => key.toLowerCase() === keyword.trim().toLowerCase())) {
                    return;
                }

                this.autoResponse.keywords.push(keyword.trim().toLowerCase());
            });
            this.keyword = '';
        },

        /**
         * Delete Keyword
         *
         * @param {integer} position
         * @return void
         */
        deleteKeyword (position) {
            this.autoResponse.keywords.splice(position, 1);
        },

        /**
         *  Create a new auto response
         */
        createAutoResponse () {
            if (this.disabledSaveBtn) {
                return;
            }

            Vue.prototype.$validation = this.$validator;

            this.$validator.validate().then((valid) => {
                if (!valid) {
                    return;
                }

                // Spinner on and disable button
                this.loading   = true;
                this.submitted = true;

                this.create({ attributes: this.autoResponse })
                    .then(() => {
                        this.loading   = false;
                        this.submitted = false;

                        Alert.success(this.$i18n.t('auto-response-created'));
                        this.clearForm();
                    })
                    .catch(() => {
                        this.loading   = false;
                        this.submitted = false;
                    });
            });
        },

        /**
         *  Edit an auto response
         */
        editAutoResponse () {
            if (this.disabledSaveBtn) {
                return;
            }

            Vue.prototype.$validation = this.$validator;

            this.$validator.validate().then((valid) => {
                if (!valid) {
                    return;
                }

                this.loading   = true;
                this.submitted = true;

                this.update({
                    id:         this.selectedAutoResponse.id,
                    attributes: this.autoResponse
                })
                    .then(() => {
                        this.loading = false;
                        Alert.success(this.$i18n.t('auto-response-updated'));
                        this.clearForm();
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            });
        },

        /**
         * Clear the auto response form
         */
        clearForm () {
            this.loading           = false;
            this.keyword           = '';
            this.isWithoutKeywords = false;
            this.autoResponse      = {
                'name':       '',
                'content':    '',
                'is-enabled': true,
                'keywords':   [],
            },
            this.selectedTemplate = this.$i18n.t('choose-template'),
            this.$bvModal.hide('auto-response-sidebar');

            if (this.editMode) {
                this.unselectAutoResponse({ id: this.selectedAutoResponse.id });
            }
        }
    }
};
</script>
