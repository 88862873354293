<template>
    <div id="data-supression" class="mt-5">
        <div class="container mb-5">
            <div class="row">
                <div class="col-md-1 ml-auto">
                    <router-link
                        v-for="language in availableLanguages"
                        :key="language.locale"
                        @click.native="changeLanguage(language.locale)"
                        :to="{ name : '' }"
                    >
                        {{ language.name }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row justify-content-center mb-5 d-block">
                <h1>{{ $t('deletion-your-data') }}</h1>
                <p v-if="status == ''">{{ $t('enter-confirmation-subtitle') }}</p>
                <p v-if="status !== ''">{{ $t('deletion-status-subtitle') }}</p>
            </div>
            <div class="row justify-content-center">
                <div class="login-box p-5">
                    <div v-if="status === ''">
                        <div class="row">
                            <div class="col-sm mb-4"><img class="img-logo text-center" src="../../../core/assets/images/logo.png" alt=""></div>
                        </div>
                        <form class="text-left">
                            <div class="form-group">
                                <label for="code">{{ $t('confirmation-code') }}</label>
                                <input type="text" name="code" class="form-control" id="code" v-model="code">
                            </div>
                            <div class="d-flex justify-content-end">
                                <router-link :to="{ name: '' }" class="button-link">
                                    <button type="submit" class="btn btn-primary rounded-pill" @click="checkDeletionStatus()" :disabled="loading">{{ $t('confirm') }}
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                                    </button>
                                </router-link>
                            </div>
                        </form>
                    </div>
                    <div v-if="status !== ''">
                        <p v-bind:class="{ 'mb-0' : status === 'error' }">{{ $t('status') }}:
                            <span v-if="status === 'deleted'"><font-awesome-icon class="ml-2 mr-1 text-primary" :icon="['fa', 'check-circle']"/>{{ $t('deleted') }}</span>
                            <span v-if="status === 'planned'"><font-awesome-icon class="ml-2 mr-1 text-warning" :icon="['fa', 'clock']"/>{{ $t('scheduled-deletion') }}</span>
                            <span v-if="status === 'error'"><font-awesome-icon class="ml-2 mr-1 text-danger" :icon="['fa', 'exclamation-circle']"/>{{ $t('error') }}</span>
                        </p>
                        <p v-if="status === 'error'">{{ $t('confirmation-code-not-found') }}</p>
                        <router-link :to="{ name: `facebook-connector:${this.$i18n.locale}` }" class="button-link">
                            <button type="submit" @click="returnToApp()" class="btn btn-primary rounded-pill mt-3">{{ $t('return-cocoapp') }}
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal services js
import { RepositoryFactory } from '../../../repositories/repositoryFactory.js';
import _ from "underscore";

import moment from 'moment';

const DataDeletion = RepositoryFactory.get('DataDeletion');

export default {
    data () {
        return {
            loading:   false,
            status:    '',
            code:      '',
            languages: [
                {
                    locale: 'fr',
                    name:   'Français'
                },
                {
                    locale: 'en',
                    name:   'English'
                }
            ]
        };
    },
    computed: {
        /**
         * Available Languages
         * Removed the currently selected language
         *
         * @return     {array}
         */
        availableLanguages: function () {
            return _.filter(this.languages, (language) => language.locale !== this.$i18n.locale);
        }
    },
    mounted () {
        this.code = this.$route.query.code;
    },
    methods: {
        checkDeletionStatus () {
            DataDeletion.getDeleteRequest(this.code)
                .then(request => {
                    if (request.data.data.attributes['is-deleted'] === true) {
                        this.status = 'deleted';
                    } else {
                        this.status = 'planned';
                    }
                })
                .catch(() => {
                    this.status = 'error';
                });
        },
        returnToApp () {
            this.$router.push({ name: `facebook-connector:${this.$i18n.locale}` });
        },

        /**
         * Change the current language
         *
         * @param    {string}    locale    The new locale
         *
         * @return    void
         */
        changeLanguage (locale) {
            if (this.$i18n.locale === locale) {
                return;
            }

            // Set the locale into local storage
            localStorage.setItem('locale', locale);

            // Change the i18n and moment locale
            this.$i18n.locale = locale;
            moment.locale(locale);
        }
    }
};
</script>
