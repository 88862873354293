<template>
    <div id="reports" class="container-fluid main-content">
        <div class="row pr-3 pl-2 mb-2">
            <div class="section-title col-sm text-left">
                <h3 class="mb-1">{{ $t('reports') }}</h3>
                <p class="m-0 fs-14"> {{ $t('data-compiled-at') }} </p>
            </div>
        </div>
        <div class="white-box m-2 p-4">
            <div class="d-flex justify-content-between px-3 py-1">
                <div class="align-self-center">
                    <date-range-picker
                        ref="picker"
                        :auto-apply="true"
                        v-model="dateRange"
                        @update="getReport()"
                        opens="right"
                        :locale-data="localeData"
                        :ranges="localeData.ranges"
                    >
                        <template v-slot:input="picker" style="min-width: 350px;">
                            <div class="d-flex align-self-center">
                                <span class="fs-14 align-self-center">
                                    <font-awesome-icon class="mr-2 text-primary" :icon="['far', 'calendar-alt']"/>
                                </span>
                                <p class="m-0" v-if="picker.startDate && picker.endDate">
                                    {{ `${moment(picker.startDate).format('YYYY-MM-DD')} - ${moment(picker.endDate).format('YYYY-MM-DD')}` }}
                                </p>
                            </div>
                        </template>
                    </date-range-picker>
                    <small class="text-primary ml-4 erase-date" v-if="dateRange.startDate" @click="clearDates">{{ $t('clear-date') }}</small>
                </div>
                <div class="d-flex" v-if="showDownloadOption">
                    <router-link
                        @click.native="downloadReport()"
                        :to="{ name : '' }"
                        class="d-flex"
                    >
                            <font-awesome-icon class="mr-2 align-self-center" :icon="['fas', 'download']" />
                            <p class="m-0 align-self-center fs-14">
                                {{ $t('download-report') }}
                            </p>
                        </router-link>
                </div>
            </div>
            <div class="loading-active-conversation-box mt-5" v-if="loading">
                <span class="spinner spinner-border text-primary"></span>
            </div>
            <div class="loading-active-conversation-box mt-5" v-if="! loading && availableReports.length === 0">
                {{ $t('no-report-available') }}
            </div>

            <!-- SMS message reports -->
            <SmsMessagesReport :reportData="report" v-if="showSmsReport && !loading && availableReports.length > 0"/>
        </div>
    </div>
</template>
<script>

// Import external libraries
import DateRangePicker          from 'vue2-daterange-picker';
import { mapState, mapActions } from 'vuex';
import moment                   from 'moment';

// Components
import SmsMessagesReport from '@/connectors/sms/components/reports/MessagesReport.vue';

export default {
    components: {
        DateRangePicker,
        SmsMessagesReport
    },

    data () {
        return {
            loading:   false,
            dateRange: {
                startDate: null,
                endDate:   null
            },
            localeData: {
                applyLabel:  this.$i18n.t('search'),
                cancelLabel: this.$i18n.t('cancel'),
                ranges:      {},
                daysOfWeek:  [this.$i18n.t('su'), this.$i18n.t('mo'), this.$i18n.t('tu'), this.$i18n.t('we'), this.$i18n.t('th'), this.$i18n.t('fr'), this.$i18n.t('sa')],
                monthNames:  [this.$i18n.t('january'), this.$i18n.t('february'), this.$i18n.t('march'), this.$i18n.t('april'), this.$i18n.t('may'), this.$i18n.t('june'), this.$i18n.t('july'), this.$i18n.t('august'), this.$i18n.t('september'), this.$i18n.t('october'), this.$i18n.t('november'), this.$i18n.t('december')]
            },
            showSmsReport:      false,
            showDownloadOption: false,
        };
    },

    computed: {
        ...mapState('core/reports', [
            'availableReports',
            'report'
        ])
    },

    created () {
        // Show a report with default dates
        this.showDefaultReport();

        // This week
        this.localeData.ranges[this.$i18n.t('this-week')] = [
            new Date(moment().clone().startOf('week').format('YYYY-MM-DD hh:mm')),
            new Date(moment().clone().endOf('week').format('YYYY-MM-DD hh:mm'))
        ];

        // This month
        this.localeData.ranges[this.$i18n.t('this-month')] = [
            new Date(moment().clone().startOf('month').format('YYYY-MM-DD hh:mm')),
            new Date(moment().clone().endOf('month').format('YYYY-MM-DD hh:mm'))
        ];

        // This year
        this.localeData.ranges[this.$i18n.t('this-year')] = [
            new Date(moment().clone().startOf('year').format('YYYY-MM-DD hh:mm')),
            new Date(moment().clone().endOf('year').format('YYYY-MM-DD hh:mm'))
        ];

        // Last month
        this.localeData.ranges[this.$i18n.t('last-month')] = [
            new Date(moment().subtract(1,'months').clone().startOf('month').format('YYYY-MM-DD hh:mm')),
            new Date(moment().subtract(1,'months').clone().endOf('month').format('YYYY-MM-DD hh:mm'))
        ];

        // Last year
        this.localeData.ranges[this.$i18n.t('last-year')] = [
            new Date(moment().subtract(1,'year').clone().startOf('year').format('YYYY-MM-DD hh:mm')),
            new Date(moment().subtract(1,'year').clone().endOf('year').format('YYYY-MM-DD hh:mm'))
        ];
    },

    methods: {
        /**
         * Define moment to use it in template
         */
        moment,

        ...mapActions('core/reports', {
            getAvailableReports: 'index',
            getReportData:       'show',
        }),

        /**
         * Show a report with default dates
         */
        showDefaultReport () {
            this.loading = true;

            // Set default dates: start and end of current month
            this.dateRange.startDate = new Date(moment().clone().startOf('month').format('YYYY-MM-DD hh:mm'));
            this.dateRange.endDate   = new Date(moment().clone().endOf('month').format('YYYY-MM-DD hh:mm'));

            // Get all available reports
            this.getAvailableReports()
                .then(() => {
                    // Get a report for the default dates
                    this.getReport();
                });
        },

        /**
         * Clear all dates
         */
        clearDates () {
            this.dateRange.startDate = null;
            this.dateRange.endDate   = null;

            this.showSmsReport = false;
        },

        /**
         * Get the Reports from API
         */
        getReport () {
            let from = moment(this.dateRange.startDate);
            let to   = moment(this.dateRange.endDate);

            // If start date equals to end date, add one day to end date
            if (from.format('YYYY-MM-DD') === to.format('YYYY-MM-DD')) {
                to = moment(from).add(1, 'day');
                this.dateRange.endDate = new Date(to.format('YYYY-MM-DD hh:mm'));
            }

            // We do not display reports for more than 12 months (we limit at 12 month)
            const months = 12;
            if (to.diff(from, 'months', true) > months) {
                to = moment(from).clone().endOf('year');
                this.dateRange.endDate = new Date(to.format('YYYY-MM-DD hh:mm'));
            }

            // If no reports available, return
            if (this.availableReports.length === 0) {
                return;
            }

            // For now we have only SMS messages report (sms-messages), we use it by default
            const smsReport = this.availableReports[0].id;

            this.loading = true;
            let payload = {
                'id':      smsReport,
                'filters': {
                    'from': from.format('YYYY-MM-DD'),
                    'to':   to.format('YYYY-MM-DD'),
                }
            };

            this.getReportData(payload)
                .then(() => {
                    this.showSmsReport = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
