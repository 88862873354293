<template>
    <button @click="continueWithFolio()" class="btn sms-btn btn-lg d-flex rounded-pill p-0">
        <p class="fb-text">{{ $t('activate') }}</p>
    </button>
</template>
<script>
export default {
    methods: {
        /**
         * Continue with Folio
         */
        continueWithFolio () {
            // Got to the connector page
            this.$router.push({ name: `foliomedian-config:${this.$i18n.locale}` });
        }
    }
};
</script>
