<template>
    <div id="contact-content" class="container-fluid main-content" v-bind:class="{ respMaincontent: collapseSidebar }">
        <div class="row mb-2 ml-1">
            <div class="col-sm text-left d-flex align-items-center profile-name-bloc">
                <contact-avatar :contact="contact" :profilePicture="profilePicture"></contact-avatar>
                <h3 v-if="contact" class="section-title ml-3">{{ contact.attributes.name }}</h3>
            </div>
        </div>
        <contact-history
            v-on:hideSidebar = "$emit('hideSidebar')"
            v-on:showSidebar = "$emit('showSidebar')"
            v-on:onChangeFilterBy = "changeFilterBy($event)"
            v-bind:collapseSidebar = "collapseSidebar"
            v-bind:contactInteractions = "contactInteractions"
            v-bind:activePageId = "activePageId"
            v-bind:loading = "loading"
            v-bind:singleContactAllInteractions = "singleContactAllInteractions"
        ></contact-history>
    </div>
</template>
<script>

// Internal componants
import ContactHistory from './ContactHistory.vue';
import ContactAvatar  from '../../components/contacts/ContactAvatar.vue';

// Import external libraries
import { mapState } from 'vuex';

export default {
    components: {
        ContactHistory,
        ContactAvatar
    },
    props: [
        'collapseSidebar',
        'activePageId',
        'contactInteractions',
        'loading',
        'singleContactAllInteractions'
    ],

    data () {
        return {
            profilePicture: null,
            contactName:    null
        };
    },

    computed: {
        ...mapState('core/contacts', {
            contact: 'single'
        })
    },

    watch: {
        contact () {
            // If user have a profile picture
            if (this.contact !== null && this.contact.attributes['profile-picture'] !== '' && this.contact.attributes['profile-picture'] !== null) {
                this.profilePicture = this.contact.attributes['profile-picture'];
            } else {
                this.profilePicture = null;
            }
        }
    }
};
</script>
