import Api from '@/helpers/Api.js';

const resource = '/connectors/facebook/v1/users';

export default {
    /**
     * Get all Facebook users
     *
     * @return  void
     */
    index () {
        return Api.get(`${resource}`);
    }
};
