/**
 * Translated routes file
 * Locale: en
 */

// Core routes
import AllContacts             from '@/core/components/contacts/AllContacts.vue';
import AllMessages             from '@/core/components/AllMessages.vue';
import ConnectorsPage          from '@/core/components/ConnectorsPage.vue';
import ForgotPassword          from '@/core/components/security/password/ForgotPassword.vue';
import HomePage                from '@/core/components/HomePage.vue';
import HelpPage                from '@/core/components/HelpPage.vue';
import ResetPassword           from '@/core/components/security/password/ResetPassword.vue';
import SingleContact           from '@/core/components/contacts/ContactPage.vue';
import Tags                    from '@/core/components/contacts/Tags.vue';
import Reports                 from '@/core/components/Reports.vue';
import AutoResponse            from '@/core/components/AutoResponse.vue';
import MessageTemplate         from '@/core/components/MessageTemplate.vue';
import Tenants                 from '@/core/components/Tenants.vue';
import TenantUsers             from '@/core/components/TenantUsers.vue';
import WorkspaceSettings       from '@/core/components/WorkspaceSettings.vue';
import SamlLogin               from '@/core/components/security/user/saml/SamlLogin.vue';
import twoFactorsLogin         from '@/core/components/security/user/twoFactors/loginForm.vue';

// Facebook connector routes
import FacebookConfig          from '@/connectors/facebook/components/FacebookConfiguration.vue';
import FacebookConnector       from '@/connectors/facebook/components/FacebookConnector.vue';
import FacebookDataDeletion    from '@/connectors/facebook/components/FacebookDataDeletion.vue';
import FacebookPage            from '@/connectors/facebook/components/FacebookRemotePage.vue';

// SMS connector routes
import SmsConnector            from '@/connectors/sms/components/SmsConnector.vue';
import SmsConfigFolio          from '@/connectors/sms/components/folio/SmsConfigFolio.vue';
import SmsConfigTwilio         from '@/connectors/sms/components/twilio/SmsConfigTwilio.vue';
import SmsConfigVoip           from '@/connectors/sms/components/voip/SmsConfigVoip.vue';
import SmsConfigTelnyx         from '@/connectors/sms/components/telnyx/SmsConfigTelnyx.vue';
import SmsConfigurationTelnyx  from '@/connectors/sms/components/telnyx/ConfigTelnyx.vue';
import SmsConfigConnectors     from '@/connectors/sms/components/SmsConfigConnectors.vue';
import SmsConfigurationFolio   from '@/connectors/sms/components/folio/ConfigFolio.vue';
import SmsConfigurationVoip    from '@/connectors/sms/components/voip/ConfigVoip.vue';
import ScheduledMessages       from '@/connectors/sms/messages/scheduled/MainPage.vue';

// Vue-i18n
import i18n from '../libraries/I18n.js';

export default [
    {
        path:      '/saml-login',
        name:      'saml-login:en',
        component: SamlLogin,
        meta:      {
            title:  i18n.t('saml-login', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/2fa-login',
        name:      '2fa-login:en',
        component: twoFactorsLogin,
        meta:      {
            title:  i18n.t('2fa-login', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/forgot-password',
        name:      'forgot:en',
        component: ForgotPassword,
        meta:      {
            title:  i18n.t('password-forgot', 'en'),
            locale: 'en'
        }
    },
    {
        path: 'https://www.cocoapp.com/en/packages/',
        name: 'cocoapp-wordpress-packages:en'
    },
    {
        path: 'https://www.cocoapp.com/en/',
        name: 'cocoapp-website:en'
    },
    {
        path:      '/facebook',
        name:      'facebook-connector:en',
        component: FacebookConnector,
        meta:      {
            title:  i18n.t('facebook', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/messages',
        name:      'all-messages:en',
        component: AllMessages,
        meta:      {
            title:  i18n.t('all-message', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/contacts',
        name:      'contact-list:en',
        component: AllContacts,
        meta:      {
            title:  i18n.t('contact-list', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/tags',
        name:      'tags:en',
        component: Tags,
        meta:      {
            title:  i18n.t('tags', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/reports',
        name:      'reports:en',
        component: Reports,
        meta:      {
            title:  i18n.t('reports', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/autoresponse',
        name:      'autoResponse:en',
        component: AutoResponse,
        meta:      {
            title:  i18n.t('auto-response', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/contact',
        name:      'contact:en',
        component: SingleContact,
        meta:      {
            title:  i18n.t('contact', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/users',
        name:      'user:en',
        component: TenantUsers,
        meta:      {
            title:  i18n.t('user', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/home',
        name:      'home:en',
        component: HomePage,
        meta:      {
            title:  i18n.t('home', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/help',
        name:      'help:en',
        component: HelpPage,
        meta:      {
            title:  i18n.t('help', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/reset-password',
        name:      'reset-password:en',
        component: ResetPassword,
        meta:      {
            title:  i18n.t('reset-password', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/register',
        name:      'register-password:en',
        component: ResetPassword,
        meta:      {
            title:  i18n.t('register-password', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/facebook/pages',
        name:      'facebook-pages:en',
        component: FacebookPage,
        meta:      {
            title:  i18n.t('connect-page', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/sms/configuration',
        name:      'config-sms-connectors:en',
        component: SmsConfigConnectors,
        meta:      {
            title:  i18n.t('config-sms-connectors', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/facebook/configuration',
        name:      'facebook-first-config:en',
        component: FacebookConfig,
        meta:      {
            title:  i18n.t('facebook-configuration', 'en'),
            locale: 'en',
            first:  true
        }
    },
    {
        path:      '/facebook/configuration',
        name:      'facebook-config:en',
        component: FacebookConfig,
        meta:      {
            title:  i18n.t('facebook-configuration', 'en'),
            locale: 'en',
            first:  false
        }
    },
    {
        path:      '/workspaces',
        name:      'tenant-list:en',
        component: Tenants,
        meta:      {
            title:  i18n.t('tenants', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/connectors',
        name:      'connectors-page:en',
        component: ConnectorsPage,
        meta:      {
            title:  i18n.t('connectors-title', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/settings',
        name:      'workspace-settings:en',
        component: WorkspaceSettings,
        meta:      {
            title:  i18n.t('workspace-settings', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/sms',
        name:      'sms-connector:en',
        component: SmsConnector,
        meta:      {
            title:  i18n.t('conversations', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/sms/configuration/foliomedian',
        name:      'foliomedian-config:en',
        component: SmsConfigurationFolio,
        meta:      {
            title:  i18n.t('sms-configuration', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/sms/configuration/voipms',
        name:      'voip-config:en',
        component: SmsConfigurationVoip,
        meta:      {
            title:  i18n.t('sms-configuration', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/templates',
        name:      'template:en',
        component: MessageTemplate,
        meta:      {
            title:  i18n.t('template', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/data-deletion',
        name:      'data-deletion:en',
        component: FacebookDataDeletion,
        meta:      {
            title:  i18n.t('data-deletion', 'en'),
            locale: 'en',
            auth:   false
        }
    },
    {
        path:      '/sms/configuration/foliomedian',
        name:      'foliomedian-activated:en',
        component: SmsConfigFolio,
        meta:      {
            title:  i18n.t('sms-configuration', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/sms/configuration/telnyx',
        name:      'telnyx-config:en',
        component: SmsConfigurationTelnyx,
        meta:      {
            title:  i18n.t('sms-configuration', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/sms/configuration/voip',
        name:      'voip-activated:en',
        component: SmsConfigVoip,
        meta:      {
            title:  i18n.t('sms-configuration', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/sms/configuration/telnyx',
        name:      'telnyx-activated:en',
        component: SmsConfigTelnyx,
        meta:      {
            title:  i18n.t('sms-configuration', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/sms/configuration/twilio',
        name:      'twilio-activated:en',
        component: SmsConfigTwilio,
        meta:      {
            title:  i18n.t('sms-configuration', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/sms/configuration/twilio',
        name:      'sms-config:en',
        component: SmsConfigTwilio,
        meta:      {
            title:  i18n.t('sms-configuration', 'en'),
            locale: 'en'
        }
    },
    {
        path:      '/sms/massive-send',
        name:      'massive-send:en',
        component: ScheduledMessages,
        meta:      {
            title:    i18n.t('massive-send', 'en'),
            locale:   'en',
            category: 'mass'
        }
    },
    {
        path:      '/sms/flash-send',
        name:      'flash-send:en',
        component: ScheduledMessages,
        meta:      {
            title:    i18n.t('flash-send', 'en'),
            locale:   'en',
            category: 'flash'
        }
    }
];
