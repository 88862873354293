<template>
    <div id="config-activate-number">
        <div class="bloc-my-number card-info d-flex">
            <div class="col text-left">{{$t('SMS-connector-number')}}</div>
            <div class="col text-right" v-if="numberToActivate">
                <span v-if="deleting">{{ $t('deactivating-number') }} </span>
                    {{ numberToActivate.attributes['phone-number'] }}
                <span v-if="deleting" class="spinner-border spinner-border-sm"
                    role="status" aria-hidden="true"></span>
                <img class="delete-btn" v-if="!deleting"
                    :title="$t('delete')"
                    src="@/core/assets/images/delete.svg" :alt="'delete'"
                    @click="deleteNumber()"
                />
            </div>
        </div>
        <steps-to-activate-number v-if="!showSummary" @submitConfigNumber="bnClickRecap"></steps-to-activate-number>
        <div class="section-summary" v-else>
            <summary-activate-number></summary-activate-number>
        </div>
    </div>
</template>

<script>

// Internal services js
import DeactivateNumberMixin from '@/core/mixins/DeactivateNumberMixin';

import StepsToActivateNumber from './StepsToActivateNumber.vue';
import SummaryActivateNumber from './SummaryActivateNumber.vue';
import { EventBus }          from '@/helpers/EventBus';

import { mapGetters } from 'vuex';

export default {
    mixins:     [DeactivateNumberMixin],
    components: {
        StepsToActivateNumber,
        SummaryActivateNumber
    },
    computed: {
        ...mapGetters('core/number', ['numberToActivate']),

        ...mapGetters('core/providers', ['connectedProvider'])
    },
    data () {
        return {
            showSummary:                   false,
            deactivateConfirmationTitle:   this.$i18n.t('deactivate-sms-confirm'),
            deactivateConfirmationMessage: this.$i18n.t('deactivate-number-confirmation')
        };
    },
    created () {
        // Check if the loa file is sent to telnyx
        if (this.connectedProvider && this.connectedProvider.attributes.config.loa) {
            this.showSummary = true;
        }

        EventBus.$on('start-over', () => {
            this.showSummary = false;
        });
    },
    methods: {
        /**
         * Increment the steps and emit the click to the parent component when the onglet is clicked
         */
        bnClickRecap () {
            this.showSummary = true;
        },

        /**
         * On click open confirmation delete number alert
         */
        deleteNumber () {
            this.deactivateNumberAlert(this.numberToActivate.id);
        }
    }
};
</script>
