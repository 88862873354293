/**
 * InfiniteScrollMixin
 *
 * Adds an infinite scroll functionality to a component.
 */
export default {
    data () {
        return {
            infiniteScrollTarget:          null,
            infiniteScrollPage:            1,
            infiniteScrollEnabled:         true,
            infiniteScrollChildrenWrapper: null
        };
    },

    methods: {
        /**
         * Disable infinite scroll.
         *
         * @return {void}
         */
        disableInfiniteScroll () {
            this.infiniteScrollEnabled = false;
        },

        /**
         * Enable infinite scroll.
         *
         * @return {void}
         */
        enableInfiniteScroll () {
            // Add a delay so it doesn't trigger right away
            const delay = 500;
            setTimeout(() => {
                this.infiniteScrollEnabled = true;
            }, delay);
        },

        /**
         * Handle the scroll event.
         *
         * @param  {object}  event     The scroll event
         * @param  {object}  position  The position data
         * @return {void}
         */
        infiniteScroll (event, position) {
            // Ignore if is disabled
            if (!this.infiniteScrollEnabled) {
                return;
            }

            // Warn if the infiniteScrollEndReached method is not defined
            if (typeof this.infiniteScrollEndReached === 'undefined') {
                console.warn('InfiniteScrollMixin: `infiniteScroll` was used, but no `infiniteScrollEndReached` method is defined in the parent component.');
                return;
            }

            this.infiniteScrollTarget = event.target.id;

            // Get the position of the scroll via the event/position
            let lastChildBottom = 0;
            const scrollPointer = position.scrollTop + event.target.getBoundingClientRect().top;

            if (this.infiniteScrollChildrenWrapper === null) {
                const lastChildRect   = event.target.lastElementChild.getBoundingClientRect();
                lastChildBottom = lastChildRect.top + (lastChildRect.bottom - lastChildRect.top);
            } else {
                lastChildBottom = this.infiniteScrollChildrenWrapper.scrollHeight - this.infiniteScrollChildrenWrapper.getBoundingClientRect().bottom;
            }

            // Check if the scrollTop has reached the top of the last child
            console.log(scrollPointer, lastChildBottom);
            if (scrollPointer > lastChildBottom) {
                // Increment the page
                this.infiniteScrollPage++;

                // Call the callback
                this.infiniteScrollEndReached(this.infiniteScrollPage);
            }
        }
    }
};
