<template>
    <div id="sms-contact-info" class="mt-3">
        <div id="contact-info" class="contact-info">
            <div class="avatar mb-2">
                <router-link @click.native="showContact(contact.id)" :to="{ name: '' }">
                    <label class="label-avatar">
                        <model-short-name v-if="profilePicture === null" v-bind:model="contact"></model-short-name>
                        <img class="contact-img" :src='profilePicture' alt="" v-else>
                    </label>
                </router-link>
            </div>
            <div class="" v-if="contact">
                <h4 class="contact-phone-number">
                    {{ formatPhoneNumber(contact.attributes['phone-number']) }}
                </h4>
                <p class="my-4 contact-details">
                    {{ contact.attributes.name }}
                </p>
                <p class="contact-details">
                    {{ contact.attributes.email }}
                </p>
            </div>
            <div v-else>
                <router-link :to="{ name: '' }">
                    {{ $t('Add-name')}}
                </router-link>
                <br>
                <router-link :to="{ name: '' }">
                    {{ $t('Add-email')}}
                </router-link>
            </div>
        </div>
        <div id="contact-tags" class="contact-tags pb-4">
            <contact-tags></contact-tags>
        </div>
        <div id="contact-notes" class="contact-notes">
            <contact-notes></contact-notes>
        </div>
    </div>
</template>
<script>

// Components
import ContactNotes    from '@/core/components/contacts/ContactNotes.vue';
import ContactTags     from '@/core/components/contacts/ContactTags.vue';
import ModelShortName  from '@/core/components/ModelShortName.vue';
import Format          from '@/helpers/Format.js';

// External libraries
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        ContactNotes,
        ContactTags,
        ModelShortName
    },

    computed: {
        ...mapState('core/contacts', {
            contact: 'single',
        }),

        /**
         * Check if we have a profile picture for the contact
         * If yes, we return the URL of the image, if not we return null
         */
        profilePicture () {
            // If user have a profile picture
            if (this.contact !== null && this.contact.attributes['profile-picture'] !== '' && this.contact.attributes['profile-picture'] !== null) {
                return this.contact.attributes['profile-picture'];
            } else {
                return null;
            }
        }
    },

    methods: {
        ...mapActions('core/contacts', [
            'getSingle'
        ]),

        /**
         * Set the Id of a contact in a session
         * and redirect to single contact page
         *
         * @return    void
         */
        showContact (contactId) {
            this.getSingle({
                id: contactId
            }).then(() => {
                this.$router.push({
                    name: `contact:${this.$i18n.locale}`
                });
            });
        },

        formatPhoneNumber: Format.formatPhoneNumber
    },

};
</script>
