<template>
    <div id="contact-list" class="container-fluid main-content">
        <div class="row pr-3 pl-2 mb-4">
            <div class="col-sm text-left d-flex">
                <h3 class="section-title ">{{ $t('all-contacts') }}</h3>
                <a class="sidebar-arrow ml-auto d-none close-btn" href="#" @click="$emit('hideSidebar')" v-if="collapseSidebar">{{ $t('close') }} <font-awesome-icon class="text-primary" :icon="['fas', 'angle-double-right']" /></a>
                <a class="sidebar-arrow ml-auto d-none open-btn" href="#" @click="$emit('showSidebar')" v-bind:class="{ fadeIn: !collapseSidebar }"><font-awesome-icon class="text-primary" :icon="['fas', 'angle-double-left']" /></a>
            </div>
        </div>
        <div class="row pr-3 pl-2 mb-2">
            <div class="col-sm text-left align-self-center ml-4">
                <input
                    type="text"
                    class="search-contact form-control"
                    v-bind:placeholder="$t('search-contact')"
                    v-on:keyup="activateFilter()"
                    v-model="search">
            </div>
            <div class="col-xl col-sm text-left">
                <form>
                    <select-dropdown
                        :options="options"
                        :selected="selectedOption"
                        v-on:selectOption="updateOption($event)"
                        :type="'options'"
                        :showLabel="true">
                    </select-dropdown>
                </form>
            </div>
        </div>
        <advance-search></advance-search>
        <contact-list-tags
            ref="contactsTag"
            v-bind:collapseSidebar="collapseSidebar"
            v-bind:global-loading="loading"
            v-bind:contacts="contacts">
        </contact-list-tags>
        <preloader-placeholder :loading="loading" :resultlength="Object.keys(this.contacts).length" :type="'contact'"></preloader-placeholder>
        <div class="box-wrap white-scrollbar pl-2 pr-3" v-bind:class="{ 'advance-search': advanceSearchBox}" v-if="loading === false">
            <all-contact-box
                v-for="contact in contacts"
                v-bind:key="contact.id"
                v-bind:contact="contact"
            ></all-contact-box>
        </div>
        <b-pagination
            v-model="page.number"
            :total-rows="contactsMeta.page.total"
            :per-page="contactsMeta.page['per-page']"
            @input="getContactsPage"
            align="center"
        ></b-pagination>
    </div>
</template>
<script>

// Components
import AllContactBox        from './AllContactbox.vue';
import SelectDropdown       from '../../../core/components/SelectDropdown.vue';
import PreloaderPlaceholder from '../PreloaderPlaceholder.vue';
import AdvanceSearch        from './ContactAdvanceSearch.vue';
import ContactListTags      from './ContactListTags.vue';

// Internal services js
import { EventBus } from '../../../helpers/EventBus.js';
import Alert from '@/helpers/Alert.js';

// Import external libraries
import { mapState, mapActions } from 'vuex';
import moment                   from 'moment';

export default {
    components: {
        AllContactBox,
        SelectDropdown,
        PreloaderPlaceholder,
        AdvanceSearch,
        ContactListTags
    },
    props: ['collapseSidebar'],

    data () {
        return {
            advanceSearchBox:  false,
            withTagContact:    [],
            withoutTagContact: [],
            contactName:       '',
            contactImage:      '',
            contactTags:       [],
            contactId:         '',
            createdDate:       '',
            search:            '',
            timer:             null,
            loading:           false,
            filterDates:       '',
            startDateSearch:   '',
            endDateSearch:     '',
            selectedOption:    this.$i18n.t('all'),
            page:              {
                number: 1,
                size:   25
            },
            options: [
                'all',
                'new-contact-7days'
            ]
        };
    },

    computed: {
        ...mapState('core/contacts', [
            'contacts',
            'contactsMeta'
        ]),

        ...mapState('core/auth', [
            'user'
        ])
    },

    mounted () {
        // Get first contacts page
        this.getContactsPage();

        // Update the contact list after importing contacts
        EventBus.$on('contact-imported', () => {
            this.page.number = 1;
            this.getContactsPage();
        });

        // Listen for click export contacts button event.
        EventBus.$on('exportContacts', () => {
            this.exportContacts();
        });

        // When opening the advance search section, bind class to readjust height of contact list.
        EventBus.$on('advance-search', () => {
            this.advanceSearchBox = !this.advanceSearchBox;
        });

        // Place tag in array to search contact with does tags.
        EventBus.$on('with-tag-search', (withTag) => {
            this.withTagContact = withTag;
            this.activateFilter();
        });

        // Place tag in array to search contact without does tags.
        EventBus.$on('without-tag-search', (withoutTag) => {
            this.withoutTagContact = withoutTag;
            this.activateFilter();
        });

        // Set date range starting date, if date is null turn in empty string
        EventBus.$on('startDateSearch', (startDate) => {
            this.startDateSearch = startDate === null ? '' : startDate;
            this.activateFilter();
        });

        // Set date range ending date, if date is null turn in empty string
        EventBus.$on('endDateSearch', (endDate) => {
            this.endDateSearch = endDate === null ? '' : endDate;
            this.activateFilter();
        });
    },

    methods: {
        /**
         * Define moment to use it in template
         */
        moment,
        ...mapActions('core/contacts', [
            'index',
            'export'
        ]),

        /**
         * Update selected option
         *
         * @return    void
         */
        updateOption (option) {
            this.selectedOption = this.$i18n.t(option);
            this.filterDates    = option;

            // Cancel the selected contacts
            this.$refs.contactsTag.cancelSelect();

            this.activateFilter();
        },

        /**
         * Get and download the liste of contacts in excel file.
         *
         * @return    void
         */
        exportContacts () {
            this.export({ filters: this.getFiltersParameter() })
                .then(() => {
                    Alert.success(`${this.$i18n.t('file-send')} "${this.user.attributes.email}".`);
                })
                .catch(() => {
                    Alert.fail(this.$i18n.t('mail-faild'));
                });
        },

        /**
         * Get the filters
         *
         * @retun   {object}    The filters parameter
         */
        getFiltersParameter () {
            const nbDaysWeek = 7;
            let fromDate = '';

            // Check if from filter is from filter or advance search
            if (this.startDateSearch !== '') {
                fromDate = this.startDateSearch;
            } else if (this.filterDates === 'new-contact-7days' && this.startDateSearch === '') {
                fromDate = moment().utc()
                    .subtract(nbDaysWeek, 'days')
                    .format('YYYY-MM-DD');
            }

            return {
                'name|first-name|last-name': this.search === '' ? void 0 : this.search,
                'with-tags':                 this.withTagContact.join() === '' ? void 0 : this.withTagContact.join(),
                'without-tags':              this.withoutTagContact.join() === '' ? void 0 : this.withoutTagContact.join(),
                from:                        fromDate === '' ?  void 0 : moment(fromDate).utc()
                    .format('YYYY-MM-DD'),
                to: this.endDateSearch === '' ? void 0 : moment(this.endDateSearch).utc()
                    .format('YYYY-MM-DD')
            };
        },

        /**
         * Activate search in contact list by typing in search bar
         * Set timout of 1 second to give time to user to type before making a ajax call
         *
         * @param     {string}    value of search bar in this.search
         *
         * @return    {object}    list of contacts that match the search
         */
        activateFilter () {
            // Cancel the selected contacts
            this.$refs.contactsTag.cancelSelect();

            // Change contact list with filters
            const searchDelay = 1000;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.getContactsPage();
            }, searchDelay);
        },

        /**
         * Get list of contacts by page
         *
         * @return    void
         */
        getContactsPage () {
            // Start loading from the api.
            this.loading = true;

            // Get list of contacts
            this.index({
                filters: this.getFiltersParameter(),
                page:    this.page
            }).then(() => {
                // Finish loading
                this.loading = false;
            });
        }
    }
};
</script>
