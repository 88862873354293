import Vue       from 'vue';
import VueRouter from 'vue-router';
import Routes    from '@/routes/routes.js';
import RoutesFr  from '@/routes/routes.fr.js';
import RoutesEn  from '@/routes/routes.en.js';
import Store from '@/core/store';

// Routing
Vue.use(VueRouter);

// Catch errors from VueRouter.push()
const originalPush       = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }

    return originalPush.call(this, location).catch((err) => {
        console.warn(err);
    });
};

// Create the router
const router = new VueRouter({
    mode:   'history',
    routes: RoutesFr.concat(RoutesEn, Routes)
});

// Set cocoapp like default title and add meta title if exist
const DEFAULT_TITLE = 'Cocoapp';
router.afterEach((to) => {
    document.title = typeof to.meta.title !== 'undefined'
        ? `${DEFAULT_TITLE} - ${to.meta.title}`
        : DEFAULT_TITLE;
});

// Guard that verify if user is not authenticated
function userNotAuthenticated (to) {
    return Store.state.core.auth.user === null &&
    (to.name !== 'login'
    && !to.name.includes('saml-login')
    && !to.name.includes('2fa-login')
    && !to.name.includes('forgot')
    && !to.name.includes('reset-password')
    && !to.name.includes('register-password'));
}

// Guard that verify if selected tenant is null
function selectedTenantIsNull (to) {
    return Store.state.core.tenants.selectedTenant === null &&
    (to.name !== 'login'
    && !to.name.includes('saml-login')
    && !to.name.includes('2fa-login')
    && !to.name.includes('tenant-list')
    && !to.name.includes('forgot')
    && !to.name.includes('reset-password')
    && !to.name.includes('register-password'));
}

// Guard that verify if user is authenticated and session exists
function userAuthenticated (to) {
    return Store.state.core.auth.user !== null && Store.state.core.session.sessionExists &&
    (to.name === 'login'
    || to.name.includes('forgot')
    || to.name.includes('reset-password')
    || to.name.includes('register-password'));
}

// Before each redirection check if the tenant is selected.
router.beforeEach((to, from, next) => {
    // Before each redirection check if an authenticated user exists and if a tenant is selected.
    if (userNotAuthenticated(to)) {
        next({ name: 'login' });
    } else if (selectedTenantIsNull(to)) {
        next({ name: `tenant-list:${router.app.$i18n.locale}` });
    } else if (userAuthenticated(to)) {
        next({ name: `home:${router.app.$i18n.locale}` });
    } else {
        next();
    }
});

export default router;
