<template>
    <div id="tags-list" class="container-fluid main-content">
        <div class="row pr-3 pl-2 mb-4">
            <div class="col-sm text-left">
                <h3 class="section-title ">{{ $t('tags') }}</h3>
            </div>
        </div>
        <div class="white-box m-2 p-4" v-if="firstLoading === false">
             <div class="row justify-content-between px-3 py-1">
                <div class="col-6">
                    <input
                        type="text"
                        class="form-control search-contact text-small py-1"
                        v-bind:placeholder="$t('search')"
                        v-model="search">
                </div>
                <div class="col-6 row">
                    <div class="col-9 text-right pr-0">
                        <input
                            type="text"
                            class="form-control text-small py-1 pr-0"
                            v-bind:placeholder="$t('add-tags')"
                            v-model=newTag
                            v-on:keyup.enter="addTag()"
                            :disabled="search !== '' "
                        >
                    </div>
                    <div class="col-3">
                        <button class="btn btn-primary rounded-pill d-flex" @click="addTag()" :disabled="disabled = activeBtn ">
                            <span class="spinner-border spinner-border-sm mt-1 mr-1" role="status" aria-hidden="true" v-if="loading"></span>
                            {{ $t('add') }}
                        </button>
                    </div>
                </div>
            </div>
            <table class="table table-borderless tag-table">
                <thead class="seperator thead-border">
                    <tr>
                        <th class="text-left number-contacts">{{$t('contacts-id')}}</th>
                        <th class="text-center">{{$t('tags')}}</th>
                        <th class="text-left">&nbsp;</th>
                    </tr>
                </thead>
                <tbody class="tag-table-body">
                    <preloader-placeholder :loading="firstLoading" :resultlength="filteredList.length" :type="'table'"></preloader-placeholder>
                    <tr v-for="(tag, i) in filteredList" :key="tag.id">
                        <td class="text-primary text-right nb-contacts">
                            {{ tag.relationships.contacts.data ? tag.relationships.contacts.data.length : 0 }}
                        </td>
                        <td class="tags text-center">
                            <div class="form-group m-0">
                                <input
                                    ref="tag"
                                    :id="`edit-tag-${tag.id}`"
                                    type="text"
                                    :name="`tag-${tag.id}`"
                                    v-validate="'required'"
                                    class="form-control edit-menu text-center"
                                    :class="{ 'is-invalid': errors.has(`tag-${tag.id}`) }"
                                    v-model="tag.attributes.name"
                                    @blur="updateTag(i, tag.id, tag.attributes.name)"
                                    @keyup.enter="$event.target.blur()"
                                    @focus="old = tag.attributes.name">
                            </div>
                        </td>
                        <td  class="edit-section">
                            <label :for="`edit-tag-${tag.id}`" class="info-label mt-2">
                                <font-awesome-icon class="text-grey edit-btn" :icon="['fas', 'pencil-alt']"  />
                            </label>
                            <button type="button" class="close ml-5 my-2" aria-label="Close" @click="removeTag(i, tag.id, tag.attributes.name)">
                                <font-awesome-icon class="text-muted text-small edit-btn" :icon="['fas', 'times']" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>

// Internal repository
import Alert                from '../../../helpers/Alert.js';
import PreloaderPlaceholder from '../PreloaderPlaceholder.vue';

// Import external libraries
import { mapGetters, mapActions } from 'vuex';
import _                          from 'underscore';

// Import Really Fast Deep Copy
const clone = require('rfdc')();

export default {
    components: {
        PreloaderPlaceholder
    },

    created () {
        // Show the preloader.
        this.firstLoading = true;

        // Get the tags
        this.getTags()
            .then(() => {
                // Hide the preloader.
                this.firstLoading = false;
            });
    },

    data () {
        return {
            old:          '',
            loading:      false,
            newTag:       '',
            search:       '',
            firstLoading: false,
            disabled:     true
        };
    },

    computed: {
        ...mapGetters('core/tags', {
            tags: 'tags'
        }),

        /**
         * Search tag in the list
         */
        filteredList () {
            // Generates a filtered array from the tag list
            return clone(_.filter(this.tags, tag => tag.attributes.name.toLowerCase().includes(this.search.toLowerCase())));
        },

        /**
         * Change button status
         */
        activeBtn () {
            return this.newTag === '';
        }
    },

    methods: {
        ...mapActions('core/tags', {
            getTags:   'index',
            createTag: 'create',
            patchTag:  'update',
            deleteTag: 'delete'
        }),

        /**
         * Detele selected tag
         *
         * @param   {integer}   position    The position of tag in contact tags table.
         * @param   {integer}   tagId       The id of the tag.
         * @param   {string}    tagName     The name of the tag.
         *
         */
        removeTag (position, tagId, tagName) {
            Alert.tagDeleteConfirmation('title-tags-delete', 'confirm-tags-delete', tagName)
                .then((response) => {
                    if (!response.isConfirmed) {
                        return;
                    }

                    // Delete tag in db
                    this.deleteTag({
                        id: tagId
                    }).then(() => {
                        Alert.success(this.$i18n.t('tag-deleted-success'));
                    }).catch(() => {
                        Alert.fail(this.$i18n.t('default-error'));
                    });
                });
        },

        /**
         * Update selected tag
         *
         * @param   {integer}   tagId       The id of the tag.
         * @param   {string}    tagName     The name of the tag.
         */
        updateTag (position, tagId, tagName) {
            // Check if the newTag exist
            if (_.filter(this.tags, tag => tag.id !== tagId).find(tag => tag.attributes.name === tagName)) {
                Alert.fail(this.$i18n.t('tags-create-error'));

                // Refocus the input concerned
                this.$refs.tag[position].focus();
                return;
            }

            // Check if tag input empty or unchanged
            if (!tagName.length || this.old === tagName) {
                return;
            }

            // Update tag in db
            this.patchTag({
                id:   tagId,
                name: tagName
            })
                .then(() => Alert.success(this.$i18n.t('information-updated')));
        },

        /**
         * Create tag
         */
        addTag () {
            // Check if the newTag exist
            if (_.find(this.tags, tag => tag.attributes.name === this.newTag)) {
                Alert.fail(this.$i18n.t('tags-create-error'));

                // Clear input
                this.newTag = '';
                return;
            }

            // Spinner on
            this.loading = true;
            this.disabled = true;

            // Create the new tag in db and update tags array
            this.createTag({
                name: this.newTag
            }).then(() => {
                // Spinner off
                this.loading = false;

                // Empty the input new tag
                this.newTag = '';

                // Get the new list of tags
                this.getTags();

                // Success message
                Alert.success(this.$i18n.t('tag-created-success'));
            }).catch(() => {
                Alert.fail(this.$i18n.t('default-error'));
            });
        }
    }
};
</script>
