<template>
    <div id="tenant-box">
        <div class="container-fluid card-info contact-card mb-3">
            <div class="row">
                <div class="col-sm-1">
                    <router-link class="align-self-center" :to="{ name: '' }" @click.native="$emit('choose-tenant', tenant.id)">
                        <tenants-avatar :tenant="tenant"></tenants-avatar>
                    </router-link>
                </div>
                <div class="col-sm text-left d-flex">
                    <router-link class="align-self-center" :to="{ name: '' }" @click.native="$emit('choose-tenant', tenant.id)">
                        <p class="m-0 text-btn">{{ tenant.attributes.name }}</p>
                    </router-link>
                </div>
                <div class="col-sm text-left align-self-center">
                    <p class="m-0 ml-5 text-primary">{{ this.tenant.attributes['is-enabled'] ? $t('active') : $t('inactive') }}</p>
                </div>
                <div class="col-sm text-left align-self-center">
                    <p class="m-0 pl-1 text-primary">{{ $t('paid') }}</p>
                </div>
                <div class="col-sm text-left align-self-center">
                    <p class="m-0 pl-3 text-primary">
                        <span v-if="hasConnector('facebook')"><font-awesome-icon :icon="['fab', 'facebook-square']" /></span>
                        <span v-if="hasConnector('sms')"><font-awesome-icon :icon="['fas', 'mobile-alt']" :class="{'ml-5': hasConnector('facebook')}"/></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal components
import TenantsAvatar  from './TenantsAvatar.vue';

export default {
    props: ['tenant'],

    components: {
        TenantsAvatar
    },

    methods: {
        /**
         * Check if a tenant has a specific connector
         *
         * @param  {string}   connectorName
         * @return boolean
         */
        hasConnector (connectorName) {
            return this.tenant.attributes['remote-plan-addons']?.some((addon) => addon.includes(connectorName));
        }
    }
};
</script>
