<template>
    <div id="connector-box" class="d-flex container-fluid connector-card-info message-card">
        <div class="row w-100">
            <div class="col-6 text-left">
                <img class="img-fluid text-center p-3" :src="connectorLogo" alt="" width="270">
            </div>
            <div class="col-6">
                <div class="connected">
                    <div class="row" v-if="connector.attributes.status === $config.connectorsStatus.ESTABLISHED">
                        <div class="col-12">
                            <p class="connected-p">
                                <font-awesome-icon class="text-primary font-check-circle" :icon="['fas', 'check-circle']" />
                                <strong class="text-primary">{{ $t('connection-established') }}</strong>
                            </p>
                        </div>
                        <div class="col-12 connected-by-message" v-if="connector.attributes['connected-by']">
                            <small class="text-grey small-connected-by" v-if="connector.attributes['connected-by']">
                                {{ $t('established-by') }}: {{ connector.attributes['connected-by'] }}
                            </small><br>
                        </div>
                        <div class="col-12 view-settings-link">
                            <router-link
                                :to="{ name: `${connector.attributes.name}-config:${$i18n.locale}`, params: { welcome: this.$route.params.welcome || false } }"
                                class="text-left text-btn"
                                v-if="user.attributes['is-admin'] || user.attributes['is-super-admin']">
                                {{ $t('view-settings') }}
                            </router-link>
                        </div>
                    </div>
                    <div class="text-left" v-if="connector.attributes.status === $config.connectorsStatus.EXPIRED">
                            <p class="mt-5 ml-4">
                                <font-awesome-icon class="exclamation-triangle" :icon="['fas', 'exclamation-triangle']"/>
                                <router-link
                                    :to="{ name: `${connector.attributes.name}-config:${$i18n.locale}`, params: { welcome: this.$route.params.welcome || false }  }"
                                    class="text-left warning-link">
                                    <strong class="warning-text">
                                        {{ $t('restore-connection') }}
                                    </strong>
                                </router-link>
                            </p>
                            <div class="connection-info" v-if="connector.attributes['connected-by']">
                                <small class="text-grey ml-5">
                                    {{ $t('last-connection-established') }}:<br>
                                </small>
                                <small class="text-grey ml-5">
                                    {{ connector.attributes['connected-by'] }}
                                </small>
                            </div>
                        <div class="learn-more-link mt-3 ml-5">
                            <router-link :to="{ name: `help:${$i18n.locale}` }" class="text-left more-info connection-info">{{ $t('learn-more') }}</router-link>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="connector.attributes['is-connected']">
                            <router-link
                                :to="{ name: `${connector.attributes.name}-config:${$i18n.locale}`, params: { welcome: this.$route.params.welcome || false }  }"
                                class="btn fb-btn fb-btn-connectors btn-lg d-flex" v-if="connector.attributes.status === $config.connectorsStatus.UNESTABLISHED">
                                    <font-awesome-icon class="icon text-white fb-icon" :icon="['fab', 'facebook-square']" />
                                    <p class="fb-text">{{$t('continue-with-facebook')}}</p>
                            </router-link>
                        </div>
                        <div v-else>
                            <a href="javascript:void(0)" @click.prevent="openPortalSession" class="btn fb-btn fb-btn-connectors btn-lg d-flex">
                                <font-awesome-icon class="icon text-white fb-icon" :icon="['fab', 'facebook-square']" />
                                <p class="fb-text">{{$t('continue-with-facebook')}}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal services js
import ConnectorMixin from '@/core/mixins/ConnectorMixin';
import chargbeePortalMixin from '@/core/mixins/chargbeePortalMixin';

// Import external libraries
import { mapState } from 'vuex';

export default {
    mixins: [ConnectorMixin, chargbeePortalMixin],
    data () {
        return {
            connectorName: 'facebook',
            connectorLogo: this.$config.images.connectorsLogo['facebook']
        };
    },

    computed: {
        ...mapState('core/connectors', [
            'facebook'
        ])
    }
};
</script>
