<template>
    <div id="help-page" class="d-flex align-items-stretch">
        <navigation></navigation>
        <questionbox></questionbox>
    </div>
</template>
<script>

// Components
import QuestionBox from './QuestionBox.vue';

export default {
    components: {
        'questionbox': QuestionBox
    }
};
</script>
