<template>
    <div id="advance-search" class="ml-3 pl-3">
        <div class="row">
            <div class="ml-3">
                <a href="#" @click="toggleSearch(), adjustHeight()">
                    <p class="text-primary text-left fs-12">
                        <font-awesome-icon class="mr-3 search-arrow" v-bind:class="{ 'fa-rotate-90': activateSearch }" :icon="['fas', 'chevron-right']" />
                        {{ $t('advance-search') }}
                    </p>
                </a>
            </div>
        </div>
        <div class="container-fluid search-section mb-2" v-bind:class="{ hidden: !activateSearch, searchDate: pickDate}">
            <div class="row">
                <div class="col-sm text-left">
                    <span class="fs-14"><font-awesome-icon class="mr-3 text-primary" :icon="['far', 'calendar-alt']"/>{{ $t('select-date') }} : </span>
                    <date-range-picker
                        class="mb-2"
                        ref="picker"
                        :auto-apply="true"
                        v-on:toggle="overflowCheck()"
                        v-model="dateRange"
                        @update="searchDates()"
                        opens="right"
                        :locale-data="localeData"
                        :ranges="localeData.ranges">
                    </date-range-picker>
                    <small class="text-primary ml-4 erase-date" v-if="dateRange.startDate" @click="clearDates">{{ $t('clear-date') }}</small>
                </div>
            </div>
            <form>
                <div class="form-row">
                    <div id="withTagWrap" class="form-group col-md-12 col-lg-6 text-left mb-0 pb-0">
                        <label for="withTag" class="fs-12 text-dark-brown">{{ $t('with-tag') }}</label>
                        <input class="form-control fs-13"
                            id="withTag"
                            name="withTag"
                            v-validate="'required'"
                            :data-vv-as="$t('withTag')"
                            list=”idOfDatalist”
                            v-model="withTagInput"
                            type="text"
                            :placeholder="$t('start-writing')"
                            @change="selectTag($event)"
                            @mouseover="showDatalist()"
                            @mouseleave="hideDatalist()"
                            ref="inputTags">
                        <datalist id=”idOfDatalist” >
                            <option v-for="tag in allTags" :key="tag.id">{{tag.attributes.name}}</option>
                        </datalist>
                        <div class="col-sm d-flex flex-wrap p-0" :class="{'mt-2': selectedWithTags.length>0}">
                            <div class="tags withTags mr-3" v-for="(tag, i) in selectedWithTags" :key="i">
                                <p class="mb-0">{{ tag }}
                                    <button type="button" class="close tags-button" aria-label="Close" @click="removeTag($event, i, 'selectedWithTags')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="withoutTagWrap" class="form-group col-md-12 col-lg-6 text-left mb-0 pb-0">
                        <label class="fs-12 text-dark-brown" for="withoutTag">{{ $t('without-tag') }}</label>
                        <input class="form-control fs-13"
                            id="withoutTag"
                            name="withoutTag"
                            v-validate="'required'"
                            :data-vv-as="$t('withoutTag')"
                            list=”idOfDatalist”
                            v-model="withoutTagInput"
                            type="text"
                            :placeholder="$t('start-writing')"
                            @change="selectTag($event)"
                            @mouseover="showDatalist()"
                            @mouseleave="hideDatalist()"
                            ref="inputTags">
                        <datalist id=”idOfDatalist” >
                            <option v-for="tag in allTags" :key="tag.id">{{tag.attributes.name}}</option>
                        </datalist>
                        <div class="col-sm d-flex flex-wrap p-0" :class="{'mt-2': selectedWithoutTags.length>0}">
                            <div class="tags withoutTags mr-3" v-for="(tag, i) in selectedWithoutTags" :key="i">
                                <p class="mb-0">{{ tag }}
                                    <button type="button" class="close tags-button" aria-label="Close" @click="removeTag($event, i, 'selectedWithoutTags')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>

// Internal services js
import { EventBus } from '../../../helpers/EventBus.js';

// External libraries
import DateRangePicker            from 'vue2-daterange-picker';
import _                          from 'underscore';
import { mapGetters, mapActions } from 'vuex';
import moment                     from 'moment';

// You need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    components: {
        DateRangePicker
    },

    created () {
        // Get tge tags
        this.index();

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);

        this.localeData.ranges[this.$i18n.t('today')]      = [today, today];
        this.localeData.ranges[this.$i18n.t('yesterday')]  = [yesterday, yesterday];
        this.localeData.ranges[this.$i18n.t('this-year')]  = [
            new Date(moment().clone().startOf('year').format('YYYY-MM-DD hh:mm')),
            new Date(moment().clone().endOf('year').format('YYYY-MM-DD hh:mm'))
        ];
        this.localeData.ranges[this.$i18n.t('last-month')] = [
            new Date(moment().subtract(1,'months').clone().startOf('month').format('YYYY-MM-DD hh:mm')),
            new Date(moment().subtract(1,'months').clone().endOf('month').format('YYYY-MM-DD hh:mm'))
        ];
    },

    data () {
        return {
            selectedWithTags:    [],
            selectedWithoutTags: [],
            withTagInput:        '',
            withoutTagInput:     '',
            submitted:           false,
            firefoxNavigator:    false,
            pickDate:            false,
            activateSearch:      false,
            showSort:            false,
            dateRange:           {
                startDate: '',
                endDate:   ''
            },
            localeData: {
                applyLabel:  this.$i18n.t('search'),
                cancelLabel: this.$i18n.t('cancel'),
                ranges:      {},
                daysOfWeek:  [this.$i18n.t('su'), this.$i18n.t('mo'), this.$i18n.t('tu'), this.$i18n.t('we'), this.$i18n.t('th'), this.$i18n.t('fr'), this.$i18n.t('sa')],
                monthNames:  [this.$i18n.t('january'), this.$i18n.t('february'), this.$i18n.t('march'), this.$i18n.t('april'), this.$i18n.t('may'), this.$i18n.t('june'), this.$i18n.t('july'), this.$i18n.t('august'), this.$i18n.t('september'), this.$i18n.t('october'), this.$i18n.t('november'), this.$i18n.t('december')]
            }
        };
    },

    computed: {
        ...mapGetters('core/tags', {
            'allTags': 'tags'
        })
    },

    mounted () {
        // Check if FireFox navigator
        const CheckNumber = -1;
        if (navigator.userAgent.indexOf('Firefox')  !==  CheckNumber) {
            this.firefoxNavigator = true;
        }
    },

    methods: {
        ...mapActions('core/tags', [
            'index'
        ]),

        // Send date range to the contact list
        searchDates () {
            EventBus.$emit('startDateSearch', this.dateRange.startDate);
            EventBus.$emit('endDateSearch', this.dateRange.endDate);
        },

        // Clear the date range and the input
        clearDates () {
            this.dateRange.startDate = null;
            this.dateRange.endDate = null;
            EventBus.$emit('startDateSearch', this.dateRange.startDate);
            EventBus.$emit('endDateSearch', this.dateRange.endDate);
        },

        // Close date picker calendar if the advance search box is close
        toggleSearch () {
            this.activateSearch = !this.activateSearch;
            if (this.activateSearch === false && this.pickDate === true) {
                this.pickDate = false;
            }
        },

        /**
         * Show dataList in Firefox navigator case
         */
        showDatalist () {
            if (this.firefoxNavigator === true) {
                this.showSort = true;
                this.$refs.inputTags.select();
            }
        },

        /**
         * Hide dataList in Firefox navigator case
         */
        hideDatalist () {
            if (this.firefoxNavigator === true) {
                this.showSort = false;
            }
        },

        /*
         * Add and Sort tags to be used for the advance search in the apropreate array
         *
         * @param event the select of a tag or submit of a tag in the input
         *
         * return 2 diffetent array one for the search with specific tag and one without specific tags
         */
        selectTag (event) {
            // Check witch input is used
            const validatename = event.target.id;

            // Validate the input
            this.$validator.validate(validatename).then(valid => {
                if (!valid) {
                    return;
                }

                // Check if tag was already used in one of the arrays
                const searchTags = this.selectedWithTags.concat(this.selectedWithoutTags);
                let existTag = '';

                // Check in which input the event is happening
                if (validatename === 'withTag') {
                    existTag = _.find(searchTags, (tag) =>

                        // Condition to return a tag unused in the with tag array
                        tag === this.withTagInput
                    );
                } else {
                    existTag = _.find(searchTags, (tag) =>

                        // Condition to return a tag unused in the without tag array
                        tag === this.withoutTagInput
                    );
                }

                // Condition to return nothing if a tag is already used in an array
                if (typeof existTag !== 'undefined') {
                    this.withTagInput = '';
                    this.withoutTagInput = '';
                    return;
                }

                // Check in which input the tag was added and send the information to the AllCOntactList components
                if (validatename === 'withTag') {
                    this.selectedWithTags.push(this.withTagInput);
                    EventBus.$emit('with-tag-search', this.selectedWithTags);
                    this.withTagInput = '';
                } else if (validatename === 'withoutTag') {
                    this.selectedWithoutTags.push(this.withoutTagInput);
                    EventBus.$emit('without-tag-search', this.selectedWithoutTags);
                    this.withoutTagInput = '';
                }
            });
            event.preventDefault();
        },

        /**
         * Remove tag from the search query
         *
         * @param event of the close button on the tag
         * @param {integer} position  The position of tag in the search query.
         * @param {string}  list      The list to remove from.
         */
        removeTag (event, position, list) {
            this[list].splice(position, 1);

            // Send updated information to both arrays in the AllCOntactList components
            EventBus.$emit('with-tag-search', this.selectedWithTags);
            EventBus.$emit('without-tag-search', this.selectedWithoutTags);
            event.preventDefault();
        },

        // Adjust the contact list height when the advance search is open
        adjustHeight () {
            EventBus.$emit('advance-search');
        },

        // Datepicker modal overflow
        overflowCheck () {
            if (this.$refs['picker'].open === true) {
                this.pickDate = true;
            }
        }
    }
};
</script>
