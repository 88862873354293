<template>
    <div id="user-list" class="container-fluid main-content">
        <div class="row pr-3 pl-2 mt-4 mb-4">
            <div class="col-lg-6 col-md-4 col-12 text-left">
                <h3>{{ $t('users') }}</h3>
            </div>
            <div class="col-lg-6 col-md-8 col-12 text-right d-flex justify-content-end">
                <p class="text-primary m-0 mr-3 align-self-center text-btn" @click="exportUser()">
                    <font-awesome-icon class="mr-3 text-primary" :icon="['fas', 'upload']"/>
                    {{ $t('export') }}
                </p>
                <p class="text-primary m-0 mr-3 align-self-center text-btn" data-toggle="modal" data-target="#import-user">
                    <font-awesome-icon class="mr-3 text-primary" :icon="['fas', 'download']"/>
                    {{ $t('import') }}
                </p>
                <button type="button" class="btn btn-primary rounded-pill" data-toggle="modal" data-target="#invite-user">{{ $t('invite-user') }}</button>
            </div>
            <import-user></import-user>
            <invite-user></invite-user>
        </div>
        <preloader-placeholder :loading="loading" :resultlength="(users ? users.length : 0)" :type="'user'"></preloader-placeholder>
        <div class="box-wrap pl-2 pr-4" v-if="loading !== true">
            <user-box
                v-for="user in users"
                :key="user.id"
                v-bind:user="user">
            </user-box>
        </div>
    </div>
</template>
<script>

// Components
import PreloaderPlaceholder from '../../PreloaderPlaceholder.vue';
import UserBox              from './UserBox.vue';
import InviteUser           from './InviteUser.vue';
import ImportUser           from './ImportUser.vue';
import { EventBus }         from '@/helpers/EventBus.js';
import moment from 'moment';

// Import external libraries
import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex';

// Internal services js
import Alert        from '@/helpers/Alert.js';

export default {
    components: {
        UserBox,
        PreloaderPlaceholder,
        InviteUser,
        ImportUser
    },

    data () {
        return {
            newUser: {
                'email':      '',
                'last-name':  '',
                'first-name': '',
                'language':   'fr',
                'is-enabled': true,
                'is-admin':   false
            },
            submitted: false,
            loading:   false
        };
    },

    computed: {
        ...mapGetters('core/users', [
            'users'
        ]),
        ...mapState('core/auth', [
            'user'
        ])
    },
    created () {
        // Check if this user is an admin
        if (!this.user.attributes['is-admin'] && !this.user.attributes['is-super-admin']) {
            this.$router.push({ name: `facebook-messages:${this.$i18n.locale}` });
        }
    },

    mounted () {
        // Show preloader
        this.loading = true;

        // Get a list of users
        this.getUsers()
            .then(() => {
                // Hide preloader
                this.loading = false;
            });

        // Update the user list after importing users
        EventBus.$on('user-imported', () => {
            this.loading = true;
            this.getUsers()
                .then(() => {
                    // Hide preloader
                    this.loading = false;
                });
        });
    },

    methods: {
        /**
         * Define moment to use it in template
         */
        moment,

        /**
         * Methods for users API request
         *
         * getUsers     get list of users for this tenant
         * export       export list of users in excel sheet
         */
        ...mapActions('core/users', {
            'getUsers': 'index',
            'export':   'export'
        }),

        /**
         * Call Export Users.
         */
        exportUser () {
            Alert.confirmation(
                this.$i18n.t('confirmation-export-contacts', { email: this.user.attributes.email }),
                this.$i18n.t('export'),
                this.$i18n.t('confirmation-required'),
                '#14866D'
            )
                .then((response) => {
                    if (response.isConfirmed) {
                        this.export()
                            .then(() => {
                                Alert.success(`${this.$i18n.t('file-send')}${this.user.attributes.email}".`);
                            })
                            .catch(() => {
                                Alert.fail(this.$i18n.t('mail-faild'));
                            });
                    }
                });
        }
    }
};
</script>
