<template>
<div class="align-items-stretch">
    <badge></badge>
    <div id="user-login" class="login-interface">
        <div class="container">
            <div class="row justify-content-center">
                <div class="login-box p-5">
                    <div class="row">
                        <div class="col-sm mb-4"><img class="img-logo text-center" src="../../../assets/images/logo.png" alt=""></div>
                    </div>
                    <popover name="rules" id="rules">
                        <div class="alert alert-warning show-rules" role="alert">
                            <password-rules></password-rules>
                        </div>
                    </popover>
                    <form class="text-left">
                        <div class="form-group" v-if="newUser === true">
                            <label for="email">{{ $t('email') }}</label>
                            <input type="text" name="email" class="form-control" id="email" v-model="email" disabled>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-10"><label for="newPassword">{{ newUser ? $t('choose-password') : $t('new-password')  }}</label></div>
                                <div class="col-2 text-right">
                                    <div v-popover.right="{name:'rules'}" type="button" ref="rules">
                                        <font-awesome-icon class="text-primary info-circle" :icon="['fa', 'info-circle']" />
                                    </div>
                                </div>
                            </div>
                            <input type="password" name="newPassword" ref="password" class="form-control" v-validate="'required|verify_password'" @keyup="showRules()" :class="{ 'is-invalid': submitted && errors.has('newPassword'), 'is-valid': submitted && !errors.has('newPassword')}" id="newPassword" :data-vv-as="$t('new-password')" v-model="newPassword">
                            <div class="invalid-feedback" v-if="submitted">
                                {{ errors.first('newPassword') }}
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="confirmPassword">{{ newUser ? $t('confirm-password') : $t('confirm-new-password') }}</label>
                            <input type="password" name="confirmPassword" class="form-control" v-validate="'required|confirmed:password'" :class="{ 'is-invalid': submitted && errors.has('confirmPassword'), 'is-valid': submitted && !errors.has('confirmPassword') && !errors.has('newPassword')}"  id="confirmPassword" :data-vv-as="$t('confirm-password')" v-model="confirmPassword">
                            <div class="invalid-feedback" v-if="submitted">
                                {{ errors.first('confirmPassword') }}
                            </div>
                        </div>
                        <router-link :to="{ name: '' }" class="button-link">
                            <button type="submit" class="btn btn-primary rounded-pill" @click="sendPassword()" :disabled="loading">{{ newUser ? $t('save') : $t('send') }}
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                            </button>
                        </router-link>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>

// Components
import PasswordSecurityRules  from './PasswordSecurityRules.vue';

// Internal services js
import Alert from '@/helpers/Alert.js';

// Import external libraries
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        'passwordRules': PasswordSecurityRules
    },

    data () {
        return {
            newPassword:     '',
            confirmPassword: '',
            loading:         false,
            submitted:       false,
            email:           '',
            token:           '',
            errorMessage:    '',
            defaultError:    false,
            newUser:         false
        };
    },

    computed: {
        ...mapState('core/session', [
            'sessionExists',
            'sessionSelectedTenant',
            'sessionUser'
        ])
    },

    mounted () {
        // If we have a new user, we need to create a password
        this.newUser = this.$router.currentRoute.name.includes('register-password');

        // Get user's email and token
        this.email = this.$route.query.email;
        this.token = this.$route.query.token;
    },

    /**
     * Check function
     * Before create this page check if user are login
     */
    beforeCreate () {
        if (this.sessionExists === true) {
            if (this.sessionUser !== null && this.sessionSelectedTenant !== null) {
                this.$router.replace({
                    name: `facebook-connector:${this.$i18n.locale}`
                });
            }
        }

        // If a language is passed, set the locale
        if (typeof this.$route.query.language !== 'undefined') {
            this.$i18n.locale = this.$route.query.language;

            // Set the locale into local storage
            localStorage.setItem('locale', this.$route.query.language);
        }
    },
    methods: {
        ...mapActions('core/auth', [
            'resetPassword'
        ]),

        /**
         * Show the password rules.
         *
         * @returns    void
         */
        showRules () {
            const timeout = 500;
            setTimeout(() => {
                let popover = document.getElementById('rules');

                if ((popover === null && this.errors.has('newPassword')) || (popover !== null && !this.errors.has('newPassword'))) {
                    this.$refs.rules.click();
                }
            }, timeout);
        },

        /**
         * Send Password
         * Send new password to api
         */
        sendPassword () {
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.resetPassword({
                        email:           this.email,
                        token:           this.token,
                        password:        this.newPassword,
                        confirmPassword: this.confirmPassword
                    })
                        .then(() => this.sentSuccess())
                        .catch((error) => {
                            this.loading = false;

                            if (error.response.detail) {
                                Alert.fail(error.response.detail);
                            }
                        });
                }
            });
        },

        /**
         * Sent success
         *
         */
        sentSuccess () {
            this.loading = false;
            Alert.success(this.$i18n.t('password-changed'));

            this.$router.replace({
                name: 'login'
            });
        }
    }
};
</script>
