<template>
    <div class="row justify-content-center " id="bloc-onglets-steps"  v-if="showOngletsSteps">
        <span v-for="stepNbr in nbrOfSteps" class="step-onglet"
            v-bind:key="stepNbr"
            @click.prevent="onClickStepNbr(stepNbr)"
            :class="{'onglet-activated': stepNbr === stepCurr}">
            {{getStep(stepNbr)}}
        </span>
    </div>
</template>

<script>

export default {
    emits: ['nextStepTriggered'],
    props: {
        stepCurrent:      Number,
        nbrOfSteps:       Number,
        showOngletsSteps: Boolean
    },
    data () {
        return { stepCurr: 1 };
    },
    watch: {
        stepCurrent () {
            this.stepCurr = this.stepCurrent;
        }
    },
    beforeMount () {
        this.stepCurr = this.stepCurrent;
    },
    methods: {
        /**
         * Get the content of the onglet
         *
         * @param  {number}  stepNbr  nbr of step
         * @return {string}  Name of onglet
         */
        getStep (stepNbr) {
            return `${this.$i18n.t('step')} ${stepNbr}`;
        },

        /**
         * Increment the steps and emit the click to the parent component when the onglet is clicked
         *
         * @param  {number}  step
         * @return emit event
         */
        onClickStepNbr (step) {
            this.stepCurr = step;
            this.$emit('nextStepTriggered', this.stepCurr);
        }
    }
};
</script>
