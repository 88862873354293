<template>
<div id="contact-list-tag" class="ml-3 pl-3 pt-2">
    <div v-if="selectContact == false" class="row mb-3">
        <div class="ml-3 text-left">
            <a href="#" @click="callSelectContact()" v-if="!globalLoading">
                <p class="text-btn mb-0">{{ $t('manage-tag') }}</p>
            </a>
        </div>
    </div>
    <popover name="add-tags" event="hover" :width="335" id="add-tag-popover" v-if="selectedContacts.length === 0">
        <p class="mb-0">{{ $t('choose-one-contact') }}</p>
    </popover>
    <div v-if="selectContact === true" class="row mb-3">
        <div class="col-sm ml-4 all-checkbox text-left">
            <label class="custom-checkbox">
                <input type="checkbox" class="custom-control-input" v-model="Allcheckbox" @click="selectOrUnselectAll()">
                <span class="custom-control-label"></span>
            </label>
            <span v-if="addTag == false && removeTag == false">
                <a href="#" class="underlined-link text-btn" @click="addTag = true">
                    {{ $t('add') }}
                </a>
                {{ $t('or') }}
                <a href="#" class="underlined-link text-btn" @click="removeTag = true">
                    {{ $t('remove') }}
                </a>
                {{ $t('a-tag') }} ?
            </span>
            <label v-if="addTag == true">
                <input class="tags-input mr-3"
                        ref="inputTags"
                        name="tags"
                        type="text"
                        v-model="tag"
                        autocomplete="off"
                        list=”idOfDatalist”
                        :disabled="showMessage === true"
                        :placeholder="$t('start-writing')">
                    <datalist id=”idOfDatalist” >
                        <option v-for="tag in allTags" :key="tag.id">{{tag.attributes.name}}</option>
                    </datalist>
                <button
                    class="btn btn-primary rounded-pill"
                    :class="{disabled : (!tag || selectedContacts.length === 0 || showMessage === true) }"
                    @click="addContactsTag()"
                    v-popover.right="{ name: 'add-tags' }"
                >
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                    {{ $t('add') }}
                </button>
            </label>
            <label v-if="removeTag == true">
                <input class="tags-input mr-3"
                        ref="inputTags"
                        name="tags"
                        type="text"
                        v-model="tag"
                        autocomplete="off"
                        list=”idOfDatalist”
                        :disabled="showMessage === true"
                        :placeholder="$t('start-writing')">
                    <datalist id=”idOfDatalist” >
                        <option v-for="tag in allTags" :key="tag.id">{{tag.attributes.name}}</option>
                    </datalist>
                <button class="btn btn-primary rounded-pill" :class="{disabled : (!tag || selectedContacts.length === 0 || showMessage === true) }" @click="getTag()"  v-popover.right="{ name: 'add-tags' }">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                    {{ $t('remove') }}
                </button>
            </label>
        </div>
        <div class="pr-5 text-right align-self-center" v-bind:class="{ 'col-md-2': collapseSidebar }">
            <a href="#" class="text-btn"  @click="cancelSelect()">{{ $t('cancel') }}</a>
        </div>
    </div>
    <div class="row mb-3" v-if="showMessage == true">
        <div class="col-sm text-left ml-4">
            <small class="d-flex">{{ selectedContacts.length }} {{ $tc('contact-tag-info', selectedContacts.length) }} <span class="tags"><p class="contact-list-tag">{{tag}}</p></span>{{ addTag === true ? $t('added') : $t('removed') }}</small>
        </div>
    </div>
</div>
</template>
<script>

// Internal services js
import { EventBus } from '../../../helpers/EventBus.js';

// Import external libraries
import { mapGetters, mapActions } from 'vuex';
import Alert from '../../../helpers/Alert.js';

export default {
    props: ['globalLoading', 'contacts', 'collapseSidebar'],
    data () {
        return {
            selectContact:    false,
            Allcheckbox:      false,
            addTag:           false,
            removeTag:        false,
            selectedContacts: [],
            tag:              null,
            showMessage:      false,
            timer:            null,
            originalTag:      null,
            loading:          false
        };
    },
    created () {
        this.getTags();
    },
    mounted () {
        EventBus.$on('selected-contact', (contact, isSelected) => {
            if (isSelected) {
                // Add the selected contact to the list.
                this.selectedContacts.push(contact);

                // If all contacts selected make AllCheckbox at true.
                if (this.selectedContacts.length === this.contacts.length) {
                    this.Allcheckbox = true;
                }
            } else {
                //  Remove the unselected contact from the list.
                this.Allcheckbox = false;
                this.selectedContacts = this.selectedContacts.filter(selectedContact => selectedContact !== contact);
            }
        });
    },

    computed: {
        ...mapGetters('core/tags', {
            allTags: 'tags'
        }),
    },

    methods: {
        ...mapActions('core/contacts', {
            getContacts: 'index'
        }),

        ...mapActions('core/tags', {
            getTags:               'index',
            createTag:             'create',
            removeTagFromContacts: 'removeFromContacts'
        }),

        /**
         * Call the select contacts methods.
         *
         * @return    void
         */
        callSelectContact () {
            this.selectContact = true;
            EventBus.$emit('open-select', this.selectContact);
        },

        /**
         * Cancel the select button
         *
         * @return    void
         */
        cancelSelect () {
            if (this.addTag === true || this.removeTag === true) {
                this.addTag = false;
                this.removeTag = false;
            }

            // Unselect all and hide checkboxes
            this.Allcheckbox = false;
            EventBus.$emit('unselect-all');

            this.selectedContacts = [];
            this.selectContact    = false;
            this.tag              = null;
            EventBus.$emit('open-select', this.selectContact);
        },

        /**
         * Select or unselect all contats
         *
         * @return    void
         */
        selectOrUnselectAll () {
            if (this.Allcheckbox === false) {
                this.Allcheckbox = true;
                this.selectedContacts = Object.values(this.contacts);
                EventBus.$emit('select-all');
            } else {
                this.Allcheckbox = false;
                this.selectedContacts = [];
                EventBus.$emit('unselect-all');
            }
        },

        /**
         * Add a new tag for the selected contacts.
         *
         * @return    void
         */
        addContactsTag () {
            if (!this.tag || this.selectedContacts.length === 0 || this.showMessage === true) {
                return;
            }

            // Start loading
            this.loading = true;

            this.createTag({
                name:     this.tag,
                contacts: this.selectedContacts
            })
                .then(() => {
                    // this.getTags();

                    // End loading
                    this.loading = false;

                    this.afterUpdateContactsTags();
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /**
         * Get the tag from the API.
         *
         * @return    {object}    tag
         */
        getTag () {
            if (!this.tag || this.selectedContacts.length === 0 || this.showMessage === true) {
                return;
            }

            const tagToRemove = Object.values(this.allTags).find(tag => tag.attributes.name === this.tag);

            if (!tagToRemove) {
                Alert.fail(this.$i18n.t('tags-contact-remove-error'));
                return;
            }

            this.removeContactsTag(tagToRemove);
        },

        /**
         * Remove tag from the selected contacts.
         *
         * @param    {object}     originalTag     the original tag.
         *
         * @return     void
         */
        removeContactsTag (originalTag) {
            // Start loading
            this.loading = true;

            this.removeTagFromContacts({
                id:       originalTag.id,
                contacts: this.selectedContacts
            })
                .then(() => {
                    // End loading
                    this.loading = false;
                    this.afterUpdateContactsTags();
                })
                .catch((response) => {
                    console.log(response);
                });
        },

        /**
         * After add a new tag.
         *
         * @return    void
         */
        afterUpdateContactsTags () {
            this.showMessage = true;

            this.getContacts().then(() => {
                const time = 2000;
                setTimeout(() => {
                    this.showMessage      = false;
                    this.tag              = null;
                    this.selectedContacts = [];

                    // Unselect all and hide checkboxes
                    this.Allcheckbox = false;
                    EventBus.$emit('unselect-all');
                }, time);
            });
        }
    }
};
</script>
