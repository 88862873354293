<template>
    <div id="sms-admin-messages" class="container-fluid main-content">
        <div class="row pr-3 pl-2 mb-3 pl-3">
            <div class="col-sm text-left pl-4">
                <h3 class="section-title ">{{ $t('all-sms-conversations') }}</h3>
                <h6>{{ smsNumber }}</h6>
            </div>
        </div>
        <div class="sms-admin-menu menu-tab pl-4">
            <ul class="nav">
                <li class="nav-item all-messages-tab" :class="{'active':currentSection === OTHER_USERS_CONVERSATIONS_SECTION}">
                    <router-link :to="{ name: '' }" :class="{ 'menu-selected' : currentSection === OTHER_USERS_CONVERSATIONS_SECTION }"
                        @click.native="switchSection(OTHER_USERS_CONVERSATIONS_SECTION)">
                        {{ $t('active-conversation')}} ({{ otherUsersConversations && otherUsersConversations.meta.page ? otherUsersConversations.meta.page.total : 0 }})
                    </router-link>
                </li>
                <li class="nav-item messages-sent-tab" :class="{'active':currentSection === WITHOUT_USERS_CONVERSATIONS_SECTION}">
                    <router-link :to="{ name: ''}" :class="{ 'menu-selected' : currentSection === WITHOUT_USERS_CONVERSATIONS_SECTION }"
                        @click.native="switchSection(WITHOUT_USERS_CONVERSATIONS_SECTION)">
                        {{ $t('sms-in-waiting') }} ({{ noUsersConversations && noUsersConversations.meta.page ? noUsersConversations.meta.page.total : 0 }})
                    </router-link>
                </li>
                <li class="nav-item mymessages-tab" :class="{'active':currentSection === CURRENT_USER_CONVERSATIONS_SECTION}">
                    <router-link :to="{ name: ''}" :class="{ 'menu-selected' : currentSection === CURRENT_USER_CONVERSATIONS_SECTION}"
                        @click.native="switchSection(CURRENT_USER_CONVERSATIONS_SECTION)">
                        {{ $t('sms-my-conversations') }} ({{ currentUserConversations && currentUserConversations.meta.page ? currentUserConversations.meta.page.total : 0 }})

                        <!-- Notification bulle of new messages (hidden for now) -->
                        <BulleNbrNewMessages v-if="false"></BulleNbrNewMessages>
                    </router-link>
                </li>
            </ul>
        </div>
        <!-- All conversations section -->
        <conversations-box @switchSection="switchSection($event.to)" :currentSection="currentSection" :loadingNumber="loadingNumber"></conversations-box>
    </div>
</template>

<script>

// Components
import ConversationsBox    from './ConversationsBox.vue';
import BulleNbrNewMessages from '../BulleNbrNewMessages.vue';

// Import external libraries
import {
    mapState, mapActions
} from 'vuex';
import Format from '@/helpers/Format.js';

export default {
    data () {
        // Available admin sections
        const OTHER_USERS_CONVERSATIONS_SECTION   = 'other-users-conversartions';
        const WITHOUT_USERS_CONVERSATIONS_SECTION = 'without-users-conversartions';
        const CURRENT_USER_CONVERSATIONS_SECTION  = 'current-user-conversartions';

        return {
            OTHER_USERS_CONVERSATIONS_SECTION,
            WITHOUT_USERS_CONVERSATIONS_SECTION,
            CURRENT_USER_CONVERSATIONS_SECTION,

            smsNumber:      '',
            currentSection: OTHER_USERS_CONVERSATIONS_SECTION,

            // Loadings
            loadingNumber: false
        };
    },

    components: {
        ConversationsBox,
        BulleNbrNewMessages
    },

    computed: {
        ...mapState('core/conversations', [
            'currentUserConversations',
            'noUsersConversations',
            'otherUsersConversations'
        ]),

        ...mapState('core/number', [
            'phoneNumber'
        ]),
    },

    mounted () {
        // Get the SMS numbers linked to the tenant
        this.getActivePhoneNumber();
    },

    methods: {
        formatPhoneNumber: Format.formatPhoneNumber,

        /**
         * Methods to get SMS active Number API request
         *
         * getPhoneNumber     API list sms numbers
         */
        ...mapActions('core/number', { getPhoneNumber: 'index' }),

        /**
         * Get the active phone number
         */
        getActivePhoneNumber () {
            this.loadingNumber = true;

            this.getPhoneNumber({})
                .then(() => {
                    for (const numberId in this.phoneNumber) {
                        this.smsNumber = Format.formatPhoneNumber(this.phoneNumber[numberId].attributes['phone-number']);
                    }
                })
                .finally(() => {
                    this.loadingNumber = false;
                });
        },

        /**
         * Switch between sections
         *
         * @param {string}  to  the section to switch to
         * @return void
         */
        switchSection (to) {
            this.currentSection = to;
        }
    }
};
</script>
