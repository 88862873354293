<template>
    <div class="d-flex align-items-stretch">
        <navigation></navigation>
        <div id="config-folio-sms" class="container-fluid main-content">
        <div class="row pr-3 pl-2 mb-4">
            <div class="col-sm text-left">
            <h3 class="section-title ">{{ $t("config-folio-connector") }}</h3>
            </div>
        </div>
        <div id="section-manage-connector">
            <button id="btn-open-modal-result" v-show="false"
                data-toggle="modal" data-target="#modal-result-config">
            </button>
            <ResutlConfigFolio
                :visible="submitted" :result="result"
                @resultSetupEmit="bnClicktResultSetup">
            </ResutlConfigFolio>

            <StepsToAccomplish
                :stepCurrent="stepActivated"
                :nbrOfSteps="nbrOfSteps"
                :detailsStep="getDetailsStepActivated()"
                :showBtnNext="true"
                :showOngletsSteps="true"
                @nextStepTriggered="onClicknextStep"
                @submitconfigConnector="onClicksubmitconfig">
            </StepsToAccomplish>
        </div>
        </div>
    </div>
</template>

<script>

// Components
import StepsToAccomplish from '../folio/StepsToAccomplish.vue';
import ResutlConfigFolio from '../folio/ResutlConfigFolio.vue';
import { mapGetters }    from 'vuex';

export default {
    name:       'SmsConfigFolio',
    components: {
        StepsToAccomplish,
        ResutlConfigFolio
    },
    data () {
        return {
            stepToAccomplish: [
                {
                    id:          1,
                    name:        'felio-step1-tiltle',
                    description: 'felio-step1-description',
                    details:     [
                        {
                            img:         'step1-1',
                            actionImg:   '',
                            extension:   'png',
                            title:       '',
                            description: '',
                            fields:      [
                                {
                                    model:       '',
                                    ref:         'domaine',
                                    name:        'domain-name',
                                    description: 'copy-domain-foliomedian',
                                    placeholder: 'placeholder-ex',
                                    indiceInput: 'domain-name',
                                    type:        'input',
                                    content:     '',
                                    readOnly:    false,
                                    required:    true
                                }
                            ]
                        },
                        {
                            img:         'step1-2',
                            extension:   'png',
                            actionImg:   '',
                            title:       '',
                            description: '',
                            fields:      [
                                {
                                    ref:         'apiKey',
                                    model:       '',
                                    name:        'API-key',
                                    description: 'create-api-key-platef-foli',
                                    help:        'how-find-api-key',
                                    helpType:    'modal',
                                    indiceInput: 'API-key',
                                    type:        'input',
                                    content:     '',
                                    readOnly:    false,
                                    required:    true
                                }
                            ]
                        }
                    ]
                },
                {
                    id:          2,
                    name:        'felio-step2-tiltle',
                    description: 'felio-step2-description',
                    details:     [
                        {
                            img:         'step2-1',
                            extension:   'png',
                            actionImg:   'pic-enlarge',
                            title:       'add-new-response',
                            description: '',
                            fields:      []
                        },
                        {
                            img:         'step2-2',
                            extension:   'png',
                            actionImg:   'pic-enlarge',
                            title:       'fill-webhook-form',
                            description: 'copy-url',
                            fields:      [
                                {
                                    ref:         'lien',
                                    indiceInput: 'link-url-cocoapp',
                                    action:      'copy',
                                    readOnly:    true,
                                    type:        'input',
                                    content:     'https://api.cocoapp.com/api/connectors/sms/v1/foliomedian/webhook?Body=_MESSAGE_&From=_NUMBER_'
                                }
                            ]
                        },
                        {
                            img:         'step2-3',
                            actionImg:   '',
                            title:       'save-new-autoresponse',
                            description: '',
                            fields:      []
                        }
                    ]
                },
                {
                    id:          3,
                    name:        'felio-step3-tiltle',
                    description: 'felio-step3-description',
                    details:     [
                        {
                            img:         'step3-1',
                            extension:   'png',
                            actionImg:   '',
                            title:       'open-System-keyword',
                            description: '',
                            fields:      []
                        },
                        {
                            img:         'step3-2',
                            extension:   'png',
                            actionImg:   '',
                            title:       'click-pencil-edit',
                            description: '',
                            fields:      []
                        },
                        {
                            img:         'step3-3',
                            extension:   'png',
                            actionImg:   '',
                            title:       'open-webhook-copy-url',
                            description: '',
                            fields:      [
                                {
                                    ref:         'lien',
                                    indiceInput: 'link-url-cocoapp',
                                    action:      'copy',
                                    readOnly:    true,
                                    type:        'input',
                                    content:     'https://api.cocoapp.com/api/connectors/sms/v1/foliomedian/webhook'
                                },
                                {
                                    ref:         'settings',
                                    indiceInput: 'settings',
                                    action:      'copy',
                                    type:        'input',
                                    readOnly:    true,
                                    content:     'Body=_MESSAGE_&From=_NUMBER'
                                }
                            ]
                        },
                        {
                            img:         'step3-4',
                            extension:   'svg',
                            actionImg:   '',
                            title:       'repeat-system-keywords',
                            description: 'default-nine-msgs',
                            fields:      []
                        }
                    ]
                }
            ],
            stepActivated: 1,
            submitted:     false,
            visibleModal:  false,
            result:        {
                errors: [
                    {
                        status: 'fail',
                        title:  'On ne peut pas communiquer avec l\'API'
                    }
                ],
                status: '401'
            }
        };
    },
    computed: {
        nbrOfSteps () {
            return this.stepToAccomplish.length;
        },
        ...mapGetters('core/number', ['smsPhoneNumber'])
    },
    methods: {
        /**
         * Get details of the step activated
         *
         * @return array of details step
         */
        getDetailsStepActivated () {
            return this.stepToAccomplish.filter((_x) => _x.id === this.stepActivated)
                ? this.stepToAccomplish.filter((_x) => _x.id === this.stepActivated)[0]
                : [];
        },

        /**
         * Click on the btn submit : open the modal result
         */
        onClicksubmitconfig (payload) {
            if (payload) {
                this.result = payload;
            }
            this.submitted = true;
            document.getElementById('btn-open-modal-result').click();
        },

        /**
         * Update the value of param with the step activated
         * @param {integer} step
         */
        onClicknextStep (step) {
            if (this.smsPhoneNumber) {
                const phone = Object.values(this.smsPhoneNumber)[0].attributes['phone-number'];
                this.stepToAccomplish[1].details[1].fields[0].content = `${this.stepToAccomplish[1].details[1].fields[0].content}&To=${phone}`;
                this.stepToAccomplish[2].details[2].fields[1].content = `Body=_MESSAGE_&From=_NUMBER_&To=${phone}`;
            }
            this.stepActivated = step;
        },

        /**
         * Click on the btn of modal (if result success: redirection else jump to step badly configured)
         */
        bnClicktResultSetup () {
            if (this.result.status === '200') {
                this.$router.push({ name: `foliomedian-activated:${this.$i18n.locale}` });
            } else {
                this.submitted = false;
                if (this.result.code === '403') {
                    this.stepActivated = 3;
                } else {
                    this.stepActivated = 1;
                }
            }
        }
    }
};
</script>
