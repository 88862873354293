import  Vue   from 'vue';

const vueFb = {
};
vueFb.install = function install (Vue, options) {
    (function (d, s, id) {
        let js = null;
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/fr_FR/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    window.fbAsyncInit = function onSDKInit () {
        window.FB.init(options);
        window.FB.AppEvents.logPageView();
        Vue.FB = window.FB;
        window.dispatchEvent(new Event('fb-sdk-ready'));
    };
    Vue.FB = void 0;
};

Vue.use(vueFb, {
    appId:            process.env.VUE_APP_FACEBOOK_CONNECTOR_APP_ID,
    autoLogAppEvents: true,
    xfbml:            true,
    version:          process.env.VUE_APP_FACEBOOK_CONNECTOR_SDK_VERSION
});

export default {

};
