<template>
    <div class="sms-page card-info">
        <div class="text-left px-2 mt-2">
            <h5>{{$t('configured-number')}}</h5>
            <p class="mb-4">{{$t('test-sms-config')}}</p>
        </div>
        <div class="spinner-border" role="status" v-if="loading === true">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="page-table" v-if="loading === false">
            <div class="box-wrap-remote overflow-auto px-2 note-history">
                <table class="table table-striped table-bordered mb-4">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left">{{$t('phone-number')}}</th>
                            <th scope="col" class="text-left" v-if="showDescription">{{$t('description')}}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="number in phoneNumber" :key="number.id">
                            <td class="text-left">
                                <p class="text-table">{{ number.attributes['phone-number'] }}</p>
                            </td>
                            <td class="text-left" v-if="showDescription">
                                {{$t('authorized-person')}}: {{ number.attributes.config.name }} <br/> {{ number.attributes.config.carrier_account_name }} - {{ number.attributes.config.carrier_account_number }}
                            </td>
                            <td>
                                <button class="btn btn-danger btn-active" @click="deactivateAlert(number.id)">
                                    {{$t('deactivate')}}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>

// Internal componants
import Alert from '@/helpers/Alert.js';

// Import external libraries
import {
    mapState, mapActions, mapGetters
} from 'vuex';
import _ from 'underscore';

export default {
    data () {
        return { loading: true };
    },
    props: ['nameConnector'],
    mounted () {
        this.loading = true;

        // Get the list of active sms phone number
        this.listNumber().then(() => {
            // Then get the state of the phone number
            this.numberGetter;
            this.loading = false;
        });
    },
    computed: {
        // Getter for the selected number
        ...mapGetters('core/number', { numberGetter: 'smsPhoneNumber' }),

        // State with activated phone number
        ...mapState('core/number', [
            'phoneNumber'
        ]),

        // Determine if we should show the description column based on telnyx phone number type
        showDescription () {
            const telnyxExist = _.find(this.phoneNumber, (phone) =>
                phone.attributes.config.telnyx_id
            );
            return (typeof telnyxExist === 'undefined');
        }
    },
    methods: {
        /**
         * Methods for SMS Phone Number API request
         *
         * listNumber       API request to list the sms number
         * deactivateNumber API request to deactivate the sms number
         */
        ...mapActions('core/number', {
            listNumber:       'index',
            deactivateNumber: 'delete'
        }),

        /**
         * Show confirm dialog alert
         *
         * @param    {string}   numberId    The number id
         *
         */
        deactivateAlert (numberId) {
            Alert.confirmation(this.$i18n.t('sms-deactivation-permanent'), this.$i18n.t('deactivate'))
                .then((response) => {
                    if (response.value) {
                        // Deactivate the sms Phone Number
                        this.loading = true;
                        this.deactivateNumber({ id: numberId }).then(() => {
                            // Get the new state of the number, should be empty
                            this.numberGetter;
                            this.loading = false;
                            Alert.success(this.$i18n.t('number-deleted-successfully'));

                            // Got to the connector page
                            this.$router.push({ name: `connectors-page:${this.$i18n.locale}` });
                        })
                            .catch((error) => {
                                console.log(error);
                                Alert.fail(this.$i18n.t(error.response.data.errors[0].detail));
                                this.loading = false;
                            });
                    } else {
                        this.loading = false;
                    }
                });
        }
    }
};
</script>
