import Api from '@/helpers/Api.js';
import qs from 'qs';

const resource = '/connectors/facebook/v1/contacts';

export default {
    /**
     * Get single contact interactions
     * Get all contact interactions or filtered by category.
     *
     * @param    {string}    contactId    The single contact id
     * @param    {object}    filters      Filter interactions by attribute
     *
     * @return    {object}    jsonapi response
     */
    getInteractions (contactId, filters) {
        const urlParams = {
            include: 'author,post,reply,reply.author,parent,parent.author,page-reaction'
        };

        if (filters !== null) {
            urlParams.filter = filters;
        }

        return Api.get(`${resource}/${contactId}/interactions`, {
            params:           urlParams,
            paramsSerializer: params => qs.stringify(params, {
                encode: false
            })
        });
    }
};
