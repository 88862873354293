import Api       from '@/helpers/Api.js';
import _         from 'underscore';
import normalize from 'json-api-normalizer';
import qs        from 'qs';

const resource = '/internal/v1/templates';

// Import mutations
import {
    ADD_TEMPLATE,
    REMOVE_TEMPLATE,
    SET_TEMPLATES,
    UPDATE_TEMPLATE,
    SELECT_TEMPLATE
} from './mutation-types.js';

// Initial state
const state = () => ({
    templates:        {},
    selectedTemplate: null
});

// Getters
const getters = {
    /**
     * Get selected template
     *
     * @param      {Object}   state          The state
     *
     * @return     {Object}
     */
    selectedTemplate: (state) => {
        return state.selectedTemplate;
    }
};

// Actions
const actions = {

    /**
     * Get all templates.
     *
     * @param      {Object}  context           The context
     * @param      {string}  payload.name      The template name
     * @param      {string}  payload.message   The template message
     *
     * @return     {Promise}
     */
    index: (context, payload) => {
        const urlParams = {};
        if (payload) {
            urlParams.sort   = payload.sort ? payload.sort : void 0;
            urlParams.filter = payload.filters ? payload.filters : void 0;
        }

        return new Promise((resolve, reject) => {
            Api.get(resource, {
                params:           urlParams,
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_TEMPLATES, normalizedData);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     *
     * @param    {Object}  context        The context
     * @param    {string}  payload.id     The template identifier
     */
    select: (context, { id }) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}/${id}`)
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SELECT_TEMPLATE, normalizedData.templates[id]);
                    resolve(normalizedData.templates[id]);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Create new template.
     *
     * @param      {Object}  context           The context
     * @param      {string}  payload.name      The template name
     * @param      {string}  payload.message   The template message
     *
     * @return     {Promise}
     */
    create: (context, { name, message }) => {
        return new Promise((resolve, reject) => {
            // Create the base data
            const data = {
                'data': {
                    'type':       'templates',
                    'attributes': {
                        'name':    name,
                        'content': message
                    }
                }
            };

            // Send the request.
            Api.post(`${resource}`, data)
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(ADD_TEMPLATE, normalizedData);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Delete a template.
     *
     * @param      {Object}  context           The context
     * @param      {string}  payload.id        The tempalte identifier
     *
     * @return     {Promise}
     */
    delete: (context, { id }) => {
        return new Promise((resolve, reject) => {
            Api.delete(`${resource}/${id}`)
                .then(() => {
                    context.commit(REMOVE_TEMPLATE, id);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     *
     */
    update: (context, { id, attributes }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/${id}`, {
                'data': {
                    'type': 'templates',
                    id,
                    attributes
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(UPDATE_TEMPLATE, { id, ...normalizedData.templates[id] });
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {
    [SET_TEMPLATES]: (state, payload) => {
        state.templates = payload.templates ? payload.templates : {};
    },
    [ADD_TEMPLATE]: (state, payload) => {
        state.templates = _.extend(state.templates, payload.templates);
    },
    [UPDATE_TEMPLATE]: (state, payload) => {
        state.templates[payload.id] = payload;
    },
    [REMOVE_TEMPLATE]: (state, id) => {
        state.templates = _.reject(state.templates, (template) => {
            return template.id === id;
        });
    },
    [SELECT_TEMPLATE]: (state, payload) => {
        state.selectedTemplate = payload;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
