import Api       from '@/helpers/Api.js';
import normalize from 'json-api-normalizer';
import Alert     from '@/helpers/Alert.js';
import i18n      from '@/libraries/I18n.js';
import _         from 'underscore';

// Import mutations
import {
    ADD_NUMBERS,
    SET_NUMBERS,
    REMOVE_NUMBER,
    SET_DOMAIN_NAME,
    SET_FOLIO_API_KEY
} from './mutation-types.js';

const resource  = '/connectors/sms/v1/phone-numbers';
const resourceProviders = '/connectors/sms/v1/providers';

// Initial state
const state = () => ({
    phoneNumber:     null,
    folioDomainName: null
});

// Getters
const getters = {
    /**
     * Get the chosen sms phone number
     *
     * @param      {Object}   state          The state
     *
     * @return     {Object}
     */
    smsPhoneNumber: (state) => state.phoneNumber,

    /**
     * Get the phone number to activate
     */
    numberToActivate: (state) => {
        if (state.phoneNumber) {
            if (!Object.values(state.phoneNumber)[0].attributes['is-configured']) {
                return Object.values(state.phoneNumber)[0];
            }
        }
        return null;
    },

    /**
     * Get the phone id to activate
     */
    numberIdToActivate: (state) => {
        if (state.phoneNumber) {
            if (!Object.values(state.phoneNumber)[0].attributes['is-configured']) {
                return Object.values(state.phoneNumber)[0].id;
            }
        }
        return null;
    }
};

// Actions
const actions = {

    /**
     * Activate SMS phone number.
     *
     * @param   {Object}  context  The context
     * @param   {string}  phoneNumber SMS phone number
     * @param   {string}  Provider Identifier
     * @param   {string}  Provider Type
     *
     * @return  {Promise}
     */
    create: (context,  {
        phoneNumber,
        providerId,
        providerType
    }) => new Promise((resolve, reject) => {
        Api.post(`${resource}`, {
            'data': {
                'type':          'phone-numbers',
                'attributes':    { 'phone-number': phoneNumber },
                'relationships': {
                    'provider': {
                        'data': {
                            'id':   providerId,
                            'type': providerType
                        }
                    }
                }
            }
        })
            .then(response => {
                const normalizedData = normalize(response.data, { camelizeKeys: false });
                context.commit(ADD_NUMBERS, normalizedData['phone-numbers'] ?? null);
                Alert.success(i18n.t('number-activated'));
                resolve(normalizedData);
            })
            .catch((error) => {
                reject(error);
            });
    }),

    /**
     * List Active SMS number
     *
     * @param   {Object}  context  The context
     *
     * @return  {Promise}
     */
    index: (context) => new Promise((resolve, reject) => {
        Api.get(`${resource}`)
            .then((response) => {
                const normalizedData = normalize(response.data, { camelizeKeys: false });
                context.commit(SET_NUMBERS, normalizedData['phone-numbers' ?? null]);
                resolve(normalizedData);
            })
            .catch((error) => {
                reject(error);
            });
    }),

    /**
     * Deactivate SMS phone number
     *
     * @param   {Object}  context  The context
     * @param   {string}  Active number Identifier
     *
     * @return  {Promise}
     */
    delete: (context, { id }) => new Promise((resolve, reject) => {
        Api.delete(`${resource}/${id}`)
            .then(() => {
                context.commit(REMOVE_NUMBER, id);
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
    }),

    /**
     * Configure Voip.ms
     * @param   {Object}  context    The context
     * @param   {string}  apiUsername Voip api username
     * @param   {string}  apiPassword Voip api password
     * @param   {string}  phoneNumber phone number
     */
    configureVoip: (context, { apiUsername, apiPassword, phoneNumber }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resourceProviders}/voip/configure?include=phone-numbers`, {
                'data': {
                    'type':       'providers',
                    'attributes': {
                        'api-username': apiUsername,
                        'api-password': apiPassword,
                        'phone-number': phoneNumber
                    }
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(ADD_NUMBERS, normalizedData['phone-numbers']);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Configure Foliomedian
     * @param   {Object}  context    The context
     * @param   {string}  domainName Folio domain name
     * @param   {string}  apiKey     Folio Api key
     */
    configureFolio: (context,  { domainName, apiKey }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resourceProviders}/foliomedian/configure?include=phone-numbers`, {
                'data': {
                    'type':       'providers',
                    'attributes': {
                        'domain':  domainName,
                        'api-key': apiKey
                    }
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(ADD_NUMBERS, normalizedData['phone-numbers']);
                    context.commit(SET_DOMAIN_NAME, normalizedData['providers']);
                    // eslint-disable-next-line no-magic-numbers
                    context.commit(SET_FOLIO_API_KEY, '*********************************' + apiKey.slice(-7));
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Configure Telnyx
     * @param   {Object}  context    The context
     * @param   {Object}  attributes The form attributes
     */
    configureTelnyx: (context, { phoneNumber }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resourceProviders}/telnyx/configure?include=phone-numbers`, {
                'data': {
                    'type':       'providers',
                    'attributes': { 'phone-number': phoneNumber }
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(ADD_NUMBERS, normalizedData['phone-numbers'] ?? null);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutation
const mutations = {
    [ADD_NUMBERS]: (state, payload) => {
        state.phoneNumber = payload;
    },
    [SET_DOMAIN_NAME]: (state, payload) => {
        state.folioDomainName = Object.values(payload)[0].attributes.config.domain;
    },
    [SET_FOLIO_API_KEY]: (state, payload) => {
        state.folioApiKey = payload;
    },
    [SET_NUMBERS]: (state, payload) => {
        state.phoneNumber = payload;
    },
    [REMOVE_NUMBER]: (id) => {
        state.phoneNumber = _.reject(state.phoneNumber, (number) => {
            return number.id === id;
        });
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
