<template>
    <div id="template-manager" class="container-fluid main-content">
        <div class="row pr-3 pl-2 mb-4">
            <div class="col-sm text-left">
                <h3 class="section-title">{{ $t('template') }}</h3>
            </div>
        </div>
        <div class="white-box m-2 p-4">
            <div class="row justify-content-between px-3 py-1">
                <div class="col-6">
                    <input
                        type="text"
                        class="form-control search-contact text-small py-1"
                        v-model="search"
                        v-bind:placeholder="$t('search')">
                </div>
                <div class="col-3 d-flex justify-content-end">
                    <button class="btn btn-primary rounded-pill d-flex style-btn" data-target="#template-sidebar" @click="$bvModal.show('template-sidebar'), isEdit = false">
                        <span class="spinner-border spinner-border-sm mt-1 mr-1" role="status" aria-hidden="true" v-if="loading"></span>
                        {{ $t('add-template') }}
                    </button>
                </div>
            </div>
            <table class="table table-borderless template-table">
                <thead class="seperator thead-border">
                    <tr>
                        <th class="text-left">{{$t('template-name')}}</th>
                        <th colspan="2" class="text-left">{{$t('preset-message')}}</th>
                        <th class="text-left">&nbsp;</th>
                    </tr>
                </thead>
                <tbody class="template-table-body">
                    <preloader-placeholder :type="'table'"></preloader-placeholder>
                    <tr v-for="template in filteredList" :key="template.id">
                        <td class="text-left align-middle">
                            <p class="m-0 align-self-center text-ellipsis">
                                {{template.attributes.name}}
                            </p>
                        </td>
                        <td colspan="2" class="text-left align-middle">
                            <p class="m-0 align-self-center text-ellipsis">
                                {{template.attributes.content}}
                            </p>
                        </td>
                        <td  class="edit-section d-flex justify-content-end align-middle">
                            <label class="info-label mt-2">
                                <font-awesome-icon class="text-grey edit-btn" :icon="['fas', 'pencil-alt']" @click="editTemplate(template.id, template.attributes.name, template.attributes.content)" data-target="#template-sidebar" />
                            </label>
                            <button type="button" class="close ml-5 my-2" aria-label="Close" @click="removeTemplate(template.id, template.attributes.name)">
                                <font-awesome-icon class="text-muted text-small edit-btn" :icon="['fas', 'times']" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <template-modal
            v-bind:isEdit="isEdit"
        ></template-modal>
    </div>
</template>
<script>

// Internal componants
import Alert                from '../../helpers/Alert.js';
import TemplateModal        from './TemplateModal.vue';
import PreloaderPlaceholder from './PreloaderPlaceholder.vue';

// Import external libraries
import { mapActions, mapState } from 'vuex';
import _                          from 'underscore';

// Import Really Fast Deep Copy
const clone = require('rfdc')();

export default {
    components: {
        TemplateModal,
        PreloaderPlaceholder
    },

    data () {
        return {
            loading:      false,
            search:       '',
            firstLoading: false,
            isEdit:       false
        };
    },
    created () {
        // Show the preloader.
        this.firstLoading = true;

        // Get the temaplate
        this.getTemplate()
            .then(() => {
                // Hide the preloader.
                this.firstLoading = false;
            });
    },
    computed: {
        ...mapState('core/templates', {
            templates:        'templates',
            selectedTemplate: 'selectedTemplate'
        }),

        /**
         * Search template in the list
         */
        filteredList () {
            // Generates a filtered array from the template list
            return clone(_.filter(this.templates, template => (template.attributes.name.toLowerCase().includes(this.search.toLowerCase()) || template.attributes.content.toLowerCase().includes(this.search.toLowerCase()))));
        }
    },
    methods: {
        ...mapActions('core/templates', {
            getTemplate:    'index',
            selectTemplate: 'select',
            createTemplate: 'create',
            patchTemplate:  'update',
            deleteTemplate: 'delete'
        }),

        /**
         * Delete selected template
         *
         * @param   {string}   ID    The Id of the selected template.
         * @param   {string}   name  The name of the selected template.
         *
         */
        removeTemplate (templateId, templateName) {
            Alert.templateDeleteConfirmation('title-template-delete', 'confirm-template-delete', templateName)
                .then((response) => {
                    if (!response.isConfirmed) {
                        return;
                    }

                    // Delete template in db
                    this.deleteTemplate({
                        id: templateId
                    });
                });
        },

        // Send template to edit modal
        editTemplate (templateId, templateName, templateContent) {
            this.isEdit = true;
            this.selectTemplate({
                id:      templateId,
                name:    templateName,
                content: templateContent
            })
                .then(() => {
                    this.$bvModal.show('template-sidebar');
                });
        }
    }
};
</script>
