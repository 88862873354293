import Api       from '@/helpers/Api.js';
import qs        from 'qs';
import _         from 'underscore';
import normalize from 'json-api-normalizer';
import Vue       from 'vue';

const resource = '/internal/v1/tags';

// Import mutations
import {
    ADD_TAG,
    SET_TAGS,
    UPDATE_TAG,
    REMOVE_TAG
} from './mutation-types.js';

// Initial state
const state = () => ({
    tags: {}
});

// Getters
const getters = {
    /**
     * Get the tags.
     *
     * @param      {Object}  state   The state
     *
     * @return     {Object}
     */
    tags: (state) => {
        return state.tags.tags;
    },

    /**
     * Get the tags included contacts.
     *
     * @param      {Object}  state   The state
     *
     * @return     {Object}
     */
    contacts: (state) => {
        return state.tags.contacts;
    }
};

// Actions
const actions = {
    /**
     * Get all tags.
     *
     * @param      {Object}   context          The context
     * @param      {Object}   payload.filters  The filters
     *
     * @return     {Promise}
     */
    index: (context, payload) => {
        return new Promise((resolve, reject) => {
            const urlParams = {
                include: 'contacts'
            };

            if (payload && payload.filters) {
                urlParams.filter = payload.filters;
            }

            Api.get(`${resource}`, {
                params:           urlParams,
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_TAGS, normalizedData);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Creates a new tag.
     * Can also associate contacts to this new tag.
     *
     * @param      {Object}  context           The context
     * @param      {string}  payload.name      The tag name
     * @param      {Array}   payload.contacts  The contacts to associate
     *
     * @return     {Promise}
     */
    create: (context, { name, contacts }) => {
        return new Promise((resolve, reject) => {
            // Create the base data
            var data = {
                'data': {
                    'type':       'tags',
                    'attributes': {
                        'name': name
                    }
                }
            };

            // Create the contacts data for the relationships if exists.
            if (typeof contacts !== 'undefined' && contacts !== null) {
                const contactsData = [];
                contacts.forEach(contact => {
                    contactsData.push({
                        'id':   contact.id,
                        'type': contact.type
                    });
                });

                // Add the contacts to relationships
                data.data.relationships = {
                    "contacts": {
                        "data": contactsData
                    }
                };
            }

            // Send the request.
            Api.post(`${resource}`, data)
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(ADD_TAG, normalizedData.tags[response.data.data.id]);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Updates a tag.
     *
     * @param      {Object}  context       The context
     * @param      {string}  payload.id    The tag identifier
     * @param      {string}  payload.name  The tag name
     *
     * @return     {Promise}
     */
    update: (context, { id, name }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/${id}`, {
                'data': {
                    'type':       'tags',
                    id:           id,
                    'attributes': {
                        'name': name
                    }
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(UPDATE_TAG, normalizedData.tags[id]);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Deletes a tag.
     *
     * @param      {Object}  context       The context
     * @param      {string}  payload.id    The tag identifier
     *
     * @return     {Promise}
     */
    delete: (context, { id }) => {
        return new Promise((resolve, reject) => {
            Api.delete(`${resource}/${id}`)
                .then(() => {
                    context.commit(REMOVE_TAG, id);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Removes tag from contacts.
     *
     * @param      {Object}  context           The context
     * @param      {Array}   payload.contacts  The contacts to disociate
     *
     * @return     {Promise}
     */
    removeFromContacts: (context, { id, contacts }) => {
        // Create the data for the relationships.
        const data = [];
        contacts.forEach(contact => {
            data.push({
                'id':   contact.id,
                'type': contact.type
            });
        });

        return Api.delete(`${resource}/${id}/relationships/contacts`, {
            'data': {
                'data': data
            }
        });
    }
};

// Mutations
const mutations = {
    [SET_TAGS]: (state, payload) => {
        state.tags = payload;
    },
    [ADD_TAG]: (state, payload) => {
        Vue.set(state.tags.tags, payload.id, payload);
    },
    [UPDATE_TAG]: (state, payload) => {
        state.tags.tags[payload.id] = payload;
    },
    [REMOVE_TAG]: (state, id) => {
        state.tags.tags = _.reject(state.tags.tags, (tag) => {
            return tag.id === id;
        });
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
