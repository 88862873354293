<template>
    <div class="contact-card">
        <div class="modal" :id="`original-post-${postId}`">
            <div class="modal-dialog modal-post">
                <div class="modal-content modal-content-post">
                    <div class="modal-header justify-content-center border-0 row">
                        <div class="col-11 title-post">
                            <h3 class="modal-title text-primary">{{ $t('post') }}</h3>
                        </div>
                        <div class="col-1 close-post">
                            <button type="button" class="close" data-dismiss="modal" id="close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="box-wrap note-history poste-history box-post px-2" v-if="originalPost !== null">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2 pr-0">
                                    <div class="page-image pt-2">
                                        <img class="post-img" :src="profilePicture" alt="">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3 pl-0">
                                    <div class="text-left page-image">
                                    <strong>{{ author.attributes.name }}</strong>
                                    <p class="created-at">{{ moment(originalPost.attributes['created-at']).format('YYYY-MM-DD H:mm:ss') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="post-comment text-left">
                                <p>{{ originalPost.attributes.message }}</p>
                            </div>
                            <div class="post-content" v-if="originalPost.attributes.picture !== null">
                                <img :src="originalPost.attributes.picture" alt="" width="474px">
                            </div>
                            <div class="row post-interactions mt-1">
                                <div class="text-left" :class="[interaction.name == 'comment' ? 'col-4' : 'col']" v-for="interaction in interactions" :key='interaction.name'>
                                    <popover :name="interaction.name" event="hover" id="reaction-info">
                                        <div class="row popover-row">
                                            <div class="col-2 text-center">
                                                <font-awesome-icon class="fb-reaction mr-2" :icon="interaction.font" v-if="interaction.type == 'font'"/>
                                                <img class="icon-stats ml-2 mt-0" :src='interaction.font' height="20" width="20" v-else>
                                            </div>
                                            <div class="col-6 text-center">
                                                <p>{{ `${$tc(interaction.name, interaction.count)} (${interaction.count})` }}</p>
                                            </div>
                                        </div>
                                    </popover>
                                    <font-awesome-icon class="fb-reaction mr-2" :icon="interaction.font" v-if="interaction.type == 'font'" v-popover:bottom="{ name: interaction.name }"/>
                                    <img class="icon-stats ml-2 mt-0" :src='interaction.font' height="20" width="20" v-else v-popover:bottom="{ name: interaction.name }">
                                    <small>{{ interaction.count }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <preloader-placeholder :loading="loading" :type="'post'"></preloader-placeholder>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal components
import PreloaderPlaceholder from '../../../core/components/PreloaderPlaceholder.vue';

// Internal services js
import { RepositoryFactory } from '../../../repositories/repositoryFactory.js';
import { EventBus }          from '../../../helpers/EventBus.js';

// External libraries
import moment from 'moment';

const FacebookPosts = RepositoryFactory.get('FacebookPosts');

export default {
    props: ['postId', 'type'],

    components: {
        PreloaderPlaceholder
    },

    mounted () {
        // Listen to the get post event.
        EventBus.$on(`get-post-${this.postId}`, () => {
            // Start loading
            this.loading = true;

            // load the post
            this.getPost();
            this.getReactions();
        });

        // Listen for the update post event.
        EventBus.$on('update-post', () => {
            this.getPost();
            this.getReactions();
        });
    },
    data () {
        return {
            loading:        false,
            originalPost:   null,
            author:         null,
            comments:       [],
            reactions:      [],
            profilePicture: this.$config.images.DEFAULT_PROFILE_PICTURE,
            token:          1,

            interactions: {
                comment: { name: 'comment', font: ['fas', 'comment'],           type: 'font', count: 0, action: 'commented' },
                like:    { name: 'like',    font: ['fas', 'thumbs-up'],         type: 'font', count: 0, action: 'liked' },
                love:    { name: 'love',    font: ['fas', 'heart'],             type: 'font', count: 0, action: 'loved' },
                care:    { name: 'care',    font: '/images/solidaire_vert.png', type: 'svg',  count: 0, action: 'supported' },
                haha:    { name: 'haha',    font: ['fas', 'grin-beam'],         type: 'font', count: 0, action: 'laughed' },
                wow:     { name: 'wow',     font: ['fas', 'grin-hearts'],       type: 'font', count: 0, action: 'surprised' },
                sad:     { name: 'sad',     font: ['fas', 'sad-tear'],          type: 'font', count: 0, action: 'saddened' },
                angry:   { name: 'angry',   font: ['fas', 'angry'],             type: 'font', count: 0, action: 'angered' }
            }
        };
    },
    watch: {
        author () {
            // If the author have a profile picture
            if (this.author.attributes['profile-picture']) {
                this.profilePicture = this.author.attributes['profile-picture'];
            }
        }
    },
    methods: {
        /**
         * Define moment to use it in template
         */
        moment,

        /**
         * Get the post.
         *
         * @returns    void
         */
        getPost () {
            FacebookPosts.get(this.postId)
                .then((response) => {
                    this.originalPost = response.data.data;

                    // Get the author for this post.
                    this.author = response.data.included.filter(author => author.type === 'contacts' && author.id === this.originalPost.relationships.author.data.id)[0];

                    // Get the comments for this post.
                    this.comments = response.data.included.filter(comments => comments.type === 'comments');

                    // Calcul the stats of comments
                    this.interactions.comment.count = this.comments.length;

                    // Add the author and the replies for each comment
                    this.comments.forEach((comment) => {
                        comment.author  = response.data.included.filter(author => author.type === 'contacts' && author.id === comment.relationships.author.data.id)[0];
                    });

                    // End loading
                    this.loading = false;
                });
        },

        /**
         * Get the reactions of this post
         */
        getReactions () {
            FacebookPosts.getReactions(this.postId)
                .then((response) => {
                    this.reactions = response.data.data;

                    // Reset the count of all interactions except comment
                    Object.keys(this.interactions).forEach((key) => {
                        if (key !== 'comment') {
                            this.interactions[key].count = 0;
                        }
                    });

                    // Calcul the stats of each reaction
                    this.reactions.forEach(reaction => {
                        this.interactions[reaction.attributes.category.toLowerCase()].count++;
                    });
                });
        }
    }
};
</script>
