<template>
    <div class="badge" v-if="badge"><img v-bind:src="badge"></div>
</template>
<script>
export default {
    beforeCreate () {
        this.badge = null;
        if (process.env.VUE_APP_ENV === 'staging') {
            this.badge = require('../../assets/images/staging-banner.png');
        } else if (process.env.VUE_APP_ENV === 'local') {
            this.badge = require('../../assets/images/local-banner.png');
        }
    }
};
</script>
