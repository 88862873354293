/**
 * LoginMixin
 *
 * Add methods and common states for Login user
 */

// Import external libraries
import { mapState, mapActions } from 'vuex';
import moment                   from 'moment';
import Alert                    from '@/helpers/Alert.js';

export default {
    computed: {
        /**
         * State of user
         *
         * user     State of the logged in user
         * userLink State of the User authentification Link
         */
        ...mapState('core/auth', [
            'user',
            'userLink'
        ]),

        /**
         * State of tenants
         *
         * tenants     State of the tenants linked to the user
         */
        ...mapState('core/tenants', {
            tenants: 'userTenants'
        }),

        /**
         * State of connectors
         *
         * connectors   State of the connectors linked to the tenant
         */
        ...mapState('core/connectors', [
            'connectors'
        ])
    },

    methods: {
        /**
         * Methods to get the authenticated user
         *
         * getAuthenticatedUser  API request to get the Authentification link
         */
        ...mapActions('core/auth', [
            'getAuthenticatedUser'
        ]),

        /**
         * Methods for session
         *
         * setSessionExists
         */
        ...mapActions('core/session', [
            'setSessionExists',
            'setSessionTenants'
        ]),

        /**
         * Methods to get tenants linked to the account
         *
         * tenantsList       API request to get list of all tenants linked to account
         * selectUserTenant  API request to get list of all tenants linked to the user
         */
        ...mapActions('core/tenants', {
            tenantsList:      'tenants',
            selectUserTenant: 'selectUserTenant'
        }),

        /**
         * Get list of tenants linked to user
         *
         */
        userTenants () {
            this.tenantsList({ sort: 'enabled' })
                .then(() => {
                    this.setSessionTenants({ tenants: this.tenants });
                    this.selectTenant();
                });
        },

        /**
         * When chosing a tenant check if user is admin
         *
         * @retun    void
         */
        selectTenant () {
            if (this.tenants.length === 0) {
                this.loading = false;
                Alert.fail(this.$i18n.t('invalid-login'));
                return;
            }

            this.selectUserTenant({ id: Object.values(this.tenants)[0].id })
                .then(() => {
                    this.loginSucess();
                });
        },

        /**
         * When login successful save user data
         *
         * @return     void
         */
        loginSucess () {
            // Use the link to get the user information
            this.getAuthenticatedUser()
                .then(() => {
                    // Use the user locale
                    if (this.user.attributes.language) {
                        this.$i18n.locale = this.user.attributes.language;
                        moment.locale(this.user.attributes.language);

                        // Set the locale into local storage
                        localStorage.setItem('locale', this.user.attributes.language);
                    }

                    this.$router.push({ name: `home:${this.$i18n.locale}` });
                });
        }
    }
};
