/**
 * MenuMixin
 *
 * Add methods and common states for Menu
 */

// Import external libraries
import { mapMutations, mapState } from 'vuex';

export default {
    computed: {
        /**
         * State of the auth user
         *
         * user   State of the authenticated user
         */
        ...mapState('core/auth', ['user']),

        // Menu state (collapsed or not)
        ...mapState('core/menu', ['collapsedMenu']),

        /**
         * State of facebook and sms connectors
         *
         * facebook    State of the facebook connector
         * sms         State of the sms connector
         */
        ...mapState('core/connectors', [
            'facebook',
            'sms'
        ]),

        /**
         * State of facebook admin token
         *
         * isValidAdminToken   State of the facebook admin valid token
         */
        ...mapState('core/facebook', ['isValidAdminToken']),

        /**
         * State of tenants
         *
         * selectedTenant   State of the tenant the user has selected
         */
        ...mapState('core/tenants', ['selectedTenant']),

        /**
         * Check the Facebook connector and token to show the exclamation triangle or not
         */
        showExclamationTriangle () {
            if (this.facebook.isConnected && this.facebook.isEstablished && !this.isValidAdminToken) {
                return true;
            }

            return false;
        }
    },

    methods: {
        /**
         *
         * Mutation to set menu state (collapsed or not)
         */
        ...mapMutations('core/menu', [
            'SET_MENU_STATE'
        ])
    }
};
