<template>
    <div id="contact-list-sidebar" class="sidebar" v-bind:class="{ closeSidebar: !collapseSidebar }">
        <topsidebar></topsidebar>
        <bottomsidebar></bottomsidebar>
    </div>
</template>
<script>

// Components
import TopSidebarContact  from './TopSidebarContact.vue';
import FbBottomSidebar    from '../../../connectors/facebook/components/FbBottomSidebar.vue';

export default {
    components: {
        'topsidebar':    TopSidebarContact,
        'bottomsidebar': FbBottomSidebar
    },
    props: ['collapseSidebar']
};
</script>
