<template>
    <div id="tag-list" class="d-flex align-items-stretch">
        <navigation></navigation>
        <template-manager></template-manager>
    </div>
</template>
<script>

// Components
import TemplateManager  from './TemplateManager.vue';

export default {
    components: {
        TemplateManager
    }
};
</script>
