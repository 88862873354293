<template>
    <b-modal modal-class="modal right fade sidebar-modal" id="massive-send-sidebar" no-close-on-backdrop hide-footer hide-header>
        <!-- Display Message Detail -->
        <div v-if="displayMessageDetail" class="px-4 d-flex flex-column add-edit-form">
            <div class="modal-header mt-4 mb-3 font-nunito">
                <h4 class="text-18">{{ $t('massive-sent-details') }}</h4>
            </div>
            <div class="modal-body mt-3 mb-3 d-flex flex-column justify-content-start">
                <div class="name-content d-flex flex-column mb-5">
                    <h6 class="text-black text-left font-nunito text-14">{{ $t('name') }}</h6>
                    <span class="text-dark-brown text-left font-nunito fs-13">
                        {{ selectedMassMessage.name }}
                    </span>
                </div>
                <div class="name-content d-flex flex-column mb-5">
                    <h6 class="text-black text-left font-nunito text-14">Message</h6>
                    <span class="text-dark-brown text-left font-nunito fs-13">
                        {{ selectedMassMessage.content }}
                    </span>
                </div>
                <div class="name-content d-flex flex-column mb-5">
                    <div class="d-flex align-items-center mb-2">
                        <h6 class="text-black text-left font-nunito text-14 m-0">Audience(s)</h6>
                        <span class="font-italic text-dark-brown font-nunito fs-13 ml-2">({{ selectedMassMessage.audienceCount }} contacts)</span>
                    </div>
                    <div v-if="selectedMassMessage.audiences !== null">
                        <div v-for="tag in selectedMassMessage.audiences" :key="tag.id"
                            class="d-flex d-inline-flex flex-row p-0 mr-2 tags">
                            <p class="font-nunito fs-13">{{ tag }}</p>
                        </div>
                        <div class="text-left text-dark-grey font-italic">
                            <span class="font-italic text-grey-9 font-nunito fs-13">
                                ({{ selectedMassMessage.sentCount ? selectedMassMessage.sentCount : 0 }} {{ $tc('sent', selectedMassMessage.sentCount) }},
                                {{ selectedMassMessage.failedCount ? selectedMassMessage.failedCount : 0 }} {{ $tc('failed', selectedMassMessage.failedCount) }})
                            </span>
                        </div>
                    </div>
                    <div v-else class="d-flex flex-column">
                        <span class="text-content font-nunito fs-13">{{ $t('all') }}</span>
                        <span class="font-italic text-content text-grey-9 font-nunito">
                            ({{ selectedMassMessage.sentCount ? selectedMassMessage.sentCount : 0 }} {{ $tc('sent', selectedMassMessage.sentCount) }},
                            {{ selectedMassMessage.failedCount ? selectedMassMessage.failedCount : 0 }} {{ $tc('failed', selectedMassMessage.failedCount) }})
                        </span>
                    </div>
                </div>
                <div class="name-content d-flex flex-column mb-2">
                    <h6 class="font-nunito text-14">{{ $t('time-date-to-send') }}</h6>
                    <div class="d-flex d-inline-flex">
                        <span class="text-dark-brown text-left mr-5 font-nunito fs-13">
                            {{ toUtc(selectedMassMessage.sentAt).date }} {{ toUtc(selectedMassMessage.sentAt).time }} <utc>UTC</utc>
                        </span>
                    </div>
                </div>
            </div>
            <div class="modal-footer mb-2 d-flex justify-content-end align-self-end">
                <button class="btn btn-primary rounded-pill px-5 py-1 mt-auto font-nunito"
                    @click="$bvModal.hide('massive-send-sidebar')">{{ $t('close') }}</button>
            </div>
        </div>
        <!-- Create/Update Mass Send Form -->
        <div class="px-4 d-flex flex-column add-edit-form" v-else>
            <div class="title my-4 modal-header">
                <h4 class="form-title modal-title text-black font-nunito" v-if="!editMessage">
                    {{ $t('add-massive-send') }}
                </h4>
                <h4 class="form-title modal-title text-black font-nunito" v-else>
                    {{ $t('edit-massive-send') }}
                </h4>
            </div>
            <div class="modal-body">
                <form>
                    <!-- Name -->
                    <div class="form-group mb-5">
                        <label class="form-title font-nunito" for="name">{{ $t('name') }}</label>
                        <input
                            type="text"
                            name="name"
                            class="form-control form-border font-nunito"
                            :placeholder="$t('template-name-placeholder')"
                            v-model.trim="massMessage.name">
                    </div>
                    <!-- Message -->
                    <div class="form-group" :class="{ 'mb-5': !errors.has('content') }">
                        <label class="form-title font-nunito"  for="name">Message</label>
                        <!-- Gabarits -->
                        <select-dropdown
                            :options="options"
                            :selected="selectedTemplate"
                            v-on:selectTemplate="chooseTemplate($event)"
                            :showLabel="false"
                            :type="'template'">
                        </select-dropdown>
                        <textarea
                            type="text"
                            name="message"
                            class="form-control form-border font-nunitor mt-2"
                            :placeholder="$t('template-text-placeholder')"
                            v-model.trim="massMessage.content"
                            v-validate="'max:1600'"
                            data-vv-as="Message"
                            data-vv-name="content"
                            :class="{ 'is-invalid': submitted && errors.has('content') }">
                        </textarea>
                        <sms-counter :sms="massMessage.content" v-on:limitExceeded="smsLimitExceeded = $event"></sms-counter>
                    </div>
                    <div class="invalid-feedback text-left mb-5 font-nunito"
                        v-if="submitted && errors.has('content')">
                        {{ errors.first('content') }}
                    </div>

                    <!-- Audiences -->
                    <div class="form-group mb-4">
                        <div class="d-flex flex-column">
                            <label class="form-title mb-0 font-nunito" for="audience">Audience(s)</label>
                            <small class="text-left text-black font-nunito">{{ $t('no-tags-selected') }}</small>
                        </div>
                        <div class="d-flex d-inline-flex flex-row p-0 mr-2 mt-3 tags"
                            v-for="(tag, i) in massMessage.audiences" :key="i">
                            <p>{{ tag }}
                                <button type="button" class="close tags-button font-nunito" aria-label="Close" @click="deleteTag(i)">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </p>
                        </div>
                        <input class="form-control w-100 add-tag-form"
                            :class="{ 'mt-3' : massMessage.audiences === null }"
                            name="tags"
                            type="text"
                            v-model.trim="newTag"
                            autocomplete="off"
                            list=”idOfDatalist”
                            :placeholder="$t('add-tag')"
                            ref="inputTags">
                        <datalist id=”idOfDatalist” >
                            <option v-for="tag in allTags" :key="tag.id" class="font-nunito">
                                {{tag.attributes.name}}
                            </option>
                        </datalist>
                        <div class="d-flex text-left justify-content-end mt-2">
                            <button class="btn btn-primary btn-sm rounded rounded-pill px-4 font-nunito"
                                :disabled="disabled = activeAddTagBtn"
                                @click.prevent="addTag()">
                                {{ $t('add') }}
                            </button>
                        </div>
                    </div>

                    <!-- Planned Date & Time -->
                    <div class="date-time-to-send-section form-group mb-5">
                        <div class="d-flex flex-column">
                            <label class="font-nunito text-14 text-dark mb-0" for="date-time">
                                {{ $t('time-date-to-send') }}
                            </label>
                            <small class="text-left text-black font-nunito">
                                {{ $t('schedule-time-to-send') }}
                            </small>
                            <small class="btn-link text-decoration"><utc>{{ $t('learn-about-utc') }}</utc></small>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <label for="planned-date" class="font-nunito text-12 text-darker-grey mb-0">
                                    {{ $t('Date') }}
                                </label>
                                <input type="date" class="form-border"
                                    name="planned-date"
                                    v-validate="`date_format:yyyy-MM-dd|after:${minDate}${dateRules}`"
                                    v-model="massMessage['planned-date']">
                                <div class="invalid-feedback" v-if="submitted && errors.has('planned-date')">
                                    {{ errors.first('planned-date') }}
                                </div>
                            </div>
                            <div class="col">
                                <label for="planned-time" class="font-nunito text-12 text-darker-grey mb-0">
                                    {{ $t('utc-time') }}
                                </label>
                                <input type="time" class="form-border"
                                    name="planned-time"
                                    v-validate="timeRules"
                                    v-model="massMessage['planned-time']">
                                <div class="invalid-feedback" v-if="submitted && errors.has('planned-time')">
                                    {{ errors.first('planned-time') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="d-flex justify-content-center" v-if="editMessage">
                    <button class="btn rounded-pill bg-white px-4 send-now-button"
                        :disabled="sendBtnDisabled"
                        @click="sendSMS()">
                        <span class="spinner-border spinner-small text-black" v-if="sendingMessage"></span>
                        <font-awesome-icon v-else class="text-dark-pink mr-2" :icon="['fas', 'paper-plane']"/>
                        <span class="text-black" v-if="sendingMessage">
                            {{ $t('sending-in-progress') }}
                        </span>
                        <span class="text-black" v-else>
                            {{ $t('send-now') }}
                        </span>
                    </button>
                </div>
            </div>
            <div class="modal-footer">
                <div class="d-flex justify-content-between w-100">
                    <button class="btn btn-link form-buttons" @click="clearMassiveSend()">
                        {{ $t('cancel')}}
                    </button>
                    <button
                        class="btn btn-primary rounded rounded-pill px-4 form-buttons"
                        @click="editMessage ? editMassMessage() : addMassMessage()"
                        :disabled="disabled = activeSaveBtn">
                        <span v-if="editMessage && !loading">{{ $t('save') }}</span>
                        <span v-if="!editMessage && !loading">{{ $t('add') }}</span>
                        <span class="spinner-border text-primary" v-if="loading"></span>
                    </button>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

import Vue from 'vue';

// Import internal libraries
import Alert  from '@/helpers/Alert.js';
import Utc    from '@/core/components/UTC.vue';
import Format from '@/helpers/Format.js';

// Import Components
import SelectDropdown from '@/core/components/SelectDropdown.vue';
import SmsCounter     from '@/connectors/sms/components/SmsCounter.vue';

// Import external libraries
import {
    mapActions,
    mapState,
    mapGetters
} from 'vuex';
const clone = require('rfdc')();
import _      from 'underscore';
import moment from 'moment';

export default {
    props:      ['editMessage', 'displayMessageDetail'],
    components: {
        Utc,
        SelectDropdown,
        SmsCounter
    },

    created () {
        this.getTags();
    },

    mounted () {
        // Get the temaplate
        this.getTemplate().then(() => {
            this.options.push({
                'id':      null,
                'name':    this.$i18n.t('choose-template'),
                'content': ''
            });
            for (const templateId in this.templates) {
                this.options.push({
                    'id':      templateId,
                    'name':    this.templates[templateId].attributes.name,
                    'content': this.templates[templateId].attributes.content
                });
            }
        });
    },

    data () {
        const MASS_CATEGORY  = 'mass';

        return {
            selectedTemplate: this.$i18n.t('choose-template'),
            newTag:           '',
            tags:             [],
            disabled:         false,
            smsLimitExceeded: false,
            loading:          false,
            submitted:        false,
            sendingMessage:   false,
            massMessage:      {
                audienceCount:  '',
                audiences:      [],
                content:        '',
                name:           '',
                category:       MASS_CATEGORY,
                'planned-date': '',
                'planned-time': ''
            },
            options: []
        };
    },

    computed: {
        ...mapState('core/scheduled-messages', {
            massMessages:        'plannedScheduledMessages',
            selectedMassMessage: 'selectedScheduledMessage'
        }),

        ...mapGetters('core/tags', { 'allTags': 'tags' }),

        /**
         * Load templates
         */
        ...mapState('core/templates', { 'templates': 'templates' }),

        /**
         * Get the min date (yesterday)
         */
        minDate () {
            return moment().subtract(1, 'days')
                .format('yyyy-MM-DD')
                .toString();
        },

        /**
         * Validates the planned date
         *
         * @return boolean
         */
        dateRules () {
            return this.massMessage['planned-time'] ? '|required' : '';
        },

        /**
         * Validates the planned time
         *
         * @return boolean
         */
        timeRules () {
            return this.massMessage['planned-date'] ? '|required' : '';
        },

        sendBtnDisabled () {
            return this.errors.any() || (this.massMessage.name === '' || this.massMessage.content === '');
        },

        /**
         * Test tag input to activate add button
         *
         * @return boolean
         */
        activeAddTagBtn () {
            return this.newTag === '';
        },

        /**
         * Test message name and content to activate add/edit button
         *
         * @return boolean
         */
        activeSaveBtn () {
            let activateSaveBtn;

            if (this.loading || this.sendingMessage) {
                activateSaveBtn = false;
            }

            if (this.massMessage.name === '' || this.massMessage.content === '' || this.smsLimitExceeded) {
                activateSaveBtn = true;
            } else {
                // Compare the text entered with existed to activate button
                activateSaveBtn = false;
            }
            return activateSaveBtn;
        },

        currentLang () {
            return this.$i18n.locale;
        }
    },

    watch: {
        currentLang () {
            this.selectedTemplate = this.$i18n.t('choose-template');
            this.options.map(option => {
                if (option.id === null) {
                    option.name = this.$i18n.t('choose-template');
                }
            });
        },

        /**
         * Selected mass message watcher
         *
         * @param      {Object}  value   The value
         */
        selectedMassMessage (value) {
            this.massMessage.audiences = clone(value.audiences);
            this.massMessage.content = clone(value.content);
            this.massMessage.name = clone(value.name);
            this.massMessage['planned-date'] = clone(value.plannedDate);
            this.massMessage['planned-time'] = clone(value.plannedTime);
        }
    },

    methods: {
        /**
         * Load templates
         */
        ...mapActions('core/templates', { getTemplate: 'index' }),

        ...mapActions('core/scheduled-messages', {
            create:                   'create',
            update:                   'update',
            send:                     'send',
            resetSelectedMassMessage: 'resetSelectedScheduledMessage'
        }),

        ...mapActions('core/tags', { getTags: 'index' }),

        /**
         * Helper method to format date to use in template
         */
        toUtc: Format.toUtc,

        /**
         * Update selected option
         *
         * @return    void
         */
        chooseTemplate (option) {
            if (this.massMessage.content === '') {
                this.selectedTemplate    = option.name;
                this.massMessage.content = option.content;
                this.disabled            = option.id === null;
            } else {
                Alert.confirmation(this.$i18n.t('this-will-replace-message'), this.$i18n.t('replace'))
                    .then((response) => {
                        if (response.isConfirmed) {
                            this.selectedTemplate = option.name;
                            this.massMessage.content = option.content;
                        }
                    });
            }
        },

        /**
         *  Add tag to table of audiences
         */
        addTag () {
            const existAudience = _.find(this.allTags, (tag) =>

                // Condition to return good tag
                tag.attributes.name === this.newTag
            );

            // Check if the newTag exist in all tags
            if (typeof existAudience !== 'undefined') {
                if (!this.massMessage.audiences || this.massMessage.audiences.length === 0) {
                    this.massMessage.audiences = [this.newTag];
                } else {
                    // Check if the newTag exist in current selected audience
                    const existTag = _.find(this.massMessage.audiences, (tag) =>

                        // Condition to return good tag
                        tag === this.newTag
                    );

                    if (typeof existTag === 'undefined') {
                        this.massMessage.audiences.push(this.newTag);
                    }
                }
            }
            this.newTag = '';
        },

        /**
         * Delete tag
         *
         * @param   {integer}   position    The position of tag in contact tags table.
         */
        deleteTag (position) {
            this.massMessage.audiences.splice(position, 1);
        },

        /**
         *  Add a massive message
         */
        addMassMessage () {
            Vue.prototype.$validation = this.$validator;

            // Spinner on and disable button
            this.loading = true;
            this.disabled = true;
            this.submitted = true;

            this.$validator.validate().then((valid) => {
                if (!valid) {
                    this.loading = false;
                    return;
                }

                this.create({ attributes: this.massMessage })
                    .then(() => {
                        this.loading = false;

                        this.resetSelectedMassMessage();
                        this.newTag = '';
                        this.$emit('massListUpdated');
                        Alert.success(this.$i18n.t('massive-send-created'));
                        this.clearMassiveSend();
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            });
        },

        /**
         *  Edit the selected massive message
         */
        editMassMessage () {
            Vue.prototype.$validation = this.$validator;

            // Spinner on and disable button
            this.loading = true;
            this.disabled = true;
            this.submitted = true;

            this.$validator.validate().then((valid) => {
                if (!valid) {
                    this.loading = false;
                    return;
                }

                this.update({
                    id:         this.selectedMassMessage.id,
                    attributes: this.massMessage
                })
                    .then(() => {
                        this.loading = false;
                        Alert.success(this.$i18n.t('massive-send-updated'));
                        this.clearMassiveSend();
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            });
        },

        /**
         * Send immediately a massive message
         */
        sendSMS () {
            Vue.prototype.$validation = this.$validator;

            // Disable button
            this.disabled = true;
            this.submitted = true;

            this.$validator.validate().then((valid) => {
                if (!valid) {
                    this.loading = false;
                    return;
                }

                Alert.scheduledMessageSendConfirmation('title-mass-message-send', 'confirm-scheduled-send')
                    .then((response) => {
                        if (!response.isConfirmed) {
                            return;
                        }
                        this.loading = true;
                        this.sendingMessage = true;
                        this.update({
                            id:         this.selectedMassMessage.id,
                            attributes: this.massMessage
                        })
                            .then(() => {
                                this.send({ id: this.selectedMassMessage.id })
                                    .then(() => {
                                        this.loading = false;
                                        this.sendingMessage = false;

                                        this.resetSelectedMassMessage();
                                        Alert.success(this.$i18n.t('scheduled-send-success'));
                                        this.clearMassiveSend();
                                    })
                                    .catch(() => {
                                        this.loading = false;
                                    });
                            })
                            .catch(() => {
                                this.loading = false;
                            });
                    });
            });
        },

        /**
         * Cancel add or edit massive message
         * Reset values to default
         */
        clearMassiveSend () {
            this.loading = false;
            this.newTag = '';
            this.resetSelectedMassMessage();
            this.$bvModal.hide('massive-send-sidebar');
        }
    }
};
</script>
