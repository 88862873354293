<template>
    <div class="d-flex align-items-stretch">
        <navigation></navigation>
        <div class="sms-config-global container-fluid">
            <preloader-placeholder :loading="loading" :type="'configuration'"></preloader-placeholder>
            <div v-if="loading === false">
                <div class="connect-text text-left mb-5">
                    <h3>{{$t('config-folio-connector')}}</h3>
                </div>
                <search-numbers v-if="phoneNumber == undefined"/>
                <active-folio v-if="phoneNumber !== undefined"/>
            </div>
           <template v-if="loading === false">
               <SmsInfoPlus></SmsInfoPlus>
            </template>
        </div>
    </div>
</template>
<script>

// Internal components
import PreloaderPlaceholder from '@/core/components/PreloaderPlaceholder.vue';
import ActiveFolio          from '../folio/ActiveFolio.vue';
import SearchNumbers        from '../SearchNumbers.vue';
import SmsInfoPlus          from '../SmsInfoPlus.vue';

// Import external libraries
import {
    mapState, mapActions, mapGetters
} from 'vuex';

export default {
    components: {
        ActiveFolio,
        SearchNumbers,
        PreloaderPlaceholder,
        SmsInfoPlus
    },

    data () {
        return {
            loading:       true,
            nameConnector: 'twilio'
        };
    },

    computed: {
        // State with activated phone number
        ...mapState('core/number', [
            'phoneNumber'
        ]),

        // Getter for the selected number
        ...mapGetters('core/number', { numberGetter: 'smsPhoneNumber' })
    },
    mounted () {
        this.nameConnector = window.location.href.split('/')[5];
        this.listNumber().then(() => {
            this.loading = false;
            this.numberGetter;
        });
    },
    methods: {
        // Method get list of activated number
        ...mapActions('core/number', { listNumber: 'index' })
    }
};
</script>
