<template>
    <div class="d-flex align-items-stretch" id="choose-connectorsSms-page">
        <navigation></navigation>
        <div class="container-fluid main-content">
            <div class="row pr-3 pl-2">
                <div class="col-sm text-left">
                    <h3 class="section-title ">{{ $t("connector-sms-config") }}</h3>
                </div>
            </div>
            <div id="section-manage-connector">
                <div class="facebook-configuration first container-fluid main-content" id="hosted-vs-generated">
                    <div class="container-fluid">
                        <div class="row justify-content-center" id="bloc-methods-nbr">
                            <div class="col card-info text-center block-option mr-4 mb-0 bloc-telnyx">
                                <div class="bloc-method-nbr">
                                    <div class="bloc-img-phone">
                                        <img  src="@/core/assets/images/phone.png" alt="Phone" class="img-phone phone-fixe"/>
                                    </div>
                                    <p class="parag-active-nbr mt-4">
                                        {{$t('activate-existing-nbr')}}
                                        <br/>
                                        <span class="descr-phone">{{$t('land-line-&-ip-line')}}</span>
                                    </p>
                                    <button class="btn btn-primary rounded-pill btn-config-nbr btn-style mt-3 py-1" id="activate-telnyx" @click="onClickActivateNumber">
                                        {{$t('activate')}}
                                    </button>
                                </div>
                            </div>
                            <div class="col card-info text-center block-option ml-4 mb-0">
                                <div class="bloc-method-nbr" id="bloc-generate-nbr">
                                    <div class="bloc-img-phone">
                                        <img  src="@/core/assets/images/generateNmbr.png" alt="Generate Number" class="img-phone"/>
                                    </div>
                                    <p  class="parag-active-nbr mt-5">
                                        {{$t('generate-sms-nbr')}}
                                        <br/>
                                        <br/>
                                    </p>
                                    <button class="btn btn-primary rounded-pill btn-config-nbr btn-style mt-3 py-1" id="generate-twilio" @click="onClickGenerateNumber">
                                        {{$t('generate')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--FOLIO-->
                    <div class="folio-voip">
                        <div class="container-fluid card-info message-card mb-3" id="connector-folio">
                            <h5 class="title-folio-voip mt-2">{{ $t('or-youre-with-our-partners') }}</h5>
                            <div class="sms-row row d-flex align-items-center">
                                <div class="col-sm text-left">
                                    <img class="img-logo text-center p-3" src="@/core/assets/images/foliomedian-logo.png" alt="">
                                </div>
                                <div class="col-sm">
                                    <continue-with-folio/>
                                </div>
                            </div>
                        </div>
                        <!--VOIPMS-->
                        <div class="container-fluid card-info message-card mb-3" id="connector-voipms">
                            <div class="sms-row row d-flex align-items-center">
                                <div class="col-sm text-left">
                                    <img class="img-logo text-center p-3" src="@/core/assets/images/voipms-logo.png" alt="">
                                </div>
                                <div class="col-sm">
                                    <continue-with-voipms/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal componants
import ContinueWithFolio   from '@/connectors/sms/components/ContinueWithFolio.vue';
import ContinueWithVoipms  from '@/connectors/sms/components/ContinueWithVoipms.vue';

// Import external libraries
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: "SmsConfigConnectors",

    components: {
        ContinueWithFolio,
        ContinueWithVoipms
    },

    /**
     * Check function
     * Before creating this page, check if the user is not logged in
     */
    created () {
        if (this.sessionExists === false) {
            this.$router.push({
                name: 'login'
            });
            return;
        }

        // Check If this user is an admin
        if (!this.user.attributes['is-admin'] && !this.user.attributes['is-super-admin']) {
            this.$router.replace({
                name: `facebook-connector:${this.$i18n.locale}`
            });
        }
    },

    computed: {
        ...mapState('core/auth', [
            'user'
        ]),

        ...mapState('core/session', [
            'sessionExists'
        ]),

        ...mapGetters('core/providers', [
            'twilioProvider',
            'telnyxProvider'
        ])
    },

    methods: {
        /**
         * Methods for Providers API request
         *
         * selectProvider   Select a provider from the list, place in State
         */
        ...mapActions('core/providers', {
            selectProvider: 'select'
        }),

        /**
         * Click on the btn Activate number
         */
        onClickActivateNumber () {
            this.selectProvider(this.telnyxProvider);
            this.$router.replace({ name: `telnyx-config:${this.$i18n.locale}` });
        },

        /**
         * Click on the btn generate number
         */
        onClickGenerateNumber () {
            this.selectProvider(this.twilioProvider);
            this.$router.replace({ name: `sms-config:${this.$i18n.locale}` });
        }
    }
};
</script>
