<template>
    <div id="contact-box" v-if="contact">
        <div class="container-fluid card-info contact-card mb-3" v-bind:class="{selected: isSelected}">
            <div class="row align-items-center">
                <div class="align-self-center checkbox-section" v-bind:class="{'show-check': showCheckbox}">
                    <label class="custom-checkbox">
                        <input type="checkbox" class="custom-control-input" v-model="isSelected" @click="selectContact()">
                        <span class="custom-control-label contacBox-checkbox"></span>
                    </label>
                </div>
                <div class="avatar col-sm-2 img-box">
                    <router-link @click.native="getContact(contact.id)" :to="{ name: '' }">
                        <label class="label-avatar">
                            <model-short-name v-if="profilePicture === null" v-bind:model="contact"></model-short-name>
                            <img class="contact-img" v-bind:class="{'checkbox-space': showCheckbox}" :src='profilePicture' alt="" v-else>
                        </label>
                    </router-link>
                </div>
                <div class="col-sm">
                    <div class="row ml-2">
                        <div class="col-sm">
                            <h6 class="contact-name text-primary text-left mb-3">
                                <router-link @click.native="getContact(contact.id)" :to="{ name: ''}"> {{ contact.attributes['name'] }} </router-link>
                            </h6>
                            <div class="col-sm d-flex flex-wrap p-0">
                                <div class="tags mr-3" v-for="tag in contact.attributes.tags" v-bind:key="tag"><p>{{tag}}</p></div>
                            </div>
                        </div>
                        <div class="col-sm">
                            <div class="col-sm-12 text-left mb-3 p-0 profile-type">
                                <icons-main v-if="this.contact.attributes['facebook-id']" name="facebook"></icons-main>
                                <icons-main v-else name="mobile"></icons-main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal components
import ModelShortName from '../../components/ModelShortName.vue';
import IconsMain from '../IconsMain.vue';

// Internal services js
import { EventBus }   from '../../../helpers/EventBus.js';

// Import external libraries
import { mapActions } from 'vuex';

export default {
    props: ['contact'],

    components: {
        ModelShortName,
        IconsMain
    },

    mounted () {
        // Listen for update profile event.
        EventBus.$on('open-select', (selectContact) => {
            this.showCheckbox = selectContact;
        });

        EventBus.$on('select-all', () => {
            this.isSelected = true;
        });

        EventBus.$on('unselect-all', () => {
            this.isSelected = false;
        });
    },
    computed: {
        profilePicture () {
            // If user have a profile picture
            if (this.contact.attributes['profile-picture'] !== '' && this.contact.attributes['profile-picture'] !== null) {
                return this.contact.attributes['profile-picture'];
            }

            return null;
        },
    },
    data () {
        return {
            isSelected:   false,
            showCheckbox: false,
            contactName:  ''
        };
    },
    methods: {
        ...mapActions('core/contacts', [
            'getSingle'
        ]),

        /**
         *
         * Set the Id of a contact in a session
         *
         * @return    void
         */
        getContact (contactId) {
            this.getSingle({
                id: contactId
            }).then(() => {
                this.$router.push({
                    name: `contact:${this.$i18n.locale}`
                });
            });
        },

        /**
         * Select or unselect this contact then add or delete it from the selected list.
         *
         * @return    void
         */
        selectContact () {
            this.isSelected = !this.isSelected;
            EventBus.$emit('selected-contact', this.contact, this.isSelected);
        }
    }
};
</script>
