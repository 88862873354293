import Api       from '@/helpers/Api.js';
import normalize from 'json-api-normalizer';
import _         from 'underscore';

// Import mutations
import {
    SET_PROVIDERS,
    SET_SELECTED_PROVIDER
} from './mutation-types.js';

const resource = '/connectors/sms/v1/providers';

// Initial state
const state = () => ({
    providers:        [],
    selectedProvider: null
});

// Getters
const getters = {
    connectedProvider: (state) => {
        return _.find(state.providers, (provider) => provider.attributes.isConnected === true);
    },

    /**
     * Gets the twilio provider.
     *
     * @param  {Object}  state  The state
     *
     * @return {Object}
     */
    twilioProvider: (state) => {
        return _.find(state.providers, (provider) => provider.attributes.name === 'Twilio');
    },

    /**
     * Gets the telnyx provider.
     *
     * @param  {Object}  state  The state
     *
     * @return {Object}
     */
    telnyxProvider: (state) => {
        return _.find(state.providers, (provider) => provider.attributes.name === 'Telnyx');
    }
};

// Actions
const actions = {
    /**
     * Get a list of Providers
     *
     * @param      {Object} context The context
     *
     * @return     {Promise}
     */
    index: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}`)
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKey: false });
                    context.commit(SET_PROVIDERS, normalizedData.providers);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Select Provider from list of Providers
     *
     * @param     {Object} context         The context
     * @param     {Object} payload.id      The provider identifier
     *
     * @return    {Promise}
     */
    select: (context, { id }) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}/${id}`)
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKey: false });
                    context.commit(SET_SELECTED_PROVIDER, normalizedData.providers[id]);
                    resolve(normalizedData.providers[id]);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Send all user informations to configure Telnyx
     *
     * @param     {Object} context         The context
     * @param     {Object} attributes      The form attributes
     *
     * @return    {Promise}
     */
    configureTelnyxWithFile: (context, { attributes }) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('bill', attributes.bill);
            formData.append('first-name', attributes['first-name']);
            formData.append('last-name', attributes['last-name']);
            formData.append('email', attributes.email);
            formData.append('phone-number', attributes['phone-number']);
            formData.append('address-line-1', attributes['address-line-1']);
            formData.append('address-line-2', attributes['address-line-2']);
            formData.append('city', attributes.city);
            if (attributes.extension) {
                formData.append('extension', attributes.extension);
            }
            formData.append('province-state', attributes['province-state']);
            formData.append('country', attributes.country);
            formData.append('postal-code', attributes['postal-code']);
            formData.append('provider-name', attributes['provider-name']);
            formData.append('account-number', attributes['account-number']);
            formData.append('signature', attributes.signature);

            Api.post(`${resource}/telnyx/configure/files`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {
    [SET_PROVIDERS]: (state,  payload) => {
        state.providers = payload;
    },
    [SET_SELECTED_PROVIDER]: (state, payload) => {
        state.selectedProvider = payload;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
