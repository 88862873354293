/**
 * DeactivateNumberMixin
 *
 * Add methods and alert for deleting a phone number
 */

import Alert from '@/helpers/Alert.js';

// Import external libraries
import { mapGetters, mapActions } from 'vuex';

export default {
    data () {
        return {
            loading:                       true,
            deleting:                      false,
            deactivateConfirmationTitle:   this.$i18n.t('confirmation-required'),
            deactivateConfirmationMessage: this.$i18n.t('deactivate')
        };
    },

    mounted () {
        this.loading = true;

        // Get the list of active sms phone number
        this.listNumber().then(() => {
            // Then get the state of the phone number
            this.numberGetter;
            this.loading = false;
        });
    },

    computed: {
        // Getter for the selected number
        ...mapGetters('core/number', { numberGetter: 'smsPhoneNumber' })
    },

    methods: {
        /**
         * Methods to for SMS Phone Number API request
         *
         * deactivateNumber API request to deactivate the sms number
         */
        ...mapActions('core/number', {
            listNumber:       'index',
            deactivateNumber: 'delete'
        }),

        /**
         * Show confirm delete number alert
         *
         * @param {string} numberId The number id
         *
         */
        deactivateNumberAlert (numberId) {
            Alert.confirmation(this.deactivateConfirmationMessage, this.$i18n.t('deactivate'), this.deactivateConfirmationTitle)
                .then((response) => {
                    if (response.value) {
                        // Deactivate the sms Phone Number
                        this.loading = true;
                        this.deleting = true;
                        this.deactivateNumber({ id: numberId }).then(() => {
                            this.deleting = false;

                            // Get the new state of the number, should be empty
                            this.numberGetter;
                            this.loading = false;
                            Alert.success(this.$i18n.t('number-deleted-successfully'));

                            // Got to the config sms connectors page
                            this.$router.push({ name: `connectors-page:${this.$i18n.locale}` });
                        })
                            .catch((error) => {
                                console.log(error);
                                Alert.fail(this.$i18n.t(error.response.data.errors[0].detail));
                                this.loading = false;
                            });
                    } else {
                        this.loading = false;
                    }
                });
        }
    }
};
