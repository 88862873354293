import Api from '@/helpers/Api.js';
import qs from 'qs';

const resource = '/connectors/facebook/v1/pages';

export default {
    /**
     * Get all pages
     *
     * @return    {object}    jsonapi response
     */
    index () {
        return Api.get(`${resource}`);
    },

    /**
     * Get single page
     *
     * @param    {string}    pageId    The page id
     *
     * @return    {object}    jsonapi response
     */
    get (pageId) {
        return Api.get(`${resource}/${pageId}`);
    },

    /**
     * Get single page interactions
     *
     * @param    {string}    pageId    The page id
     * @param    {object}    filters   The interactions filters
     * @param    {object}    page      The pagination page
     *
     * @return {object}  jsonapi response
     */
    getInteractions (pageId, filters, page) {
        const defaultPageSize = 25;
        let pageParam = page;
        if (typeof page === 'undefined') {
            pageParam = {};
        }

        pageParam.number = pageParam.number || 1;
        pageParam.size   = pageParam.size || defaultPageSize;

        const urlParams = {
            include: 'author,post,reply,reply.author,parent,parent.author,page-reaction',
            page:    pageParam
        };

        if (filters !== null) {
            urlParams.filter = filters;
        }

        return Api.get(`${resource}/${pageId}/interactions`, {
            params:           urlParams,
            paramsSerializer: params => qs.stringify(params, {
                encode: false
            })
        });
    },

    /**
     * Delete Page
     *
     * Delete this active page
     *
     * @param    {string}    pageId    The page id
     *
     * @return    {object}    jsonapi response
     */
    delete (pageId) {
        return Api.delete(`${resource}/${pageId}`);
    },

    /**
     * Create a page
     *
     * @param    {string}    remotePageId    The id of remote page
     *
     * @return    {object}    jsonapi response
     */
    create (remotePageId) {
        return Api.post(`${resource}`, {
            'data': {
                'type':       'pages',
                'attributes': {
                    'remote_page_id': remotePageId
                }
            }
        });
    },

    /**
     * Get page stats
     *
     * @param    {string}    pageId    The page id
     *
     * @return    {object}    jsonapi response
     */
    getStats (pageId) {
        return Api.get(`${resource}/${pageId}/stats`);
    }
};
