// Repository.js
import axios            from 'axios';
import HttpErrorHandler from '../helpers/HttpErrorHandler.js';
import i18n             from '../libraries/I18n.js';

// API link
axios.defaults.baseURL = `${process.env.VUE_APP_APIURL}/api`;
axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/vnd.api+json',
        'Accept':       'application/vnd.api+json',
    }
});

// Intercept the request before it's sent, to update the header if needed.
axiosInstance.interceptors.request.use((config) => {
    config.headers['Accept-Language'] = `${i18n.locale}`;
    return config;
});

// Intercept the response in errors case, should be treated in httpErrorHandler first.
axiosInstance.interceptors.response.use(null, (error) => {
    HttpErrorHandler.checkHttpErrors(error);
    return Promise.reject(error);
});

export default axiosInstance;
