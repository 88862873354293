<template>
    <div id="tag-page" class="d-flex align-items-stretch">
        <navigation></navigation>
        <reports-manager></reports-manager>
    </div>
</template>
<script>

// Components
import ReportsManager  from './ReportsManager.vue';

export default {
    components: {
        ReportsManager
    }
};
</script>
