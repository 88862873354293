<template>
    <div class="avatar">
        <router-link @click.native="redirectToSingleContact()" :to="{ name: '' }">
            <label class="label-avatar">
                <model-short-name v-if="profilePicture === null" v-bind:model="contact ? contact : singleContact"></model-short-name>
                <img class="contact-img" :src='profilePicture' alt="" v-else>
            </label>
        </router-link>
    </div>
</template>
<script>

// Components
import ModelShortName from '../../components/ModelShortName.vue';

// Import external libraries
import { mapActions, mapState } from 'vuex';

export default {
    components: { ModelShortName },
    props:      ['contact'],
    computed:   {
        ...mapState('core/contacts', {
            singleContact: 'single'
        }),

        /**
         * Check if we have a profile picture for the contact
         * If yes, we return the URL of the image, if not we return null
         */
        profilePicture () {
            // If user have a profile picture
            if (this.contact && this.contact.attributes['profile-picture']) {
                return this.contact.attributes['profile-picture'];
            }
            return null;
        }
    },

    methods: {
        ...mapActions('core/contacts', [
            'getSingle'
        ]),

        /**
         * Redirect user to the single contact page
         *
         * @return    void
         */
        redirectToSingleContact () {
            if (!this.$router.currentRoute.name.includes('contact')) {
                const contactId = this.contact.attributes['parent-id'] ? this.contact.attributes['parent-id'] : this.contact.id;
                this.getSingle({
                    id: contactId
                }).then(() => {
                    this.$router.push({
                        name: `contact:${this.$i18n.locale}`
                    });
                });
            }
        }
    }
};
</script>
