<template>
    <div class="modal" id="form-edit-contact" v-if="contact">
        <div class="modal-dialog">
            <div class="modal-content px-5 py-3">
                <div class="modal-header justify-content-center border-0">
                    <h3 class="modal-title text-primary">{{ $t('edit-contact') }}</h3>
                </div>
                <div class="modal-body add-contact-body">
                    <transition name="slide-down" mode="out-in">
                        <form v-if="currentStep === STEP_UPDATE" key="form">
                            <div class="form-group text-left" v-if="typeof contact.attributes['facebook-id'] === 'undefined' || contact.attributes['facebook-id'] === null">
                                <label for="phone-number" class="col-form-label">{{ $t('phone-number') }} <strong class="obligatory" v-if="contact.attributes['facebook-id'] === null">*</strong></label>
                                <input
                                    id="phone-number"
                                    name="phone-number"
                                    type="text"
                                    class="form-control"
                                    v-model.trim="contactUpdatableData['phone-number']"
                                    v-validate="'required|valid_phone'"
                                    :class="{ 'is-invalid': submitted && errors.has('phone-number') }"
                                    :data-vv-as="$t('phone-number')"
                                    @keyup="disabled = activeBtn"
                                >
                                <div class="invalid-feedback" v-if="(submitted && (typeof contact.attributes['facebook-id'] === 'undefined' || contact.attributes['facebook-id'] === null))">
                                    {{ errors.first('phone-number') }}
                                </div>
                            </div>
                            <div class="form-group text-left">
                                <label for="first-name" class="col-form-label">{{ $t('first-name') }}</label>
                                <input
                                    id="first-name"
                                    name="first-name"
                                    type="text"
                                    class="form-control"
                                    v-model.trim="contactUpdatableData['first-name']"
                                    :class="{ 'is-invalid': submitted && errors.has('first-name') }"
                                    @keyup="disabled = activeBtn"
                                >
                            </div>
                            <div class="form-group text-left" >
                                <label for="last-name" class="col-form-label">{{ $t('last-name') }}</label>
                                <input
                                    id="last-name"
                                    name="last-name"
                                    type="text"
                                    class="form-control"
                                    v-model.trim="contactUpdatableData['last-name']"
                                    :class="{ 'is-invalid': submitted && errors.has('last-name') }"
                                    @keyup="disabled = activeBtn"
                                >
                            </div>
                            <div class="form-group text-left" v-if="typeof contact.attributes['facebook-id'] !== 'undefined' && contact.attributes['facebook-id'] !== null">
                                <label for="facebook-id" class="col-form-label">{{ $t('facebook-id') }}</label>
                                <h6>{{ contact.attributes['facebook-id'] }} <small>({{ contact.attributes['facebook-name'] }})</small></h6>
                            </div>
                            <div class="form-group text-left">
                                <label for="email" class="col-form-label">{{ $t('email-adress') }}</label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    class="form-control"
                                    v-validate="'email'"
                                    :data-vv-as="$t('email-adress')"
                                    v-model.trim="contactUpdatableData['email']"
                                    :class="{ 'is-invalid': submitted && errors.has('email') }"
                                    @keyup="disabled = activeBtn"
                                >
                            </div>
                            <div class="invalid-feedback" v-if="(submitted && (typeof contact.attributes['facebook-id'] === 'undefined' || contact.attributes['facebook-id'] !== null))">
                                {{ errors.first('email') }}
                            </div>
                        </form>
                        <div class="col-sm-12" v-if="currentStep === STEP_UPLOAD" key="picture">
                            <div class="avatar form-group">
                                <label class="label-avatar" for="contactAvatar">
                                    <model-short-name v-if="profilePicture === null && previewPicture === null" v-bind:model="contact"></model-short-name>
                                    <img class="contact-img" :src="previewPicture" alt="" v-if="previewPicture !== null">
                                    <img class="contact-img" :src="profilePicture" alt="" v-if="profilePicture !== null && previewPicture === null">
                                </label>
                                <div class="form-group mt-3">
                                    <label class="label-avatar" for="contactAvatar"><h6 class="text-primary">{{ profilePicture === null && previewPicture === null  ? $t('add-picture') : $t('change-picture') }}</h6></label>
                                    <input type="file" class="form-control-file" accept="image/*" ref="file" @change="previewImage()" id="contactAvatar" hidden><br>
                                    <label class="label-avatar" @click="deletePreviewPicture()" v-if="(profilePicture !== null || previewPicture != null) && !(profilePicture !== null && previewPicture != null)"><small class="delete-avatar">{{ $t('delete-picture') }}</small></label>
                                    <label class="label-avatar" @click="deletePreviewPicture()" v-if="profilePicture !== null && previewPicture != null"><small class="delete-avatar">{{ $t('cancel-change-picture') }}</small></label>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn text-btn style-btn" ref="closeButton" @click="cancelUpdate()" data-dismiss="modal" id="close" v-show="currentStep === STEP_UPDATE">{{ $t('cancel') }}</button>
                    <button type="button" class="btn btn-primary rounded-pill invite-btn style-btn" :disabled="disabled" @click="stepsController()">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" :disabled="disabled" v-if="loading"></span>
                        {{ $t('save') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';

// Internal componants
import ModelShortName from '../../components/ModelShortName.vue';

// Internal services js
import Alert        from '../../../helpers/Alert.js';
import { EventBus } from '../../../helpers/EventBus.js';

// External libraries
import { mapState, mapActions } from 'vuex';
import HttpStatus               from 'http-status-codes';
import Format                   from '@/helpers/Format.js';

export default {
    components: {
        ModelShortName
    },

    created () {
        // Set the first step.
        this.currentStep = this.STEP_UPDATE;

        // Update contact data
        this.updateContactData();
    },

    data () {
        return {
            contactUpdatableData: {},
            profilePicture:       null,
            previewPicture:       null,
            loading:              false,
            submitted:            false,
            currentStep:          '',
            STEP_UPDATE:          'update_contact',
            STEP_UPLOAD:          'upload_image',
            disabled:             true
        };
    },

    computed: {
        ...mapState('core/contacts', {
            'contact': 'single'
        }),

        // Active save button if the form is correct
        activeBtn () {
            return this.contact.attributes['email']        === this.contactUpdatableData['email']
                && this.contact.attributes['first-name']   === this.contactUpdatableData['first-name']
                && this.contact.attributes['last-name']    === this.contactUpdatableData['last-name']
                && this.contact.attributes['phone-number'] === this.contactUpdatableData['phone-number'];
        },
    },

    watch: {
        contact () {
            this.updateContactData();
        }
    },

    methods: {
        ...mapActions('core/contacts', [
            'patch',
            'uploadProfilePicture',
            'deleteProfilePicture'
        ]),

        /**
         * Steps controller.
         */
        stepsController () {
            switch (this.currentStep) {
                case this.STEP_UPDATE:
                    this.editContact();
                    break;
                case this.STEP_UPLOAD:
                    this.uploadImage();
                    break;
            }
        },

        updateContactData () {
            // If user have a profile picture
            if (this.contact !== null && this.contact.attributes['profile-picture'] !== '' && this.contact.attributes['profile-picture'] !== null) {
                this.profilePicture = this.contact.attributes['profile-picture'];
            }

            // Save the original contact data.
            this.contactUpdatableData = {
                'email':        this.contact.attributes['email'] === null ? '' : this.contact.attributes['email'],
                'name':         this.contact.attributes['name'],
                'phone-number': this.contact.attributes['phone-number'] === null ? '' : this.contact.attributes['phone-number'],
                'first-name':   this.contact.attributes['first-name'],
                'last-name':    this.contact.attributes['last-name']
            };
        },

        /**
         * Upload image for profile
         */
        uploadImage () {
            if (!this.$refs.file.files[0]) {
                this.updateDone();
                return;
            }

            // Upload the image selected by user.
            this.uploadProfilePicture({
                id:      this.contact.id,
                picture: this.$refs.file.files[0]
            })
                .then((profilePicture) => {
                    // Use timestamp (Date.now()) to show the new image.
                    this.profilePicture = profilePicture;
                    this.previewPicture = null;
                    this.contact.attributes['profile-picture'] = this.profilePicture.data.data.links['profile-picture'];
                    this.updateDone();
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                        Alert.fail(this.$i18n.t('must-be-image'));
                        this.deletePreviewPicture();
                    }
                });
        },

        /**
         * Create a new contact
         *
         * @return     void
         */
        editContact () {
            Vue.prototype.$validation = this.$validator;
            this.submitted = true;
            this.loading   = true;
            this.$validator.validate().then((valid) => {
                this.disabled = true;
                if (!valid) {
                    this.loading = false;
                    return;
                }

                // Concatenate the new first-name and the new last-name to create a new full name.
                if (this.contactUpdatableData['first-name'] && this.contactUpdatableData['last-name']) {
                    this.contactUpdatableData.name = `${this.contactUpdatableData['first-name']} ${this.contactUpdatableData['last-name']}`;
                } else if (this.contactUpdatableData['first-name'] || this.contactUpdatableData['last-name']) {
                    this.contactUpdatableData.name = this.contactUpdatableData['first-name'] ?? this.contactUpdatableData['last-name'];
                } else {
                    this.contactUpdatableData.name = this.contact.attributes['facebook-name'] ?? this.contact.attributes['phone-number'];
                }

                // Normalize mobile phone numbers into E.164 format (USA or CAN)
                this.contactUpdatableData['phone-number'] = Format.phone(this.contactUpdatableData['phone-number']).phoneNumber;

                this.sendModificationRequestToApi(this.contactUpdatableData);
            });
        },

        /**
         * preview an image before it is uploaded
         */
        previewImage () {
            if (this.$refs.file.files[0]) {
                this.previewPicture = URL.createObjectURL(this.$refs.file.files[0]);
            }
        },

        /**
         * Delete the preview picture if exist.
         */
        deletePreviewPicture () {
            if (this.$refs.file.files[0]) {
                this.previewPicture = null;
                this.$refs.file.value = '';
                return;
            }

            this.deleteAvatar();
        },

        /**
         * Delete contact picture
         */
        deleteAvatar () {
            Alert.deleteConfirmation(this.$i18n.t('confirm-delete-picture'))
                .then((response) => {
                    if (!response.value) {
                        return;
                    }

                    this.$refs.file.value = '';
                    this.deleteProfilePicture({
                        id: this.contact.id
                    })
                        .then(() => {
                            this.afterUpdate();
                        });
                });
        },

        /**
         * Send request with contact id and attributes to the api
         *
         * @param    {object}    attributes    The new contact information
         *
         * @returns   void
         */
        sendModificationRequestToApi (attributes) {
            this.patch({
                id:         this.contact.id,
                attributes: attributes
            })
                .then(() => {
                    this.updateDone();
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        /**
         * After contact update
         */
        afterUpdate () {
            if (this.currentStep === this.STEP_UPDATE) {
                // After update the contact information.
                this.submitted = false;
                this.loading   = false;
                this.disabled  = false;

                // Go to the upload step.
                this.currentStep = this.STEP_UPLOAD;
                return;
            } else {
                // After update the contact avatar.
                this.profilePicture = null;

                // Update the contacts list.
                EventBus.$emit('contact-updated', this.profilePicture);
            }
        },

        /**
         * If the update is done.
         */
        updateDone () {
            // Show the success alert and close the model.
            Alert.success(this.$i18n.t('contact-updated'));
            this.$refs.closeButton.click();

            // Back to update step
            this.currentStep = this.STEP_UPDATE;

            // Disable update button
            this.disabled = true;
        },

        /**
         * Cancel the update and show the original contact information.
         */
        cancelUpdate () {
            this.submitted = false;
            this.loading   = false;

            // Reste the validation
            this.$validator.reset();

            this.contactUpdatableData = {
                'email':        this.contact.attributes['email'],
                'name':         this.contact.attributes['name'],
                'phone-number': this.contact.attributes['phone-number'],
                'first-name':   this.contact.attributes['first-name'],
                'last-name':    this.contact.attributes['last-name']
            };
        }
    }
};
</script>
