/**
 * ConnectorMixin
 *
 * Add common states and data for Connector Box
 */

// Import external libraries
import { mapState } from 'vuex';

export default {
    data () {
        return {
            connector: null,
            loading:   false
        };
    },

    created () {
        this.connector = Object.values(this.connectors).find(connector => connector.attributes.name === this.connectorName);
    },

    computed: {
        /**
         * State of the auth user
         *
         */
        ...mapState('core/auth', [
            'user'
        ]),

        /**
         * State of the connectors
         *
         */
        ...mapState('core/connectors', [
            'connectors'
        ])
    }
};
