<template>
    <div class="reaction-wrapper d-flex reaction-bloc" v-if="!interaction.attributes.content">
        <div class="align-self-center" v-if="reaction.type === 'font'">
            <font-awesome-icon class="fb-reaction mr-2" :icon="reaction.font"/>
        </div>
        <div class="align-self-center" v-else>
            <img class="mr-2" :src='reaction.font' height="17" width="17">
        </div>
        <p class="reaction-text m-0 align-self-center">{{ $tc(reaction.message) }}</p>
    </div>
</template>
<script>
export default {
    props: ['interaction'],

    data () {
        return {
            reaction:  { font: ['fas', 'thumbs-up'], type: 'font', message: '' },
            reactions: {
                like:  { font: ['fas', 'thumbs-up'],         type: 'font' },
                love:  { font: ['fas', 'heart'],             type: 'font' },
                haha:  { font: ['fas', 'grin-beam'],         type: 'font' },
                wow:   { font: ['fas', 'grin-hearts'],       type: 'font' },
                sad:   { font: ['fas', 'sad-tear'],          type: 'font' },
                angry: { font: ['fas', 'angry'],             type: 'font' },
                care:  { font: '/images/solidaire_vert.png', type: 'svg'  },
            }
        };
    },

    mounted () {
        // If this interaction is a reaction get the font, the type and the message.
        if (this.interaction.attributes.category !== this.$config.interactions.COMMENT &&
            this.interaction.attributes.category !== this.$config.interactions.POST) {
            this.reaction.font    = this.reactions[this.interaction.attributes.category.toLowerCase()].font;
            this.reaction.type    = this.reactions[this.interaction.attributes.category.toLowerCase()].type;
            this.reaction.message = this.interaction.attributes.category.toLowerCase();
        }
    }
};
</script>
