<template>
    <div class="col-sm align-self-center sort-section d-flex w-100 p-0">
        <label class="filterby-label mr-2 mt-2 text-center text-primary" v-if="showLabel">{{ $t('filter-by') }}</label>
        <button
            class="dropdown-toggle text-left d-flex select form-control w-100"
            id="dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="isDisabled"
            :class="{'disabled': isDisabled}"
        >
            <div class="row">
                <div class="col-11 option">
                    <p class="select-template">{{ selected }}</p>
                </div>
                <div class="col-1 text-center">
                    <font-awesome-icon class="icon sort-down" :icon="['fa', 'sort-down']" />
                </div>
            </div>
        </button>
        <div class="dropdown-menu select-dropdown" aria-labelledby="dropdown" v-if="type === 'template'">
            <router-link
                v-for="option in options"
                :key="option.id"
                class="dropdown-item"
                @click.native="$emit('selectTemplate', option)"
                :to="{ name : '' }"
            >
                {{ $tc(option.name, 2) }}
            </router-link>
        </div>
        <div class="dropdown-menu select-dropdown" aria-labelledby="dropdown" v-if="type === 'options'">
            <router-link
                v-for="option in options"
                :key="option"
                class="dropdown-item"
                @click.native="$emit('selectOption', option)"
                :to="{ name : '' }"
            >
                {{ $tc(option, 2) }}
            </router-link>
        </div>
        <div class="dropdown-menu select-dropdown" aria-labelledby="dropdown" v-if="type === 'name'">
            <router-link
                v-for="option in options"
                :key="option.name"
                class="dropdown-item"
                @click.native="$emit('selectOption', option)"
                :to="{ name : '' }"
            >
                {{ $tc(option.name, 2) }}
            </router-link>
        </div>
        <div class="dropdown-menu select-dropdown" aria-labelledby="dropdown" v-if="type === 'language'">
            <router-link
                v-for="option in options"
                :key="option.name"
                class="dropdown-item"
                @click.native="$emit('selectLanguage', option)"
                :to="{ name : '' }"
            >
                {{ option.name }}
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        options: {
            type: [Array, Object]
        },
        selected:   String,
        showLabel:  Boolean,
        type:       String,
        isDisabled: {
            type:   Boolean,
            defaut: false
        }
    }
};
</script>
