<template>
    <div id="scheduled-messages">
        <div class="d-flex justify-content-end mb-4">
            <button
                class="btn btn-primary rounded-pill px-4 add-btn"
                data-target="#massive-send-sidebar"
                :disabled="!sms.isEstablished"
                @click="addMassMessage()">
                {{ $t('add-massive-send') }}
            </button>
        </div>
        <div class="loading-active-conversation-box" v-if="loading">
            <span class="spinner spinner-border text-primary"></span>
        </div>
        <div class="d-flex flex-column align-items-stretch" v-else>
            <table class="table table-borderless m-0 template-table">
                <thead class="table-header d-flex align-items-start">
                    <th scope="col" class="text-left text-dark-black name-content">{{ $t('sending-name') }}</th>
                    <th scope="col" class="text-left text-dark-black message-content mr-auto">Messages</th>
                    <th scope="col" class="text-left text-dark-black tags-content">Audience</th>
                    <th scope="col" class="text-left text-dark-black date-time-content">{{ $t('schedule-diffusion') }}</th>
                    <th scope="col" class="text-left text-dark-black edit-scheduled-message">&nbsp;</th>
                </thead>
                <tbody class="table-content mb-3">
                    <preloader-placeholder :type="'table'"></preloader-placeholder>
                    <tr v-for="message in massMessagesPlanned" :key="message.id"  :class="{'empty-scheduled-send':Object.keys(massMessagesPlanned).length === 0}">
                        <td scope="col" class="text-left font-color-content name-content text-ellipsis">
                            {{ message.attributes.name }}
                        </td>
                        <td scope="col" class="text-left font-color-content message-content text-ellipsis">
                            <span> {{ message.attributes.content }} </span>
                        </td>
                        <td scope="col" class="text-left font-color-content tags-content">
                            <div v-if="message.attributes.audiences === null || message.attributes.audiences.length === 0">
                                <span>{{ $t('all') }}</span>
                            </div>
                            <div v-else>
                                <div class="d-flex d-inline-flex flex-row p-0 mr-2 tags"
                                    v-for="tag in message.attributes.audiences" :key="tag.id">
                                    <p>{{ tag }}</p>
                                </div>
                            </div>
                        </td>
                        <td scope="col" class="text-left font-color-content date-time-content">
                            <div v-if="message.attributes.plannedDate || message.attributes.plannedTime">
                                <div><span>{{ message.attributes.plannedDate }}</span></div>
                                <div><span>{{ message.attributes.plannedTime }} <utc>UTC</utc></span></div>
                            </div>
                            <div v-else>
                                <p>---</p>
                            </div>
                        </td>
                        <td scope="col" class="edit-scheduled-message buttons-content ml-3">
                            <button class="border-0 bg-white mr-3 single-message-btns"
                                @click="sendMassMessage(message.id)">
                                <font-awesome-icon class="text-dark-pink" :icon="['fas', 'paper-plane']"/>
                            </button>
                            <button class="border-0 bg-white mr-3 single-message-btns"
                                @click="editMassMessage(message)">
                                <font-awesome-icon class="text-grey" :icon="['fas', 'pencil-alt']"/>
                            </button>
                            <button class="border-0 bg-white single-message-btns"
                                @click="deleteMassMessage(message.id)">
                                <font-awesome-icon class="text-grey" :icon="['fas', 'times']" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <b-pagination
                v-model="page.number"
                :total-rows="massScheduledMeta.page.total"
                :per-page="massScheduledMeta.page['per-page']"
                @input="loadMassMessages"
                align="center"
            ></b-pagination>
        </div>
        <send-modal
            :editMessage="editMessage"
            @massListUpdated="loadMassMessages()"
        >
        </send-modal>
    </div>
</template>

<script>

// Internal repository
import SendModal            from './SendModal.vue';
import Alert                from '@/helpers/Alert.js';
import PreloaderPlaceholder from '@/core/components/PreloaderPlaceholder.vue';
import Utc                  from '@/core/components/UTC.vue';

// Import external libraries
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        SendModal,
        PreloaderPlaceholder,
        Utc
    },
    data () {
        const MASS_CATEGORY  = 'mass';

        return {
            MASS_CATEGORY,
            loading:     false,
            editMessage: false,
            page:        {
                number: 1,
                size:   25
            },
        };
    },

    computed: {
        ...mapState('core/scheduled-messages', {
            'massMessagesPlanned': 'plannedScheduledMessages',
            'massScheduledMeta':   'plannedScheduledMeta'
        }),

        ...mapState('core/connectors', [
            'sms'
        ])
    },

    mounted () {
        this.loadMassMessages();
    },

    methods: {
        ...mapActions('core/scheduled-messages', {
            delete:                   'delete',
            indexPlanned:             'indexPlanned',
            indexSent:                'indexSent',
            selectMassMessage:        'select',
            send:                     'send',
            resetSelectedMassMessage: 'resetSelectedScheduledMessage'
        }),

        /**
         * Load planned and not_planned mass messages
         */
        loadMassMessages () {
            this.loading = true;

            // Get list of massive planned messages
            this.indexPlanned({
                'category': this.MASS_CATEGORY,
                page:       this.page
            })
                .then(() => {
                    // Finish loading
                    this.loading = false;
                });

            // Get list of massive sent messages
            this.indexSent({
                'category': this.MASS_CATEGORY,
                page:       this.page
            });
        },

        /**
         * Add massive message
         */
        addMassMessage () {
            this.resetSelectedMassMessage();
            this.editMessage = false;
            this.$bvModal.show('massive-send-sidebar');
        },

        /**
         * Edit a selected massive message
         */
        editMassMessage (massMessage) {
            this.editMessage = true;
            this.selectMassMessage({ id: massMessage.id, status: 'planned' })
                .then(() => {
                    this.$bvModal.show('massive-send-sidebar');
                });
        },

        /**
         * Send immediately a massive message
         */
        sendMassMessage (id) {
            Alert.scheduledMessageSendConfirmation('title-mass-message-send', 'confirm-scheduled-send')
                .then((response) => {
                    if (!response.isConfirmed) {
                        return;
                    }
                    this.send({ id })
                        .then(() => {
                            Alert.success(this.$i18n.t('scheduled-send-success'));
                        }).catch(() => {
                            Alert.fail(this.$i18n.t('default-error'));
                        });
                });
        },

        /**
         * Delete a selected massive message
         */
        deleteMassMessage (id) {
            Alert.scheduledMessageDeleteConfirmation('title-mass-message-delete', 'confirm-mass-message-delete')
                .then((response) => {
                    if (!response.isConfirmed) {
                        return;
                    }

                    // Delete mass message
                    this.delete({ id }).then(() => {
                        Alert.success(this.$i18n.t('scheduled-deleted-success'));
                    }).catch(() => {
                        Alert.fail(this.$i18n.t('default-error'));
                    });
                });
        }
    }
};
</script>
