<template>
    <div id="scheduled-messages-sent">
        <div class="loading-active-conversation-box" v-if="loading">
            <span class="spinner spinner-border text-primary"></span>
        </div>
        <div class="d-flex flex-column align-items-stretch" v-else>
            <table class="table table-borderless template-table">
                <thead class="table-header d-flex align-items-start">
                    <th scope="col" class="text-left text-dark-black date-time-content">
                        {{ $t('sent-date') }}
                    </th>
                    <th scope="col" class="text-left text-dark-black name-content">
                        {{ $t('sending-name') }}
                    </th>
                    <th scope="col" class="text-left text-dark-black message-content">
                        Message
                    </th>
                    <th scope="col" class="text-left text-dark-black tags-content">Audience</th>
                    <th scope="col" class="text-left text-dark-black edit-scheduled-message">&nbsp;</th>
                </thead>
                <tbody class="table-content" :class="{'empty-scheduled-send': Object.keys(massMessagesSent).length === 0}">
                    <preloader-placeholder :type="'table'"></preloader-placeholder>
                    <tr class="mb-3" v-for="message in massMessagesSent" :key="message.id">
                        <td scope="col" class="text-left font-color-content date-time-content">
                            <p>
                                <span>{{ toUtc(message.attributes.sentAt).date }}</span>
                                <span class="time"> {{ toUtc(message.attributes.sentAt).time }} <utc>UTC</utc></span>
                            </p>
                        </td>
                        <td scope="col" class="text-left font-color-content name-content text-ellipsis">
                            {{ message.attributes.name }}
                        </td>
                        <td scope="col" class="text-left font-color-content message-content text-ellipsis">
                            <span>{{ message.attributes.content }}</span>
                        </td>
                        <td scope="col" class="text-left font-color-content tags-content">
                            <div v-if="message.attributes.audiences == null">
                                <p>{{ $t('all') }}</p>
                                <div class="text-left contact-number">
                                    <span>({{ message.attributes.audienceCount }} contacts)</span>
                                </div>
                            </div>
                            <div v-else>
                                <div v-for="tag in message.attributes.audiences" :key="tag.id" class="d-flex d-inline-flex flex-row p-0 mr-2 tags">
                                    <p>{{ tag }}</p>
                                </div>
                                <div class="text-left contact-number">
                                    <span>({{ message.attributes.audienceCount }} contacts)</span>
                                </div>
                            </div>
                        </td>
                        <td scope="col" class="edit-scheduled-message buttons-content">
                            <button class="border-0 bg-white mr-3" data-target="#massive-send-sidebar"
                                @click="showMassMessageDetails(message.id)">
                                <font-awesome-icon class="text-grey" :icon="['fa', 'eye']" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <b-pagination
                v-model="page.number"
                :total-rows="massScheduledMeta.page.total"
                :per-page="massScheduledMeta.page['per-page']"
                @input="loadMassMessages"
                align="center"
            ></b-pagination>
        </div>
        <massive-send-modal :displayMessageDetail="displayMessageDetails"></massive-send-modal>
    </div>
</template>

<script>

import MassiveSendModal     from './SendModal.vue';
import PreloaderPlaceholder from '@/core/components/PreloaderPlaceholder.vue';
import Utc                  from '@/core/components/UTC.vue';
import Format               from '@/helpers/Format.js';

// Import external libraries
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        MassiveSendModal,
        PreloaderPlaceholder,
        Utc
    },
    data () {
        const MASS_CATEGORY = 'mass';

        return {
            MASS_CATEGORY,
            loading:               false,
            displayMessageDetails: false,
            page:                  {
                number: 1,
                size:   25
            },
        };
    },

    computed: {
        ...mapState('core/scheduled-messages', {
            'massMessagesSent':  'sentScheduledMessages',
            'massScheduledMeta': 'sentScheduledMeta'
        })
    },

    mounted () {
        this.loadMassMessages();
    },

    methods: {
        ...mapActions('core/scheduled-messages', {
            index:             'indexSent',
            selectMassMessage: 'select'
        }),

        /**
         * Load planned and not_planned mass messages
         */
        loadMassMessages () {
            this.loading = true;

            // Get list of massive all messages
            this.index({
                'category': this.MASS_CATEGORY,
                page:       this.page
            })
                .then(() => {
                    // Finish loading
                    this.loading = false;
                });
        },

        /**
         * Open the mass message selected detail modal
         */
        showMassMessageDetails (massMessageId) {
            this.selectMassMessage({ id: massMessageId, status: 'sent' })
                .then(() => {
                    this.displayMessageDetails = true;
                    this.$bvModal.show('massive-send-sidebar');
                });
        },

        /**
         * Helper method to format date to use in template
         */
        toUtc: Format.toUtc
    }
};
</script>
