// Repository factory
import FacebookAuthRepository      from './FacebookAuthRepository.js';
import FacebookPagesRepository     from './FacebookPagesRepository.js';
import FacebookUsersRepository     from './FacebookUsersRepository.js';
import FacebookContactsRepository  from './FacebookContactsRepository.js';
import FacebookCommentsRepository  from './FacebookCommentsRepository.js';
import FacebookReactionsRepository from './FacebookReactionsRepository.js';
import FacebookPostsRepository     from './FacebookPostsRepository.js';
import FacebookRemotePages         from './FacebookRemotePages.js';

const repositories = {
    FacebookAuth:        FacebookAuthRepository,
    FacebookPages:       FacebookPagesRepository,
    FacebookContacts:    FacebookContactsRepository,
    FacebookUsers:       FacebookUsersRepository,
    FacebookComments:    FacebookCommentsRepository,
    FacebookReactions:   FacebookReactionsRepository,
    FacebookPosts:       FacebookPostsRepository,
    FacebookRemotePages: FacebookRemotePages
};

export const RepositoryFactory = {
    get: name => repositories[name]
};
