<template>
    <div class="d-flex align-items-stretch">
        <badge></badge>
        <div id="main-menu">
            <nav class="menu-bord">
                <div>
                    <img class="img-fluid text-center p-3" src="../../../core/assets/images/logo.png" alt="">
                </div>
            </nav>
        </div>
        <topheader></topheader>
        <useredit></useredit>
        <div class="facebook-configuration container-fluid main-content">
            <div class="connect-text text-left">
                <h3>{{$t('config-FB-connector')}}</h3>
            </div>
             <div class="card-info text-left">
                <div class="row">
                    <div class="col-sm text-left">
                        <div class="">
                            <h4>{{$t('linked-user')}}</h4>
                            <p v-for="facebookUser in facebookUsers" :key="facebookUser.id">
                                {{ facebookUser.attributes.name }}
                            </p>
                        </div>
                    </div>
                    <div class="col-sm">
                        <continue-with-facebook class="mt-2"></continue-with-facebook>
                    </div>
                </div>
            </div>
            <remote-pages></remote-pages>
        </div>
    </div>
</template>
<script>

// Components
import RemotePages  from './RemotePages.vue';
import ContinueWithFacebook from './ContinueWithFacebook.vue';

// Internal services js
import { RepositoryFactory } from '../../../repositories/repositoryFactory.js';

const FacebookUsersRepository = RepositoryFactory.get('FacebookUsers');

// Import external libraries
import { mapState } from 'vuex';

export default {
    components: {
        RemotePages,
        ContinueWithFacebook
    },

    data () {
        return {
            facebookUsers: []
        };
    },

    computed: {
        ...mapState('core/auth', [
            'user'
        ]),

        ...mapState('core/session', [
            'sessionExists'
        ]),

        ...mapState('core/facebook', [
            'hasActivePage'
        ])
    },

    /**
     * Check function
     * Before creating this page, check if the user is not logged in
     */
    beforeCreate () {
        if (this.sessionExists === false) {
            this.$router.replace({
                name: 'login'
            });
            return;
        }

        // Check If this user is an admin
        if (!this.user.attributes['is-admin'] && !this.user.attributes['is-super-admin']) {
            this.$router.replace({
                name: `facebook-connector:${this.$i18n.locale}`
            });
            return;
        }

        // Check If we have an active page
        if (this.hasActivePage === true) {
            this.$router.replace({
                name: 'facebook-config'
            });
        }
    },

    mounted () {
        FacebookUsersRepository.index()
            .then((response) => {
                if (response.data.data.length !== 0) {
                    this.facebookUsers = response.data.data;
                } else if (this.$route.params.welcome !== true) {
                    // If no Facebook user are connected
                    this.$router.push({ name: `connectors-page:${this.$i18n.locale}`, params: { welcome: true } });
                }
            })
            .catch((errors) => {
                console.log(errors);
            });
    }
};
</script>
