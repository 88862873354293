import Api       from '@/helpers/Api.js';
import normalize from 'json-api-normalizer';
import qs        from 'qs';
import _         from 'underscore';
import Vue       from 'vue';

// Import mutations
import {
    SET_USERS,
    ADD_USER,
    REMOVE_USER,
    UPDATE_USER,
    UPDATE_USER_PICTURE,
    DELETE_USER_PICTURE,
    SET_EXPORT,
    SET_TEMPLATE
} from './mutation-types.js';

import { SET_AUTH_USER } from '../auth/mutation-types.js';

const resource = '/internal/v1/users';

// Initial state
const state = () => ({
    users:    [],
    selected: null,
    template: null,
});

// Getters
const getters = {
    /**
     * Get the users.
     *
     * @param      {Object}  state   The state
     *
     * @return     {Object}
     */
    users: (state) => {
        return state.users.users;
    },

    /**
     * Get the users included tenants.
     *
     * @param      {Object}  state   The state
     *
     * @return     {Object}
     */
    tenants: (state) => {
        return state.users.tenants;
    }
};

// Actions
const actions = {
    /**
     * Get a list of users.
     *
     * @param      {Object}            context          The context
     * @param      {string|undefined}  payload.sort     The sort
     * @param      {Object|undefined}  payload.filters  The filters
     *
     * @return     {Promise}
     */
    index: (context, ...{ sort, filters }) => {
        return new Promise((resolve, reject) => {
            Api.get(resource, {
                params: {
                    sort,
                    filter:  filters,
                    include: 'tenants'
                },
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_USERS, normalizedData);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Create a new user.
     *
     * @param      {Object}  context             The context
     * @param      {string}  payload.tenantId    The tenant identifier
     * @param      {Object}  payload.attributes  The attributes
     *
     * @return     {Promise}
     */
    create: (context, { tenantId, attributes }) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`, {
                'data': {
                    'type':          'users',
                    attributes,
                    'relationships': {
                        'tenants': {
                            'data': [
                                {
                                    'id':   tenantId,
                                    'type': 'tenants'
                                }
                            ]
                        }
                    }
                }
            })
                .then(response => {
                    context.commit(ADD_USER, response.data.data);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Send patch request to the api to edit user's informations.
     *
     * @param      {Object}  context             The context
     * @param      {string}  payload.id          The user identifier
     * @param      {Object}  payload.attributes  The attributes
     *
     * @return     {Promise}
     */
    patch: (context, { id, attributes }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/${id}`, {
                'data': {
                    'type': 'users',
                    id,
                    attributes
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });

                    // Update the user in the list of users
                    if (context.state.users.length !== 0) {
                        context.commit(UPDATE_USER, { id, ...normalizedData.users[id] });
                    }

                    // Update the authenticated user
                    if (id === context.rootState.core.auth.user.id) {
                        context.commit(`core/auth/${SET_AUTH_USER}`, response.data.data, { root: true });
                    }

                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Upload image for the user.
     *
     * @param      {Object}  context        The context
     * @param      {string}  payload.id     The user identifier
     * @param      {string}  payload.image  The image
     *
     * @return     {Promise}
     */
    updloadProfilePicture: (context, { id, image }) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('profile-picture', image);
            Api.post(`${resource}/${id}/profile-picture`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
                .then(response => {
                    // Update the user in the list of users
                    if (context.state.users.length !== 0) {
                        context.commit(UPDATE_USER_PICTURE, { id, ...response.data.data });
                    }

                    // Update the authenticated user
                    if (id === context.rootState.core.auth.user.id) {
                        context.commit(`core/auth/${UPDATE_USER_PICTURE}`, response.data.data, { root: true });
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Delete image for the user.
     *
     * @param      {Object}  context     The context
     * @param      {string}  payload.id  The user identifier
     *
     * @return     {Promise}
     */
    deleteProfilePicture: (context, { id }) => {
        return Api.delete(`${resource}/${id}/profile-picture`)
            .then(() => {
                // Update the user in the list of users
                if (context.state.users.length !== 0) {
                    context.commit(DELETE_USER_PICTURE, { id });
                }

                // Update the authenticated user
                if (id === context.rootState.core.auth.user.id) {
                    context.commit(`core/auth/${DELETE_USER_PICTURE}`, null, { root: true });
                }
            });
    },

    /**
     * Get the template file.
     *
     * @param      {Object}   context  The context
     *
     * @return     {Promise}
     */
    template: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}/template`, { responseType: 'blob' })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    context.commit(SET_TEMPLATE, url);
                    resolve(url);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Import user from a file.
     *
     * @param      {Object}  context       The context
     * @param      {Object}  payload.file  The file
     *
     * @return     {Promise}
     */
    import: (context, { file }) => {
        const formData = new FormData();
        formData.append('file', file);
        return Api.post(`${resource}/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    /**
     * Export users to a file.
     *
     * @param      {Object}   context           The context
     * @param      {Object}   payload.filters   The filters
     *
     * @return     {Promise}
     */
    export: (context, ...{ filters }) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}/export`, {
                responseType: 'blob',
                params:       {
                    filter: filters
                },
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    context.commit(SET_EXPORT, url);
                    resolve(url);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {
    [SET_USERS]: (state, payload) => {
        state.users = payload;
    },
    [ADD_USER]: (state, payload) => {
        Vue.set(state.users.users, payload.id, payload);
    },
    [REMOVE_USER]: (state, payload) => {
        state.users.users = _.reject(state.users.users, (user) => {
            return user.id === payload.id;
        });
    },
    [UPDATE_USER]: (state, payload) => {
        state.users.users[payload.id] = { ...state.users.users[payload.id], ...payload };
    },
    [UPDATE_USER_PICTURE]: (state, payload) => {
        state.users.users[payload.id].attributes['profile-picture'] = payload.links['profile-picture'];
    },
    [DELETE_USER_PICTURE]: (state, payload) => {
        state.users.users[payload.id].attributes['profile-picture'] = null;
    },
    [SET_EXPORT]: (state, payload) => {
        state.export = payload;
    },
    [SET_TEMPLATE]: (state, payload) => {
        state.template = payload;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
