<template>
    <div class="align-items-stretch">
        <badge></badge>
        <div id="user-login" class="login-interface">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="login-box p-5">
                        <div class="row">
                            <div class="col-sm mb-4">
                                <img class="img-logo text-center" src="@/core/assets/images/logo.png" alt="">
                            </div>
                        </div>
                        <form class="text-left">
                            <div class="mb-4">
                                {{ $t('enter-code') }}
                            </div>
                            <div class="form-group">
                                <label for="code">
                                    {{ $t('verification-code') }}
                                    <strong class="obligatory">*</strong>
                                </label>
                                <input
                                    type="text"
                                    name="code"
                                    id="code"
                                    :data-vv-as="$t('verification-code')"
                                    v-model="code"
                                    class="form-control"
                                    v-validate="'required'"
                                    :class="{ 'is-invalid': submitted && errors.has('code') }"
                                >
                                <div class="invalid-feedback" v-if="submitted">
                                    {{ errors.first('code') }}
                                </div>
                            </div>
                            <router-link :to="{ name: '' }" class="button-link">
                                <button type="submit" class="btn btn-primary rounded-pill" @click="login()" :disabled="loading">
                                    {{ $t('continue') }}
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                                </button>
                            </router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal services js
import LoginMixin   from '@/core/mixins/LoginMixin';
import Alert        from '@/helpers/Alert.js';

// External libraries
import { mapActions } from 'vuex';
import HttpStatus     from 'http-status-codes';
import moment         from 'moment';
import { mapState }   from 'vuex';

export default {
    mixins: [LoginMixin],
    data () {
        return {
            code:      '',
            submitted: false,
            loading:   false
        };
    },

    beforeCreate () {
        // If locale is set in the local storage, use it
        this.$i18n.locale = localStorage.getItem('locale')
            ?? process.env.VUE_APP_I18N_DEFAULT_LOCALE;

        moment.locale(localStorage.getItem('locale') ?? process.env.VUE_APP_I18N_DEFAULT_LOCALE);

        // Check if user already logged in and has a tenant selected
        if (this.sessionExists === true) {
            if (this.sessionUser !== null && this.sessionSelectedTenant !== null) {
                this.tenantsTemp = this.sessionSelectedTenant;
                this.$router.replace({ name: `connectors-page:${this.$i18n.locale}` });
            }
        }
    },

    created () {
        // Check if user has tenants assigned to the user and get that tenants information
        this.tenantsTemp = this.sessionTenants;
    },

    computed: {
        ...mapState('core/session', [
            'sessionExists',
            'sessionSelectedTenant',
            'sessionTenants',
            'sessionUser'
        ])
    },

    methods: {
        ...mapActions('core/auth', [
            'challengeTwoFactorAuthentication'
        ]),

        /**
         * Login user with 2fa code
         *
         * @return void
         */
        login () {
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.loading = true;

                    this.challengeTwoFactorAuthentication({
                        code: this.code
                    })
                        .then(() => {
                            this.loading = false;
                            this.setSessionExists({ sessionExists: true });
                            this.userTenants();
                        })
                        .catch((error) => {
                            if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                                Alert.fail(this.$i18n.t(error.response.data.errors[0].detail));
                                this.loading = false;
                            }
                        });
                }
            });
        }
    }
};
</script>
