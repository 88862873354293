<template>
    <div id="tenants" class="d-flex align-items-stretch">
        <navigation></navigation>
        <tenantslist
            v-on:hideSidebar=" collapseSidebar = false"
            v-on:showSidebar="collapseSidebar = true"
            v-bind:collapseSidebar="collapseSidebar"
        >
        </tenantslist>
    </div>
</template>
<script>

// Components
import TenantsList from './TenantsList.vue';

export default {
    components: {
        'tenantslist': TenantsList
    },
    data () {
        return {
            collapseSidebar: true
        };
    }
};
</script>
