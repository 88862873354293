<template>
    <div class="sms-page configure-phone-number card-info">
        <div class="text-left">
            <h5>{{$t('search-sms-number')}}</h5>
            <p>{{$t('search-sms-description')}}</p>
        </div>
        <div class="px-2">
            <div class="row mb-3">
                <div class="col">
                    <select-dropdown
                        :options="countries"
                        :selected="selectedCountry"
                        v-on:selectOption="selectedCountryValue = $event.value"
                        :showLabel="false"
                        :type="'name'">
                    </select-dropdown>
                    <p class="text-primary text-left text-small">{{ $t('country') }}</p>
                </div>
                <div class="col">
                    <select-dropdown
                        :options="positions"
                        :selected="selectedPosition"
                        v-on:selectOption="selectedPositionValue = $event.value"
                        :showLabel="false"
                        :type="'name'">
                    </select-dropdown>
                </div>
                <div class="col">
                     <input
                        id="phone-number"
                        name="phone-number"
                        type="text"
                        class="form-control"
                        v-model="phoneNumber"
                        v-validate="'required|numeric'"
                        :disabled="loading"
                        :class="{ 'is-invalid': submitted && errors.has('phone-number'), 'is-valid': submitted && !errors.has('phone-number') }"
                        :data-vv-as="$t('phone-number')"
                        :placeholder="$t('phone-number')"
                    >
                    <div class="invalid-feedback text-left" v-if="submitted && errors.has('phone-number')">
                        {{ errors.first('phone-number') }}
                    </div>
                    <p class="text-primary text-left text-small" v-else>{{ $t('phone-number') }}</p>
                </div>
                <div class="mt-2">
                    <font-awesome-icon class="info-tooltip text-grey" :icon="['fas', 'info-circle']"/>
                </div>
                <div class="col text-left pl-5">
                    <button type="button" class="btn btn-primary rounded-pill invite-btn"
                        :disabled="loading"
                        @click="searchNumber()">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            v-if="loading"></span>
                        {{ $t('search') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="spinner-border" role="status" v-if="loading === true">
            <span class="sr-only">{{ $t('Loading...') }}</span>
        </div>
        <div class="remote-numbers-table" v-if="remoteNumber">
            <div class="text-left">
                <h5>{{$t('results')}}</h5>
            </div>
            <div class="number-table" v-if="loading === false">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                        <th scope="col" class="text-left w-25">{{$t('phone-number')}}</th>
                        <th scope="col" class="text-left w-50">{{$t('description')}}</th>
                        <th scope="col" class="w-25"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="number in remoteNumbers" :key="number.id">
                            <td class="text-left">
                                <p class="text-table">{{number.attributes.friendlyName}}</p>
                            </td>
                            <td class="text-left">
                                <p class="text-table">{{number.attributes.phoneNumber}}</p>
                            </td>
                            <td>
                                <button class="btn btn-primary btn-active" @click="chooseNumber(number.attributes.phoneNumber)">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                                    {{$t('activate')}}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p class="m-0 text-center w-100" v-if="remoteNumbers.length === 0"> {{ $t('no-remote-numbers-found') }} </p>
            </div>
       </div>
   </div>
</template>
<script>

// Internal components
import SelectDropdown from '../../../core/components/SelectDropdown.vue';

// Import external libraries
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    components: {
        SelectDropdown,
    },

    data () {
        const CANADA_CODE = 'CA';
        const USA_CODE    = 'US';
        const BEGIN       = 'begin';
        const ANYWHERE    = 'anywhere';
        const END         = 'end';

        return {
            // Constants
            CANADA_CODE,
            USA_CODE,
            BEGIN,
            ANYWHERE,
            END,

            loading:               false,
            submitted:             false,
            remoteNumber:          false,
            phoneNumber:           '',
            numbersTable:          [],
            selectedCountryValue:  CANADA_CODE,
            selectedPositionValue: BEGIN,
        };
    },

    computed: {
        // State with list of remote numbers
        ...mapState('core/remote-phone-number', [
            'remoteNumbers'
        ]),

        // Getter for the selected provider
        ...mapState('core/providers', [
            'selectedProvider'
        ]),

        // Getter for the selected number
        ...mapGetters('core/number', {
            numberGetter: 'smsPhoneNumber'
        }),

        /**
         * Get the Countries for the search
         */
        countries () {
            return [
                {
                    value: this.CANADA_CODE,
                    name:  `${this.$i18n.t('canada')} (+1)`
                },
                {
                    value: this.USA_CODE,
                    name:  `${this.$i18n.t('usa')} (+1)`
                }
            ];
        },

        /**
         * Get the selected Country name
         */
        selectedCountry () {
            return this.countries.find(country => country.value === this.selectedCountryValue).name;
        },

        /**
         * Get the Positions of the search
         */
        positions () {
            return [
                {
                    value: this.BEGIN,
                    name:  this.$i18n.t('start-with')
                },
                {
                    value: this.ANYWHERE,
                    name:  this.$i18n.t('contains')
                },
                {
                    value: this.END,
                    name:  this.$i18n.t('to-end-by')
                }
            ];
        },

        /**
         * Get the position Country name
         */
        selectedPosition () {
            return this.positions.find(position => position.value === this.selectedPositionValue).name;
        }
    },

    methods: {

        // Method get list of remote numbers
        ...mapActions('core/remote-phone-number', {
            remoteNumbersList: 'index'
        }),

        // Method activate number
        ...mapActions('core/number', {
            activateNumber: 'create'
        }),

        /**
         * Methods to get connectors linked to the account
         *
         * getConnectors get connectors linked to account
         */
        ...mapActions('core/connectors', {
            getConnectors: 'index'
        }),

        /**
         * Get remote number parameter from
         *
         * @retun   {object}    The filters parameter
         */
        getFiltersParameter () {
            return {
                'country-code': this.selectedCountryValue,
                'position':     this.selectedPositionValue,
                'search':       this.phoneNumber
            };
        },

        /**
         * Search for remote phone number
         *
         * @param   {string}   position    The position of template in contact tags table.
         */
        searchNumber () {
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (!valid) {
                    this.loading = false;
                    return;
                }

                // We're done with validation, we can set submitted to false and loading to true
                this.submitted = false;
                this.loading = true;

                // Search phone number
                let provider = this.selectedProvider.attributes.name.toLowerCase();
                this.remoteNumbersList({
                    provider: provider,
                    filters:  this.getFiltersParameter()
                })
                    .then(() => {
                        this.loading = false;
                        this.remoteNumber = true;
                    });
            });
        },

        /**
         * Choose Remote number as active number
         *
         * @param   {string}   number    The remote number selected.
         */
        chooseNumber (number) {
            this.loading = true;
            this.activateNumber({
                phoneNumber:  number,
                providerId:   this.selectedProvider.id,
                providerType: this.selectedProvider.type
            })
                .then(() => {
                    this.numberGetter;
                    this.getConnectors();
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
};
</script>
