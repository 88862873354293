<template>
    <div class="messages-reports col-12 align-self-center mt-5">
        <p class="text-left fs-12"> {{ $t('number-sms-messages') }} </p>
        <div class="d-flex justify-content-between">
            <div class="d-flex">
                <p class="fs-10 d-flex" :class="{ 'mr-4': ((index + 1) < messageLabels.length) }" v-for="(label, index) in messageLabels" :key="label.key">
                    <span class="fs-14 mr-2 tagged" :style="{'background-color': label.background , color: label.color}"> {{ formatTotals(label.total) }} </span>
                    <span class="align-self-center"> {{ label.name }} </span>
                </p>
            </div>
            <div>
                <p class="fs-10 d-flex">
                    <span class="fs-14 mr-2 tagged" :style="{'background-color': '#333333', 'color': '#ffff'}"> {{ formatTotals(reportData.attributes.total) }} </span>
                    <span class="align-self-center"> {{ $t('total-messages') }} </span>
                </p>
            </div>
        </div>
        <div class="h-100">
            <Bar
                :chart-options="chartOptions"
                :chart-data="chartData"
                class="h-100"
            />
        </div>
    </div>
</template>
<script>

import moment  from 'moment';
import { Bar } from 'vue-chartjs/legacy';

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js';

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale);

export default {
    components: {
        Bar
    },

    props: ['reportData'],

    data () {
        const DAILY_REPORT   = 'daily';
        const WEEKLY_REPORT  = 'weekly';
        const MONTHLY_REPORT = 'monthly';

        return {
            DAILY_REPORT,
            WEEKLY_REPORT,
            MONTHLY_REPORT,
            chartOptions: {
                responsive:          true,
                maintainAspectRatio: false,
                scales:              {
                    x: {
                        ticks: {
                            color: '#462f25',
                        }
                    },
                    y: {
                        suggestedMax: 5,
                        ticks:        {
                            stepSize: 1,
                            color:    '#462f25',
                        }
                    }
                }
            },
            messageLabels: [
                {
                    key:        'message_received',
                    name:       this.$i18n.t('messages-received'),
                    background: '#feaab0',
                    color:      '#333333',
                    totalKey:   'total_received',
                    total:      0
                },
                {
                    key:        'message_sent',
                    name:       this.$i18n.t('messages-sent'),
                    background: '#149178',
                    color:      '#ffffff',
                    totalKey:   'total_sent',
                    total:      0
                }
            ]
        };
    },

    computed: {
        chartData () {
            let labels   = [];
            let datasets = [];

            // Init datasets
            this.messageLabels.forEach(element => {
                element.total = this.reportData.attributes[element.totalKey];
                datasets.push({
                    label:           element.name,
                    backgroundColor: element.background,
                    data:            []
                });
            });

            let dates  = Object.keys(this.reportData.attributes.messages);
            let data   = Object.values(this.reportData.attributes.messages);

            data.forEach((element, key) => {
                // Get the formatted dates
                let formattedDate = '';
                switch (this.reportData.attributes['report_type']) {
                    case this.DAILY_REPORT:
                        formattedDate = moment(dates[key]).format('ddd M/D');
                        break;
                    case this.WEEKLY_REPORT:
                        formattedDate = `${moment(dates[key]).format('M/D')} - ${moment(moment(dates[key]).clone().endOf('week')).format('M/D')}`;
                        break;

                    default:
                        // Monthly report
                        formattedDate = moment(dates[key]).format('MMM Y');
                        break;
                }

                labels.push(formattedDate);
                this.messageLabels.forEach((label, index) => {
                    datasets[index].data.push(element[label.key]);
                });
            });

            return {
                labels,
                datasets
            };
        }
    },

    methods: {
        /**
         * Format the totals numbers with spaces
         */
        formatTotals (total) {
            return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    }
};
</script>
