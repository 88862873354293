import Api from '@/helpers/Api.js';

// Import mutations
import {
    SET_PORTAL_SESSION,
    REMOVE_PORTAL_SESSION
} from './mutation-types.js';

const resource = '/internal/v1/chargebee/portal-sessions';

// Initial state
const state = () => ({
    portalSession: null
});

// Getters
const getters = {
};

// Actions
const actions = {
    /**
     * Creates a Chargebee Portal Session.
     *
     * @param      {Object}   context  The context
     *
     * @return     {Promise}
     */
    createPortalSession: (context) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`)
                .then((response) => {
                    context.commit(SET_PORTAL_SESSION, response.data.data.attributes);
                    resolve(response.data.data.attributes);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Deletes a Chargebee Portal Session.
     *
     * @param      {Object}   context  The context
     *
     * @return     {Promise}
     */
    deletePortalSession: (context) => {
        return new Promise((resolve, reject) => {
            Api.delete(`${resource}/${context.state.portalSession.id}`)
                .then(() => {
                    context.commit(REMOVE_PORTAL_SESSION);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {
    [SET_PORTAL_SESSION]: (state, payload) => {
        state.portalSession = payload;
    },
    [REMOVE_PORTAL_SESSION]: (state) => {
        state.portalSession = null;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
