<template>
    <div :style="wrapperStyle">
        <vue-content-loading v-if="loading === true && type === 'box'" :width="450" :height="100" :primary="'#FFF0AF'" :secondary="'#FFFAE2'">
            <circle cx="30" cy="25" r="13" />
            <rect x="13" y="20" rx="4" ry="4" width="10" height="10" />
            <rect x="60" y="13" rx="4" ry="4" width="50" height="10" />
            <rect x="60" y="30" rx="4" ry="4" width="70" height="8" />
            <rect x="60" y="43" rx="4" ry="4" width="250" height="12" />
            <rect x="60" y="60" rx="4" ry="4" width="40" height="9" />
            <rect x="400" y="13" rx="4" ry="4" width="10" height="10" />
        </vue-content-loading>
        <vue-content-loading v-if="loading === true && type === 'table'" :width="450" :height="100" :primary="'#FFF0AF'" :secondary="'#FFFAE2'">
            <rect x="30" y="13" rx="4" ry="4" width="100" height="10" />
            <rect x="250" y="13" rx="4" ry="4" width="100" height="10" />
            <rect x="380" y="13" rx="4" ry="4" width="45" height="10" />
            <rect x="30" y="28" rx="4" ry="4" width="70" height="5" />
            <rect x="30" y="50" rx="4" ry="4" width="300" height="40" />
        </vue-content-loading>
        <vue-content-loading v-if="loading === true && type === 'user'" :width="450" :height="100" :primary="'#FFF0AF'" :secondary="'#FFFAE2'">
            <circle cx="40" cy="20" r="8" />
            <rect x="85" y="14" rx="4" ry="4" width="60" height="5" />
            <rect x="85" y="23" rx="4" ry="4" width="40" height="4" />
        </vue-content-loading>
        <vue-content-loading v-if="loading === true && type === 'contact'" :width="450" :height="100" :primary="'#FFF0AF'" :secondary="'#FFFAE2'">
            <circle cx="40" cy="25" r="8" />
            <rect x="85" y="18" rx="4" ry="4" width="60" height="5" />
            <rect x="85" y="27" rx="4" ry="4" width="40" height="4" />
        </vue-content-loading>
        <vue-content-loading v-if="loading === true && type === 'tenant'" :width="450" :height="30" :primary="'#FFF0AF'" :secondary="'#FFFAE2'">
            <circle cx="20" cy="15" r="8" />
            <rect x="44"  y="13" rx="2" ry="4" width="70" height="5" />
            <rect x="158" y="13" rx="2" ry="4" width="50" height="5" />
            <rect x="246" y="13" rx="2" ry="4" width="30" height="5" />
            <rect x="351" y="13" rx="2" ry="4" width="15" height="5" />
        </vue-content-loading>
        <vue-content-loading v-if="loading === true && type === 'configuration'" :width="450" :height="300" :primary="'#FFF0AF'" :secondary="'#FFFAE2'">
            <rect x="0" y="0"   rx="5"  ry="5"  width="450" height="15" />
            <rect x="0" y="25"  rx="10" ry="10" width="450" height="50" />
            <rect x="0" y="90"  rx="10" ry="10" width="450" height="100" />
            <rect x="0" y="200" rx="10" ry="10" width="450" height="100" />
        </vue-content-loading>
        <vue-content-loading v-if="loading === true && type === 'connector'" :width="450" :height="175" :primary="'#FFF0AF'" :secondary="'#FFFAE2'">
            <rect x="0"   y="0"   rx="5"  ry="5"  width="450" height="20" />
            <rect x="100" y="30"  rx="5"  ry="5"  width="300" height="15" />
            <rect x="0"   y="75"  rx="10" ry="10" width="450" height="100" />
        </vue-content-loading>
        <vue-content-loading v-if="loading === true && type === 'post'" :width="450" :height="175" :primary="'#FFFFFF'" :secondary="'#f1f1f1'">
            <circle cx="30" cy="20" r="19" />
            <rect x="55"   y="8"   rx="5"  ry="5"  width="80" height="10" />
            <rect x="55"   y="23"  rx="5"  ry="5"  width="40" height="7" />
            <rect x="10"   y="50"  rx="10" ry="10" width="430" height="100" />
        </vue-content-loading>
        <empty-message-box v-if="resultlength === 0 && loading === false"></empty-message-box>
    </div>
</template>
<script>
import { VueContentLoading } from 'vue-content-loading';
import EmptyMessageBox       from '../../connectors/facebook/components/EmptyMessageBox.vue';

export default {
    components: {
        VueContentLoading,
        EmptyMessageBox
    },

    props: ['loading', 'resultlength', 'type'],

    computed: {
        wrapperStyle () {
            return this.loading ? `height: ${this.height}px;` : '';
        }
    },

    data () {
        return {
            height:  100,
            padding: 53
        };
    },

    mounted () {
        switch (this.type) {
            case 'box':
            case 'table':
            case 'user':
            case 'contact':
                // eslint-disable-next-line no-magic-numbers
                this.height = 100 + this.padding;
                break;

            case 'tenant':
                // eslint-disable-next-line no-magic-numbers
                this.height = 30 + this.padding;
                break;

            case 'configuration':
                // eslint-disable-next-line no-magic-numbers
                this.height = 300 + this.padding;
                break;

            case 'connector':
            case 'post':
                // eslint-disable-next-line no-magic-numbers
                this.height = 175 + this.padding;
                break;
        }
    }
};
</script>
