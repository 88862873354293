import Api       from '@/helpers/Api.js';
import normalize from 'json-api-normalizer';

// Import mutations
import {
    ADD_SINGLE_CONTACT_NEW_NOTE
} from '../contacts/mutation-types.js';

const resource = '/internal/v1/notes';

// Initial state
const state = () => ({});

// Getters
const getters = {
};

// Actions
const actions = {
    /**
     * Create note for a contact.
     *
     * @param      {Object}   context            The context
     * @param      {Object}   payload.note       The note
     * @param      {string}   payload.contactId  The contact identifier
     *
     * @return     {Promise}
     */
    create: (context, { note, contactId }) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`, {
                'data': {
                    'type':       'notes',
                    'attributes': {
                        'content': note
                    },
                    'relationships': {
                        'contact': {
                            'data': {
                                'id':   contactId,
                                'type': 'contacts'
                            }
                        }
                    }
                }
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(`core/contacts/${ADD_SINGLE_CONTACT_NEW_NOTE}`, normalizedData.notes, { root: true });
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
