<template>
    <div class="menu reponsiveMenu">
        <nav>
            <div @click="SET_MENU_STATE(false)">
                <a href="#" id="unroll-menu">
                    <font-awesome-icon class="text-btn" :icon="['fas', 'angle-double-right']" />
                </a>
            </div>
            <div class="tenant-menu dropdown mb-5">
                <div v-if="user && (user.attributes['is-admin'] || user.attributes['is-super-admin'])">
                    <a class="d-flex menu-tenant-name justify-content-center align-items-baseline" href="#" role="button" id="tenantDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <tenants-avatar :tenant="selectedTenant"></tenants-avatar>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="tenantDropdown" v-if="user && (user.attributes['is-admin'] || user.attributes['is-super-admin'])">
                        <router-link class="dropdown-item" :to="{ name: `workspace-settings:${$i18n.locale}` }">{{ $t('workspace-settings') }}</router-link>
                        <router-link class="dropdown-item" :to="{ name : `user:${$i18n.locale}` }">{{ $t('user-managment') }}</router-link>
                        <router-link class="dropdown-item" :to="{ name: '' }" data-toggle="modal" data-target="#invite-user">{{ $t('invite-user') }}</router-link>
                        <router-link class="dropdown-item" :to="{ name: `connectors-page:${$i18n.locale}` }">{{ $t('connectors') }}</router-link>
                    </div>
                    <invite-user></invite-user>
                </div>
                <tenants-avatar :tenant="selectedTenant" v-else></tenants-avatar>
            </div>
            <div class="messages-menu list">
                <h6 class="menu-title">
                    <font-awesome-icon class="exclamation-triangle" :icon="['fas', 'exclamation-triangle']"
                        v-if="showExclamationTriangle"/>
                </h6>
                <p class="m-0 text-center" v-if="facebook.isConnected">
                    <router-link :to="{ name: `facebook-connector:${$i18n.locale}` }" class="menu-link">
                        <font-awesome-icon :icon="['fab', 'facebook-square']" v-b-tooltip.hover.bottomleft="$t('facebook')" variant="primary"/>
                    </router-link>
                </p>
                <p class="m-0 text-center" v-if="sms.isConnected">
                    <router-link :to="{ name: `sms-connector:${$i18n.locale}` }" class=" menu-link">
                        <font-awesome-icon :icon="['fas', 'mobile-alt']" v-b-tooltip.hover.bottomleft="$t('sms')" variant="primary"/>
                    </router-link>
                </p>
                <p class="m-0 text-center" v-if="sms.isConnected">
                    <router-link :to="{ name: `flash-send:${$i18n.locale}` }" class=" menu-link">
                        <font-awesome-icon :icon="['fas', 'bolt-lightning']" v-b-tooltip.hover.bottomleft="$t('flash-message-tooltip')" variant="primary"/>
                    </router-link>
                </p>
                <p class="m-0 text-center" v-if="sms.isConnected">
                    <router-link :to="{ name: `massive-send:${$i18n.locale}` }" class=" menu-link">
                        <font-awesome-icon :icon="['fas', 'paper-plane']" v-b-tooltip.hover.bottomleft="$t('massive-send')" variant="primary"/>
                    </router-link>
                </p>
                <p class="m-0 text-center">
                    <router-link :to="{ name: `template:${$i18n.locale}` }" class=" menu-link">
                        <img src="/images/template-icon.png" alt="" v-b-tooltip.hover.bottomleft="$t('message-templates')" variant="primary">
                    </router-link>
                </p>
                <p class="m-0 text-center">
                    <router-link :to="{ name: `contact-list:${$i18n.locale}` }" class=" menu-link">
                        <font-awesome-icon :icon="['fas', 'users']" v-b-tooltip.hover.bottomleft="$t('all-contacts')" variant="primary"/>
                    </router-link>
                </p>
                <p class="m-0 text-center" v-if="sms.isConnected">
                    <router-link :to="{ name: '' }" class=" menu-link" data-toggle="modal" data-target="#form-add-contact">
                        <font-awesome-icon :icon="['fas', 'user-plus']" v-b-tooltip.hover.bottomleft="$t('add-contacts')" variant="primary"/>
                    </router-link>
                </p>
                <p class="m-0 text-center">
                    <router-link :to="{ name: `tags:${$i18n.locale}` }" class=" menu-link">
                        <font-awesome-icon :icon="['fas', 'tags']" v-b-tooltip.hover.bottomleft="$t('tags')" variant="primary"/>
                    </router-link>
                </p>
                <p class="m-0 text-center" @click="$emit('hidemenu')">
                    <router-link :to="{ name: '' }" class=" menu-link" data-toggle="modal" data-target="#import-contact">
                        <font-awesome-icon :icon="['fas', 'download']" v-b-tooltip.hover.bottomleft="$t('import')" variant="primary"/>
                    </router-link>
                </p>
                <p class="m-0 text-center" @click="$emit('hidemenu')">
                    <router-link :to="{ name: `reports:${$i18n.locale}` }" class="menu-link">
                        <font-awesome-icon :icon="['fas', 'chart-pie']" v-b-tooltip.hover.bottomleft="$t('reports')" variant="primary"/>
                    </router-link>
                </p>
                <p class="m-0 text-center" @click="$emit('hidemenu')">
                    <router-link :to="{ name: `autoResponse:${$i18n.locale}` }" class="menu-link">
                        <font-awesome-icon :icon="['fas', 'repeat']" v-b-tooltip.hover.bottomleft="$t('auto-response')" variant="primary" />
                    </router-link>
                </p>
            </div>
        </nav>
        <div class="sidebar-footer">
            <img class="img-fluid text-center p-1" src="/images/logoCercle.png" alt="">
        </div>
    </div>
</template>
<script>

// Internal components
import InviteUser    from './security/user/InviteUser.vue';
import TenantsAvatar from './TenantsAvatar.vue';

// Internal services js
import MenuMixin from '@/core/mixins/MenuMixin';

export default {
    mixins: [MenuMixin],

    components: {
        InviteUser,
        TenantsAvatar
    }
};
</script>
