<template>
    <div id="all-contact" class="d-flex align-items-stretch">
        <navigation></navigation>
        <contactlist
            v-on:hideSidebar=" collapseSidebar = false"
            v-on:showSidebar="collapseSidebar = true"
            v-bind:collapseSidebar="collapseSidebar"
        ></contactlist>
        <contactlistsidebar
            v-bind:collapseSidebar="collapseSidebar"
        ></contactlistsidebar>
    </div>
</template>
<script>

// Components
import ContactlistSidebar  from './ContactlistSidebar.vue';
import ContactList         from './AllContactList.vue';

export default {
    components: {
        'contactlistsidebar': ContactlistSidebar,
        'contactlist':        ContactList
    },
    data () {
        return {
            collapseSidebar: true
        };
    }
};
</script>
