<template>
    <div id="profile" class="d-flex align-items-stretch">
        <navigation></navigation>
        <user-list></user-list>
    </div>
</template>
<script>

// Components
import UserList from './security/user/UserList.vue';

export default {
    components: {
        UserList
    }
};
</script>
