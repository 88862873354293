<template>
    <div class="modal" id="activate-security">
        <div class="modal-dialog modal-lg modal-border-raduis">
            <div class="modal-header justify-content-center border-0 pb-0 pt-5 text-black">
                <h3 v-if="securityStep === 1" class="modal-title">{{ $t('security-qr') }}</h3>
                <h3 v-else-if="securityStep === 2" class="modal-title">{{ $t('security-enter-code') }}</h3>
                <h3 v-else-if="securityStep === 3" class="modal-title">{{ $t('save-your-recovery-codes') }}</h3>
            </div>
            <div class="modal-content px-4 pb-4" >
                <div class="modal-body">
                    <div class="row justify-content-center" v-if="securityStep === 1">
                        <div class="col-10">
                            <p class="text-center text-black fs-14">{{ $t('security-description') }}</p>
                            <div class="row justify-content-center text-center qr-section">
                                <div class="align-self-center spinner-border" role="status" v-if="loadingQrCode">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="d-flex justify-content-center text-center col-12 py-4" v-else>
                                    <qr-code :text="qrCode"></qr-code>
                                </div>
                            </div>
                            <div class="row text-left">
                                <div class="col">
                                    <p class="text-black fs-14">{{ $t('security-code') }}</p>
                                    <div class="copy-code-bloc">
                                        <input
                                            type="text"
                                            name="security-code"
                                            :disabled="true"
                                            class="form-control"
                                            v-model="secret"
                                            ref="secret"
                                        >
                                        <button type="button" class="btn text-btn" :disabled="loadingQrCode" @click="copyText('secret', 'security-copied-code')">
                                            <img class="mr-2" src="/images/copy.svg">{{ $tc('security-copy-code', 1) }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container" v-else-if="securityStep === 2">
                        <div class="row">
                            <div class="col-6 mt-5 text-left">
                                <p class="parag-security text-black">{{ $t('security-enter-code-note') }}</p>
                                <input
                                    type="text"
                                    id="activation-code"
                                    v-validate="'required'"
                                    :class="{ 'is-invalid': errors.has('activation-code') }"
                                    name="activation-code"
                                    v-model="activationCode"
                                    class="form-control mb-3"
                                    placeholder="e.g., 123 456"
                                >
                                <p class="parag-security text-black" v-html="$t('security-missing-code')"></p>
                            </div>
                            <div class="col-6">
                                <img src="../../../assets/images/code-security.png">
                            </div>
                        </div>
                    </div>
                    <div class="container text-left" v-else-if="securityStep === 3">
                        <div class="d-flex flex-column">
                            <div class="details-section">
                                <p class="fs-14 text-black">
                                    <span> {{ $t('save-your-recovery-codes') }} </span>
                                    <br>
                                    <span>
                                        {{ $t('use-recovery-to-authenticate') }}
                                    </span>
                                </p>
                                <p class="fs-14 text-black">
                                    {{ $t('save-recovery-in-safe-place') }}
                                </p>
                            </div>
                            <div class="px-5 d-flex justify-content-center">
                                <div class="col-10">
                                    <div class="recovery-codes p-4">
                                        <textarea
                                            class="p-0 text-center fs-13"
                                            v-model="adjustedRecoveryCodes"
                                            :rows="recoveryCodes.length"
                                            ref="recoveryCodes"
                                            v-if="recoveryCodes.length > 0"
                                            :disabled="true"
                                        >
                                        </textarea>
                                    </div>
                                    <button type="button" class="btn text-btn" :disabled="loadingQrCode" @click="copyText('recoveryCodes', 'recovery-codes-copied')">
                                        <img class="mr-2" src="/images/copy.svg">{{ $tc('security-copy-code', 2) }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 justify-content-between">
                    <div class="w-100 text-right" v-if="securityStep === 1">
                        <button type="button" class="btn text-btn px-5" @click="changeSecurityStep(1)" data-dismiss="modal" id="close" ref="closeButton">
                            {{ $t('cancel') }}
                        </button>
                        <button type="button" class="btn btn-primary rounded-pill px-5" :disabled="loadingQrCode" @click="changeSecurityStep(2)">
                            {{ $t('next') }}
                        </button>
                    </div>
                    <div class="row w-100" v-if="securityStep === 2">
                        <div class="col-4 text-left">
                            <button type="button" class="btn text-btn" @click="changeSecurityStep(1)">
                                <img src="../../../assets/images/arrow-green.svg"> {{ $t('security-rescan') }}
                            </button>
                        </div>
                        <div class="col-8 text-right">
                            <button
                                type="button"
                                id="close"
                                class="btn text-btn px-5"
                                ref="closeButton"
                                @click="changeSecurityStep(1, true)"
                                data-dismiss="modal"
                            >
                                {{ $t('cancel') }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary rounded-pill px-5"
                                @click="activateSecurity()"
                            >
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="activation"></span>
                                {{ $t('next') }}
                            </button>
                        </div>
                    </div>
                    <div class="w-100 text-right" v-if="securityStep === 3">
                        <button type="button" class="btn btn-primary rounded-pill px-5" data-dismiss="modal" @click="changeSecurityStep(1)">
                            {{ $t('finish') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// External libraries
import { mapState, mapActions } from 'vuex';
import HttpStatus               from 'http-status-codes';
import QrCode                   from 'vue-qrcode-component';

import Alert from '@/helpers/Alert.js';

export default {
    props: ['loadingQrCode'],

    computed: {
        ...mapState('core/auth', [
            'qrCode',
            'secret',
            'recoveryCodes'
        ]),

        adjustedRecoveryCodes () {
            let codes = '';
            this.recoveryCodes.forEach((code, index) => {
                codes = `${codes}${index > 0 ? '\r\n' : ''}${code}`;
            });

            return codes;
        }
    },

    components: {
        QrCode
    },

    data () {
        return {
            securityStep:   1,
            activation:     false,
            activationCode: null
        };
    },

    methods: {
        ...mapActions('core/auth', [
            'confirmTwoFactorAuthentication',
            'getAuthenticatedUser'
        ]),

        /**
         * Activate the security
         */
        activateSecurity () {
            this.$validator.validate().then(valid => {
                if (!valid) {
                    return;
                }

                this.activation = true;
                this.confirmTwoFactorAuthentication({
                    code: this.activationCode.replace(' ', '')
                })
                    .then(() => {
                        this.getAuthenticatedUser()
                            .then(() => {
                                this.securityStep = 3;
                                this.activation   = false;
                            });
                    })
                    .catch((error) => {
                        if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                            Alert.fail(this.$i18n.t(error.response.data.errors[0].detail));
                            this.activation = false;
                        }
                    });
            });
        },

        /**
         * Copy a text from an input
         *
         * @param   {string}   ref              the input ref
         * @param   {string}   successMessage   the key to show the success message
         */
        copyText (ref, successMessage) {
            const element = this.$refs[ref];
            element.disabled = false;
            const selectionrange = 200;
            element.select();
            element.setSelectionRange(0, selectionrange);
            document.execCommand('copy');
            element.disabled = true;
            Alert.success(this.$t(successMessage));
        },

        /**
         * Change security step
         */
        changeSecurityStep (step, close = false) {
            if (close) {
                this.$refs.closeButton.click();
            }
            this.securityStep = step;
        }
    }

};
</script>
