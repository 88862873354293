import Swal from 'sweetalert2/dist/sweetalert2.js';
import i18n from '../libraries/I18n.js';

import 'sweetalert2/src/sweetalert2.scss';

export default {
    /**
     * Show fail alert
     *
     * @param    {string}   message   fail message
     *
     */
    fail (message) {
        Swal.fire({
            position:           'top-end',
            icon:               'error',
            title:              i18n.t('error'),
            text:               message,
            showCloseButton:    true,
            allowEscapeKey:     false,
            showConfirmButton:  false,
            confirmButtonColor: '#0c5748',
            toast:              true,
            timer:              6000,
            timerProgressBar:   true
        });
    },

    /**
     * Show alert when the session expired
     *
     * @param    {string}   message    message
     *
     */
    sessionExpired (message) {
        if (Swal.isVisible() && Swal.getTitle()?.innerHTML.includes(i18n.t('session-expired'))) {
            return;
        }

        Swal.fire({
            icon:               'warning',
            title:              i18n.t('session-expired'),
            html:               `<p class="text-black font-nunito fs-18">${message}</p>`,
            showCloseButton:    true,
            allowEscapeKey:     false,
            showConfirmButton:  true,
            confirmButtonColor: '#0c5748',
            confirmButtonText:  'OK'
        });
    },

    /**
     * Show success alert
     *
     * @param    {string}   message   success message
     *
     */
    success (message) {
        Swal.fire({
            position:           'top-end',
            icon:               'success',
            title:              i18n.t('success'),
            html:               message,
            showCloseButton:    true,
            allowEscapeKey:     false,
            confirmButtonColor: '#0c5748',
            showConfirmButton:  false,
            toast:              true,
            timer:              6000,
            timerProgressBar:   true
        });
    },

    /**
     * Show info alert
     *
     * @param    {string}   message   info message
     * @param    {string}   title     info title
     *
     */
    info (title, message) {
        Swal.fire({
            icon:               'info',
            title:              title,
            html:               `<p class="text-black font-nunito fs-18">${message}</p>`,
            showCloseButton:    true,
            allowEscapeKey:     false,
            showConfirmButton:  true,
            confirmButtonColor: '#0c5748',
            confirmButtonText:  'OK'
        });
    },

    /**
     * Show email sent in success alert
     *
     * @param    {string}   message   success message
     *
     */
    emailSent (message) {
        Swal.fire({
            icon:              'success',
            title:             message,
            showConfirmButton: false,
            allowOutsideClick: false
        });
    },

    /**
     * Show invalid token alert
     *
     * @param    {string}    refreshTokenMessage     the alert message.
     * @param    {string}    contactAdminMessage     the alert message.
     * @param    {string}    connectionLink          the connection link.
     *
     * @return    void
     */
    invalidTokenAlert (refreshTokenMessage, contactAdminMessage, connectionLink) {
        Swal.fire({
            position:          'top',
            width:             '100%',
            title:             `<div class="row invalid-token-alert"><div class="col-1 pl-0 text-right top-exclamation-alert"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="exclamation-triangle svg-inline--fa fa-exclamation-triangle fa-w-18"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" class=""></path></svg></div><div class="col-7 pl-0 text-left">${refreshTokenMessage} ${contactAdminMessage}</div><div class="col-2 p-0 text-right">${connectionLink}</div><div class="col-2 p-0 text-center"><a href="/help">En savoir plus</a></div></div>`,
            showCloseButton:   true,
            showConfirmButton: false,
            allowEscapeKey:    false,
            toast:             true,
            showClass:         { popup: 'animate__animated animate__fadeInDown' },
            hideClass:         { popup: 'animate__animated animate__fadeOutUp' },
            customClass:       {
                container: 'ivalid-token-container-class',
                popup:     'ivalid-token-popup-class'
            }
        });
    },

    /**
     * Show global error alert
     *
     * @param    {string}   message   success message
     *
     */
    globalError (message) {
        Swal.fire({
            position:            'bottom-start',
            icon:                'error',
            title:               i18n.t('error'),
            text:                message,
            confirmButtonText:   i18n.t('refresh-page'),
            showCloseButton:     true,
            allowOutsideClick:   false,
            allowEscapeKey:      false,
            confirmButtonColor:  '#0c5748',
            showLoaderOnConfirm: true,
            footer:              `<a href='/'>${i18n.t('what-going-on')}</a>`
        });
    },

    /**
     * Show a confirm dialog alert
     *
     * @param    {string}   message   confirm dialog
     *
     */
    confirmation (message, confirmationButton, title = i18n.t('confirmation-required'), confirmButtonColor = '#FF6666') {
        return Swal.fire({
            title:             `<p class="font-nunito">${title}</p>`,
            html:              `<p class="text-black font-nunito fs-14">${message}</p>`,
            showCancelButton:  true,
            reverseButtons:    true,
            confirmButtonColor,
            cancelButtonColor: '#FFFFFF',
            confirmButtonText: `<p class="font-nunito">${confirmationButton}</p>`,
            cancelButtonText:  `<p class="green-text font-nunito">${i18n.t('cancel')}</p>`
        });
    },

    /**
     * Show a delete confirm alert
     *
     * @param    {string}   message   confirm dialog
     *
     */
    deleteConfirmation (message) {
        return Swal.fire({
            html:               `<p class="text-black font-nunito fs-14">${message}</p>`,
            showCancelButton:   true,
            confirmButtonColor: '#d33',
            cancelButtonColor:  '#ffffff',
            confirmButtonText:  `<p class="font-nunito">${i18n.t('delete')}<p>`,
            cancelButtonText:   `<p class="green-text font-nunito">${i18n.t('cancel')}</p>`,
            reverseButtons:     true
        });
    },

    /**
     * Show a send confirm alert for scheduled message
     *
     * @param    {string}   message   confirm dialog
     * @param    {string}   email     User email
     *
     */
    scheduledMessageSendConfirmation (title, message) {
        return Swal.fire({
            showCancelButton:   true,
            html:               `<img src="/images/confirm-send-scheduled-message.png" width="120" class="img-fluid"/><p class="title">${i18n.t(title)}</p><p>${i18n.t(message)}</p>`,
            confirmButtonColor: '#d33',
            cancelButtonColor:  '#ffffff',
            confirmButtonText:  i18n.t('send-scheduled-message'),
            cancelButtonText:   `<p class="green-text ">${i18n.t('cancel')}</p>`,
            reverseButtons:     true,
            customClass:        'swal-confirm-wide'
        });
    },

    /**
     * Show a delete confirm alert for scheduled message
     *
     * @param    {string}   message   confirm dialog
     * @param    {string}   email     User email
     *
     */
    scheduledMessageDeleteConfirmation (title, message) {
        return Swal.fire({
            title:              `<p class="green-text font-nunito">${i18n.t(title)}</p>`,
            showCancelButton:   true,
            html:               `<p class="text-black font-nunito fs-14">${i18n.t(message)}</p>`,
            confirmButtonColor: '#ff6666',
            cancelButtonColor:  '#ffffff',
            confirmButtonText:  `<p class="text-white font-nunito">${i18n.t('delete')}</p>`,
            cancelButtonText:   `<p class="green-text font-nunito">${i18n.t('cancel')}</p>`,
            reverseButtons:     true
        });
    },

    /**
     * Show a delete confirm alert for user
     *
     * @param    {string}   message   confirm dialog
     * @param    {string}   email     User email
     *
     */
    userDeleteConfirmation (message, email) {
        return Swal.fire({
            showCancelButton:   true,
            html:               `<p class="text-black fs-14 font-nunito">${i18n.t(message)}</p><div class="m-4"><p class="text-black fs-14 font-nunito">${i18n.t('user')}: <span class="text-primary fs-14">${email}</span></p></div>`,
            confirmButtonColor: '#d33',
            cancelButtonColor:  '#ffffff',
            confirmButtonText:  `<p class="font-nunito">${i18n.t('delete')}</p>`,
            cancelButtonText:   `<p class="green-text font-nunito">${i18n.t('cancel')}</p>`,
            reverseButtons:     true
        });
    },

    /**
     * Show a UTC modal information
     */
    showUtcModal () {
        return Swal.fire({
            showCancelButton:   false,
            html:               `<p class="text-left text-13 font-nunito">${i18n.t('utc-modal')}</p>`,
            confirmButtonColor: '#14866d',
            confirmButtonText:  i18n.t('close'),
            customClass:        'swal-utc'
        });
    },

    /**
     * Show a delete confirm alert for tag
     *
     * @param    {string}   title   title of the alert
     * @param    {string}   message message of the alert
     * @param    {string}   tag     name of the tag
     *
     */
    tagDeleteConfirmation (title, message, tag) {
        return Swal.fire({
            title:              `<p class="green-text font-nunito">${i18n.t(title)}</p>`,
            text:               `<p class="text-black font-nunito fs-14">${i18n.t(message)}</p>`,
            showCancelButton:   true,
            html:               `<div class="tags m-4 d-flex justify-content-center"><p class="edit-menu pb-2 px-4">${tag}</p></div><p class="text-black font-nunito fs-14">${i18n.t(message)}</p>`,
            confirmButtonColor: '#FF6666',
            cancelButtonColor:  '#ffffff',
            confirmButtonText:  `<p class="font-nunito">${i18n.t('delete')}</p>`,
            cancelButtonText:   `<p class="green-text font-nunito">${i18n.t('cancel')}</p>`,
            reverseButtons:     true
        });
    },

    /**
     * Show a delete confirm alert for tag
     *
     * @param    {string}   title   title of the alert
     * @param    {string}   message message of the alert
     * @param    {string}   tag     name of the tag
     *
     */
    templateDeleteConfirmation (title, message, template) {
        return Swal.fire({
            title:              `<p class="green-text font-nunito">${i18n.t(title)}</p>`,
            text:               i18n.t(message),
            showCancelButton:   true,
            html:               `<p class="edit-menu pb-2 px-4 font-nunito">${template}</p><p class="texr-black font-nunito fs-14">${i18n.t(message)}</p>`,
            confirmButtonColor: '#FF6666',
            cancelButtonColor:  '#ffffff',
            confirmButtonText:  `<p class="font-nunito">${i18n.t('delete')}</p>`,
            cancelButtonText:   `<p class="green-text font-nunito">${i18n.t('cancel')}</p>`,
            reverseButtons:     true
        });
    },

    /**
     * Show a confirmation modal with an input text
     *
     * @param  {string}  title    title of the alert
     * @param  {string}  message  message of the alert
     *
     */
    terminateConvoConfirmation (title, message) {
        return Swal.fire({
            title,
            input:               'text',
            inputAttributes:     { autocapitalize: 'off' },
            html:                `<label for="my-input">${message}</label>`,
            showCancelButton:    true,
            confirmButtonColor:  '#FF6666',
            cancelButtonColor:   '#FFFFFF',
            cancelButtonText:    `<p class="green-text ">${i18n.t('cancel')}</p>`,
            confirmButtonText:   i18n.t('finish'),
            showLoaderOnConfirm: true,
            reverseButtons:      true,
            preConfirm:          (convoTheme) => convoTheme
        });
    }
};
