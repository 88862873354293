import Api from '@/helpers/Api.js';

const resource = '/connectors/facebook/v1/auth';

export default {
    post (accessToken) {
        return Api.post(`${resource}`, {
            'data': {
                'attributes': {
                    'access_token': accessToken
                }
            }
        });
    }
};
