// Import mutations
import {
    SET_ACTIVE_PAGE,
    SET_HAS_ACTIVE_PAGE,
    SET_IS_VALID_ADMIN_TOKEN,
    SET_CONNECT
} from './mutation-types.js';

// Initial state
const state = () => ({
    facebookActivePage: null,
    hasActivePage:      false,
    isValidAdminToken:  false,
    connect:            false
});

// Getters
const getters = {};

// Actions
const actions = {
    /**
     * Set active Facebook page
     */
    setActivePage (context,  { activePage }) {
        context.commit(SET_ACTIVE_PAGE, activePage);
    },

    /**
     * Set hasActive Facebook page boolean
     */
    setHasActivePage (context, { hasActivePage }) {
        context.commit(SET_HAS_ACTIVE_PAGE, hasActivePage);
    },

    /**
     * Set validAdminToken for Facebook page
     */
    setIsValidAdminToken (context, { isValid }) {
        context.commit(SET_IS_VALID_ADMIN_TOKEN, isValid);
    },

    /**
     * Set connect for Facebook
     */
    setFacebookConnect (context, { connect }) {
        context.commit(SET_CONNECT, connect);
    }
};

// Mutations
const mutations = {
    [SET_ACTIVE_PAGE]: (state, activePage) => {
        state.facebookActivePage = activePage;
    },
    [SET_HAS_ACTIVE_PAGE]: (state, hasActivePage) => {
        state.hasActivePage = hasActivePage;
    },
    [SET_IS_VALID_ADMIN_TOKEN]: (state, isValid) => {
        state.isValidAdminToken = isValid;
    },
    [SET_CONNECT]: (state, connect) => {
        state.connect = connect;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
