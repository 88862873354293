import Api       from '@/helpers/Api.js';
import normalize from 'json-api-normalizer';
import qs        from 'qs';

// Import mutations
import {
    SET_REMOTE_NUMBERS,
} from './mutation-types.js';

const resource  = '/connectors/sms/v1';

// Initial state
const state = () => ({
    remoteNumbers: []
});

// Getters
const getters = {};

// Actions
const actions = {
    /**
     * Get a list of remote Numbers.
     *
     * @param   {Object}  context  The context
     *
     * @return  {Promise}
     */
    index: (context, { provider, filters }) => {
        // Init the state
        context.commit(SET_REMOTE_NUMBERS, []);

        return new Promise((resolve, reject) => {
            Api.get(`${resource}/${provider}/remote-phone-numbers`, {
                params:           { filter: filters },
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKey: false });

                    // Always check if results are not empty, then call mutations.
                    if (normalizedData.remotePhoneNumbers) {
                        context.commit(SET_REMOTE_NUMBERS, Object.values(normalizedData.remotePhoneNumbers));
                    }

                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {
    [SET_REMOTE_NUMBERS]: (state, payload) => {
        state.remoteNumbers = payload;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
