<template>
    <div id="auto-response" class="container-fluid main-content">
        <div class="row pr-3 pl-2 mb-4">
            <div class="col-sm text-left">
                <h3 class="section-title ">{{ $t('auto-response') }}</h3>
            </div>
        </div>
        <popover name="create-auto-response" class="auto-response" event="hover" :width="335" id="create-auto-response">
            <p class="mb-0 fs-14 text-left p-2">
                {{ $t('cocoapp-auto-response') }}
            </p>
        </popover>
        <popover name="auto-response-keywords" class="auto-response" event="hover" :width="335" id="auto-response-keywords">
            <p class="mb-0 fs-14 text-left p-2" v-html="$t('auto-response-keywords')"></p>
        </popover>
        <div class="white-box m-2 p-4">
            <div class="d-flex justify-content-between px-3 py-1">
                <div class="col-6">
                    <input
                        v-if="existAutoResponses"
                        type="text"
                        class="form-control search-contact text-small py-1"
                        v-bind:placeholder="$t('search-in-title-and-keywords')"
                        v-model.trim="search"
                        @keyup="getAutoResponses()">
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <button class="btn btn-primary rounded-pill d-flex px-5 py-2" data-target="#auto-response-sidebar" @click="createAutoResponse()">
                        {{ $t('add-auto-response') }}
                    </button>
                </div>
            </div>
            <div class="loading-active-conversation-box mt-4" v-if="firstLoading">
                <span class="spinner spinner-border text-primary"></span>
            </div>
            <table class="table table-borderless tag-table autoResponse-table" v-if="!firstLoading && existAutoResponses">
                <thead class="seperator thead-border">
                    <tr class="fs-14">
                        <th class="text-left">{{$t('auto-response-title')}}</th>
                        <th class="text-left">{{$t('keywords')}}</th>
                        <th class="text-left">{{$t('autoresponse-sent')}}</th>
                        <th class="text-left">&nbsp;</th>
                    </tr>
                </thead>
                <div class="loading-active-conversation-box mt-4" v-if="loading">
                    <span class="spinner spinner-border text-primary"></span>
                </div>
                <tbody v-else class="tag-table-body">
                    <tr class="fs-14" v-for="(autoResponse, i) in autoResponses" v-bind:key="autoResponse.id">
                        <td class="align-middle text-left text-dark-brown align-items-center">
                            {{ autoResponse.attributes.name }}
                        </td>
                        <td class="align-middle autoResponse-keyword text-left">
                            <div class="form-group m-0 d-flex flex-wrap" v-if="autoResponse.attributes.keywords.length > 0">
                                <p v-for="keyword in autoResponse.attributes.keywords" :key="keyword">
                                    {{ keyword }}
                                </p>
                            </div>
                            <div v-else>
                                <span class="m-0 fs-12 text-dark-brown font-nunito-bold">
                                    {{ $t('without-keyword') }}
                                </span>
                            </div>
                        </td>
                        <td class="align-middle">
                            <p class="m-0 text-left text-ellipsis">
                                {{ autoResponse.attributes.content }}
                            </p>
                        </td>
                        <td class="align-middle">
                            <div class="edit-section">
                                <div class="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        :checked="autoResponse.attributes.isEnabled"
                                        @click="enabledOrDisabled(i)"
                                        :id="`customSwitch-${i}`"
                                    >
                                    <label class="custom-control-label" :for="`customSwitch-${i}`"></label>
                                </div>
                                <button type="button" class="close" aria-label="Close" @click="editAutoResponse(autoResponse.id, autoResponse.attributes.name)">
                                    <font-awesome-icon class="text-muted text-small edit-btn" :icon="['fas', 'pencil-alt']"  />
                                </button>
                                <button type="button" class="close" aria-label="Close" @click="removeAutoResponse(autoResponse.id, autoResponse.attributes.name)">
                                    <font-awesome-icon class="text-muted text-small edit-btn" :icon="['fas', 'times']" />
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <preloader-placeholder :loading="loading" :resultlength="autoResponses.length" :type="'table'" v-if="!loading && !firstLoading"></preloader-placeholder>
            </table>
            <div v-if="!firstLoading && !existAutoResponses" class="info pt-5 text-left">
                <p class="mt-5">
                    {{ $t('autoresponse-description') }}
                </p>
                <p class="mt-2">
                    {{ $t('received-sms-keyword') }}
                </p>
                <img class="w-100 text-center p-3" src="@/core/assets/images/auto-response.png" alt="">
            </div>
        </div>
        <auto-response-modal
            class="auto-response-sidebar"
            :editMode="editMode"
        >
        </auto-response-modal>
    </div>
</template>
<script>

// Internal repository
import Alert from '../../helpers/Alert.js';

// Components
import AutoResponseModal    from '@/core/components/AutoResponseModal.vue';
import PreloaderPlaceholder from '@/core/components/PreloaderPlaceholder.vue';

// Import external libraries
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        AutoResponseModal,
        PreloaderPlaceholder
    },

    data () {
        return {
            editMode:           false,
            search:             '',
            disabled:           true,
            loading:            false,
            firstLoading:       false,
            existAutoResponses: false
        };
    },

    created () {
        this.firstLoading = true;
        this.index()
            .then(() => {
                this.firstLoading = false;
            });
    },

    watch: {
        autoResponses () {
            this.existAutoResponses = this.autoResponses.length !== 0;
        }
    },

    computed: {
        ...mapState('core/autoResponses', {
            autoResponses: 'autoResponses'
        }),
    },

    methods: {
        ...mapActions('core/autoResponses', ['index', 'update', 'delete', 'selectAutoResponse']),

        /**
         * Show modal to create new auto-response
         */
        createAutoResponse () {
            this.editMode = false;
            this.$bvModal.show('auto-response-sidebar');
        },

        /**
         * Load the auto responses
         */
        getAutoResponses () {
            this.loading = true;
            var filters  = null;

            if (this.search !== '') {
                filters = {
                    'search': this.search
                };
            }

            const searchDelay = 1000;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.index({
                    filters
                })
                    .then(() => {
                        this.loading      = false;
                        this.firstLoading = false;
                    });
            }, searchDelay);
        },

        /**
         * Enable or disable an auto response
         *
         * @param {integer}  index  the auto response index
         * @return void
         */
        enabledOrDisabled (index) {
            this.update({
                id:         this.autoResponses[index].id,
                attributes: {
                    'is-enabled': !this.autoResponses[index].attributes.isEnabled
                },
            })
                .catch((errors) => {
                    console.log(errors);
                });
        },

        /**
         * Detele auto response
         *
         * @param   {integer}   id          The id of the auto response.
         * @param   {string}    name        The name of the auto response.
         *
         * @return  void
         */
        removeAutoResponse (id, name) {
            Alert.deleteConfirmation(this.$i18n.t('confirm-auto-response-delete', {
                name: name
            }))
                .then((response) => {
                    if (!response.isConfirmed) {
                        return;
                    }

                    this.delete({
                        id: id
                    }).then(() => {
                        Alert.success(this.$i18n.t('auto-response-deleted-success'));
                    }).catch(() => {
                        Alert.fail(this.$i18n.t('default-error'));
                        this.getAutoResponses();
                    });
                });
        },

        /**
         * Update auto response
         *
         * @param {integer} id The id of the auto response.
         *
         * @return void
         */
        editAutoResponse (id) {
            this.editMode = true;
            this.selectAutoResponse({ id })
                .then(() => {
                    this.$bvModal.show('auto-response-sidebar');
                });
        }
    }
};
</script>
