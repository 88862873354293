<template>
    <div class="import-card">
        <div class="modal" id="import-contact" data-backdrop="static">
            <div
                class="modal-dialog"
                :class="{'modal-lg': results.total === null || results.fail > 0, 'modal-dialog-centered': results.total !== null && results.fail === 0}"
            >
                <div class="modal-content" v-if="results.total === null">
                    <div class="modal-header justify-content-center border-0">
                        <h3 class="modal-title text-primary">{{ $t('import-contact') }}</h3>
                    </div>
                    <div class="modal-body">
                        <form class="user-information">
                            <div class="container">
                                <div class="sample-excel mb-3">
                                    <a class="text-left" href="#" @click="downloadFile()">
                                        <font-awesome-icon  class="fb-reaction mr-2" :icon="['far', 'file-excel']" />{{ $t('use-format-sample-excel') }}
                                    </a>
                                </div>
                                <div class="form-group import-file pt-2 mb-1">
                                    <label for="contacts-file" class="import-label">
                                        <font-awesome-icon  class="fb-reaction mr-2" :icon="['fas', 'download']" />
                                        {{ $t('select-your-list') }}
                                    </label><br>
                                    <div class="custom-file d-flex justify-content-center">
                                        <div class="d-flex align-self-center">
                                            <label for="contacts-file" class="m-0 align-self-center pointer fs-16 text-grey-9"> {{ fileChosen }} </label>
                                            <font-awesome-icon class="text-danger align-self-center pointer ml-3" :icon="['fas', 'xmark']" @click="removeFile()" v-if="fileExists"/>
                                        </div>
                                        <input
                                            type="file"
                                            id="contacts-file"
                                            ref="file"
                                            accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            :data-choose="$t('choose-file')"
                                            v-on:change="onChangeFileUpload()"
                                            hidden
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer border-0 px-0 justify-content-center">
                        <button type="button" class="btn text-btn" data-dismiss="modal" id="close" ref="closeButton">{{ $t('cancel') }}</button>
                        <button type="button" class="btn btn-primary rounded-pill" @click="uploadFile()" :disabled="!fileExists">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                            {{ $t('import') }}
                        </button>
                    </div>
                </div>

                <!-- Errors Modal -->
                <div class="modal-content" v-if="results.fail > 0">
                    <div class="modal-header justify-content-center border-0">
                        <div class="d-flex flex-column text-center">
                            <h3 class="modal-title mb-3">{{ $t('import-complete') }}</h3>
                            <p class="modal-title fs-18 text-primary font-nunito-SemiBold"> {{ results.success }} {{ $tc('contact-imported', results.success) }} </p>
                            <p class="modal-title fs-18 text-danger font-nunito-SemiBold"> {{ results.fail }} {{ $tc('contact-not-imported', results.fail) }} </p>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="errors-section">
                            <p class="text-left">
                                {{ $t('importation-details') }}
                            </p>
                            <div class="errors-input px-1 py-2">
                                <textarea
                                    class="p-0 w-100 text-left"
                                    v-model="importationErrors"
                                    :rows="10"
                                    ref="recoveryCodes"
                                    :disabled="true"
                                >
                                </textarea>
                            </div>
                            <div class="d-flex justify-content-start">
                                <button type="button" class="btn text-btn" @click="copyText('recoveryCodes', 'errors-list-copied')">
                                    <img class="mr-2" src="/images/copy.svg">{{ $t('copy') }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-0 px-0 justify-content-center">
                        <button type="button" class="btn text-btn" data-dismiss="modal" @click="close()">{{ $t('close') }}</button>
                    </div>
                </div>

                <!-- Success Modal -->
                <div class="modal-content" v-else-if="results.fail === 0">
                    <div class="modal-header justify-content-center py-0 border-0">
                        <div class="success-section">
                            <img src="/images/team_success.png" alt="">
                            <div class="pt-4">
                                <h4 class="m-0 text-primary">
                                    {{ $t('list-imported') }}
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body p-0"></div>
                    <div class="modal-footer border-0 px-0 justify-content-center">
                        <button type="button" class="btn text-btn" data-dismiss="modal" @click="close()">{{ $t('close') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal services js
import { EventBus } from '@/helpers/EventBus';
import Alert from '@/helpers/Alert.js';
import ImportMixin from '@/core/mixins/ImportMixin';

// External libraries
import { mapActions } from 'vuex';

export default {
    mixins: [ImportMixin],
    data () {
        return {
            loading:    false,
            fileChosen: this.$t('no-file-chosen'),
            fileExists: false,

            // Importation results
            results: {
                'total':   null,
                'success': null,
                'fail':    null,
                'errors':  []
            }
        };
    },

    computed: {
        /**
         * All importation errors
         */
        importationErrors () {
            let errors = '';

            this.results.errors.forEach((line, index) => {
                errors = `${errors}${index > 0 ? '\r\n' : ''}  ${this.$t('line')} ${line.line} "${line.name}"`;
                line.errors.forEach(error => {
                    errors = `${errors}\r\n\t•  ${error}`;
                });
                errors = `${errors}${(index + 1) !== this.results.errors.length ? '\r\n' : ''}`;
            });
            return errors;
        }
    },

    methods: {
        ...mapActions('core/contacts', [
            'template',
            'import'
        ]),

        /**
         * Upload the File.
         */
        uploadFile () {
            if (!this.$refs.file.files[0]) {
                return;
            }

            // Start loading
            this.loading = true;

            // Upload the file selected by user.
            this.import({
                file: this.$refs.file.files[0]
            })
                .then((response) => {
                    this.results = response.data.data;
                    this.removeFile();
                })
                .catch(() => {
                    Alert.fail(this.$i18n.t('contacts-import-error'));
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Copy a text from an input
         *
         * @param   {string}   ref              the input ref
         * @param   {string}   successMessage   the key to show the success message
         */
        copyText (ref, successMessage) {
            const element = this.$refs[ref];
            element.disabled = false;
            const selectionrange = element.value.length;
            element.select();
            element.setSelectionRange(0, selectionrange);
            document.execCommand('copy');
            element.disabled = true;
            Alert.success(this.$t(successMessage));
        },

        /**
         * Close the modal
         */
        close () {
            this.results = {
                'total':   null,
                'success': null,
                'fail':    null,
                'errors':  []
            };
            EventBus.$emit('contact-imported');
        }
    }
};
</script>
