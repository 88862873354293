<template>
    <div>
        <h6 class="sibebar-title text-left text-black">{{ $t('notes') }}</h6>
        <div class="text-left">
            <textarea
                name="note"
                class="form-control note-box w-100 mb-2"
                v-model="newNote"
                v-validate="'required'"
            ></textarea>
        </div>
        <div class="note-add text-right mb-3">
            <button type="submit" class="btn btn-primary rounded-pill add-note-btn" @click="addNote()" :disabled="loading === true || newNote === ''">
                {{ $t('add-note') }}
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading === true"></span>
            </button>
        </div>
        <div class="note-history">
            <div class="single-note mb-3" v-for="note in contactNotes" :key="note.id">
                <p class="note-date text-left mb-0 text-grey-9 fs-10">
                    {{ moment(note.attributes['created-at']).format('YYYY-MM-DD H:mm:ss') }}
                </p>
                <p class="note mb-1 text-left mb-3 text-black">{{ note.attributes.content }}</p>
            </div>
        </div>
    </div>
</template>
<script>

// External libraries
import moment                   from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
    data () {
        return {
            loading: false,
            newNote: '',
        };
    },

    computed: {
        ...mapState('core/contacts', {
            contact:      'single',
            contactNotes: 'singleNotes'
        }),
    },

    mounted () {
        this.getSingleNotes({
            id: this.contact.id
        });
    },

    watch: {
        contact () {
            this.getSingleNotes({
                id: this.contact.id
            });
        }
    },

    methods: {
        ...mapActions('core/contacts', {
            getSingleNotes: 'getSingleNotes'
        }),

        ...mapActions('core/notes', {
            createNote: 'create'
        }),

        /**
         * Define moment to use it in template
         */
        moment,

        /**
         * Create a new note for this contact
         */
        addNote () {
            this.$validator.validate('note').then(valid => {
                if (!valid) {
                    return;
                }

                this.loading = true;
                this.createNote({
                    note:      this.newNote,
                    contactId: this.contact.id
                })
                    .then(() => {
                        this.newNote = '';
                        this.loading = false;
                    });
            });
        }
    }

};
</script>
