<template>
    <div id="bulle-unread-msgs"  :class="{'position-absolute' : numberUnreadMessages > 0, 'show-icon-nbr': shownInMessageBox}">
        <span class="nbr-unread-msgs">
            {{ numberUnreadMessages}}
         </span>
    </div>
</template>
<script>
export default { props: ['numberUnreadMessages','shownInMessageBox'] };
</script>
