<template>
    <div id="contact-history" class="container-fluid p-0">
        <div class="row mb-2">
            <div class="col-sm-12 text-left d-flex">
                <h5 class="ml-4 pl-3">{{$t('history')}}</h5>
            </div>
            <div class="menu-tab">
                <ul class="nav ml-5">
                    <li
                        class="nav-item sms-tab selected"
                        @click="selectedHistory = 'sms'"
                        v-if="typeof contact.attributes['facebook-id'] === 'undefined' || contact.attributes['facebook-id'] === null">
                        <router-link :to="{ name: '' }" class="nav-link" data-toggle="pill" data-target="#pills-sms">{{ $t('sms') }}</router-link>
                    </li>
                    <li class="nav-item fb-tab selected" @click="selectedHistory = 'facebook'"
                        v-else>
                        <router-link :to="{ name: '' }" class="nav-link" data-toggle="pill" data-target="#pills-facebook">{{ $t('facebook') }}</router-link>
                    </li>
                    <hr class="menu-line" />
                </ul>
            </div>
            <a class="sidebar-arrow ml-auto d-none close-btn text-btn" href="#" @click="$emit('hideSidebar')" v-if="collapseSidebar">{{ $t('close') }} <font-awesome-icon :icon="['fas', 'angle-double-right']" /></a>
            <a class="sidebar-arrow ml-auto d-none open-btn text-btn" href="#" @click="$emit('showSidebar')" v-bind:class="{ fadeIn: !collapseSidebar }"><font-awesome-icon :icon="['fas', 'angle-double-left']" /></a>

        </div>
        <div class="tab-pane" id="pills-sms" v-if="typeof contact.attributes['facebook-id'] === 'undefined' || contact.attributes['facebook-id'] === null">
            <div class="history-bg">
                <sms-chat></sms-chat>
            </div>
        </div>
        <div class="tab-pane" id="pills-facebook" v-else>
            <preloader-placeholder :loading="loading" :resultlength="contactInteractions.length" :type="'box'"></preloader-placeholder>
            <div class="history-wrap">
                <div class="history-bg">
                    <div v-for="interaction in contactInteractions" :key="interaction.id">
                        <facebook-message-box
                            v-bind:interaction="interaction"
                            v-if="interaction.attributes.category != 'post'"
                            v-bind:activePageId="activePageId">
                        </facebook-message-box>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Components
import SmsChat              from '../../../connectors/sms/components/SmsChat.vue';
import FacebookMessageBox   from '../../../connectors/facebook/components/FacebookMessageBox.vue';
import PreloaderPlaceholder from '../PreloaderPlaceholder.vue';

// Import external libraries
import { mapState } from 'vuex';

export default {
    props: [
        'collapseSidebar',
        'contactInteractions',
        'activePageId',
        'loading',
        'singleContactAllInteractions'
    ],
    components: {
        FacebookMessageBox,
        PreloaderPlaceholder,
        SmsChat
    },

    data () {
        return {
            selectedHistory: ''
        };
    },

    computed: {
        ...mapState('core/contacts', {
            contact: 'single'
        }),
    },
};
</script>
