<template>
    <div id="sms-conversation-box"
        :class="[(activeConversation && !currentUser.attributes['is-admin']) ? 'open-conversation' : '', currentUser.attributes['is-admin'] ? 'px-2' : 'px-4']"
        @click.prevent="$emit('openConversation', contact.attributes['parent-id'])"
    >
        <popover name="active-user" id="active-user-popover" event="hover" v-if="currentUser.attributes['is-admin'] && activeUser !== null">
            <p class="mb-0">{{ `${activeUser.attributes['first-name']} ${activeUser.attributes['last-name']}` }}</p>
        </popover>
        <div class="container-fluid card-info message-card px-0">
            <div class="d-flex justify-content-between">
                <div class="d-flex">
                    <div>
                        <div class="contact-avatar-wrapper">
                            <div class="connector-icon sms-connector image-icon position-absolute">
                                <font-awesome-icon class="icon text-white" :icon="['fas', 'mobile-alt']" />
                            </div>
                            <!--div class="message-counter">{{ conversationMessages.length }}</div-->
                            <contact-avatar :contact="contact" ref="contactAvatar"></contact-avatar>
                        </div>
                    </div>
                    <div class="align-self-center">
                        <div class="row">
                            <div class="col-sm text-left contact-details">
                                <router-link @click.native="getContact(contact.attributes['parent-id'])" :to="{ name: '' }" class="contact-link">
                                    <h6 class="message-title mb-0 text-black">{{ formatPhoneNumber(contact.attributes['phone-number']) }}</h6>
                                </router-link>
                                <p class="mb-0 fs-12 text-black">
                                    {{ contact.attributes['first-name'] }} {{ contact.attributes['last-name'] }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="mr-5" v-if="currentUser.attributes['is-admin'] && activeUser !== null && activeUser.id !== currentUser.id">
                        <span class="initials fs-10" v-popover.top="{ name: 'active-user' }">
                            {{ userInitials }}
                        </span>
                        <img class="icone-message mb-4" src="@/core/assets/images/icon-message.png" alt="">
                    </div>
                    <div v-if="activeConversation">
                        <font-awesome-icon class="active-arrow text-primary" :icon="['fas', 'arrow-alt-circle-right']" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm text-left last-message-section">
                    <p
                        class="new-sms-text mb-0 text-black"
                        :class="{'contact-message': latestMessage.direction === 'mobile_originated' }"
                        @click.prevent="$emit('openConversation', contact.attributes['parent-id'])"
                    >
                        <span v-if="latestMessage['is-mass']"> {{ $t('massive-send') }}: </span>
                        <span v-else-if="latestMessage['is-flash']"> {{ $t('flash-message') }}: </span>
                        <span v-else-if="latestMessage['is-auto-response']"> {{ $t('auto-response') }}: </span>
                        <span v-else-if="latestMessage.direction === 'mobile_terminated'"> {{ $t('you') }}: </span>
                        <span> {{latestMessage.content}} </span>
                    </p>
                    <small class="date-time-message text-black">
                        {{ formatDate(moment(latestMessage['created-at'])) }}
                    </small>
                </div>
            </div>
        </div>
        <div class="seperator-border" v-if="!currentUser.attributes['is-admin']"></div>
    </div>
</template>
<script>

// Components
import ContactAvatar from '@/core/components/contacts/ContactAvatar.vue';

// Import external libraries
import {
    mapActions, mapGetters, mapState
} from 'vuex';
import moment from 'moment';
import _      from 'underscore';
import Format from '@/helpers/Format.js';

// Import Really Fast Deep Copy
const clone = require('rfdc')();

export default {
    components: {
        ContactAvatar
    },
    emits: ['openConversation'],

    props: ['contactId', 'conversationMessages', 'conversationId', 'usersIds'],

    data () {
        return {
            profilePicture: null,
            latestMessage:  null,
            contact:        {},
            messageDate:    '',
            messageCount:   [],
        };
    },

    created () {
        this.contact = _.find(this.convoContacts, (contact) => contact.id == this.contactId);

        this.getTheLastConversationMessage();
    },

    watch: {
        convoMessages () {
            this.getTheLastConversationMessage();
        }
    },

    computed: {
        ...mapState('core/auth', {
            'currentUser': 'user'
        }),

        /**
         * Getter of SMS contacts included in conversation
         * Getter of SMS messages included in conversation
         *
         * contacts    contacts included in conversation
         * messages    messages included in conversation
         */
        ...mapGetters('core/conversations', {
            convoContacts: 'convoContacts',
            convoMessages: 'convoMessages',
            convoUsers:    'convoUsers'
        }),

        /**
         * Getter the id of the contact active
         */
        ...mapState('core/conversations', [
            'activeContactId'
        ]),

        /**
         * State of tenants
         *
         * selectedTenant    State of the tenant the user has selected
         */
        ...mapState('core/tenants', [
            'selectedTenant'
        ]),

        /**
         *
         * Find out if the current conversation with the current contact is active or not
         */
        activeConversation () {
            return this.activeContactId === this.contact.attributes['parent-id'];
        },

        /**
         * Get the active user of this conversation
         */
        activeUser () {
            // Get the fist active user in this conversation
            let activeUser = clone(_.find(this.convoUsers, (user) => user.id == this.usersIds[0]));
            return activeUser ? activeUser : null;
        },

        /**
         * Get the current conversation active user initials
         */
        userInitials () {
            if (this.activeUser) {
                return this.activeUser.attributes['first-name'].charAt(0).toUpperCase() + this.activeUser.attributes['last-name'].charAt(0).toUpperCase();
            }

            return null;
        }
    },

    methods: {
        /**
         * Define moment to use it in template
         */
        moment,

        /**
         * Methods to get contact that sent SMS message
         *
         * getSingle     Single contact
         */
        ...mapActions('core/contacts', [
            'getSingle'
        ]),

        /**
         *
         * Get the contact data then redirect to single contact page
         *
         * @return  void
         */
        getContact (contactId) {
            this.getSingle({ id: contactId }).then(() => {
                this.$router.push({ name: `contact:${this.$i18n.locale}` });
            });
        },

        /**
         * Get the last conversation message
         */
        getTheLastConversationMessage () {
            this.latestMessage = clone(_.find(this.convoMessages, (message) => message.id == this.conversationMessages[this.conversationMessages.length - 1].id).attributes);
        },

        formatDate:        Format.formatDate,
        formatPhoneNumber: Format.formatPhoneNumber
    }
};
</script>
