<template>
    <div id="admin-conversations-box" class="d-flex mt-4">
        <div class="admin-conversations-section white-scrollbar d-flex justify-content-center" :class="[shownConversation ? 'col-4' : 'col-12']" v-scroll="infiniteScroll">
            <div class="white-box">
                <div class="conversations-sections w-100">
                    <div class="spinner-border mt-5 text-primary" role="status" v-if="isLoading">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div id="conversations-box" v-else-if="conversationsData.conversations.length > 0">
                        <sms-conversation-box
                            v-for="conversation in conversationsData.conversations"
                            v-bind:key="conversation.id"
                            v-bind:conversationId="conversation.id"
                            v-bind:usersIds="conversation.attributes['active-users']"
                            v-bind:contactId="conversation.relationships.contact.data.id"
                            v-bind:conversationMessages="conversation.relationships.messages.data"
                            v-on:openConversation="openConversation($event, {})"
                        ></sms-conversation-box>
                        <span
                            id="load-more-conversations"
                            class="spinner spinner-border text-secondary loading-spinner align-self-center"
                            role="status"
                            v-if="loadMoreLoading">
                        </span>
                    </div>
                    <div v-else class="mt-5">
                        {{ $t('no-messages-in-section') }}
                    </div>
                </div>
            </div>
            <div class="next-conversations" v-if="conversationsData.meta.page['current-page'] < conversationsData.meta.page['last-page']">
                <span>{{ $t('next-messages') }}</span><font-awesome-icon :icon="['fa', 'angles-down']" />
            </div>
        </div>
        <div id="contact-sms" class="w-100 col-8" v-if="shownConversation">
            <div id="chat-info" class="white-box">
                <div class="loading-section d-flex justify-content-center" v-if="openingConversations">
                    <span class="spinner spinner-border text-primary loading-spinner align-self-center" role="status"></span>
                </div>
                <div class="row" v-else>
                    <div id="chat" :class="[showContactInfoSection ? 'col-7' : 'col-12']" >
                        <sms-chat
                            :showDashboard="currentSection !== OTHER_USERS_CONVERSATIONS_SECTION"
                            :loadTerminatedConversations="false"
                            v-on:conversationUpdated="updateSections()"
                            v-on:conversationTerminated="terminateConversation()"
                        >
                        </sms-chat>
                    </div>
                    <div id="info" class="col info-contact-block responsive-bloc-width" v-if="showContactInfoSection">
                        <sms-contact-info></sms-contact-info>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal components
import SmsChat            from '../../components/SmsChat.vue';
import SmsConversationBox from '../../components/SmsConversationBox.vue';
import SmsContactInfo     from '../../components/SmsContactInfo.vue';

// Mixins
import InfiniteScrollMixin from '@/core/mixins/InfiniteScrollMixin.js';

// Import external libraries
import {
    mapActions, mapState, mapGetters
} from 'vuex';

export default {
    components: {
        SmsConversationBox,
        SmsContactInfo,
        SmsChat
    },

    props: ['currentSection', 'loadingNumber'],

    data () {
        // Available admin sections
        const OTHER_USERS_CONVERSATIONS_SECTION   = 'other-users-conversartions';
        const WITHOUT_USERS_CONVERSATIONS_SECTION = 'without-users-conversartions';
        const CURRENT_USER_CONVERSATIONS_SECTION  = 'current-user-conversartions';

        return {
            OTHER_USERS_CONVERSATIONS_SECTION,
            WITHOUT_USERS_CONVERSATIONS_SECTION,
            CURRENT_USER_CONVERSATIONS_SECTION,

            openingConversations:   false,
            shownConversation:      false,
            hideContactInfoSection: false,

            // Loadings
            currentUserLoading: false,
            noUsersLoading:     false,
            otherUsersLoading:  false,
            loadMoreLoading:    false
        };
    },

    mixins: [
        InfiniteScrollMixin
    ],

    mounted () {
        this.loadConversations();

        // Listen to the websocket event
        this.update({ tenantId: this.selectedTenant.id, filters: { 'is-active': 1 } });

        window.addEventListener('resize', this.hideContactInfo());

        this.infiniteScrollChildrenWrapper = document.getElementById('conversations-box');
    },

    computed: {
        /**
         * Getter of SMS conversations
         */
        ...mapGetters('core/conversations', [
            'convoContacts'
        ]),

        ...mapState('core/menu', ['collapsedMenu']),

        ...mapState('core/tenants', { selectedTenant: 'selectedTenant' }),

        ...mapState('core/conversations', [
            'currentUserConversations',
            'noUsersConversations',
            'otherUsersConversations',
            'newMessage',
            'currentContactDisabled',
        ]),

        isLoading () {
            return (this.loadingNumber || this.currentUserLoading || this.noUsersLoading || this.otherUsersLoading || this.loadingUsers);
        },

        showContactInfoSection () {
            return (this.currentSection === this.CURRENT_USER_CONVERSATIONS_SECTION && !this.hideContactInfoSection);
        },

        conversationsData () {
            switch (this.currentSection) {
                case this.OTHER_USERS_CONVERSATIONS_SECTION:
                    return this.otherUsersConversations;
                case this.WITHOUT_USERS_CONVERSATIONS_SECTION:
                    return this.noUsersConversations;

                default:
                    return this.currentUserConversations;
            }
        }
    },

    watch: {
        currentSection (newSection) {
            // Init the conversation board and then load new data
            this.resetSingleConversation();
            this.setActiveContact({ contactId: null });
            this.infiniteScrollPage = 1;

            switch (newSection) {
                case this.OTHER_USERS_CONVERSATIONS_SECTION:
                    this.otherUsersLoading = true;
                    this.loadOtherUsersConversations();
                    break;

                case this.WITHOUT_USERS_CONVERSATIONS_SECTION:
                    this.noUsersLoading = true;
                    this.loadWithoutUsersConversations();
                    break;

                default:
                    this.currentUserLoading = true;
                    this.loadCurrentUserConversations();
                    break;
            }
        },

        isLoading (newValue) {
            if (newValue === true) {
                this.shownConversation = false;
            }
        },

        newMessage () {
            this.updateConversations();
        },

        currentContactDisabled () {
            this.openingConversations = true;
            this.shownConversation    = false;
            this.updateConversations();
        },

        collapsedMenu () {
            this.hideContactInfo();
        }
    },

    methods: {
        // Get conversations for a single contact
        ...mapActions('core/contacts', [
            'getSingle'
        ]),

        /**
         * GetConversations method to get SMS list of conversations
         * update           method to get SMS message real time
         *
         */
        ...mapActions('core/conversations', {
            getConversations:        'index',
            getContactConversations: 'getContactConversations',
            setActiveContact:        'setActiveContact'
        }),

        /**
         * Send event to open conversation in the right panel
         *
         * @param  {string}   contactId
         * @param  {object}   filters   like is-active filter
         * @return  void
         */
        openConversation (contactId, filters) {
            this.openingConversations = true;
            this.shownConversation    = true;

            this.getSingle({ id: contactId }).then(() => {
                this.getContactConversations({
                    id: contactId,
                    filters
                }).then(() => {
                    this.setActiveContact({ contactId });
                    this.openingConversations = false;
                });
            });
        },

        /**
         * Terminate a conversation
         */
        terminateConversation () {
            this.openingConversations = true;

            let newContactId = null;
            if (this.currentSection === this.CURRENT_USER_CONVERSATIONS_SECTION) {
                newContactId = this.currentUserConversations.conversations.length !== 0 ?
                    this.convoContacts.find((contact) => contact.id == this.currentUserConversations.conversations[0].relationships.contact.data.id).attributes['parent-id'] :
                    null;
            }

            this.setActiveContact({ contactId: newContactId });

            if (newContactId === null) {
                this.shownConversation    = false;
                this.openingConversations = false;
            } else {
                this.openConversation(newContactId, { 'is-active': 1 });
            }
        },

        /**
         * Methods for users API request
         *
         * getUsers     get list of users for this tenant
         */
        ...mapActions('core/users', {
            'getUsers': 'index',
        }),

        /**
         * GetConversations method to get SMS list of conversations
         * update           method to get SMS message real time
         *
         */
        ...mapActions('core/conversations', {
            getConversations:         'index',
            update:                   'update',
            stopListeningToWebsocket: 'stopListeningToWebsocket',
            setActiveContact:         'setActiveContact',
            resetSingleConversation:  'resetSingleConversation'
        }),

        /**
         * Get the conversations from the API and show the first one.
         */
        loadConversations () {
            // Init the conversation board and then load new data
            this.resetSingleConversation();
            this.setActiveContact({ contactId: null });

            // Start loadings
            this.otherUsersLoading  = true;
            this.currentUserLoading = true;
            this.noUsersLoading     = true;

            this.updateConversations();
        },

        /**
         * Update section conversations after chat update
         */
        updateSections () {
            if (this.currentSection === this.WITHOUT_USERS_CONVERSATIONS_SECTION) {
                this.$emit('switchSection', {
                    to: this.CURRENT_USER_CONVERSATIONS_SECTION
                });
            }

            this.updateConversations();
        },

        /**
         * Update the list of current, without and other users conversations
         */
        updateConversations () {
            this.loadOtherUsersConversations();
            this.loadCurrentUserConversations();
            this.loadWithoutUsersConversations();
        },

        /**
         * Load the current user conversations
         */
        loadCurrentUserConversations () {
            this.getConversations({
                filters: {
                    'related-to':              'current-user',
                    'is-active':               1,
                    'with-subscribed-contact': 1
                },
                sort: '-waiting-messages',
                page: {
                    number: 1,
                    size:   10
                }
            }).then(() => {
                if (this.currentSection === this.CURRENT_USER_CONVERSATIONS_SECTION && this.currentUserConversations.conversations.length !== 0 && !this.shownConversation) {
                    const contactId = this.convoContacts.find((contact) => contact.id == this.currentUserConversations.conversations[0].relationships.contact.data.id).attributes['parent-id'];
                    this.openConversation(contactId, { 'is-active': 1 });
                }

                this.currentUserLoading = false;
                this.loadMoreLoading = false;
                this.enableInfiniteScroll();
            });
        },

        /**
         * Load the conversations without users
         */
        loadWithoutUsersConversations (append = false, page = 1) {
            this.getConversations({
                filters: {
                    'related-to':              'no-users',
                    'with-subscribed-contact': 1,
                    'is-active':               1
                },
                sort: 'waiting-messages',
                page: {
                    number: page,
                    size:   10
                },
                append
            }).then(() => {
                if (this.currentSection === this.WITHOUT_USERS_CONVERSATIONS_SECTION && this.noUsersConversations.conversations.length !== 0 && !this.shownConversation) {
                    const contactId = this.convoContacts.find((contact) => contact.id == this.noUsersConversations.conversations[0].relationships.contact.data.id).attributes['parent-id'];
                    this.openConversation(contactId, { 'is-active': 1 });
                }

                this.noUsersLoading = false;
                this.loadMoreLoading = false;
                this.enableInfiniteScroll();
            });
        },

        /**
         * Load the conversations of other users
         */
        loadOtherUsersConversations () {
            this.getConversations({
                filters: {
                    'related-to':              'other-users',
                    'with-subscribed-contact': 1,
                    'is-active':               1
                },
                sort: 'waiting-messages',
                page: {
                    number: 1,
                    size:   10
                }
            }).then(() => {
                if (this.currentSection === this.OTHER_USERS_CONVERSATIONS_SECTION && this.otherUsersConversations.conversations.length !== 0 && !this.shownConversation) {
                    const contactId = this.convoContacts.find((contact) => contact.id == this.otherUsersConversations.conversations[0].relationships.contact.data.id).attributes['parent-id'];
                    this.openConversation(contactId, { 'is-active': 1 });
                }

                this.otherUsersLoading = false;
                this.loadMoreLoading = false;
                this.enableInfiniteScroll();
            });
        },

        /**
         * Hide the contact info section automatically if the width of the window
         * is less than 1440 and the main menu is not collapsed
         */
        hideContactInfo () {
            this.hideContactInfoSection = (window.innerWidth < '1440' && !this.collapsedMenu);
        },

        /**
         * Infinite scroll end reached callback from infinite scroll mixin.
         */
        infiniteScrollEndReached (page) {
            // Disable the infinite scroll when the user reaches the end
            this.disableInfiniteScroll();

            // Do not load more if the user is already on the last page
            if (this.conversationsData.meta.page['current-page'] >= this.conversationsData.meta.page['last-page']) {
                return;
            }

            this.loadMoreLoading = true;
            switch (this.currentSection) {
                case this.CURRENT_USER_CONVERSATIONS_SECTION:
                    this.loadCurrentUserConversations(true, page);
                    break;

                case this.OTHER_USERS_CONVERSATIONS_SECTION:
                    this.loadOtherUsersConversations(true, page);
                    break;

                case this.WITHOUT_USERS_CONVERSATIONS_SECTION:
                    this.loadWithoutUsersConversations(true, page);
                    break;
            }
        }
    },

    beforeDestroy () {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize, { passive: true });
            return false;
        }
    },

    destroyed () {
        this.stopListeningToWebsocket(this.selectedTenant.id);
    }
};
</script>
