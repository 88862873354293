import Api       from '@/helpers/Api.js';
import _         from 'underscore';
import normalize from 'json-api-normalizer';
import Vue       from 'vue';
import qs        from 'qs';

// Import mutations
import {
    ADD_SCHEDULED_MESSAGE,
    REMOVE_SCHEDULED_MESSAGE,
    SELECT_SCHEDULED_MESSAGE,
    SET_SCHEDULED_META,
    SEND_SCHEDULED_MESSAGE,
    SET_SCHEDULED_MESSAGES,
    SET_SENT_SCHEDULED_MESSAGES,
    UPDATE_SCHEDULED_MESSAGE,
    RESET_SELECT_SCHEDULED_MESSAGE
} from './mutation-types.js';

const resource  = '/connectors/sms/v1/scheduled-messages';

// Initial state
const state = () => ({
    plannedScheduledMessages: {},
    sentScheduledMessages:    {},
    plannedScheduledMeta:     {},
    sentScheduledMeta:        {},
    selectedScheduledMessage: {
        id:            '',
        audienceCount: '',
        audiences:     [],
        content:       '',
        name:          '',
        category:      '',
        plannedDate:   '',
        plannedTime:   ''
    }
});

// Getters
const getters = {

};

// Actions
const actions = {

    /**
     * List sent and pending scheduled messages
     *
     * @param   {Object}  context  The context
     *
     * @return  {Promise}
     */
    indexSent: (context, payload) => {
        return new Promise((resolve, reject) => {
            Api.get(resource, {
                params: {
                    sort:   '-sent-at',
                    filter: {
                        'status':   ['sent', 'pending'],
                        'category': payload.category ? payload.category : void 0
                    },
                    page: payload.page ? payload.page : void 0
                },
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKey: false });
                    context.commit(SET_SENT_SCHEDULED_MESSAGES, normalizedData.scheduledMessages ?? {});

                    if (response.data.meta) {
                        context.commit(SET_SCHEDULED_META, { type: 'sent', meta: response.data.meta });
                    }

                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * List planned and not planned scheduled messages
     *
     * @param   {Object}  context  The context
     *
     * @return  {Promise}
     */
    indexPlanned: (context, payload) => {
        return new Promise((resolve, reject) => {
            Api.get(resource, {
                params: {
                    sort:   'planned-at',
                    filter: {
                        'status':   ['not_planned', 'planned'],
                        'category': payload.category ? payload.category : void 0
                    },
                    page: payload.page ? payload.page : void 0
                },
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKey: false });
                    context.commit(SET_SCHEDULED_MESSAGES, normalizedData.scheduledMessages ?? {});

                    if (response.data.meta) {
                        context.commit(SET_SCHEDULED_META, { type: 'planned', meta: response.data.meta });
                    }

                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     *
     * @param    {Object}  context        The context
     * @param    {string}  payload.id     The scheduled message identifier
     */
    select: (context, { id, status }) => {
        const scheduledList = status === 'sent' ? 'sentScheduledMessages' : 'plannedScheduledMessages';
        const selectedScheduledMessage = _.find(context.state[scheduledList], (scheduledMessage) => scheduledMessage.id === id);
        context.commit(SELECT_SCHEDULED_MESSAGE, selectedScheduledMessage);
    },

    /**
     * Create new scheduledive message.
     *
     * @param      {Object}  context             The context
     * @param      {Object}  payload.attributes  The attributes
     *
     * @return     {Promise}
     */
    create: (context, { attributes }) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`, {
                'data': {
                    'type': 'scheduled-messages',
                    attributes
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: true });
                    context.commit(ADD_SCHEDULED_MESSAGE, normalizedData.scheduledMessages[response.data.data.id]);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Delete Scheduled Message
     *
     * @param   {Object}  context  The context
     * @param   {string}  Active number Identifier
     *
     * @return  {Promise}
     */
    delete: (context, { id }) => {
        return new Promise((resolve, reject) => {
            Api.delete(`${resource}/${id}`)
                .then(() => {
                    context.commit(REMOVE_SCHEDULED_MESSAGE, id);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     *  Send a scheduled message
     */
    send: (context, { id }) => {
        return new Promise((resolve, reject) => {
            Api.post(`/connectors/sms/v1/scheduled-message/${id}/send`, {
                'data': {
                    'type': 'scheduled-messages',
                    id
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: true });
                    context.commit(SEND_SCHEDULED_MESSAGE, { id, ...normalizedData.scheduledMessages[id] });
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     *  Update a scheduled message
     */
    update: (context, { id, attributes }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/${id}`, {
                'data': {
                    'type': 'scheduled-messages',
                    id,
                    attributes
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: true });
                    context.commit(UPDATE_SCHEDULED_MESSAGE, { id, ...normalizedData.scheduledMessages[id] });
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Reset state.selectedScheduledMessage
     */
    resetSelectedScheduledMessage (context) {
        context.commit(RESET_SELECT_SCHEDULED_MESSAGE);
    }
};

// Mutation
const mutations = {
    [ADD_SCHEDULED_MESSAGE]: (state, payload) => {
        Vue.set(state.plannedScheduledMessages, payload.id, payload);
    },
    [UPDATE_SCHEDULED_MESSAGE]: (state, payload) => {
        state.plannedScheduledMessages[payload.id] = payload;
    },
    [SEND_SCHEDULED_MESSAGE]: (state, payload) => {
        state.plannedScheduledMessages = _.reject(state.plannedScheduledMessages, (scheduledMessage) => {
            return scheduledMessage.id === payload.id;
        });
        Vue.set(state.sentScheduledMessages, payload.id, payload);
    },
    [REMOVE_SCHEDULED_MESSAGE]: (state, id) => {
        state.plannedScheduledMessages = _.reject(state.plannedScheduledMessages, (scheduledMessage) => {
            return scheduledMessage.id === id;
        });
    },
    [SET_SCHEDULED_MESSAGES]: (state, payload) => {
        state.plannedScheduledMessages = payload;
    },
    [SET_SENT_SCHEDULED_MESSAGES]: (state, payload) => {
        state.sentScheduledMessages = payload;
    },
    [SET_SCHEDULED_META]: (state, payload) => {
        state[`${payload.type}ScheduledMeta`] = payload.meta;
    },
    [SELECT_SCHEDULED_MESSAGE]: (state, payload) => {
        state.selectedScheduledMessage = payload.attributes;
        state.selectedScheduledMessage.id = payload.id;
    },
    [RESET_SELECT_SCHEDULED_MESSAGE]: (state) => {
        state.selectedScheduledMessage = {
            audienceCount: '',
            audiences:     [],
            content:       '',
            name:          '',
            category:      '',
            plannedDate:   '',
            plannedTime:   ''
        };
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
