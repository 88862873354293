<template>
    <span v-if="name==='facebook-fa'">
        <font-awesome-icon class="text-primary mr-3" :icon="['fab', 'facebook-f']"/>
    </span>
    <span class="text-primary" v-else-if="name==='mobile-fa'">
        <font-awesome-icon :icon="['fas', 'mobile-alt']"/>
    </span>
    <span class="icon-main icon-mobile" v-else-if="name==='mobile'">
        <img src="@/core/assets/images/mobile.svg" alt="Mobile icon"/>
    </span>
    <span class="icon-main icon-facebook" v-else-if="name==='facebook'">
        <img src="@/core/assets/images/facebook.svg" alt="Facebook icon"/>
    </span>
    <span class="icon-main icon-mail" v-else-if="name==='email'">
        <img src="@/core/assets/images/mail.svg" alt="Email icon"/>
    </span>
</template>

<script>
export default {
    name:  'IconsMain',
    props: ['name']
};
</script>
