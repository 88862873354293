<template>
    <div class="d-flex align-items-stretch">
        <main-navigation></main-navigation>
        <div id="scheduled-send" class="w-100 px-4">
            <div class="main-content scheduled-send-block">
                <div class="scheduled-send-title">
                    <h1 class="text-left scheduled-title">{{ currentScheduledType === MASS_CATEGORY ? $t('massive-send') : $t('flash-send') }}</h1>
                    <h4 class="text-left scheduled-text">{{ currentScheduledType === MASS_CATEGORY ? $t('send-sms-to-multiple-contacts') : $t('send-flash-messages') }}</h4>
                </div>
                <div class="scheduled-send-menu menu-tab pl-4">
                    <ul class="nav pt-2">
                        <li class="nav-item all-messages-tab" :class="{ 'menu-selected' : tabSelected === 'pending' }">
                            <router-link
                                :to="{ name: '' }"
                                @click.native="tabSelected = 'pending'"
                            >
                                {{ currentScheduledType === MASS_CATEGORY ? $t('planned-mass-message') : $t('planned-flash-message') }}
                                ({{ plannedScheduledMessages ? Object.keys(plannedScheduledMessages).length : 0 }})
                            </router-link>
                        </li>
                        <li class="nav-item messages-sent-tab" :class="{ 'menu-selected' : tabSelected === 'messages-sent' }">
                            <router-link
                                :to="{ name: ''}"
                                @click.native="tabSelected = 'messages-sent'"
                            >
                                {{ currentScheduledType === MASS_CATEGORY ? $t('sent-mass-messages') : $t('sent-flash-messages') }}
                                ({{ sentScheduledMessages ? Object.keys(sentScheduledMessages).length : 0 }})
                            </router-link>
                        </li>
                    </ul>
                </div>
                <!-- Massive section -->
                <div class="white-box mt-1" v-if="currentScheduledType === MASS_CATEGORY">
                    <pending-massive v-if="tabSelected === 'pending'"></pending-massive>
                    <sent-massive v-else></sent-massive>
                </div>

                <!-- Flash section -->
                <div class="white-box mt-1" v-else>
                    <pending-flash v-if="tabSelected === 'pending'"></pending-flash>
                    <sent-flash v-else></sent-flash>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// Internal Components
import MainNavigation  from '@/core/components/MainNavigation.vue';
import PendingMassive  from './massive/Pending.vue';
import SentMassive     from './massive/Sent.vue';
import PendingFlash    from './flash/Pending.vue';
import SentFlash       from './flash/Sent.vue';

// Import external libraries
import { mapState } from 'vuex';

export default {
    components: {
        MainNavigation,
        PendingMassive,
        SentMassive,
        PendingFlash,
        SentFlash
    },
    data () {
        const MASS_CATEGORY  = 'mass';
        const FLASH_CATEGORY = 'flash';

        return {
            tabSelected: 'pending',
            MASS_CATEGORY,
            FLASH_CATEGORY
        };
    },
    watch: {
        currentScheduledType () {
            // Init the default selected tab for each scheduled type update
            this.tabSelected = 'pending';
        }
    },
    computed: {
        ...mapState('core/scheduled-messages', [
            'plannedScheduledMessages',
            'sentScheduledMessages'
        ]),

        currentScheduledType () {
            return this.$route.meta.category;
        }
    }
};
</script>
