<template>
    <div class="d-flex align-items-stretch">
        <navigation></navigation>
        <div class="sms-config-global container-fluid" id="page-config-numbr">
            <preloader-placeholder :loading="loading" :type="'configuration'"></preloader-placeholder>
            <div v-if="loading === false" class="container-fluid main-content">
                <div class="connect-text text-left mb-5">
                    <h3 v-if="phoneNumber !== undefined">{{$t('config-twilio-connector')}}</h3>
                    <h3 v-if="phoneNumber === undefined">{{$t("connector-sms-config")}}</h3>
                </div>
                <active-twilio v-if="phoneNumber !== undefined"/>
                <generate-number-to-activate v-if="phoneNumber === undefined" :nameConnector="nameConnector"/>
            </div>
            <template  v-if="loading === false && phoneNumber !== undefined">
               <SmsInfoPlus></SmsInfoPlus>
            </template>
        </div>
    </div>
</template>
<script>

// Internal components
import PreloaderPlaceholder from '@/core/components/PreloaderPlaceholder.vue';
import ActiveTwilio         from '../twilio/Activetwilio.vue';
import SmsInfoPlus          from '../SmsInfoPlus.vue';
import GenerateNumberToActivate from '@/connectors/sms/components/twilio/GenerateNumberToActivate';

// Import external libraries
import {
    mapState, mapActions, mapGetters
} from 'vuex';

export default {
    components: {
        GenerateNumberToActivate,
        ActiveTwilio,
        SmsInfoPlus,
        PreloaderPlaceholder
    },

    data () {
        return {
            loading:       true,
            nameConnector: 'twilio'
        };
    },

    computed: {
        // State with activated phone number
        ...mapState('core/number', [
            'phoneNumber'
        ]),

        // Getter for the selected number
        ...mapGetters('core/number', { numberGetter: 'smsPhoneNumber' })
    },
    mounted () {
        this.nameConnector = window.location.href.split('/')[5];
        this.listNumber().then(() => {
            this.loading = false;
            this.numberGetter;
        });
    },
    methods: {
        // Method get list of activated number
        ...mapActions('core/number', { listNumber: 'index' })
    }
};
</script>
