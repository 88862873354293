// Import mutations
import {
    DESTROY_SESSION,
    SET_SESSION_CONTACT_ID,
    SET_SESSION_EXIST,
    SET_SESSION_SELECTED_TENANT,
    SET_SESSION_TENANTS,
    SET_SESSION_USER
} from './mutation-types.js';

// Initial state
const state = () => ({
    sessionContactId:      null,
    sessionExists:         false,
    sessionSelectedTenant: null,
    sessionTenants:        null,
    sessionUser:           null
});

// Getters
const getters = {};

// Actions
const actions = {
    /**
     * Set session contact id
     */
    setContactId (context, { contactId }) {
        context.commit(SET_SESSION_CONTACT_ID, contactId);
    },

    /**
     * Set session exist
     */
    setSessionExists (context,  { sessionExists }) {
        context.commit(SET_SESSION_EXIST, sessionExists);
    },

    /**
     * Set session selected tenant
     */
    setSessionSelectedTenant (context, { selectedTenant }) {
        context.commit(SET_SESSION_SELECTED_TENANT, selectedTenant);
    },

    /**
     * Set session tenants
     */
    setSessionTenants (context, { tenants }) {
        context.commit(SET_SESSION_TENANTS, tenants);
    },

    /**
     * Set session user
     */
    setSessionUser (context, { user }) {
        context.commit(SET_SESSION_USER, user);
    },

    destroySession (context) {
        context.commit(DESTROY_SESSION);
    }
};

// Mutations
const mutations = {
    [DESTROY_SESSION]: (state) => {
        state.sessionExists = false;
    },
    [SET_SESSION_CONTACT_ID]: (state, contactId) => {
        state.sessionContactId = contactId;
    },
    [SET_SESSION_EXIST]: (state, sessionExists) => {
        state.sessionExists = sessionExists;
    },
    [SET_SESSION_SELECTED_TENANT]: (state, selectedTenant) => {
        state.sessionSelectedTenant = selectedTenant;
    },
    [SET_SESSION_TENANTS]: (state, tenants) => {
        state.sessionTenants = tenants;
    },
    [SET_SESSION_USER]: (state, user) => {
        state.sessionUser = user;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
