<template>
    <div id="most-active">
        <div class="export-button">
            <button class="btn btn-primary rounded-pill" @click="callExportContactsMethod()">{{ $t('Export-contact') }}</button>
        </div>
        <div id="contact-top-sidebar" class="white-box new-contact mb-5 mt-1">
            <p class="sidebar-title text-center mb-0">{{ $t('week-most-actif') }}</p>
            <div class="row justify-content-center mt-3">
                <div class="col-3 avatar" v-if="topContact !== null">
                    <router-link @click.native="setContactLink()" :to="{ name: '' }">
                        <label class="label-avatar">
                            <model-short-name v-if="profilePicture === null" v-bind:model="topContact"></model-short-name>
                            <img class="contact-img" :src='profilePicture' alt="" v-else>
                        </label>
                    </router-link>
                </div>
                <div class="col pt-2" v-if="topContact !== null">
                    <router-link
                        @click.native="setContactLink()"
                        :to="{ name: ''}"
                        class="contact-link">
                            <p class="top-contact">{{ topContact.attributes.name }}</p>
                    </router-link>
                </div>
                <div class="col-12" v-else>
                    <small class="top-contact">{{ $i18n.t('none') }}</small>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal componants
import ModelShortName from '../../components/ModelShortName.vue';

// Internal services js
import Alert from '@/helpers/Alert.js';
import { EventBus } from '../../../helpers/EventBus.js';

// External libraries
import { mapState, mapActions } from 'vuex';

export default {
    components: { ModelShortName },

    data () {
        return {
            topContact:     null,
            profilePicture: null
        };
    },
    computed: {
        ...mapState('core/auth', [
            'user'
        ]),
        ...mapState('core/contacts',
            { topContacts: 'mostActiveContacts' }
        )
    },

    mounted () {
        this.indexMostActive().then(() => this.updateTopContact);
    },

    methods: {
        ...mapActions('core/contacts', [
            'indexMostActive'
        ]),

        ...mapActions('core/session', [
            'setSessionContactId'
        ]),

        /**
         * Call Export Contacts Method using Event bus.
         */
        callExportContactsMethod () {
            Alert.confirmation(
                this.$i18n.t('confirmation-export-contacts', { email: this.user.attributes.email }),
                this.$i18n.t('export'),
                this.$i18n.t('confirmation-required'),
                '#14866D'
            )
                .then((response) => {
                    if (response.isConfirmed) {
                        EventBus.$emit('exportContacts');
                    }
                });
        },

        updateTopContact () {
            // Save top contact data only if the array has data
            if (this.topContacts.length !== 0) {
                this.topContact = this.topContacts[0];

                // If user have a profile picture
                if (this.topContact.attributes['profile-picture']) {
                    this.profilePicture = this.topContact.attributes['profile-picture'];
                }
            }
        },

        /**
         * Set session for id of most active contact
         * set router link to the most active contact page
         *
         *
         * @return    {object}    top contact page link
         */
        setContactLink () {
            this.$router.push({
                name: `contact:${this.$i18n.locale}`
            });
            this.setSessionContactId({ contactId: this.topContact.id });
        }
    }
};
</script>
