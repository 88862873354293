<template>
    <div class="d-flex align-items-stretch">
        <navigation></navigation>
        <div class="container-fluid main-content" v-if="loading">
            <preloader-placeholder :loading="loading" :type="'connector'"></preloader-placeholder>
        </div>
        <facebook-messages
            v-if="!loading"
            v-on:hideSidebar="collapseSidebar = false"
            v-on:showSidebar="collapseSidebar = true"
            v-bind:collapseSidebar="collapseSidebar"
        ></facebook-messages>
        <facebook-sidebar
            v-bind:collapseSidebar="collapseSidebar"
            v-if="!loading && this.isValidAdminToken === true"
        ></facebook-sidebar>
    </div>
</template>
<script>

// Import du scss du connecteur facebook
import '../assets/scss/global.scss';

// Components
import FacebookMessages     from './FacebookMessages.vue';
import FacebookSidebar      from './FacebookSidebar.vue';
import PreloaderPlaceholder from '@/core/components/PreloaderPlaceholder.vue';

// Internal services js
import { RepositoryFactory } from '@/repositories/repositoryFactory.js';

// Repositories
const FacebookUsers = RepositoryFactory.get('FacebookUsers');
const FacebookPages = RepositoryFactory.get('FacebookPages');

// Import external libraries
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        FacebookSidebar,
        FacebookMessages,
        PreloaderPlaceholder
    },

    data () {
        return {
            collapseSidebar: true,
            loading:         true
        };
    },
    computed: {
        ...mapState('core/facebook', [
            'isValidAdminToken'
        ])
    },

    created () {
        this.checkFacebookUserConnection();
    },

    methods: {
        ...mapActions('core/facebook', [
            'setIsValidAdminToken',
            'setActivePage',
            'setHasActivePage',
            'setFacebookConnect'
        ]),

        /**
         * Check if the Facebook user is connected
         *
         * @return     void
         */
        checkFacebookUserConnection () {
            FacebookUsers.index()
                .then((response) => {
                    if (response.data.data.length !== 0) {
                        this.setFacebookConnect({ connect: true });
                        this.checkActivePage();
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Check if we have a configured pages
         *
         * @return     void
         */
        checkActivePage () {
            FacebookPages.index()
                .then((request) => {
                    if (request.data.data.length !== 0) {
                        this.setIsValidAdminToken({ isValid: request.data.data[0].attributes['is-remote-access-token-valid'] });
                        this.setActivePage({ activePage: request.data.data[0] });
                        this.setHasActivePage({ hasActivePage: true });
                        return;
                    }

                    // If we don't have an active page
                    this.setHasActivePage({ hasActivePage: false });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

