<template>
    <div class="d-flex align-items-stretch">
        <badge v-if="firstConfiguration"></badge>
        <topheader v-if="firstConfiguration"></topheader>
        <useredit v-if="firstConfiguration"></useredit>
        <navigation v-if="!firstConfiguration"></navigation>
        <div class="facebook-config-global w-100 d-flex justify-content-center">
            <preloader-placeholder :loading="loading" :type="'configuration'"></preloader-placeholder>
            <div class="col-8" v-if="loading === false">
                <div class="connect-text text-left">
                    <h3>{{$t('config-FB-connector')}}</h3>
                </div>
                <div class="card-info text-left">
                    <div class="row">
                        <div class="col-sm text-left">
                            <div>
                                <h4>{{$t('linked-user')}}</h4>
                                <p v-for="facebookUser in facebookUsers" :key="facebookUser.id">
                                    {{ facebookUser.attributes.name }}
                                </p>
                            </div>
                        </div>
                        <div class="col-sm">
                            <continue-with-facebook class="mt-2"></continue-with-facebook>
                        </div>
                    </div>
                </div>
                <active-page v-if="user.attributes['is-admin'] || user.attributes['is-super-admin'] && !loading"></active-page>
                <remote-pages v-if="user.attributes['is-admin'] || user.attributes['is-super-admin'] && !loading" :facebookUsers="facebookUsers"></remote-pages>
            </div>
        </div>
    </div>
</template>
<script>

// Internal components
import ContinueWithFacebook from './ContinueWithFacebook.vue';
import RemotePages          from './RemotePages.vue';
import ActivePage           from './ActiveFacebookPage.vue';
import PreloaderPlaceholder from '../../../core/components/PreloaderPlaceholder.vue';

// Internal services js
import { RepositoryFactory } from '../../../repositories/repositoryFactory.js';
import { EventBus }          from '../../../helpers/EventBus.js';

const FacebookUsersRepository = RepositoryFactory.get('FacebookUsers');

// Import external libraries
import { mapState } from 'vuex';

export default {
    components: {
        RemotePages,
        ActivePage,
        ContinueWithFacebook,
        PreloaderPlaceholder
    },

    /**
     * Check function
     * Before creating this page, check if the user is not logged in
     */
    beforeCreate () {
        if (this.sessionExists === false) {
            this.$router.replace({ name: 'login' });
            return;
        }

        // Check If this user is an admin
        if (this.user && (!this.user.attributes['is-admin'] && !this.user.attributes['is-super-admin'])) {
            this.$router.replace({ name: `facebook-connector:${this.$i18n.locale}` });
        }
    },

    data () {
        return {
            facebookUsers:      [],
            loading:            false,
            firstConfiguration: false
        };
    },

    computed: {
        ...mapState('core/auth', [
            'user'
        ]),

        ...mapState('core/session', [
            'sessionExists'
        ])
    },

    mounted () {
        // See if first time
        this.firstConfiguration = this.$route.params.welcome;
        EventBus.$on('activate-page', () => {
            this.firstConfiguration = false;
        });

        this.loading = true;
        FacebookUsersRepository.index()
            .then((response) => {
                if (typeof response.data.data !== 'undefined' && response.data.data.length !== 0) {
                    this.facebookUsers = response.data.data;
                }

                this.loading = false;
            })
            .catch((errors) => {
                console.error(errors);
            });
    }
};
</script>
