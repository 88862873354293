<template>
    <select-dropdown
        :options="INTERACTIONS_OPTIONS"
        :selected="selectedOption"
        v-on:selectOption="updateOption($event, 2)"
        :showLabel="true"
        :type="'options'"
    ></select-dropdown>
</template>
<script>

// Component
import SelectDropdown from '../../../core/components/SelectDropdown.vue';

export default {
    components: {
        SelectDropdown
    },

    data () {
        return {
            selectedOption:       this.$i18n.t('all'),
            INTERACTIONS_OPTIONS: [
                'all',
                'comment',
                'like',
                'love',
                'haha',
                'sad',
                'angry',
                'wow',
                'care'
            ]
        };
    },

    methods: {
        /**
         * Update selected option
         *
         * @param    {string}    option           The new selected option
         * @param    {integer}   pluralization    To show the pluralization
         */
        updateOption (option, pluralization) {
            this.selectedOption = this.$i18n.tc(option, pluralization);
            this.$emit('selectOption', option);
        },
    }
};
</script>
