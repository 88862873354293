import Api       from '@/helpers/Api.js';
import moment    from 'moment';
import normalize from 'json-api-normalizer';
import qs        from 'qs';
import Vue       from 'vue';

// Import mutations
import {
    ADD_CONTACT,
    SET_CONTACTS,
    SET_CONTACTS_META,
    SET_MOST_ACTIVE_CONTACTS,
    SET_NEW_CONTACTS,
    SET_EXPORT,
    SET_SINGLE_CONTACT,
    SET_SINGLE_CONTACT_NOTES,
    SET_TEMPLATE,
    UPDATE_CONTACT,
    UPDATE_SINGLE_CONTACT,
    UPDATE_CONTACT_PICTURE,
    DELETE_CONTACT_PICTURE,
    ADD_SINGLE_CONTACT_NEW_NOTE,
    SET_SINGLE_CONTACT_ALL_INTERACTIONS,
} from './mutation-types.js';

const resource    = '/internal/v1/contacts';
const smsResource = '/connectors/sms/v1/contacts';

// Initial state
const state = () => ({
    contacts:                     {},
    contactsMeta:                 {},
    mostActiveContacts:           {},
    newContacts:                  {},
    single:                       null,
    singleNotes:                  {},
    template:                     null,
    export:                       null,
    singleContactAllInteractions: null
});

// Getters
const getters = {
    /**
     * Gets the tags of a single contact.
     *
     * @param      {Object}  state   The state
     *
     * @return     {Object}
     */
    singleTags: (state) => state.single.attributes.tags
};

// Actions
const actions = {
    /**
     * Get all contacts.
     *
     * @param      {Object}   context     The context
     * @param      {Object}   payload     The payload (sort and filters)
     *
     * @return     {Promise}
     */
    index: (context, payload) => {
        const urlParams = {};

        if (payload) {
            urlParams.sort   = payload.sort ? payload.sort : void 0;
            urlParams.filter = payload.filters ? payload.filters : void 0;
            urlParams.page   = payload.page ? payload.page : void 0;
        }

        return new Promise((resolve, reject) => {
            Api.get(resource, {
                params:           urlParams,
                paramsSerializer: params => qs.stringify(params, { encode: false })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_CONTACTS, normalizedData.contacts ?? normalizedData);

                    if (response.data.meta) {
                        context.commit(SET_CONTACTS_META, response.data.meta);
                    }

                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Get most active contacts.
     *
     * @param      {Object}   context          The context
     *
     * @return     {Promise}
     */
    indexMostActive: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(resource, {
                params: {
                    sort: 'most-active'
                },
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_MOST_ACTIVE_CONTACTS, normalizedData.contacts);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Get newest contacts.
     *
     * @param      {Object}   context          The context
     *
     * @return     {Promise}
     */
    indexNew: (context) => {
        const weekInDays = 7;
        return new Promise((resolve, reject) => {
            Api.get(resource, {
                params: {
                    sort:   '-created-at',
                    filter: {
                        from: moment()
                            .subtract(weekInDays, 'days')
                            .format('YYYY-MM-DD')
                    },
                    page: {
                        size: 10
                    }
                },
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_NEW_CONTACTS, normalizedData.contacts ?? {});
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Get single contact.
     *
     * @param      {Object}   context     The context
     * @param      {string}   payload.id  The contact identifier
     *
     * @return     {Promise}
     */
    getSingle: (context, { id }) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}/${id}`)
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_SINGLE_CONTACT, normalizedData.contacts[id]);
                    resolve(normalizedData.contacts[id]);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Get single contact's notes.
     *
     * @param      {Object}             context     The context
     * @param      {string|undefined}   payload.id  The contact identifier
     *
     * @return     {Promise}
     */
    getSingleNotes: (context, { id, filters }) => {
        // Init the single contact notes first
        context.commit(SET_SINGLE_CONTACT_NOTES, {});

        return new Promise((resolve, reject) => {
            if (context.state.single === null && typeof id === 'undefined') {
                reject();
            }

            /**
             * Either get the passed contact identifier
             * or the state single contact identifier
             */
            const contactId = id || context.state.single.id;

            Api.get(`${resource}/${contactId}/notes`, {
                params: {
                    filter: filters
                },
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_SINGLE_CONTACT_NOTES, normalizedData.notes);
                    resolve(normalizedData.notes);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Get all interactions : Facebook + sms
     * @param      {Object}             context     The context
     * @param      {string|undefined}   payload.id  The contact identifier
     *
     * @return     {Promise}
     */
    getSingleAllInteractions: (context, { id }) => {
        return new Promise((resolve, reject) => {
            if (context.state.single === null && typeof id === 'undefined') {
                reject();
            }

            /**
             * Either get the passed contact identifier
             * or the state single contact identifier
             */
            const contactId = id || context.state.single.id;
            Api.get(`${resource}/${contactId}/interactions`)
                .then((response) => {
                    context.commit(SET_SINGLE_CONTACT_ALL_INTERACTIONS, response);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Creates a contact.
     *
     * @param      {Object}  context             The context
     * @param      {Object}  payload.attributes  The attributes
     *
     * @return     {Promise}
     */
    create: (context, { attributes }) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`,{
                'data': {
                    'type':       'contacts',
                    'attributes': attributes
                }
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(ADD_CONTACT, normalizedData.contacts[response.data.data.id]);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Creates a new SMS contact.
     *
     * @param      {Object}  context             The context
     * @param      {Object}  payload.attributes  The attributes
     *
     * @return     {Promise}
     */
    createSmsContact: (context, { attributes }) => {
        return new Promise((resolve, reject) => {
            Api.post(`${smsResource}`,{
                'data': {
                    'type':       'contacts',
                    'attributes': attributes
                }
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Patch a contact.
     *
     * @param      {Object}  context             The context
     * @param      {string}  payload.id          The contact identifier
     * @param      {Object}  payload.attributes  The attributes
     *
     * @return     {Promise}
     */
    patch: (context, { id, attributes }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/${id}`, {
                'data': {
                    'type': 'contacts',
                    'id':   id,
                    attributes
                }
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(UPDATE_CONTACT, { id, ...normalizedData.contacts[id] });

                    // Update single contact
                    if (context.state.single.id === id) {
                        context.commit(UPDATE_SINGLE_CONTACT, normalizedData.contacts[id]);
                    }

                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Uploads a contact profile picture.
     *
     * @param      {Object}  context          The context
     * @param      {string}  payload.id       The contact identifier
     * @param      {Object}  payload.picture  The picture
     *
     * @return     {Promise}
     */
    uploadProfilePicture: (context, { id, picture }) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('profile-picture', picture);

            Api.post(`${resource}/${id}/profile-picture`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    context.commit(UPDATE_CONTACT_PICTURE, { id, ...response.data.data });
                    context.commit(UPDATE_SINGLE_CONTACT, { id, ...response.data.data });
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Deletes a contact profile picture.
     *
     * @param      {Object}  context     The context
     * @param      {string}  payload.id  The contact identifier
     *
     * @return     {Promise}
     */
    deleteProfilePicture: (context, { id }) => {
        return new Promise((resolve, reject) => {
            Api.delete(`${resource}/${id}/profile-picture`)
                .then(() => {
                    context.commit(DELETE_CONTACT_PICTURE, { id, ...{ 'profile-picture': null } });
                    context.commit(UPDATE_SINGLE_CONTACT, { id, ...{ 'profile-picture': null } });
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Get the template file.
     *
     * @param      {Object}   context  The context
     *
     * @return     {Promise}
     */
    template: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}/template`, { responseType: 'blob' })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    context.commit(SET_TEMPLATE, url);
                    resolve(url);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Export contacts to a file.
     *
     * @param      {Object}   context           The context
     * @param      {Object}   payload.filters   The filters
     *
     * @return     {Promise}
     */
    export: (context, ...{ filters }) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}/export`, {
                responseType: 'blob',
                params:       {
                    filter: filters
                },
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    context.commit(SET_EXPORT, url);
                    resolve(url);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Import contacts from a file.
     *
     * @param      {Object}  context       The context
     * @param      {Object}  payload.file  The file
     *
     * @return     {Promise}
     */
    import: (context, { file }) => {
        const formData = new FormData();
        formData.append('file', file);

        return Api.post(`${resource}/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
};

// Mutations
const mutations = {
    [ADD_CONTACT]: (state, payload) => {
        Vue.set(state.contacts, payload.id, payload);
        Vue.set(state.newContacts, payload.id, payload);
    },
    [SET_CONTACTS]: (state, payload) => {
        state.contacts = payload;
    },
    [SET_CONTACTS_META]: (state, payload) => {
        state.contactsMeta = payload;
    },
    [SET_MOST_ACTIVE_CONTACTS]: (state, payload) => {
        state.mostActiveContacts = payload;
    },
    [SET_NEW_CONTACTS]: (state, payload) => {
        state.newContacts = payload;
    },
    [SET_EXPORT]: (state, payload) => {
        state.export = payload;
    },
    [SET_SINGLE_CONTACT]: (state, payload) => {
        state.single = payload;
    },
    [SET_SINGLE_CONTACT_NOTES]: (state, payload) => {
        state.singleNotes = payload;
    },
    [SET_TEMPLATE]: (state, payload) => {
        state.template = payload;
    },
    [UPDATE_CONTACT]: (state, payload) => {
        Vue.set(state.contacts, payload.id, payload);
    },
    [UPDATE_SINGLE_CONTACT]: (state, payload) => {
        state.single = { ...state.single, ...payload };
    },
    [UPDATE_CONTACT_PICTURE]: (state, payload) => {
        state.contacts[payload.id].attributes['profile-picture'] = payload.links['profile-picture'];
    },
    [DELETE_CONTACT_PICTURE]: (state, payload) => {
        state.contacts[payload.id].attributes['profile-picture'] = null;
    },
    [ADD_SINGLE_CONTACT_NEW_NOTE]: (state, payload) => {
        state.singleNotes = { ...payload, ...state.singleNotes };
    },
    [SET_SINGLE_CONTACT_ALL_INTERACTIONS]: (state, payload) => {
        state.singleContactAllInteractions = payload;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
