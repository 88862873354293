<template>
    <div class="align-items-stretch">
        <badge></badge>
        <div id="user-login" class="login-interface">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="login-box p-5">
                        <div class="row">
                            <div class="col-sm mb-4"><img class="img-logo text-center" src="../../../../assets/images/logo.png" alt=""></div>
                        </div>
                        <div class="loading-active-conversation-box" v-if="loading">
                            <div class="d-block mb-3">
                                <p> {{ $t('authentication-check') }}... </p>
                            </div>
                            <span class="spinner spinner-border text-primary"></span>
                        </div>
                        <router-link :to="{ name: 'login' }" class="button-link" v-else>
                            <button type="submit" class="btn btn-primary rounded-pill">
                                {{ $t('login-page') }}
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal services js
import Alert from '@/helpers/Alert.js';
import LoginMixin from '@/core/mixins/LoginMixin';

// External libraries
import { mapActions } from 'vuex';
import moment from 'moment';

export default {
    mixins: [LoginMixin],
    data () {
        return {
            loading:          true,
            selectedTenantId: null
        };
    },

    created () {
        this.login();
    },

    methods: {
        /**
         * Methods to login into the application
         *
         * samlLogin    API request to login SAML user into the app
         * updateIdPId  API request to get the Authentification link
         */
        ...mapActions('core/auth', {
            samlLogin:   'samlLogin',
            updateIdPId: 'updateIdPId'
        }),

        /**
         * Methods to get tenants linked to the account
         *
         * tenantsList       API request to get list of all tenants linked to account
         * selectSamlUserTenant  API request to get list of all tenants linked to the user
         */
        ...mapActions('core/tenants', {
            selectSamlUserTenant: 'selectSamlUserTenant'
        }),

        /**
         * Check if a logged in SAML user exist
         */
        login () {
            this.samlLogin({
                token:    this.$route.query.token,
                id:       this.$route.query.id,
                tenantId: this.$route.query.tenant
            })
                .then((response) => {
                    this.setSessionExists({ sessionExists: true });
                    this.selectedTenantId = response.data.data.tenants.id;
                    this.updateIdPId({
                        id: response.data.data['id-provider'].id
                    });
                    this.userTenants();
                })
                .catch((error) => {
                    this.loading          = false;
                    this.selectedTenantId = null;
                    Alert.fail(error.response.data.errors[0].detail);
                });
        },

        /**
         * When chosing a tenant check if user is admin
         *
         * @retun    void
         */
        selectTenant () {
            if (this.tenants.length === 0) {
                this.loading = false;
                Alert.fail(this.$i18n.t('invalid-login'));
                return;
            }

            this.selectSamlUserTenant({ id: this.selectedTenantId })
                .then(() => {
                    this.loginSucess();
                });
        },

        /**
         * When login successful save user data
         *
         * @return     void
         */
        loginSucess () {
            // Use the link to get the user information
            this.getAuthenticatedUser()
                .then(() => {
                    // Use the user locale
                    if (this.user.attributes.language) {
                        this.$i18n.locale = this.user.attributes.language;
                        moment.locale(this.user.attributes.language);

                        // Set the locale into local storage
                        localStorage.setItem('locale', this.user.attributes.language);
                    }

                    this.$router.push({ name: `home:${this.$i18n.locale}` });
                });
        }
    }
};
</script>
