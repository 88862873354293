<template>
    <div id="connectors-page" class="d-flex align-items-stretch">
        <navigation></navigation>
        <div class="connectors-content container-fluid main-content">
            <preloader-placeholder :loading="loading" :type="'connector'"></preloader-placeholder>
            <div v-if="loading === false">
                <div v-if="this.$route.params.welcome">
                    <h1>{{$t('welcome-aboard')}}</h1>
                    <p>{{$t('connect-platform-to-cocoapp')}}</p>
                </div>
                <div v-else>
                    <h3>{{$t('connectors-title')}}</h3>
                    <p>{{$t('connectors-text-title')}}</p>
                </div>
                <div class="connectors-list mt-5">
                    <connector-box-facebook class="mb-5" v-if="facebook.isConnected || !this.$route.params.welcome"></connector-box-facebook>
                    <connector-box-sms class="mt-5" v-if="sms.isConnected || !this.$route.params.welcome"></connector-box-sms>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal components
import ConnectorBoxFacebook from './ConnectorBoxFacebook.vue';
import ConnectorBoxSms      from './ConnectorBoxSms.vue';
import PreloaderPlaceholder from './PreloaderPlaceholder.vue';

// Import external libraries
import {
    mapState,
    mapActions
} from 'vuex';

export default {
    components: {
        ConnectorBoxSms,
        ConnectorBoxFacebook,
        PreloaderPlaceholder
    },

    created () {
        // Get a list of connectors of the current tenant.
        this.getAllConnectors().finally(() => {
            this.loading = false;
        });
    },

    data () {
        return { loading: true };
    },

    computed: {
        ...mapState('core/connectors', [
            'connectors',
            'facebook',
            'sms'
        ])
    },

    methods: {
        ...mapActions('core/connectors', { getAllConnectors: 'index' })
    }
};

</script>
