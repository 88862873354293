import { render, staticRenderFns } from "./ConversationsBox.vue?vue&type=template&id=70adbf32&"
import script from "./ConversationsBox.vue?vue&type=script&lang=js&"
export * from "./ConversationsBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports