<template>
    <div id="invite-user" class="modal">
        <div class="modal-dialog">
            <div class="modal-content px-5 py-3">
                <div class="modal-header justify-content-center border-0">
                    <h3 class="modal-title text-primary">{{ $t('invite-user') }}</h3>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group text-left">
                            <label for="email-invite" class="col-form-label">{{ $t('email-adress') }} <strong class="obligatory">*</strong></label>
                            <input
                                id="email-invite"
                                name="email"
                                type="email"
                                class="form-control"
                                v-model="newUser.email"
                                v-validate="'required'"
                                :class="{ 'is-invalid': submitted && errors.has('email') }"
                                :data-vv-as="$t('email-adress')"
                                @change="disabled = false"
                            >
                            <div class="invalid-feedback" v-if="submitted">
                                {{ errors.first('email') }}
                            </div>
                        </div>
                        <div class="form-group text-left">
                            <label for="first-name-invite" class="col-form-label">{{ $t('first-name') }} <strong class="obligatory">*</strong></label>
                            <input
                                id="first-name-invite"
                                name="first-name"
                                type="text"
                                class="form-control"
                                v-model="newUser['first-name']"
                                v-validate="'required'"
                                :class="{ 'is-invalid': submitted && errors.has('first-name') }"
                                :data-vv-as="$t('first-name')"
                                @change="disabled = false"
                            >
                            <div class="invalid-feedback" v-if="submitted">
                                {{ errors.first('first-name') }}
                            </div>
                        </div>
                        <div class="form-group text-left">
                            <label for="last-name-invite" class="col-form-label">{{ $t('last-name') }} <strong class="obligatory">*</strong></label>
                            <input
                                id="last-name-invite"
                                name="last-name"
                                type="text"
                                class="form-control"
                                v-model="newUser['last-name']"
                                v-validate="'required'"
                                :class="{ 'is-invalid': submitted && errors.has('last-name') }"
                                :data-vv-as="$t('last-name')"
                                @change="disabled = false"
                            >
                            <div class="invalid-feedback" v-if="submitted">
                                {{ errors.first('last-name') }}
                            </div>
                        </div>
                        <div class="form-group text-left">
                            <label for="invite-role">{{ $t('role') }}</label>
                            <select class="form-control px-2" v-model="newUser['is-admin']" @change="disabled = false">
                                <option :value="true">{{ $t('admin') }}</option>
                                <option :value="false">{{ $t('user') }}</option>
                            </select>
                        </div>
                        <div class="form-group text-left">
                            <label for="invite-language">{{ $t('language') }}</label>
                            <select class="form-control px-2" v-model="newUser.language" @change="disabled = false">
                                <option value="fr">{{ $t('french') }}</option>
                                <option value="en">{{ $t('english') }}</option>
                            </select>
                        </div>
                    </form>
                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn text-btn style-btn" data-dismiss="modal" ref="closeButton" @click="cancelInvite()">{{ $t('cancel') }}</button>
                    <button type="button" class="btn btn-primary rounded-pill invite-btn style-btn" :disabled="disabled" @click="inviteUser()">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" :disabled="disabled" v-if="loading"></span>
                        {{ $t('invite') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import HttpStatus  from 'http-status-codes';

// Internal services js
import Alert from '@/helpers/Alert.js';

// External libraries
import { mapState, mapActions } from 'vuex';

export default {
    data () {
        return {
            newUser: {
                'email':      '',
                'last-name':  '',
                'first-name': '',
                'language':   'fr',
                'is-enabled': true,
                'is-admin':   false
            },
            submitted: false,
            loading:   false,
            disabled:  true
        };
    },

    computed: {
        ...mapState('core/tenants', [
            'selectedTenant'
        ])
    },

    methods: {
        ...mapActions('core/users', [
            'create'
        ]),

        /**
         * Invite a new user
         *
         * @return     void
         */
        inviteUser () {
            Vue.prototype.$validation = this.$validator;
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (!valid) {
                    return;
                }

                // Start loading and disable button while loading
                this.loading  = true;
                this.disabled = true;

                // Send the new informations to the api
                this.create({
                    tenantId:   this.selectedTenant.id,
                    attributes: this.newUser
                })
                    .then(() => {
                        this.loading   = false;
                        this.submitted = false;

                        // Show the success alert and close the model
                        Alert.success(this.$i18n.t('user-invited'));
                        this.cancelInvite();
                        this.$refs.closeButton.click();
                    })
                    .catch((error) => {
                        if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                            Alert.fail(this.$i18n.t(error.response.data.errors[0].detail));
                        }
                        this.loading  = false;
                        this.disabled = false;
                    });
            });
        },

        /**
         * Cancel invite
         *
         * @return     void
         */
        cancelInvite () {
            this.newUser = {
                'email':      '',
                'last-name':  '',
                'first-name': '',
                'language':   'fr',
                'is-enabled': true,
                'is-admin':   false
            };
            this.submitted = false;
            this.loading   = false;
        },
    }
};
</script>
