<template>
    <div class="d-flex align-items-stretch" id="page-enter-numbr">
        <div class="container-fluid main-content pt-0 col-10">
            <template v-if="showActivateNumber">
                <div class="sms-page config-phoneNumber card-info">
                    <div class="text-left mb-4">
                        <h5>{{$t('Enter-number-activate')}}</h5>
                    </div>
                    <div class="px-2">
                        <div class="row mb-3">
                            <div class="col-md-6 col-12 text-left">
                                <input id="phone-number" name="phone-number"
                                       type="text" class="form-control"
                                       v-model="phoneNumber" v-validate="'required|valid_phone'"
                                       :disabled="loading"
                                       :data-vv-as="$t('phone-number')">
                                <span class="field-name">{{$t('phone-number')}}</span>
                            </div>
                            <div class="col text-center mt-2"  v-if="!validNumber">
                                <span class="text-invalid-number">{{$t('invalid-number')}}</span>
                            </div>
                            <div class="col text-right pl-5">
                                <button type="button" class="btn btn-primary rounded-pill check-btn btn-style"
                                    @click="onClickCheckNumber()" :disabled="loading || activating">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                                    {{ $t('check') }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="spinner-border d-none" role="status" v-if="loading">
                        <span class="sr-only">{{ $t('Loading...') }}</span>
                    </div>
                </div>

                <!-- Remote available numbers -->
                <div class="sms-page config-phoneNumber card-info" v-if="remoteNumber || loadingRemoteNumbers">
                    <div class="text-left my-4">
                        <h5>{{$t('number-invalid-valid-numbers')}}</h5>
                    </div>
                    <div class="spinner-border" role="status" v-if="loadingRemoteNumbers === true">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="remote-numbers-table" v-else>
                        <div class="number-table">
                            <table class="table table-striped table-bordered">
                                <thead>
                                <tr>
                                    <th scope="col" class="text-right w-25">{{$t('phone-number')}}</th>
                                    <th scope="col" class="text-left w-50">{{$t('description')}}</th>
                                    <th scope="col" class="w-25"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="number in remoteNumbers" :key="number.id">
                                    <td class="text-right">
                                        <p class="text-table">{{number.attributes.friendlyName}}</p>
                                    </td>
                                    <td class="text-left">
                                        <p class="text-table">{{number.attributes.phoneNumber}}</p>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary btn-active btn-style" @click="chooseNumber(number.attributes.phoneNumber)"
                                            :disabled="activating">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                                v-if="activating && (activatingPhone === number.attributes.phoneNumber)"></span>
                                            {{$t('activate')}}
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </template>
            <SmsActivateNumber v-else/>
        </div>
    </div>

</template>

<script>

import { EventBus }      from '@/helpers/EventBus.js';
import SmsActivateNumber from './SmsActivateNumber.vue';
import Format            from '@/helpers/Format.js';

import {
    mapActions,
    mapGetters,
    mapState
} from 'vuex';

export default {
    components: { SmsActivateNumber },

    data () {
        const REMOTE_NUMBER_LIMIT = 3;
        const STARTS_WITH_FROM   = 2;
        const STARTS_WITH_TO     = 5;

        return {
            loading:              false,
            loadingRemoteNumbers: false,
            activating:           false,
            activatingPhone:      null,
            submitted:            false,
            validNumber:          true,
            showActivateNumber:   true,
            phoneNumber:          '',
            remoteNumber:         false,
            remoteNumberLimit:    REMOTE_NUMBER_LIMIT,
            startsWithFrom:       STARTS_WITH_FROM,
            startsWithTo:         STARTS_WITH_TO
        };
    },

    mounted () {
        if (this.numberToActivate) {
            this.showActivateNumber = false;
        }
        EventBus.$on('start-over', () => {
            this.showActivateNumber = true;
        });
    },

    computed: {
        ...mapGetters('core/number', ['numberToActivate']),

        // Getter for the selected provider
        ...mapState('core/providers', [
            'selectedProvider'
        ]),

        // State with list of remote numbers
        ...mapState('core/remote-phone-number', [
            'remoteNumbers'
        ])
    },

    methods: {
        // Method get list of remote numbers
        ...mapActions('core/remote-phone-number', { remoteNumbersList: 'index' }),

        ...mapActions('core/number', [
            'configureTelnyx'
        ]),

        // Method activate number
        ...mapActions('core/number', { activateNumber: 'create' }),

        /**
         * Get remote number parameter from
         *
         * @return   {object}    The filters parameter
         */
        getFiltersParameter () {
            return {
                'country-code': 'CA',
                'starts-with':  this.phoneNumber.substring(this.startsWithFrom, this.startsWithTo),
                'limit':        this.remoteNumberLimit
            };
        },

        /**
         * Check the format of the phone number
         * and validate number with Telnyx
         */
        onClickCheckNumber () {
            this.loading = true;
            this.submitted = true;
            this.validNumber = true;

            // Normalize mobile phone numbers into E.164 format
            this.phoneNumber = Format.phone(this.phoneNumber).phoneNumber;

            this.$validator.validate().then(valid => {
                if (!valid) {
                    this.loading = false;
                    this.validNumber = false;
                    return;
                }
                this.configureTelnyx({
                    phoneNumber: this.phoneNumber
                })
                    .then(() => {
                        this.submitted = false;
                        this.loading = false;
                        this.validNumber = true;
                        this.showActivateNumber = false;
                    })
                    .catch(() => {
                        this.validNumber = false;
                        this.loading = false;
                        this.loadRemoteNumbers();
                    });
            });
        },

        /**
         * Load Telnyx available remote phone numbers to activate
         */
        loadRemoteNumbers () {
            this.loadingRemoteNumbers = true;
            const provider = this.selectedProvider.attributes.name.toLowerCase();
            this.remoteNumbersList({
                provider,
                filters: this.getFiltersParameter()
            })
                .then(() => {
                    this.loading = false;
                    this.remoteNumber = true;
                    this.loadingRemoteNumbers = false;
                });
        },

        /**
         * Choose Remote number as active number
         *
         * @param   {string}   number    The remote number selected.
         */
        chooseNumber (number) {
            this.activating = true;
            this.activatingPhone = number;
            this.activateNumber({
                phoneNumber:  number,
                providerId:   this.selectedProvider.id,
                providerType: this.selectedProvider.type
            })
                .then(() => {
                    this.$router.go(this.$router.currentRoute);
                });
        }
    }
};
</script>
