<template>
    <div class="import-card">
        <div id="import-user" class="modal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header justify-content-center border-0">
                        <h3 class="modal-title text-primary">{{$t('import-users-list')}}</h3>
                    </div>
                    <div class="modal-body">
                        <form class="user-information">
                            <div class="container">
                                <div class="sample-excel mb-3">
                                    <a class="text-left" href="#" @click="downloadFile()">
                                        <font-awesome-icon  class="fb-reaction mr-2" :icon="['far', 'file-excel']" />{{ $t('use-format-sample-excel') }}
                                    </a>
                                </div>
                                <div class="form-group import-file pt-2 mb-1">
                                    <label for="users-file" class="import-label">
                                        <font-awesome-icon  class="fb-reaction mr-2" :icon="['fas', 'download']" />
                                        {{ $t('select-your-list') }}
                                    </label><br>
                                    <div class="custom-file d-flex justify-content-center">
                                        <div class="d-flex align-self-center">
                                            <label for="users-file" class="m-0 align-self-center pointer fs-16 text-grey-9"> {{ fileChosen }} </label>
                                            <font-awesome-icon class="text-danger align-self-center pointer ml-3" :icon="['fas', 'xmark']" @click="removeFile()" v-if="fileExists"/>
                                        </div>
                                        <input
                                            type="file"
                                            id="users-file"
                                            ref="file"
                                            accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            :data-choose="$t('choose-file')"
                                            v-on:change="onChangeFileUpload()"
                                            hidden
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer border-0 px-0 justify-content-center">
                        <button type="button" class="btn text-btn" data-dismiss="modal" id="close" ref="closeButton">{{ $t('cancel') }}</button>
                        <button type="button" class="btn btn-primary rounded-pill" @click="uploadFile()" :disabled="!fileExists">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                            {{ $t('import') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal services js
import { EventBus } from '@/helpers/EventBus.js';
import Alert        from '@/helpers/Alert.js';
import ImportMixin from '@/core/mixins/ImportMixin';
import HttpStatus   from 'http-status-codes';

// External libraries
import { mapActions } from 'vuex';

export default {
    mixins: [ImportMixin],
    data () {
        return {
            loading:    false,
            fileChosen: this.$t('no-file-chosen'),
            fileExists: false,
        };
    },

    methods: {
        /**
         * Methods for users API request
         *
         * template     get downloaf excel template file
         * import       API request for import of users with excel
         */
        ...mapActions('core/users', [
            'template',
            'import'
        ]),

        /**
         * Upload the File.
         */
        uploadFile () {
            if (!this.$refs.file.files[0]) {
                return;
            }

            // Start loading
            this.loading = true;

            // Upload the file selected by user.
            this.import({
                file: this.$refs.file.files[0]
            })
                .then((response) => {
                    let nbUsersImported = response.data.data.success;
                    let nbUsersFailed = response.data.data.fail;
                    let nbUsersTotal = response.data.data.total;
                    if (nbUsersImported == nbUsersTotal) {
                        Alert.success(`${nbUsersImported} ${this.$i18n.tc('user-imported', nbUsersImported)}`);
                    } else if (nbUsersFailed == nbUsersTotal) {
                        Alert.fail(`${nbUsersFailed} ${this.$i18n.tc('user-not-imported', nbUsersFailed)}`);
                    } else {
                        Alert.fail(`${nbUsersImported}  ${this.$i18n.tc('user-imported', nbUsersImported)}, ${this.$i18n.t('but')} ${nbUsersFailed} ${this.$i18n.tc('user-not-imported', nbUsersFailed)}`);
                    }

                    this.removeFile();
                    EventBus.$emit('user-imported');
                })
                .catch((error) => {
                    this.removeFile();
                    if (error.response.status == HttpStatus.UNPROCESSABLE_ENTITY) {
                        Alert.fail(error.response.data.errors[0].detail);
                    } else {
                        Alert.fail(this.$i18n.t('user-import-error'));
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.$refs.closeButton.click();
                });
        }
    }
};

</script>
