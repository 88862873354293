<template>
    <div id="tenants-list" class="container-fluid main-content">
        <div class="row pr-3 pl-2 mb-4">
            <div class="col-sm-12 d-flex justify-content-center">
                <h3>{{ $t('your-tenants') }} (<span class="text-primary">{{ tenants !== null ? Object.values(this.tenants).length : 0 }}</span>)</h3>
            </div>
            <div class="col-sm">
                <p>{{ $t('chose-tenant') }}</p>
            </div>
        </div>
        <div class="row pr-3 pl-2 mb-5">
            <div class="col-sm-2 text-left">
                <router-link :to="{ name: `cocoapp-wordpress-packages:${this.$i18n.locale}` }" target="_blank">
                    <button class="btn btn-primary rounded-pill px-4">{{ $t('add') }}</button>
                </router-link>
            </div>
            <form class="form-inline col-sm">
                <div class="d-flex w-100 justify-content-end">
                    <label class="mr-3">
                        {{ $t('search-tenants') }}
                    </label>
                    <input
                        type="text"
                        class="form-control w-50"
                        v-bind:placeholder="$t('search-contact')"
                        v-on:keyup="searchTenant()"
                        v-model="search">
                </div>
            </form>
        </div>
        <div class="row px-3">
            <div class="col-sm-1 image-table-holder"></div>
            <div class="col-sm text-left">
                <p class="ml-2">{{ $t('tenant-name') }}</p>
            </div>
            <div class="col-sm text-left">
                <p class="ml-5">{{ $t('status') }}</p>
            </div>
            <div class="col-sm text-left">
                <p>{{ $t('plan') }}</p>
            </div>
            <div class="col-sm text-left">
                <p>{{ $t('connectors') }}</p>
            </div>
        </div>
        <preloader-placeholder :loading="loading" :resultlength="tenants !== null ? Object.values(tenants).length : 0" :type="'tenant'"></preloader-placeholder>
        <div class="box-wrap px-2" v-if="loading === false">
            <tenant-picker
                ref="tenants"
            ></tenant-picker>
            <tenant-box
                v-for="tenant in tenants"
                :key="tenant.id"
                v-bind:tenant="tenant"
                v-bind:tenantId="tenant.id"
                v-on:choose-tenant="$refs.tenants.chooseTenant($event)"
            ></tenant-box>
        </div>
    </div>
</template>
<script>

// Components
import TenantPicker         from './TenantPicker.vue';
import TenantBox            from './TenantBox.vue';
import PreloaderPlaceholder from './PreloaderPlaceholder.vue';

// Import external libraries
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        TenantBox,
        TenantPicker,
        PreloaderPlaceholder
    },

    props: ['collapseSidebar'],

    data () {
        return {
            search:  '',
            loading: false,
            userId:  null
        };
    },

    mounted () {
        this.index();
    },

    computed: {

        /**
         * State of user
         *
         * user    State of the logged in user
         */
        ...mapState('core/auth', [
            'user'
        ]),

        /**
         * State of tenants
         *
         * selectedTenant    State of the tenant the user has selected
         * tenants           State with list of all tenants linked to user
         */
        ...mapState('core/tenants', {
            selectedTenant: 'selectedTenant',
            tenants:        'userTenants'
        })
    },

    methods: {
        /**
         * Methods to list tenants linked to logged in user
         *
         * tenantsList     API request to list all tenants linked to logged in user
         */
        ...mapActions('core/tenants', {
            tenantsList: 'tenants'
        }),

        /**
         * Get list of tenants
         *
         * in no parameter in index() return all tenants linked to user
         *
         * @return    {object}    all the tenants in the list linked to the user
         */
        index () {
            this.loading = true;
            this.tenantsList({
                sort: 'enabled'
            })
                .then(() => {
                    this.loading = false;
                });
        },

        /**
         * Filter tenants by search query
         *
         * @param    {string}    User id     the user id
         * @param    {string}    search      text to be searched
         *
         * @retun    list of tenants filtered by the name search
         */
        searchTenant () {
            this.loading = true;
            var filters  = null;

            if (this.search !== '') {
                filters = {
                    'name': this.search
                };
            }

            const searchDelay = 1000;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.tenantsList({
                    sort: 'enabled',
                    filters
                })
                    .then(() => {
                        this.loading = false;
                    });
            }, searchDelay);
        }

    }
};
</script>
