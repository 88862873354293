<template>
    <p class="mb-0 shortName-letters">{{ shortName }}</p>
</template>
<script>
export default {
    props: [
        'model'
    ],
    computed: {
        /**
         * Get the first letter of an attribute
         */
        letterUppercase () {
            return (number, AttributeName) => this.model.attributes[AttributeName]?.charAt(number)?.toUpperCase();
        },

        // Create a short name for this model.
        shortName () {
            let shortNameValue = null;
            switch (this.model.type) {
                case 'tenants':
                    if (this.model.attributes.name) {
                        shortNameValue = `${this.letterUppercase(0, 'name')}${this.letterUppercase(1, 'name')}`;
                    }
                    break;
                case 'contacts':
                case 'users':
                    if (this.model.attributes['first-name'] && this.model.attributes['last-name']) {
                        shortNameValue = `${this.letterUppercase(0, 'first-name')}${this.letterUppercase(0, 'last-name')}`;
                    } else if (this.model.attributes['first-name']) {
                        shortNameValue = `${this.letterUppercase(0, 'first-name')}${this.letterUppercase(1, 'first-name')}`;
                    } else if (this.model.attributes['last-name']) {
                        shortNameValue = `${this.letterUppercase(0, 'last-name')}${this.letterUppercase(1, 'last-name')}`;
                    } else if (this.model.attributes['facebook-name']) {
                        shortNameValue = `${this.letterUppercase(0, 'name')}${this.letterUppercase(1, 'name')}`;
                    } else {
                        shortNameValue = '#';
                    }
                    break;
                default:
                    if (this.model.attributes['first-name']) {
                        shortNameValue = `${this.letterUppercase(0, 'first-name')}${this.letterUppercase(0, 'last-name')}`;
                    }
            }
            return shortNameValue;
        }
    }
};
</script>
