<template>
    <div id="content-page-setup-connector" class="p-2">
        <SmsBlocTabsSteps
            :showOngletsSteps="showOngletsSteps"
            :nbrOfSteps="nbrOfSteps"
            :stepCurrent="stepCurrent"
            @nextStepTriggered="onClickStepNbr">
        </SmsBlocTabsSteps>
        <div class="step-content mr-2">
            <div class="row justify-content-center" id="bloc-steps-config">
                <div class="col-12">
                    <h2 class="title-step my-4">{{$t(detailsStep.name)}}</h2>
                    <p class="descrp-step" v-html="$t(detailsStep.description)" ></p>
                </div>
            </div>
            <div class="row bloc-steps-config-fields" v-for="(details, index) in detailsStep.details" v-bind:key="details.img" :class="`step-folio-${stepCurrent}`">
                <div v-if="details.img!=='' " class="bloc-img-step col-md-6 col-12"
                    :style="{order: 1}">
                    <VueEasyLightbox
                        @hide="handleHide"
                        scrollDisabled escDisabled moveDisabled
                        :index="index"
                        :visible="showLightBoxPic"
                        :ref="details.img"
                        :class="{
                            'svg-screenshot-expl-step': details.extension === 'svg',
                            'img-screenshot-expl-step': details.extension === 'png'
                        }"
                        :imgs="[imgs]">
                    </VueEasyLightbox>
                    <img :class="{'svg-screenshot-expl-step': details.extension ==='svg', 'img-screenshot-expl-step': details.extension ==='png'}"
                                :src="getImgUrl(details.img, details.extension)"
                                :alt="details.img"/>
                    <h4 v-if="stepCurrent == 3 && index == 3" class="repeat-steps-folio">{{ $t('repeat') }}</h4>
                    <div class="text-right" v-if="details.actionImg!== ''">
                        <button class="action-img-step" @click="onclickPicBigger(details.img,details.extension)">
                            {{$t(details.actionImg)}}
                        </button>
                    </div>
                </div>
                <div class="text-left bloc-explain-step"
                    :style="{order:  2}"
                    :class="{'col-md-5 col-12': details.img !== '', 'align-self-center': stepCurrent != 2, 'custom-margin-step-2': stepCurrent == 2}">
                    <h5 v-if="details.title!== ''"
                         class="title-explain-step">
                        {{$i18n.t(details.title)}}
                    </h5>
                    <span v-if="details.description!== ''" class="paragraph-explain-step"
                          v-html="$t(details.description)">
                    </span>
                        <template v-if="details.fields.length > 0">
                        <div class="bloc-inputs" v-for="field in details.fields"
                                v-bind:key="field.name">
                                <p class="title-field" v-if="field.name!== ''">{{$i18n.t(field.name)}}</p>
                                <p class="descrip-field" v-if="field.description!== ''" v-html="$i18n.t(field.description)"></p>
                                <GetApiKeyModal></GetApiKeyModal>
                                <p v-if="field.help!== '' && field.helpType==='modal'"
                                    class="help-field">
                                    <span data-toggle="modal" data-target="#modal-help-get-apiKey">
                                        {{$t(field.help)}}
                                    </span>
                                </p>
                                <p v-else @click="onclickHelpStep(field.help)" class="help-field">{{$t(field.help)}}</p>
                                <button v-if="field.type === 'button'">
                                    {{$t(field.name)}}
                                </button>
                                <div v-else class="bloc-input-config">
                                    <input v-if="field.required"
                                        :ref="field.ref"
                                        :readonly="field.readOnly"
                                        v-bind:placeholder="$i18n.t(field.placeholder)"
                                        v-validate="field.required ? 'required': ''"
                                        :name="field.ref"
                                        v-model="field.model"
                                        class="input-step-config form-control"
                                        :class="{ 'is-invalid': submitted && errors.has(field.ref) }"
                                    />
                                    <input v-else class="input-step-config"
                                        :value="field.content"
                                        :ref="field.ref"
                                        :readonly="field.readOnly"
                                        v-bind:placeholder="$t(field.placeholder)"
                                        :name="field.ref"
                                    />
                                    <button class="btn-copy-input ml-2" v-if="field.action === 'copy'"
                                            :title="$t('copy')"
                                            @click.prevent="onClickCopyContent(field.ref)">
                                        <img class="svg-copy-input"
                                            :title="$t('copy')"
                                            src="@/core/assets/images/copy.svg" :alt="'copy'"/>
                                    </button>
                                </div>
                                <span class="indice-input" v-if="field.indiceInput!== ''">
                                    {{$t(field.indiceInput)}}
                                </span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="bloc-end-step text-right pt-4 pb-4 pr-4" v-if="showBtnNext">
                <button class="btn-next-step" @click.prevent="onClickNextStep">
                    {{getStepInBtn()}}
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

// Import external libraries
import VueEasyLightbox from 'vue-easy-lightbox';

// Import components
import GetApiKeyModal from   '../folio/GetApiKeyModal.vue';
import SmsBlocTabsSteps from '../SmsBlocTabsSteps.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name:       'StepsToAccomplish',
    components: {
        VueEasyLightbox,
        SmsBlocTabsSteps,
        GetApiKeyModal
    },
    emits: ['submitconfigConnector', 'nextStepTriggered'],
    data () {
        return {
            stepCurr:         1,
            submitted:        false,
            visible:          false,
            showLightBoxPic:  false,
            showOngletsSteps: true,
            index:            0,
            imgs:             '',
            loading:          false
        };
    },
    props: {
        stepCurrent: Number,
        detailsStep: Object,
        nbrOfSteps:  Number,
        showBtnNext: Boolean
    },
    watch: {
        stepCurrent () {
            this.stepCurr = this.stepCurrent;
        }
    },
    computed: { ...mapGetters('core/number', ['smsPhoneNumber']) },
    beforeMount () {
        this.stepCurr = this.stepCurrent;
    },
    methods: {
        /**
         * Methods for Providers API request
         *
         * getProviders     Gets Provider list from API
         * selectProvider   Select a provider from the list, place in State
         */
        ...mapActions('core/number', { configureFolio: 'configureFolio' }),

        /**
         * Get the content of the onglet
         *
         * @param  {number}  stepNbr  nbr of step
         * @return {string}  Name of onglet
         */
        getStep (stepNbr) {
            return `${this.$i18n.t('step')} ${stepNbr}`;
        },

        /**
         * Get the content of the btn (btn steps) according to the step activated
         *
         * @return {string}  Name of btn
         */
        getStepInBtn () {
            if (this.stepCurr < this.nbrOfSteps)
            {
                return (`${this.$i18n.t('step')} ${this.stepCurr + 1} >`);
            }
            return (this.$i18n.t('Validate-API-key'));
        },

        /**
         * Get the path of pic
         *
         * @param  {string}  pic  name pic
         * @param  {string}  extension  extension pic
         * @return {string}  path img
         */
        getImgUrl (pic, extension = 'png') {
            return require(`@/core/assets/images/img-steps-folio/${pic}.${extension}`);
        },

        /**
         * Increment the steps and emit the click to the parent component when the onglet is clicked
         *
         * @param  {number}  step
         * @return emit event
         */
        onClickStepNbr (step) {
            this.stepCurr = step;
            this.$emit('nextStepTriggered', this.stepCurr);
        },

        /**
         * Increment the steps and emit the click to the parent component when the btn next step is clicked
         *
         * @return emit event
         */
        onClickNextStep () {
            if (this.stepCurr === 1) {
                this.submitted = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.loading = true;
                        this.configureFolio({
                            domainName: this.detailsStep.details[0].fields[0].model,
                            apiKey:     this.detailsStep.details[1].fields[0].model,
                        }).then(() => {
                            const beginBloc = document.getElementById('content-page-setup-connector');
                            beginBloc.scrollIntoView();
                            this.stepCurr++;
                            this.$emit('nextStepTriggered', this.stepCurr);
                            return this.stepCurr;
                        }).catch((error) => {
                            let payload = {
                                errors: [
                                    {
                                        status: 'fail',
                                        title:  error.response.data.errors[0].detail
                                    }
                                ],
                                status: error.response.data.errors[0].status
                            };
                            this.$emit('submitconfigConnector', payload);
                        })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                });
            }
            else if (this.stepCurr < this.nbrOfSteps) {
                this.submitted = false;
                const beginBloc = document.getElementById('content-page-setup-connector');
                beginBloc.scrollIntoView();
                this.stepCurr++;
                this.$emit('nextStepTriggered', this.stepCurr);
                return this.stepCurr;
            }
            else {
                this.submitted = true;
                let payload = {
                    status:  '200',
                    account: Object.values(this.smsPhoneNumber)[0].attributes['phone-number']
                };
                this.$emit('submitconfigConnector', payload);
            }
        },

        /**
         * Copy the content of an input from it's reference
         *
         * @param  {string}  refInput  ref of the inputInput
         * @return value of the inputn is copied
         */
        onClickCopyContent (refInput) {
            this.$refs[refInput][0].select();
            document.execCommand('copy');
        },

        /**
         * Show the VueEasyLightbox to display img (in a bigger way)
         *
         */
        onclickPicBigger (img, ext) {
            this.imgs = this.getImgUrl(img, ext);
            this.showLightBoxPic = true;
        },

        /**
         * Hide the VueEasyLightbox
         *
         */
        handleHide () {
            this.showLightBoxPic = false;
        }
    }
};
</script>
