export const ADD_CONTACT                            = 'ADD_CONTACT';
export const SET_CONTACTS                           = 'SET_CONTACTS';
export const SET_CONTACTS_META                      = 'SET_CONTACTS_META';
export const SET_MOST_ACTIVE_CONTACTS               = 'SET_MOST_ACTIVE_CONTACTS';
export const SET_NEW_CONTACTS                       = 'SET_NEW_CONTACTS';
export const SET_EXPORT                             = 'SET_EXPORT';
export const SET_SINGLE_CONTACT                     = 'SET_SINGLE_CONTACT';
export const SET_SINGLE_CONTACT_NOTES               = 'SET_SINGLE_CONTACT_NOTES';
export const SET_TEMPLATE                           = 'SET_TEMPLATE';
export const UPDATE_CONTACT                         = 'UPDATE_CONTACT';
export const UPDATE_SINGLE_CONTACT                  = 'UPDATE_SINGLE_CONTACT';
export const UPDATE_CONTACT_PICTURE                 = 'UPDATE_CONTACT_PICTURE';
export const DELETE_CONTACT_PICTURE                 = 'DELETE_CONTACT_PICTURE';
export const ADD_SINGLE_CONTACT_NEW_NOTE            = 'ADD_SINGLE_CONTACT_NEW_NOTE';
export const SET_SINGLE_CONTACT_ALL_INTERACTIONS    = 'SET_SINGLE_CONTACT_ALL_INTERACTIONS';
