import Api       from '@/helpers/Api.js';
import _         from 'underscore';
import normalize from 'json-api-normalizer';
import qs        from 'qs';
import moment    from 'moment';
import Alert     from '@/helpers/Alert.js';
import i18n      from '@/libraries/I18n.js';
import Vue       from 'vue';

// Import mutations
import {
    SET_CONVERSATION,
    APPEND_CONVERSATIONS,
    NEW_MESSAGE_EVENT,
    NEW_CURRENT_CONTACT_DISABLED,
    SET_ACTIVE_CONTACT_ID,
    TERMINATE_CONVERSATION,
    SET_SINGLE_CONTACT_CONVERSATIONS,
    SET_SINGLE_CONTACT_ACTIVE_CONVERSATION,
    RESET_SINGLE_CONTACT_CONVERSATION
} from './mutation-types.js';

const resource = '/connectors/sms/v1';

// Initial state
const state = () => ({
    /**
     *  -----------------------------------------------------
     * | All conversations section (filtred with related to) |
     *  -----------------------------------------------------
     */

    currentUserConversations: {
        conversations: [],
        included:      [],
        meta:          []
    },
    otherUsersConversations: {
        conversations: [],
        included:      [],
        meta:          []
    },
    noUsersConversations: {
        conversations: [],
        included:      [],
        meta:          []
    },

    // Websockets new events
    newMessage:             0,
    currentContactDisabled: 0,

    /**
     *  ---------------------------------------------------
     * | SMS Chat and Current contact conversation section |
     *  ---------------------------------------------------
     */

    activeContactId:           null,
    contactActiveConversation: null,
    contactHasConversations:   false,
    contactConversations:      {
        conversations: [],
        included:      [],
        meta:          []
    },
});

// Getters
const getters = {
    /**
     *  -----------------------------------------------------
     * | All conversations section (filtred with related to) |
     *  -----------------------------------------------------
     */

    /**
     * Get the included contacts.
     *
     * @param      {Object}  state   The state
     *
     * @return     {Object}
     */
    convoContacts: (state) => {
        const totalIncluded = [...state.currentUserConversations.included, ...state.otherUsersConversations.included, ...state.noUsersConversations.included];
        return totalIncluded.filter(include => include.type  === 'contacts') ? totalIncluded.filter(include => include.type  === 'contacts') : [];
    },

    /**
     * Get the included messages.
     *
     * @param      {Object}  state   The state
     *
     * @return     {Object}
     */
    convoMessages: (state) => {
        const totalIncluded = [...state.currentUserConversations.included, ...state.otherUsersConversations.included, ...state.noUsersConversations.included];
        return totalIncluded.filter(include => include.type  === 'messages') ? totalIncluded.filter(include => include.type  === 'messages') : [];
    },

    /**
     * Get the included users.
     *
     * @param      {Object}  state   The state
     *
     * @return     {Object}
     */
    convoUsers: (state) => {
        const totalIncluded = [...state.currentUserConversations.included, ...state.otherUsersConversations.included, ...state.noUsersConversations.included];
        return totalIncluded.filter(include => include.type  === 'users') ? totalIncluded.filter(include => include.type  === 'users') : [];
    },

    /**
     *  ---------------------------------------------------
     * | SMS Chat and Current contact conversation section |
     *  ---------------------------------------------------
     */

    /**
     * Tells if the current contact is subscribed or not to conversations
     *
     * @param      {Object}  state   The state
     *
     * @return     {Boolean}
     *
     */
    isSubscribed: (state) => {
        if (state.contactConversations.contacts) {
            let contact = Object.values(state.contactConversations.contacts)[0];
            return contact.attributes['is-subscribed'];
        }
        return true;
    },

    /**
     * Check if the current user can chat with the current contact,
     * only if the active conversation is assigned to the current user or if it's userless
     *
     * @param      {Object}  state     The state
     * @param      {Object}  getters   The getters
     * @param      {Object}  rootState The state
     *
     * @return     {Boolean}
     */
    currentUserCanChatWithContact: (state, getters, rootState) => {
        if (!rootState.core.auth.user) {
            return false;
        }

        return (state.contactActiveConversation !== null && state.contactActiveConversation.attributes['active-users'].some(id => id === rootState.core.auth.user.id)) ||
            state.contactActiveConversation.attributes['active-users'].length === 0;
    },

    /**
     * Get the current conversation assigned user
     *
     * @param {object} state
     */
    currentConversationUser: (state) => {
        if (state.contactActiveConversation === null ||
            state.contactActiveConversation.attributes['active-users'].length === 0 ||
            !state.contactConversations.users
        ) {
            return null;
        }
        return state.contactConversations.users[state.contactActiveConversation.attributes['active-users'][0]];
    }
};

// Actions

const actions = {
    /**
     *  -----------------------------------------------------
     * | All conversations section (filtred with related to) |
     *  -----------------------------------------------------
     */

    /**
     * List Conversations received to number
     *
     * @param   {Object}  context  The context
     *
     * @return  {Promise}
     */
    index: (context, payload) => {
        const urlParams = { include: 'contact,messages,users' };
        if (payload) {
            urlParams.sort   = payload.sort ? payload.sort : void 0;
            urlParams.filter = payload.filters ? payload.filters : void 0;
            urlParams.page   = payload.page ? payload.page : void 0;
        }

        return new Promise((resolve, reject) => {
            Api.get(`${resource}/conversations`, {
                params:           urlParams,
                paramsSerializer: params => qs.stringify(params, { encode: false })
            })
                .then(response => {
                    if (payload.append === true) {
                        context.commit(APPEND_CONVERSATIONS, {
                            name:     urlParams.filter?.['related-to'],
                            data:     response.data.data,
                            included: response.data.included !== void 0 ? response.data.included : [],
                            meta:     response.data.meta !== void 0 ? response.data.meta : []
                        });

                        return resolve(response);
                    }

                    context.commit(SET_CONVERSATION, {
                        name:     urlParams.filter?.['related-to'],
                        data:     response.data.data,
                        included: response.data.included !== void 0 ? response.data.included : [],
                        meta:     response.data.meta !== void 0 ? response.data.meta : []
                    });

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Update Conversation include messages
     *
     * @param   {Object}  context  The context
     * @param   {String}  tenantId Id of the tenant
     *
     * @return  {Promise}
     */
    update: (context, { tenantId, filters }) => {
        const channelName = `SMS.Connectors.Messages.${tenantId}.all`;
        const currentChannel = window.Echo.private(channelName);

        // Listen to new message received event
        currentChannel.listen('.MessageReceived', () => {
            context.commit(NEW_MESSAGE_EVENT);

            // Update the current contact conversation if exists
            if (context.state.activeContactId) {
                context.dispatch("getContactConversations", {
                    id: context.state.activeContactId,
                    filters
                });
            }
        });

        // Listen to contact updated receiving status event
        currentChannel.listen('.ContactMessageReceivingStatusUpdated', (event) => {
            // Update the current contact conversation if exists
            if (context.state.activeContactId) {
                context.dispatch("getContactConversations", {
                    id: context.state.activeContactId,
                    filters
                });
            }

            // If the current contact no longer subscribed dispatch NEW_CURRENT_CONTACT_DISABLED else dispatch a simple NEW_MESSAGE_EVENT
            if (!event.contact['is-subscribed'] && event.contact['parent-id'] === context.state.activeContactId) {
                context.commit(NEW_CURRENT_CONTACT_DISABLED);
            } else {
                context.commit(NEW_MESSAGE_EVENT);
            }
        });
    },

    /**
     * Stop listening to websockets when we don't need it
     *
     * @param   {String}  tenantId Id of the tenant
     *
     * @return  void
     */
    stopListeningToWebsocket: (context, tenantId) => {
        const channelName = `SMS.Connectors.Messages.${tenantId}.all`;
        const currentChannel = window.Echo.private(channelName);
        currentChannel.stopListening('.MessageReceived');
        currentChannel.stopListening('.ContactMessageReceivingStatusUpdated');
    },

    /**
     * Set the active conversation contact
     */
    setActiveContact: (context, contactId) => {
        context.commit(SET_ACTIVE_CONTACT_ID, contactId);
    },

    /**
     * Terminate a conversation
     *
     * @param      {Object}             context     The context
     * @param      {string|undefined}   payload.id  The conversation identifier
     *
     * @return     {Promise}
     */
    terminateConversation: (context, { id }) => new Promise((resolve, reject) => {
        Api.patch(`${resource}/conversations/${id}`, {
            'data': {
                'type':       'conversations',
                id,
                'attributes': { 'ended_at': moment().format() }
            }
        })
            .then((response) => {
                const normalizedData = normalize(response.data, { camelizeKeys: false });
                context.commit(TERMINATE_CONVERSATION, id);
                Alert.success(i18n.t('conversation-terminated'));
                resolve(normalizedData);
            })
            .catch((error) => {
                reject(error);
            });
    }),

    /**
     * Assign a conversation to a specific User
     *
     * @param      {Object}   context         The context
     * @param      {string}   conversationId  The conversation identifier
     * @param      {string}   userId          The user identifier
     *
     * @return     {Promise}
     */
    assignConversation: (context, { conversationId, userId }) => new Promise((resolve, reject) => {
        Api.patch(`${resource}/conversations/${conversationId}/assign`, {
            'data': {
                'type':       'conversations',
                'id':         conversationId,
                'attributes': {
                    'user_id': userId
                }
            }
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    }),

    /**
     * Unassign a conversation to a specific User
     *
     * @param      {Object}   context         The context
     * @param      {string}   conversationId  The conversation identifier
     * @param      {string}   userId          The user identifier
     *
     * @return     {Promise}
     */
    unassignConversation: (context, { conversationId, userId }) => new Promise((resolve, reject) => {
        Api.patch(`${resource}/conversations/${conversationId}/unassign`, {
            'data': {
                'type':       'conversations',
                'id':         conversationId,
                'attributes': {
                    'user_id': userId
                }
            }
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    }),

    /**
     *  ---------------------------------------------------
     * | SMS Chat and Current contact conversation section |
     *  ---------------------------------------------------
     */

    /**
     * Get single contact's conversations.
     *
     * @param      {Object}             context     The context
     * @param      {string|undefined}   payload.id  The contact identifier
     *
     * @return     {Promise}
     */
    getContactConversations: (context, { id, filters }) => {
        return new Promise((resolve, reject) => {
            /**
             * Either get the passed contact identifier
             * or the state active Contact Id
             */
            const contactId = id || context.state.activeContactId;

            Api.get(`${resource}/contacts/${contactId}/conversations`, {
                params: {
                    filter:  filters,
                    include: 'contact,messages,users',
                    sort:    '-created_at'
                },
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_SINGLE_CONTACT_CONVERSATIONS, normalizedData);
                    context.commit(SET_SINGLE_CONTACT_ACTIVE_CONVERSATION, null);
                    for (let conversation of response.data.data) {
                        if (conversation.attributes['is-active']) {
                            context.commit(SET_SINGLE_CONTACT_ACTIVE_CONVERSATION, conversation);
                            break;
                        }
                    }
                    resolve(normalizedData);
                })
                .catch((error) => {
                    context.commit(SET_SINGLE_CONTACT_CONVERSATIONS, {
                        conversations: []
                    });
                    reject(error);
                });
        });
    },

    /**
     * Reset state.singleConversation to null
     */
    resetSingleConversation (context) {
        context.commit(RESET_SINGLE_CONTACT_CONVERSATION);
    }
};

// Mutations
const mutations = {
    [SET_CONVERSATION]: (state, { name, data, included, meta }) => {
        var filtredConversations = {
            'current-user': 'currentUserConversations',
            'other-users':  'otherUsersConversations',
            'no-users':     'noUsersConversations',
        };

        Vue.set(state[filtredConversations[name]], 'conversations', data);
        Vue.set(state[filtredConversations[name]], 'included', included);
        Vue.set(state[filtredConversations[name]], 'meta', meta);
    },
    [APPEND_CONVERSATIONS]: (state, { name, data, included, meta }) => {
        var filtredConversations = {
            'current-user': 'currentUserConversations',
            'other-users':  'otherUsersConversations',
            'no-users':     'noUsersConversations',
        };

        Vue.set(state[filtredConversations[name]], 'conversations', state[filtredConversations[name]].conversations.concat(data));
        Vue.set(state[filtredConversations[name]], 'included', state[filtredConversations[name]].included.concat(included));
        Vue.set(state[filtredConversations[name]], 'meta', meta);
    },
    [TERMINATE_CONVERSATION]: (state, id) => {
        var filtredConversations = ['currentUserConversations', 'otherUsersConversations', 'noUsersConversations'];
        filtredConversations.forEach(list => {
            state[list].conversations = _.reject(state[list].conversations, (conversation) => conversation.id === id);
        });
    },
    [SET_ACTIVE_CONTACT_ID]: (state, { contactId }) => {
        state.activeContactId = contactId;
    },
    [NEW_MESSAGE_EVENT]: (state) => {
        state.newMessage++;
    },
    [NEW_CURRENT_CONTACT_DISABLED]: (state) => {
        state.currentContactDisabled++;
    },

    /**
     *  ---------------------------------------------------
     * | SMS Chat and Current contact conversation section |
     *  ---------------------------------------------------
     */

    [SET_SINGLE_CONTACT_CONVERSATIONS]: (state, payload) => {
        state.contactConversations = {
            conversations: []
        };
        state.contactHasConversations = false;
        if (payload.conversations && Object.values(payload.conversations).length) {
            state.contactConversations = payload;
            state.contactHasConversations = true;
        }
    },
    [SET_SINGLE_CONTACT_ACTIVE_CONVERSATION]: (state, payload) => {
        state.contactActiveConversation = payload;
    },
    [RESET_SINGLE_CONTACT_CONVERSATION]: (state) => {
        state.contactConversations = {
            conversations: []
        };
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
