<template>
    <div id="search-input">
        <div class="text-left d-flex flex-wrap h-100 select form-control w-100" @click="focusInput()" :class="{'is-focused': inputFocused && !disabled, 'border-0 pl-0': disabled}">
            <p class="tag m-0 align-self-center mr-1 my-1" v-for="(contact, index) in selectedContacts" :key="contact.phoneNumber">
                {{ contact.name !== '' ? contact.name : contact.phoneNumber }}
                <span class="ml-1" @click="removeContact(index)" v-if="!disabled">x</span>
            </p>
            <input v-if="!disabled" class="search p-0 my-1" type="text" v-model="search" @keyup.enter="validatePhone" @focusout="focusOutInput" ref="searchInput" @keyup="searchContacts()">
        </div>
        <div class="dropdown" :class="[(search !== '' && options.length > 0 && !invalidPhone) ? 'displayDropdown' : 'hideDropdown']" >
            <div class="d-flex justify-content-center p-4" v-if="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </div>
            <router-link class="dropdown-item" v-for="option in options" :key="option.phoneNumber" @click.native="addContact(option)" :to="{ name : '' }" v-else>
                {{ `${option.name}` }}
                <br v-if="option.name !== ''">
                {{ `${option.phoneNumber}` }}
            </router-link>
        </div>
        <div class="invalid-feedback" v-if="invalidPhone">
            {{ errorMessage }}
        </div>
    </div>
</template>
<script>

// Import external libraries
import { mapActions, mapState } from 'vuex';
import { EventBus }             from '../../../helpers/EventBus.js';
import { phone }                from 'phone';
import Format                   from '@/helpers/Format.js';

export default {
    props: ['defaultList', 'disabled'],

    data () {
        return {
            inputFocused:     false,
            search:           '',
            selectedContacts: [],
            loading:          false,
            invalidPhone:     false,
            errorMessage:     ''
        };
    },

    mounted () {
        EventBus.$on('clear-contact-list', () => {
            this.selectedContacts = [];
            this.search           = '';
            this.invalidPhone     = false;
        });

        if (this.defaultList.length > 0) {
            this.showDefaultList();
        }
    },

    watch: {
        search (value) {
            if (value === '') {
                this.invalidPhone = false;
            }
        }
    },

    computed: {
        ...mapState('core/contacts', [
            'contacts'
        ]),

        options () {
            let data = [];

            Object.values(this.contacts).forEach(element => {
                const name = (element.attributes['first-name'] !== null && element.attributes['last-name'] !== null) ?
                    `${element.attributes['first-name']} ${element.attributes['last-name']}` : '';

                data.push({
                    name,
                    id:          element.id,
                    phoneNumber: element.attributes['phone-number'],
                });
            });

            return data;
        }
    },

    methods: {
        ...mapActions('core/contacts', [
            'index'
        ]),

        /**
         * If the default list props contain contacts show them
         */
        showDefaultList () {
            this.defaultList.forEach(phone => {
                this.addContact(this.options.find(contact => contact.phoneNumber === phone));
            });
        },

        /**
         * Search a contact
         */
        searchContacts () {
            this.loading      = true;
            const searchDelay = 500;

            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.index({
                    filters: {
                        'first-name|last-name|phone-number': this.search === '' ? void 0 : this.search.replace('+', ''),
                        'sms-contact':                       true,
                    }
                })
                    .then(() => {
                        this.loading = false;
                    });
            }, searchDelay);
        },

        /**
         * Focus the input in the search div section
         */
        focusInput () {
            if (this.disabled) {
                return;
            }

            this.inputFocused = true;
            this.$refs.searchInput.focus();
        },

        /**
         * Focus out the input in the search div section
         */
        focusOutInput () {
            if (this.disabled) {
                return;
            }

            this.inputFocused = false;
        },

        /**
         * Validate the phone entered then add it to the liste
         */
        validatePhone () {
            const supportedContriesFormat = [
                'CAN', 'USA'
            ];

            let isValidePhone = supportedContriesFormat.some(countryCode => {
                return phone(this.search, { country: countryCode }).isValid;
            });

            if (isValidePhone) {
                this.invalidPhone = false;

                // Normalize mobile phone numbers into E.164 format (USA or CAN)
                const phone   = Format.phone(this.search).phoneNumber;

                // Get the contact from the list of contacts if exists or create a new one
                let contact = this.options.find(element => element.phoneNumber === phone);
                if (!contact) {
                    contact = {
                        id:          null,
                        name:        phone,
                        phoneNumber: phone
                    };
                }

                this.addContact(contact);
            } else {
                var regex = new RegExp('^[0-9]*$');

                if (this.search !== '' && regex.test(this.search) == true) {
                    this.errorMessage = this.$i18n.t('invalid-phone-number');
                    this.invalidPhone = true;
                } else {
                    this.invalidPhone = false;
                }
            }
        },

        /**
         * Add a new constact to the selected list
         *
         * @param {object}  contact  the contact to add
         */
        addContact (contact) {
            if (!this.selectedContacts.find(element => element.phoneNumber === contact.phoneNumber)) {
                this.selectedContacts.push(contact);
                this.search = '';
                this.$emit('listUpdated', this.selectedContacts);
            } else {
                this.errorMessage = this.$i18n.t('phone-number-exists');
                this.invalidPhone = true;
            }
        },

        /**
         * Remove a contact from the selected list
         *
         * @param {int}  index  the phone number index
         */
        removeContact (index) {
            this.selectedContacts.splice(index, 1);
            this.$emit('listUpdated', this.selectedContacts);
        }
    }
};
</script>

