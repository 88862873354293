<template>
    <div id="tenants-menu-list" class="my-3">
        <popover :name="`tenant-${tenant.id}`" class="test-ts" event="hover" :id="`tenant-${tenant.id}`">
            <p class="mb-0 fs-14 text-left p-2">
                {{ tenant.attributes.name }}
            </p>
        </popover>
        <div class="menu-tenant">
            <a href="#" class="align-self-center" @click="chooseTenant()" v-popover.right="{ name: `tenant-${tenant.id}` }">
                <tenants-avatar :tenant="tenant"></tenants-avatar>
            </a>
        </div>
    </div>
</template>
<script>

// Internal components
import TenantsAvatar  from './TenantsAvatar.vue';

export default {
    props: ['tenant'],

    components: {
        TenantsAvatar
    },

    methods: {
        /**
         * Switch to this selected tenant
         */
        chooseTenant () {
            this.$emit('choose-tenant', this.tenant.id);
        }
    }
};
</script>
