import Vue                           from 'vue';
import App                           from './App.vue';
import i18n                          from './libraries/I18n.js';
import Raters                        from 'vue-rate-it';
import facebookSdk                   from './libraries/FacebookSdk.js';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueObserveVisibility          from 'vue-observe-visibility';
import axios                         from 'axios';
import vuescroll                     from 'vue-scroll';

// Import VueHead to edit html head
import VueHead from 'vue-head';

// Import validation
import validation from './libraries/VeeValidate.js';

// Import Router
import router from './libraries/VueRouter.js';

// Import popver to show information in popup
import Popover from 'vue-js-popover';

// Import vTitle
import vTitle from 'vuejs-title';

// Import the animate
import 'animate.css';

// Import component
import MainMenu         from './core/components/MainMenu.vue';
import TopHeader        from './core/components/header/TopHeader.vue';
import UserEdit         from './core/components/security/user/UserEdit.vue';
import MainNavigation   from './core/components/MainNavigation.vue';
import Badge            from './core/components/header/badge.vue';

// Import mixins
import apiMixing from './mixins/api.mixin.js';

// Import vuex stores
import store from './core/store';

// Init Websocket (laravel-echo)
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster:  'pusher',
    key:          process.env.VUE_APP_PUSHER_APP_KEY,
    cluster:      process.env.VUE_APP_PUSHER_CLUSTER,
    wsHost:       process.env.VUE_APP_PUSHER_WS_HOST,
    wsPort:       6001,
    disableStats: true,
    forceTLS:     true,

    // Only use websockets protocols
    enabledTransports: ['ws', 'wss'],

    // Auth to /broadcasting/auth using axios (cookies with JWT) before each listening event.
    authorizer: (channel) => ({
        authorize: (socketId, callback) => {
            axios.post(`https://${process.env.VUE_APP_PUSHER_WS_HOST}/broadcasting/auth`, {
                'socket_id':    socketId,
                'channel_name': channel.name
            }, { headers: { 'x-app-id': process.env.VUE_APP_PUSHER_APP_ID } })
                .then(response => {
                    callback(false, response.data);
                })
                .catch(error => {
                    callback(true, error);
                });
        }
    })
});

// Bootstrap
require('bootstrap');

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Configuration
require('./config/config.js');

// Sass
import './core/assets/scss/app.scss';

// Font awesome
import { library }     from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faFacebookSquare }  from '@fortawesome/free-brands-svg-icons';
import {
    faEye, faCommentAlt, faPhoneAlt, faMobileAlt, faUsers, faBell, faUserCircle, faReply, faHeart, faLaughSquint,
    faAngry, faSadTear, faSurprise, faHistory, faAt, faCommentDots, faThumbsUp as thumbsUpSolidIcon, faPencilAlt,
    faInfoCircle, faAngleDoubleLeft, faAngleDoubleRight, faBars, faCog, faTimes, faSortDown, faGrinBeam, faGrinHearts,
    faEllipsisV, faTag, faEnvelope, faAngleDown, faAngleUp, faAnglesDown, faDownload, faChevronRight, faChevronLeft, faCheckCircle, faPlusSquare,
    faExclamationTriangle, faComment, faComments, faPaperPlane, faInbox, faUserPlus, faTags, faCaretDown,
    faArrowAltCircleRight, faUpload, faTimesCircle, faBoltLightning, faChartPie, faRepeat
} from '@fortawesome/free-solid-svg-icons';
import {
    faUser, faThumbsUp, faQuestionCircle, faFileExcel, faCalendarAlt
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
    faEye, faCommentAlt, faPhoneAlt, faFacebookF, faCog, faSortDown, faTimes, faEllipsisV, faFacebookSquare, faMobileAlt, faUsers, faBell, faUserCircle, faUser,
    faThumbsUp, thumbsUpSolidIcon, faReply, faHeart, faLaughSquint, faAngry, faSadTear, faSurprise, faCommentDots, faPencilAlt, faQuestionCircle, faHistory, faAt,
    faInfoCircle, faAngleDoubleLeft, faAngleDoubleRight, faGrinBeam, faGrinHearts, faBars, faTag, faEnvelope, faAngleDown, faAngleUp, faCheckCircle, faFileExcel, faDownload,
    faChevronRight, faChevronLeft, faCalendarAlt, faPlusSquare, faExclamationTriangle, faComment, faComments, faPaperPlane, faInbox, faUserPlus, faTags, faCaretDown,
    faArrowAltCircleRight, faUpload, faTimesCircle, faBoltLightning, faChartPie, faRepeat, faAnglesDown
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('image-rating', Raters.ImageRating);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// VueHead
Vue.use(VueHead);

import VueQriously from 'vue-qriously';
Vue.use(VueQriously);

// Popover
Vue.use(Popover, { tooltip: true });

// VTitle
Vue.use(vTitle, { bgColor: '#ffffff', color: '#14866d'  });

// VueObserveVisibility
Vue.use(VueObserveVisibility);

// Vue-scroll
Vue.use(vuescroll, { debounce: 300 });

Vue.config.productionTip = false;

Vue.component('mainmenu', MainMenu);
Vue.component('topheader', TopHeader);
Vue.component('navigation', MainNavigation);
Vue.component('useredit', UserEdit);
Vue.component('badge', Badge);
Vue.mixin(apiMixing);

new Vue({
    i18n,
    store,
    render: h => h(App),
    router,
    facebookSdk,
    validation
}).$mount('#app');
