import Vue       from 'vue';
import { phone } from 'phone';

// We will use VeeValidate for the validation of forms.
import fr                         from 'vee-validate/dist/locale/fr';
import VeeValidate, { Validator } from 'vee-validate';

import attributesFr from '@/locales/attributes/fr.json';
import attributesEn from '@/locales/attributes/en.json';

// Add VeeValidate to Vue with attributes dictionnary and default locale to french
Vue.use(VeeValidate, {
    locale:     'fr',
    dictionary: {
        en: { attributes: attributesEn },
        fr: { attributes: attributesFr }
    }
});

// Install and Activate the french locale.
Validator.localize('fr', fr);

// All Validator extends to be used on forms.
const methods = [
    { name: 'verify_password', strongRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&$/*?])(?=.{11,})/ },
];

methods.forEach(method => {
    Validator.extend(method.name, {
        validate: function (value) {
            return method.strongRegex.test(value);
        }
    });
});

// Add phone (country USA and CAN) validation extension
Validator.extend('valid_phone', {
    validate: (value) => {
        const supportedContriesFormat = [
            'CAN', 'USA'
        ];

        return supportedContriesFormat.some(countryCode => {
            return phone(value, { country: countryCode }).isValid;
        });
    }
});
