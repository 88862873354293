import Vue         from 'vue';
import router      from '../libraries/VueRouter.js';
import HttpStatus  from 'http-status-codes';
import Alert       from './Alert.js';
import i18n        from '../libraries/I18n.js';
import coreStore   from '@/core/store';

// A waiting time before redirecting
const waitingTime = 50;

export default {
    /**
     * Check http errors
     *
     * @param     {object}     error      the api error response
     */
    checkHttpErrors (error) {
        switch (error.response.status) {
            case HttpStatus.UNAUTHORIZED:
                // IF we are in login page
                if (router.currentRoute.name === 'login' ||
                    router.currentRoute.name.includes('saml-login') ||
                    router.currentRoute.name.includes('2fa-login')
                ) {
                    if (error.response.config.method === 'post') {
                        Alert.fail(error.response.data.errors[0].detail);
                    }

                    // If there are late response don't show the Alert (session Expired) again.
                    break;
                }

                /**
                 * If session expired after 60 minutes
                 * Clear session (deprecated) and local storage (vuex state)
                 */
                window.localStorage.clear();

                // Redirect to login
                setTimeout(() => {
                    Alert.sessionExpired(i18n.t('login-again'));
                    if (coreStore.state.core.auth.user.attributes['is-saml']) {
                        // redirect the saml user to the sso login url using the previews id-provider id and selected tenant.
                        let url = `${process.env.VUE_APP_APIURL}/saml/${coreStore.state.core.tenants.selectedTenant.id}/${coreStore.state.core.auth.idProviderId}/login`;
                        window.location.replace(url);
                    } else {
                        router.push({ name: 'login' });
                    }
                }, waitingTime);
                break;
            case HttpStatus.UNPROCESSABLE_ENTITY:
                // Check what type of permission for 422 error
                if (error.response.code === 'missing_permission' || error.response.code === 'invalid_facebook_token') {
                    Alert.fail(this.$i18n.t(coreStore.state.core.auth.user.attributes['is-admin'] ? 'add-page-again' : 'admin-been-informed'));
                }

                // Use the backend validation.
                error.response.data.errors.forEach(error => {
                    if (!error.source || !Vue.prototype.$validation) {
                        return;
                    }

                    const fieldName = error.source.pointer.split('/')[3];
                    const field =     Vue.prototype.$validation.fields.find({ name: fieldName });

                    if (!field) {
                        return;
                    }

                    // Add a new validation error using the backend error detail.
                    Vue.prototype.$validation.errors.add({
                        id:    field.id,
                        field: fieldName,
                        msg:   error.detail
                    });

                    field.setFlags({
                        invalid:   true,
                        valid:     false,
                        validated: true
                    });
                });
                break;
            case HttpStatus.NOT_FOUND:
                // Do not go to default, should be treated in the script if needed
                break;
            default:
                Alert.globalError(i18n.t('default-error'));
                break;
        }
    }
};
