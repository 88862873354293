import Api from '@/helpers/Api.js';
import qs  from 'qs';

// Import mutations
import {
    SET_AVAILABLE_REPORTS,
    SET_REPORT_DATA
} from './mutation-types.js';

const resource = '/internal/v1/reports';

// Initial state
const state = () => ({
    // This will contain the available reports IDs
    availableReports: [],

    // For now only sms report
    report: []
});

// Getters
const getters = {
};

// Actions
const actions = {
    /**
     * Get the available reports for the differents connectors from the api
     *
     * @return  {Promise}
     */
    index: (context) => {
        return Api.get(`${resource}`)
            .then(response => {
                context.commit(SET_AVAILABLE_REPORTS, response.data.data.reports);
            });
    },

    /**
     * Get a specific report from the api
     *
     * @return  {Promise}
     */
    show: (context, payload) => {
        const urlParams = {};

        if (payload) {
            urlParams.sort   = payload.sort ? payload.sort : void 0;
            urlParams.filter = payload.filters ? payload.filters : void 0;
        }

        // Init data
        context.commit(SET_REPORT_DATA, []);

        return Api.get(`${resource}/${payload.id}`, {
            params:           urlParams,
            paramsSerializer: params => qs.stringify(params, { encode: false })
        })
            .then(response => {
                context.commit(SET_REPORT_DATA, response.data.data);
            });
    }
};

// Mutations
const mutations = {
    [SET_AVAILABLE_REPORTS]: (state, payload) => {
        state.availableReports = payload;
    },
    [SET_REPORT_DATA]: (state, payload) => {
        state.report = payload;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
