<template>
    <div></div>
</template>
<script>

// External libraries
import { mapState, mapActions } from 'vuex';

export default {
    computed: {
        ...mapState('core/auth', [
            'user',
            'userLink'
        ]),

        ...mapState('core/tenants', [
            'selectedTenant'
        ])
    },

    methods: {
        ...mapActions('core/tenants', {
            selectUserTenant: 'selectUserTenant'
        }),

        /**
         * When chosing a tenant check if user is admin
         *
         * @param    {string}    tenantId     the tenant id
         *
         * @retun    void
         */
        chooseTenant (tenantId) {
            // Return early if already chosen
            if (this.selectedTenant.id === tenantId) {
                return;
            }

            // Select the new tenant
            this.selectUserTenant({
                id: tenantId
            })
                .then(() => {
                    // Redirect user to home page
                    this.$router.push({
                        name: `home:${this.$i18n.locale}`
                    });
                });
        }
    }
};
</script>
