<template>
    <div class="align-items-stretch">
        <badge></badge>
        <div id="forgot-password" class="login-interface">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="login-box p-5">
                        <div class="row">
                            <div class="col-sm mb-4"><img class="img-logo text-center" src="../../../assets/images/logo.png" alt=""></div>
                        </div>
                        <form class="text-left" v-if="emailSent === false">
                            <div class="form-group">
                                <label for="email">{{ $t('email-adress') }}</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="email" id="email"
                                    v-validate="'required|email'"
                                    :class="{ 'is-invalid': submitted && errors.has('email'), 'is-valid': submitted && !errors.has('email') }"
                                    :data-vv-as="$t('email')"
                                    v-model="email"
                                    :disabled="loading === true"
                                >
                                <div class="invalid-feedback" v-if="submitted">
                                    {{errors.first('email')}}
                                </div>
                            </div>
                            <router-link :to="{ name : '' }" class="button-link">
                                <button type="submit" class="btn btn-primary rounded-pill" @click="sendEmail()" :disabled="loading === true">{{ $t('send') }}
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                                </button>
                            </router-link>
                        </form>
                        <div v-if="emailSent === true">
                            <h6 class="text-primary" v-html="$t('email-sent')"></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal services js
import Alert from '@/helpers/Alert.js';

// External libraries
import HttpStatus     from 'http-status-codes';
import { mapActions, mapState } from 'vuex';

export default {
    data () {
        return {
            email:        '',
            errorMessage: '',
            loading:      false,
            submitted:    false,
            emailSent:    false
        };
    },

    computed: {
        ...mapState('core/session', [
            'sessionExists'
        ])
    },

    /**
     * Check function
     * Before creating this page, check if the user is not logged in
     */
    beforeCreate () {
        if (this.sessionExists === true) {
            this.$router.replace({
                name: `facebook-connector:${this.$i18n.locale}`
            });
        }
    },
    methods: {
        ...mapActions('core/auth', [
            'forgotPassword'
        ]),

        /**
         * SentEmail
         * Ajax request to sent user email to the system
         */
        sendEmail () {
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (!valid) {
                    return;
                }

                this.loading = true;
                this.forgotPassword({
                    email: this.email
                })
                    .then(() => {
                        // When Email with Link Reset send to user successfully
                        this.loading   = false;
                        this.emailSent = true;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.submitted = false;

                        if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                            if (error.response.detail) {
                                Alert.fail(error.response.detail);
                                return;
                            }

                            Alert.fail(this.$i18n.t('error-email-not-sent', {
                                email: this.email
                            }));
                        }
                    });
            });
        }
    }
};
</script>
