<template>
    <b-modal modal-class="modal right fade sidebar-modal" id="template-sidebar" hide-footer hide-header>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <div class="w-100 text-left">
                        <h5 class="modal-title ml-3 mt-5" v-if="!isEdit">{{ $t('add-template') }}</h5>
                        <h5 class="modal-title ml-3 mt-5" v-if="isEdit">{{ $t('edit-template') }}</h5>
                    </div>
                </div>
                <div class="modal-body d-flex flex-column justify-content-center px-4">
                    <form>
                        <div class="form-group text-left mb-5">
                            <label for="exampleInputPassword1">{{$t('template-name')}}</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model=templateName
                                v-bind:placeholder="$t('template-name-placeholder')"
                                @keyup="disabled = activeBtn">
                        </div>
                        <div class="form-group text-left">
                            <label for="exampleInputPassword1">{{$t('preset-message')}}</label>
                            <textarea
                                name="note"
                                class="form-control note-box w-100 mb-2"
                                v-model=templateText
                                rows="6"
                                v-bind:placeholder="$t('template-text-placeholder')"
                                @keyup="disabled = activeBtn"
                            ></textarea>
                            <sms-counter :sms="templateText" v-on:limitExceeded="smsLimitExceeded = $event"></sms-counter>
                        </div>
                    </form>
                </div>
                <div class="modal-footer pb-4">
                    <div class="d-flex justify-content-between w-100">
                        <button type="button" class="btn text-btn style-btn" data-dismiss="modal" id="close" @click="cancelTemplate()" ref="closeButton">
                        {{ $t('cancel') }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary rounded-pill style-btn"
                            @click="isEdit ? updateTemplate() : addTemplate()"
                            data-dismiss="modal"
                            :disabled="disabled">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                            {{ $t('save') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>

// Internal componants
import Alert        from '../../helpers/Alert.js';
import SmsCounter   from '@/connectors/sms/components/SmsCounter.vue';

// Import external libraries
import { mapActions, mapState } from 'vuex';
import _                        from 'underscore';
const clone = require('rfdc')();

export default {
    props: ['isEdit'],

    components: {
        SmsCounter
    },

    data () {
        return {
            templateId:            '',
            templateName:          '',
            templateNameTemp:      '',
            templateText:          '',
            templateTextTemp:      '',
            templateUpdatableData: {},
            loading:               false,
            disabled:              true,
            smsLimitExceeded:      false
        };
    },
    computed: {
        ...mapState('core/templates', {
            templates:        'templates',
            selectedTemplate: 'selectedTemplate'
        }),

        // Validate form to activate submit form
        activeBtn () {
            if (this.isEdit) {
                return this.templateText === this.templateTextTemp && this.templateName === this.templateNameTemp;
            } else {
                return this.templateText === '' || this.templateName === '' || this.smsLimitExceeded;
            }
        },
    },
    watch: {
        /**
         * selected template watcher
         *
         * @param      {Object}  value   The value
         */
        selectedTemplate: function (value) {
            this.templateName = clone(value.attributes.name);
            this.templateText = clone(value.attributes.content);

            // Put Data on temporary variable
            this.templateNameTemp = this.templateName;
            this.templateTextTemp = this.templateText;
        }
    },

    methods: {
        ...mapActions('core/templates', {
            getTemplate:    'index',
            createTemplate: 'create',
            patchTemplate:  'update',
        }),

        /**
         * create new template
         */
        addTemplate () {
            // If template input is empty
            if (!this.templateName.length || !this.templateText.length) {
                return;
            }

            // Check if the new Template exist
            if (_.find(this.templates, template => template.attributes.name === this.templateName)) {
                Alert.fail(this.$i18n.t('template-create-error'));
                return;
            }

            // Spinner on and disable button
            this.loading  = true;
            this.disabled = true;

            // Create the new Template in db and update template Object
            this.createTemplate({
                name:    this.templateName,
                message: this.templateText
            }).then(() => {
                // Spinner off
                this.loading = false;

                // Empty the input for the template and disable button
                this.templateName = '';
                this.templateText = '';
                this.disabled     = true;
                this.$bvModal.hide('template-sidebar');
                this.getTemplate();
            });
        },

        /**
         * Send request with contact id and attributes to the api
         *
         * @param   {string}   templateId       The id of the tag.
         * @param   {object}   attributes       name and message attributes of the template
         *
         */
        updateTemplate (attributes) {
            this.loading = true;
            attributes = {
                'name':    this.templateName,
                'content': this.templateText
            };

            // Verify if template name already exist
            if (_.filter(this.templates, template => template.id !== this.selectedTemplate.id).find(template => template.attributes.name === attributes.name)) {
                Alert.fail(this.$i18n.t('template-create-error'));
                this.loading = false;
                return;
            }

            // Update template in db
            this.patchTemplate({
                id:         this.selectedTemplate.id,
                attributes: attributes
            })
                .then(() => {
                    this.templateName = '';
                    this.templateText = '';
                    this.loading      = false;
                    this.disabled     = true;
                    this.$bvModal.hide('template-sidebar');
                    Alert.success(this.$i18n.t('information-updated'));
                    this.getTemplate();
                });
        },

        /**
         * Cancel add or edit template
         * Reset values to default
         */
        cancelTemplate () {
            this.loading = false;
            this.templateId   = '';
            this.templateName = '';
            this.templateText = '';
            this.$bvModal.hide('template-sidebar');
        }
    }
};
</script>
