<template>
    <div id="section-steps" class="card-info">
        <h3 class="title title-result">{{$t('number-willBe-active')}}</h3>
        <div class="container section-result mb-3">
            <template v-if="!setUpFinished">
                <div class="row text-left align-items-center mb-2"  v-for="(value, key) in configurationData" :key="key">
                     <span class="fz-2-65" :class="{'mr-3': value === 'failed'}">
                        <font-awesome-icon v-if="value === 'failed'" class="icon icon-error ml-2" :icon="['fas', 'times']" />
                        <img v-else-if="value === 'pending'" class="icon icon-clock" src="@/core/assets/images/pending.svg" alt="celebration" />
                        <font-awesome-icon v-else class="icon icon-check-cercle" :icon="['fas', 'check-circle']" />
                    </span>
                    <div :class="{'paragraph-error': value === 'failed', 'ml-2': key === 'verification', 'ml-3': key !== 'verification'}">
                        <span>{{ getStepName(key) }}</span>
                    </div>
                    <div v-if="key === 'signature' && value === 'pending'" class="bloc-help-step">
                        <p class="paragraph">
                            {{ $t('7days-to-complete-process') }}
                        </p>
                        <button class="btn btn-link text-primary">
                            {{ $t('resend-email') }}
                        </button>
                    </div>
                    <div v-if="key === 'verification' && value === 'pending'" class="ml-5 error-text">
                        <p class="m-0 fs-13">
                            {{ $t('verification-pending') }}
                        </p>
                    </div>
                    <div v-if="key === 'verification' && value === 'failed'" class="ml-5 error-text">
                        <p class="paragraph paragraph-error">
                            {{ $t('error') }} : {{ $t('invalid-property-number') }}
                        </p>
                    </div>
                </div>
            </template>
            <div class="row justify-content-center text-left" v-else>
                <div class="col-lg-5 col-md-6">
                    <div v-for="(value, key) in configurationData" :key="key" class="row align-items-center">
                        <span class="fz-2-65" >
                            <font-awesome-icon class="icon icon-check-cercle" :icon="['fas', 'check-circle']" />
                        </span>
                        <span class="ml-3">{{ getStepName(key) }}</span>
                    </div>
                </div>
                <div class="col-lg-5 col-md-6 text-center justify-content-center">
                    <img class="img-celebration mb-3" alt="celebration"
                         src="@/core/assets/images/celebrationNewNum.png" />
                    <br/>
                    <span class="text-congrats">
                        {{$t('Congrats')}}
                        <br/>
                        {{$t('Account')}} 514-999-8888 {{$t('valid')}}.
                    </span>
                    <div class="mt-4">
                        <button class="btn btn-primary rounded-pill btn-close-afterConfig btn-style"
                                @click.prevent="onClickTestNum">
                            {{$t('test-your-number')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" v-if="!setUpFinished && false">
            <div class="row pl-5">
                <p
                    class="start-over mb-3"
                    @click.prevent="startOver()"
                    >
                    {{ $t('start-over') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>

import { EventBus } from '@/helpers/EventBus.js';
import { mapState, mapGetters } from 'vuex';
export default {
    data () {
        return {
            configurationData: {
                admissibile:    'success',
                smsEligibility: 'success',
                verification:   'success',
                signature:      'success'
            },
            setUpFinished: false
        };
    },
    watch: {
        result: {
            handler (newValue) {
                if (newValue.data.attributes) {
                    this.setUpFinished = true;
                    this.setUpFinished = Object.values(newValue.data.attributes).filter(_x => _x !== 'success').length === 0;
                }
            },
            deep: true
        }
    },
    mounted () {
        this.checkVerificationStatus();
    },
    computed: {
        ...mapState('core/providers', [
            'selectedProvider'
        ]),

        ...mapGetters('core/providers', ['connectedProvider']),

        ...mapGetters('core/number', ['numberToActivate']),
    },
    methods: {
        /**
         * Get name of the step according to the attribute of a result
         *
         * @param  {string}  attribute  attribute to get it's key
         * @return {string}  the correct name of step
         */
        getStepName (attribute) {
            switch (attribute) {
                case 'signature':
                    return this.$i18n.t('Electronic-signature');
                case 'admissibile':
                    return this.$i18n.t('preliminary-eligibility');
                case 'smsEligibility':
                    return this.$i18n.t('SMS-eligibility');
                case 'verification':
                    return this.$i18n.t('number-property');
            }
        },

        /**
         * Check the verification with Telnyx status
         */
        checkVerificationStatus () {
            let config = this.numberToActivate.attributes.config;
            this.configurationData.verification = config['verification_status'] ? config['verification_status'] : 'pending';
        },

        /**
         * Test the number by starting an sms conversation
         */
        onClickTestNum () {
            this.$router.push({ name: `sms-connector:${this.$i18n.locale}` });
        },

        /**
         * Start over all the process of activating an sms number
         */
        startOver () {
            EventBus.$emit('start-over');
        }
    }
};
</script>
