<template>
    <div id="facebook-sidebar" class="sidebar" v-bind:class="{ closeSidebar: !collapseSidebar }">
        <topsidebar></topsidebar>
        <bottomsidebar></bottomsidebar>
    </div>
</template>
<script>

// Components
import FbBottomSidebar from './FbBottomSidebar.vue';
import FbTopSidebar    from './FbTopSidebar.vue';

export default {
    components: {
        'bottomsidebar': FbBottomSidebar,
        'topsidebar':    FbTopSidebar
    },
    props: ['collapseSidebar']
};
</script>
