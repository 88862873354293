<template>
    <div class="container-fluid main-content">
        <SearchNumbers/>
    </div>
</template>

<script>

// Internal components
import SearchNumbers from '../SearchNumbers.vue';
export default {
    components: {
        SearchNumbers
    },
};
</script>
