<template>
    <div id="section-steps" class="card-info">
        <SmsBlocTabsSteps
            :showOngletsSteps="showOngletsSteps"
            :nbrOfSteps="nbrOfSteps"
            :stepCurrent="stepCurrent"
            @nextStepTriggered="onClickStepNbr">
        </SmsBlocTabsSteps>

        <!-- STEP 1 - Form -->
        <template v-if="stepCurrent === 1">
            <h3 class="title">
                {{$t('authorized-user')}}
            </h3>
            <form class="container justify-content-center text-left py-4">
                <div class="row mb-3">
                    <div class="col-5">
                        <input :ref="'firstName'"
                               v-validate="'required'" :name="'first-name'"
                               v-model="AuthorizedUserFormData['first-name']" class="form-control"
                               :class="{ 'is-invalid': submitted && errors.has('first-name')}"
                        />
                        <span class="indice-input text-left">
                            {{$t('first-name')}}*
                        </span>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-5">
                        <input :ref="'lastName'"
                               v-validate="'required'" :name="'last-name'"
                               v-model="AuthorizedUserFormData['last-name']" class="form-control"
                               :class="{ 'is-invalid': submitted && errors.has('last-name')}"
                        />
                        <span class="indice-input text-left">
                            {{$t('last-name')}}*
                        </span>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-3">
                        <input :ref="'phone'"
                               v-validate="'required|valid_phone'" :name="'phone'"
                               v-model="AuthorizedUserFormData['phone']" class="form-control"
                               :class="{ 'is-invalid': submitted && errors.has('phone')}"
                        />
                        <span class="indice-input text-left">
                            {{$t('phone')}}*
                        </span>
                    </div>
                    <div class="col-2">
                        <input :ref="'extension'" :name="'extension'"
                               v-validate="'numeric'"
                               v-model="AuthorizedUserFormData['extension']" class="form-control"
                               :class="{ 'is-invalid': submitted && errors.has('extension')}"
                        />
                        <span class="indice-input text-left">
                            {{$t('extension')}}
                        </span>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <h3 class="title mt-3">{{$t('Address-linked-toNumber')}}</h3>
                    </div>
                    <div class="col-5">
                        <input :ref="'adressLine1'"
                               v-validate="'required'" :name="'address-line-1'"
                               v-model="AuthorizedUserFormData['address-line-1']" class="form-control"
                               :class="{ 'is-invalid': submitted && errors.has('address-line-1')}"
                        />
                        <span class="indice-input text-left">
                            {{$t('address-line-1')}}*
                        </span>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-5">
                        <input :ref="'adressLine2'" :name="'address-line-2'"
                               v-model="AuthorizedUserFormData['address-line-2']" class="form-control"
                               :class="{ 'is-invalid': submitted && errors.has('address-line-2')}"
                        />
                        <span class="indice-input text-left">
                               {{$t('address-line-2')}}
                        </span>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-5">
                        <input :ref="'city'"
                               v-validate="'required'" :name="'city'"
                               v-model="AuthorizedUserFormData['city']" class="form-control"
                               :class="{ 'is-invalid': submitted && errors.has('city')}"
                        />
                        <span class="indice-input text-left">
                            {{$t('city')}}*
                        </span>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-5">
                        <input :ref="'stateProvince'"
                               v-validate="'required'" :name="'state-province'"
                               v-model="AuthorizedUserFormData['state-province']" class="form-control"
                               :class="{ 'is-invalid': submitted && errors.has('state-province')}"
                        />
                        <span class="indice-input text-left">
                            {{$t('state-province')}}*
                        </span>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-5">
                        <input :ref="'country'"
                               v-validate="'required'" :name="'country'"
                               v-model="AuthorizedUserFormData['country']" class="form-control"
                               :class="{ 'is-invalid': submitted && errors.has('country')}"
                        />
                        <span class="indice-input text-left">
                            {{$t('country')}}*
                        </span>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-5">
                        <input :ref="'zipCode'"
                               v-validate="'required'" :name="'zip-code'"
                               v-model="AuthorizedUserFormData['zip-code']" class="form-control"
                               :class="{ 'is-invalid': submitted && errors.has('zip-code')}"
                        />
                        <span class="indice-input text-left">
                            {{$t('zip-code')}}*
                        </span>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <h3 class="title mt-3">{{$t('Information-about-number')}} {{ numberToActivate.attributes['phone-number'] }}</h3>
                    </div>
                    <div class="col-5">
                        <input :ref="'provider-name'"
                               v-validate="'required'" :name="'provider-name'"
                               v-model="AuthorizedUserFormData['provider-name']" class="form-control"
                               :class="{ 'is-invalid': submitted && errors.has('provider-name')}"
                        />
                        <span class="indice-input text-left">
                            {{$t('provider-name')}}*
                        </span>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-5">
                        <input :ref="'account-number'"
                               v-validate="'required'" :name="'account-number'"
                               v-model="AuthorizedUserFormData['account-number']" class="form-control"
                               :class="{ 'is-invalid': submitted && errors.has('account-number')}"
                        />
                        <span class="indice-input text-left">
                            {{$t('account-number')}}*
                        </span>
                        <div class="invalid-feedback">
                            {{ errors.first('account-number') }}
                        </div>
                        <div class="text-right pt-4 pb-2">
                            <button class="btn btn-primary rounded-pill btn-next-step btn-style"
                                @click.prevent="onClickNextStep">
                                {{$t('next')}}
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    v-if="loading"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </template>

        <!-- STEP 2 - Upload PDF -->
        <template v-if="stepCurrent === 2">
            <form>
            <h3 class="title mt-3">{{$t('Confirmation-ownership')}}</h3>
            <div class="container" id="bloc-2-number">
                <div class="d-flex justify-content-center mt-2">
                    <div class="paragraph text-left" :class="'paragraph-'+$i18n.locale"
                        v-html="$t('paragraph-number-step2')"></div>
                </div>
                <div class="row justify-content-center d-flex mt-4" id="upload-bill">
                    <div class="col-md-3 mt-3 text-left">
                        <p class="m-0">
                            {{ this.bill ? this.bill.name : '' }}
                        </p>
                        <input
                            ref="file"
                            type="file"
                            :name="'pdf-bill'"
                            @change="onChangeBillUpload()"
                            accept="application/pdf"
                            class="form-control"
                            :class="{ 'is-invalid': submitted && errors.has('pdf-bill-path')}"
                        />
                        <input type="text" v-validate="'required'" name="pdf-bill-path" ref="filePath" :value="bill" hidden />
                        <span class="indice-input text-left">
                            {{$t('pdf-format-accepted')}}*
                        </span>
                    </div>
                </div>
                <div class="col-12 text-right mt-5">
                    <button class="btn rounded-pill btn-next-step btn-style btn-primary"
                        @click.prevent="onClickNextStep">
                        {{$t('next')}}
                    </button>
                </div>
            </div>
            </form>
        </template>

        <!-- STEP 3 - Signature & Submit -->
        <template v-if="stepCurrent === 3">
            <form>
                <h3 class="title mt-3">{{$t('Electronic-signature')}}</h3>
                <div class="container" id="bloc-3-number">
                    <div class="row justify-content-center">
                        <div class="col-6 my-2">
                            <p class="paragraph">{{ $t('authorise-cocoapp') }}</p>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col my-2">
                            <div class="border-info d-flex justify-content-center">
                                <vueSignature ref="signature" id="signature-bloc"
                                    :sigOption="option" :w="'450px'" :h="'110px'"
                                    :disabled="!(firstStepValid && secondStepValid)"
                                    @endStroke="endStrokeReceived"></vueSignature>
                            </div>
                            <div class="mt-4">
                                <button class="btn btn-link mr-4" @click.prevent="clear">{{ $t('clear') }}</button>
                                <button class="btn btn-link" @click.prevent="save"
                                    :disabled="saveButtonDisabled">{{ $t('save') }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="text-right pb-4 pr-4 bloc-btn-summary">
                        <button class="btn btn-primary rounded-pill btn-next-step btn-style"
                                @click.prevent="onClickSummary"
                                :disabled="!configurationIsValid || loading">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                            {{$t('submit')}}
                        </button>
                    </div>
                </div>
            </form>
        </template>
    </div>
</template>

<script>
import SmsBlocTabsSteps from '@/connectors/sms/components/SmsBlocTabsSteps.vue';
import vueSignature     from 'vue-signature';

import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    components: {
        SmsBlocTabsSteps,
        vueSignature
    },

    emits: ['submitConfigNumber'],

    data () {
        return {
            stepCurrent:            1,
            showOngletsSteps:       true,
            nbrOfSteps:             3,
            AuthorizedUserFormData: {},
            loading:                false,
            codeValidated:          false,
            submitted:              false,
            firstStepValid:         false,
            secondStepValid:        false,
            thirdStepValid:         false,
            saveButtonDisabled:     true,
            signature:              null,
            bill:                   null
        };
    },

    computed: {
        ...mapState('core/auth', [
            'user'
        ]),

        ...mapGetters('core/number', ['numberToActivate']),

        formAttributes () {
            return {
                'bill':           this.bill,
                'first-name':     this.AuthorizedUserFormData['first-name'],
                'last-name':      this.AuthorizedUserFormData['last-name'],
                'email':          this.user.attributes.email,
                'phone-number':   this.AuthorizedUserFormData['phone'],
                'extension':      this.AuthorizedUserFormData['extension'],
                'address-line-1': this.AuthorizedUserFormData['address-line-1'],
                'address-line-2': this.AuthorizedUserFormData['address-line-2'],
                'city':           this.AuthorizedUserFormData['city'],
                'province-state': this.AuthorizedUserFormData['state-province'],
                'country':        this.AuthorizedUserFormData['country'],
                'postal-code':    this.AuthorizedUserFormData['zip-code'],
                'provider-name':  this.AuthorizedUserFormData['provider-name'],
                'account-number': this.AuthorizedUserFormData['account-number'],
                'signature':      this.signature
            };
        },

        configurationIsValid () {
            return this.firstStepValid && this.secondStepValid && this.thirdStepValid;
        },

        option () {
            return {
                penColor:        '#000000',
                backgroundColor: this.firstStepValid && this.secondStepValid ? '#FFFFFF' : '#EEEEEE'
            };
        }
    },

    methods: {
        ...mapActions('core/providers', [
            'configureTelnyxWithFile'
        ]),

        ...mapActions('core/number', {
            'getPhoneNumbers': 'index'
        }),

        /**
         * When the user finish to draw his signature
         */
        endStrokeReceived () {
            this.saveButtonDisabled = false;
        },

        onChangeBillUpload () {
            this.bill = typeof this.$refs.file.files[0] !== 'undefined'
                ? this.$refs.file.files[0]
                : null;
        },

        /**
         * Increment the steps and emit the click to the parent component when the onglet is clicked
         *
         * @param  {number}  step
         * @return emit event
         */
        onClickStepNbr (step) {
            this.stepCurrent = step;
        },

        /**
         * Increment the steps and emit the click to the parent component when the btn next step is clicked
         *
         * @return emit event
         */
        onClickNextStep () {
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (valid) {
                    if (this.stepCurrent < this.nbrOfSteps) {
                        if (this.stepCurrent === 1) {
                            this.firstStepValid = true;
                        }
                        // eslint-disable-next-line no-magic-numbers
                        else if (this.stepCurrent === 2) {
                            this.secondStepValid = true;
                        }
                        // eslint-disable-next-line no-magic-numbers
                        else if (this.stepCurrent === 3) {
                            // Fix a bug when Blob is not being save for the first time
                            this.$refs.signature.clear();
                        }
                        this.stepCurrent++;
                    }
                }
            });
        },

        /**
         * Send the form to configure Telnyx
         */
        onClickSummary () {
            this.loading = true;
            this.configureTelnyxWithFile({
                attributes: this.formAttributes
            })
                .then(() => {
                    this.getPhoneNumbers().then(() => {
                        this.$emit('submitConfigNumber');
                        this.loading = false;
                        this.$bvModal.hide('massive-send-sidebar');
                    });
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        /**
         * Save signature in an intermediate variable
         */
        save () {
            if (!this.$refs.signature.isEmpty()) {
                this.signature = this.convertBase64ToBlob(this.$refs.signature.save("image/jpeg"));
                this.thirdStepValid = true;
            } else {
                this.thirdStepValid = false;
            }
        },

        /**
         * Custom function that convert base64Image to Blob object
         * to be sent to the API
         */
        convertBase64ToBlob (base64Image) {
            // Split into two parts
            const parts = base64Image.split(';base64,');

            // Hold the content type
            const imageType = parts[0].split(':')[1];

            // Decode Base64 string
            const decodedData = window.atob(parts[1]);

            // Create UNIT8ARRAY of size same as row data length
            const uInt8Array = new Uint8Array(decodedData.length);

            // Insert all character code into uInt8Array
            for (let i = 0; i < decodedData.length; ++i) {
                uInt8Array[i] = decodedData.charCodeAt(i);
            }

            // Return BLOB image after conversion
            return new Blob([uInt8Array], { type: imageType });
        },

        /**
         * Clear the signature in order to do it again
         */
        clear () {
            this.$refs.signature.clear();
            this.signature = null;
            this.thirdStepValid = false;
            this.saveButtonDisabled = true;
        }
    }
};
</script>
