import UserLogin from '../core/components/security/user/UserLogin.vue';
import NotFound  from '../core/components/security/errors/NotFound.vue';
import i18n      from '../libraries/I18n.js';

export default [
    {
        path:      '/',
        name:      'login',
        component: UserLogin,
        meta:      {
            title: i18n.t('login')
        }
    },
    {
        path:      '/:pathMatch(.*)*',
        name:      `not-found:${localStorage.getItem('locale')}`,
        component: NotFound,
        meta:      {
            title:  i18n.t('erreur-not-found', 'en'),
            locale: localStorage.getItem('locale')
        }
    },
];
