/**
 * Chargebee Mixin
 *
 * Add methods and common states for Sms chat
 */

// Import external libraries
import { mapState, mapActions } from 'vuex';
import Alert                    from '@/helpers/Alert.js';
import HttpStatus               from 'http-status-codes';

export default {
    data () {
        return {
            subscriptionUpdated: false
        };
    },

    mounted () {
        const element = document.createElement('script');
        element.onload = () => {
            // Initialize Chargebee
            window.Chargebee.init({ site: process.env.VUE_APP_CHARGEBEE_SITE_NAME });
            window.Chargebee.registerAgain();

            // Save the Chargebee instance
            this.chargebeeInstance = window.Chargebee.getInstance();
        };
        element.setAttribute('src', process.env.VUE_APP_CHARGEBEE_SCRIPT_SRC);
        document.body.appendChild(element);
    },

    computed: {
        // State with chargebee portal session
        ...mapState('core/chargebee', { chargebeePortalSession: 'portalSession' }),

        // State with the tenant
        ...mapState('core/tenants', { tenant: 'selectedTenant' })
    },

    methods: {
        // Action to create portal session
        ...mapActions('core/chargebee', [
            'createPortalSession'
        ]),

        /**
         * Opens a Chargebee portal session.
         *
         * @return     {void}
         */
        openPortalSession () {
            // Create the portal session
            this.createPortalSession()
                .then(() => {
                    // Set the portal session into the Chargebee instance
                    this.chargebeeInstance.setPortalSession(this.chargebeePortalSession);

                    // Create a portal and open it
                    this.chargebeePortal = this.chargebeeInstance.createChargebeePortal();
                    this.chargebeePortal.open({
                        subscriptionChanged: () => {
                            this.subscriptionUpdated = true;
                        },
                        close: () => this.closePortalSession()
                    });
                })
                .catch((error) => {
                    if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                        Alert.fail(this.$i18n.t(error.response.data.errors[0].detail));
                    }
                });
        },

        /**
         * Closes a Chargebee portal session (logout).
         *
         * @return     {void}
         */
        closePortalSession () {
            if (this.subscriptionUpdated) {
                Alert.info(this.$t('change-in-progress'), this.$t('change-take-minutes'));
                this.subscriptionUpdated = false;
            }
            this.chargebeeInstance.logout();
        },
    }
};
