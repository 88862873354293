<template>
    <div id="facebook-message" class="container-fluid main-content">
        <div class="row pr-3 pl-2 mb-4">
            <div class="col-sm text-left">
                <h3 class="section-title ">{{ $t('all-messages') }} {{ $t('facebook') }}</h3>
            </div>
        </div>
        <div class="row px-4">
            <div class="col-sm-12 col-xl-6 ml-4 text-left pl-0 d-flex">
            <p v-if="hasActivePage === true">
                {{ `${facebookActivePage.attributes.name} - ${facebookActivePage.attributes.category}`}}
            </p>
            <p v-else>
                {{ $t('no-active-facebook-page') }}
            </p>
            <a
                class="sidebar-arrow ml-auto d-none close-btn text-btn"
                href="#" @click="$emit('hideSidebar')"
                v-if="collapseSidebar">
                {{ $t('close') }}
                <font-awesome-icon :icon="['fas', 'angle-double-right']" />
            </a>
            <a
                class="sidebar-arrow ml-auto d-none open-btn text-btn"
                href="#" @click="$emit('showSidebar')"
                v-bind:class="{ fadeIn: !collapseSidebar }">
                <font-awesome-icon :icon="['fas', 'angle-double-left']" />
            </a>
            </div>
            <div class="col-xl col-sm text-right filter-section pl-0 sort-section">
                <form>
                    <select-facebook-interactions
                        v-if="hasActivePage && isValidAdminToken"
                        v-on:selectOption="onChangeFilterBy($event)">
                    </select-facebook-interactions>
                </form>
            </div>
        </div>
        <preloader-placeholder v-if="isValidAdminToken"
            :loading="loading"
            :resultlength="interactionsFilterBy.length"
            :type="'box'"
            ></preloader-placeholder>
        <div class="box-wrap-facebook-messages box-wrap px-3 facebook-interactions box-wrap-responsive"
            v-if="loading === false && isValidAdminToken" v-scroll="infiniteScroll">
            <div id="interaction" v-for="interaction in interactionsFilterBy" :key="interaction.id" >
                <facebook-message-box
                    v-bind:interaction="interaction"
                    v-bind:activePageId="pageId"
                    v-bind:templates="options"
                ></facebook-message-box>
            </div>
            <preloader-placeholder :loading="loadingMore" resultlength="1" :type="'box'" v-if="isValidAdminToken"></preloader-placeholder>
        </div>
        <div class="mt-5" v-if="hasActivePage && !isValidAdminToken">
            <div class="row">
                <div class="col-1 text-right">
                    <font-awesome-icon class="exclamation-triangle" :icon="['fas', 'exclamation-triangle']"/>
                </div>
                <div class="col-11 text-left">
                    <p>{{ $t('refresh-token', {
                        adminName: facebookActivePage.attributes['admin-name']
                    }) }}</p>
                    <router-link :to="{ name: 'facebook-config' }" class="text-left">{{ $t('learn-more') }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Components
import FacebookMessageBox         from './FacebookMessageBox.vue';
import SelectFacebookInteractions from './SelectFacebookInteractions.vue';
import PreloaderPlaceholder       from '../../../core/components/PreloaderPlaceholder.vue';

// Internal services js
import { RepositoryFactory } from '../../../repositories/repositoryFactory.js';
import Alert                 from '../../../helpers/Alert.js';
import InfiniteScrollMixin    from '@/core/mixins/InfiniteScrollMixin';

// Import external libraries
import { mapState, mapActions } from 'vuex';
import _                                    from 'underscore';

const FacebookPages = RepositoryFactory.get('FacebookPages');

export default {
    props:      ['collapseSidebar'],
    mixins:     [InfiniteScrollMixin],
    components: {
        FacebookMessageBox,
        SelectFacebookInteractions,
        PreloaderPlaceholder
    },
    created () {
        // Get the temaplate
        this.getTemplate()
            .then(() => {
                for (let templateId in this.templatesGetter) {
                    this.options.push({
                        'id':      templateId,
                        'name':    this.templatesGetter[templateId].attributes.name,
                        'content': this.templatesGetter[templateId].attributes.content
                    });
                }
            });
    },

    /**
     * Get the active facebook pages
     */
    mounted () {
        // Start loading from the api.
        this.loading = true;

        // Get the user`s active pages
        FacebookPages.index()
            .then(request => this.showActivePage(request));

        // Check admin token.
        this.checkAdminToken();
    },

    data () {
        return {
            activePage:           [],
            pageId:               '',
            interactions:         [],
            authors:              [],
            primaryPosts:         [],
            interactionsFilterBy: [],
            filterById:           '',
            replyComments:        [],
            pageReactions:        [],
            loading:              false,
            loadingMore:          false,
            options:              []
        };
    },

    computed: {
        ...mapState('core/auth', [
            'user'
        ]),
        ...mapState('core/facebook', [
            'isValidAdminToken',
            'facebookActivePage',
            'hasActivePage'
        ]),
        ...mapState('core/templates', {
            templatesGetter: 'templates'
        })
    },

    methods: {
        ...mapActions('core/templates', {
            getTemplate: 'index',
        }),

        ...mapActions('core/facebook', [
            'setHasActivePage',
            'setIsValidAdminToken'
        ]),

        /**
         * Show alert if the admin page token is invalid.
         *
         * @return    void
         */
        checkAdminToken () {
            if (this.hasActivePage && !this.isValidAdminToken) {
                let link                = '';
                let refreshTokenMessage = this.$i18n.t('refresh-token');
                let contactAdminMessage = this.$i18n.t('contact-admin', { adminName: this.facebookActivePage.attributes['admin-name'] });

                if (this.user.attributes['is-admin'] || this.user.attributes['is-super-admin']) {
                    contactAdminMessage = '';

                    // Resolve the link
                    let route = this.$router.resolve({ name: `facebook-config:${this.$i18n.locale}` });
                    link      = `<a href="${route.href}">${this.$i18n.t('restore-connection')}</a>`;
                }

                Alert.invalidTokenAlert(refreshTokenMessage, contactAdminMessage, link);
            }
        },

        /**
         * Get the active page.
         *
         * @param {object}  request     The api request
         *
         */
        showActivePage (request) {
            if (request.data.data[0] === void 0) {
                this.setHasActivePage({ hasActivePage: false });
                this.activePage = null;
                return;
            }

            this.activePage = request.data.data[0];
            this.pageId = this.activePage.id;
            this.getPageInteractions(this.pageId);

            // Check the page token.
            this.setHasActivePage({ hasActivePage: true });
            this.setIsValidAdminToken({ isValid: this.activePage.attributes['is-remote-access-token-valid'] });
        },

        /**
         * Get all interactions of the active page
         *
         * @param {string}  pageId    The page id
         *
         */
        getPageInteractions (pageId) {
            FacebookPages.getInteractions(pageId)
                .then((request) => this.setInteractions(request));
        },

        /**
         * Set interactions of the active page in interactions table
         * Set authors of interactions in authors table
         *
         * @param  {object}  request    The api request
         * @param  {bool}    append     Append instead of replacing
         */
        setInteractions (request, append) {
            if (request.data.data.length === 0) {
                this.loading = false;
                this.loadingMore = false;
                this.infiniteScrollEnabled = false;
                return;
            }

            this.interactions = request.data.data;
            for (let i = 0; i < request.data.included.length; i++) {
                if (request.data.included[i].type === 'contacts') {
                    this.authors.push(request.data.included[i]);
                } else if (request.data.included[i].type === 'posts') {
                    this.primaryPosts.push(request.data.included[i]);
                } else if (request.data.included[i].type === 'comments') {
                    this.replyComments.push(request.data.included[i]);
                } else {
                    this.pageReactions.push(request.data.included[i]);
                }
            }

            this.cuttingInteractions(this.interactions, append);
        },

        /**
         * Add author and post to interactions table
         *
         * @param   {object}    interactions    The page interactions
         * @param   {bool}      append          Append instead of replacing
         */
        cuttingInteractions (interactions, append) {
            for (let i = 0; i < interactions.length; i++) {
                interactions[i].author = _.find(this.authors, (author) => author.id === interactions[i].relationships.author.data.id);
                interactions[i].post   = _.find(this.primaryPosts, (post) => post.id === interactions[i].relationships.post.data.id);

                if (interactions[i].relationships.parent.data !== null) {
                    interactions[i].parent = _.find(this.replyComments, (parent) => parent.id === interactions[i].relationships.parent.data.id);

                    interactions[i].authorParent = _.find(this.authors, (authorParent) => authorParent.id === interactions[i].parent.relationships.author.data.id);
                }

                if (interactions[i].relationships.reply.data !== null) {
                    interactions[i].reply = _.find(this.replyComments, (reply) => reply.id === interactions[i].relationships.reply.data.id);
                }

                if (interactions[i].relationships['page-reaction'].data !== null) {
                    interactions[i]['page-reaction'] = _.find(this.pageReactions, (reaction) => reaction.id === interactions[i].relationships['page-reaction'].data.id);
                }

                if (typeof append !== 'undefined' && append === true) {
                    this.interactionsFilterBy.push(interactions[i]);
                }
            }

            // Stop the loading and show the interactions
            this.loading     = false;
            this.loadingMore = false;

            if (typeof append === 'undefined' || append === false) {
                this.interactionsFilterBy = interactions;
            }
        },

        /**
         * If the select option change we change the showing interactions
         * event.target.value is the option value (category).
         *
         * @param   {string}    option    The selected option
         */
        onChangeFilterBy (option) {
            this.loading = true;
            this.filterById = option;

            if (this.filterById === 'all') {
                this.interactionsFilterBy = this.interactions;
                this.loading = false;
            } else {
                FacebookPages.getInteractions(this.pageId, {
                    category: this.filterById
                })
                    .then((response) => this.cuttingInteractions(response.data.data));
            }
        },

        /**
         * The infinite scroll end was reached.
         *
         * @param  {int}  pageNumber
         */
        infiniteScrollEndReached (pageNumber) {
            this.loadingMore = true;
            let filters      = null;

            if (this.filterById !== '') {
                filters = { category: this.filterById };
            }

            // Get the next page of interactions
            FacebookPages.getInteractions(this.pageId, filters, { number: pageNumber })
                .then((response) => this.setInteractions(response, true));
        }
    }
};
</script>
