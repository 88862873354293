<template>
    <div id="sms-counter">
        <span class="d-flex flex-column jusify-content-right text-right">
            <popover name="sms-counter" class="sms-counter" :width="335" id="sms-counter-popover" event="hover">
                <p class="mb-0 fs-12" v-html="$t('sms-counter-description')"></p>
            </popover>
            <span class="text-small font-nunito fs-12 text-dark-brown" :class="{'text-danger font-nunito-bold': smsLimitExceeded}">
                {{ remainingCharacters() }}
                {{ smsLimitExceeded ? $tc('extra-characters', remainingCharacters()) : $tc('remaining-characters', remainingCharacters()) }}
                ({{ smsParts() }} {{ $tc('sms-parts', smsParts()) }})
                <font-awesome-icon class="info-tooltip pointer ml-1" :icon="['fas', 'info-circle']" v-popover.left="{ name: 'sms-counter' }"/>
            </span>
        </span>
    </div>
</template>

<script>
import SmsCounter from 'sms-counter';

export default {
    props: ['sms'],

    data () {
        return {
            maxParts:         10,
            smsLimitExceeded: false
        };
    },

    methods: {
        /**
         * Calculate the number of characters remaining
         *
         * @return number
         */
        remainingCharacters () {
            let counter = SmsCounter.count(this.sms);

            if ((counter.per_message * this.maxParts) < counter.length) {
                this.smsLimitExceeded = true;
                this.$emit('limitExceeded', true);

                return counter.length - (counter.per_message * this.maxParts);
            } else if (this.smsLimitExceeded) {
                this.smsLimitExceeded = false;
                this.$emit('limitExceeded', false);
            }

            return (counter.per_message * this.maxParts) - counter.length;
        },

        /**
         * Calculate the number of SMS parts
         *
         * @return number
         */
        smsParts () {
            let counter = SmsCounter.count(this.sms);
            return counter.messages;
        }
    }
};
</script>
