<template>
    <span @click="showModal()" class="utc-link">
        <slot/>
    </span>
</template>
<script>

// Import internal libraries
import Alert from '@/helpers/Alert.js';

export default {
    methods: {
        showModal () {
            Alert.showUtcModal();
        }
    }
};
</script>
