<template>
    <div class="d-flex align-items-stretch">
        <div id="main-menu" class="position-relative">
            <div class="menu">
                <div id="tenant-bar" class="bg-secondary h-100 w-25 position-absolute pt-4 d-flex flex-column"></div>
            </div>
        </div>
        <div class="not-found-content container-fluid main-content">
            <div id="not-found-box" class="d-flex container-fluid not-found-card-info message-card mb-3">
                <div class="row">
                    <div class="col-12 text-center p-4">
                        <h1>
                            {{ $t('erreur-not-found') }}
                        </h1>
                    </div>
                    <div class="col-12">
                        <p>
                            {{ $t('page-not-found') }}
                            <br>
                            <a :href="home"> {{ $t('back-to-messages') }} </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    computed: {
        home () {
            return this.$i18n.locale === 'fr' ? '/accueil' : '/home';
        }
    }
};
</script>
