
<template>
    <div class="facebook-page card-info">
        <div class="text-left">
            <h5>{{$t('configured-page')}}</h5>
        </div>
        <div class="spinner-border" role="status" v-if="!chargedRemotePages">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="page-table">
            <div class="box-wrap-active overflow-auto px-2 note-history">
                <table class="table table-striped table-bordered" v-if="chargedRemotePages">
                    <thead>
                        <tr>
                        <th scope="col" class="text-left">{{$t('page')}}</th>
                        <th scope="col" class="text-left">{{$t('activated-by')}}</th>
                        <th scope="col" class="text-left">{{$t('category')}}</th>
                        <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="page in pagesTable" :key="page.id">
                        <td class="text-left">
                            <p class="text-table">{{page.attributes.name}} </p>
                        </td>
                        <td class="text-left">
                            <p class="text-table">{{page.attributes['admin-name']}} </p>
                        </td>
                        <td class="text-left">
                            {{page.attributes.category}}
                        </td>
                        <td>
                            <button class="btn btn-primary btn-active" @click="confirmAlert(page.id)" :disabled="loading">
                                {{$t('deactivate')}}
                            </button>
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>

// Internal services js
import { RepositoryFactory } from '../../../repositories/repositoryFactory.js';
import Alert                 from '../../../helpers/Alert.js';
import { EventBus }          from '../../../helpers/EventBus.js';

// External libraries
import { mapActions } from 'vuex';

const FacebookPages = RepositoryFactory.get('FacebookPages');

export default {
    /**
     * Get facebook active page
     */
    mounted () {
        this.getActivePages();

        // Listen for the activate page event.
        EventBus.$on('activate-page', () => {
            this.getActivePages();
            this.getConnectors();
        });
    },
    data () {
        return {
            chargedRemotePages: false,
            loading:            false,
            pagesTable:         []
        };
    },
    methods: {
        ...mapActions('core/facebook', [
            'setActivePage',
            'setHasActivePage'
        ]),

        /**
         * Methods to get connectors linked to the account
         *
         * getConnectors get connectors linked to account
         */
        ...mapActions('core/connectors', {
            getConnectors: 'index'
        }),

        /**
         * Get active pages
         *
         * @return    void
         */
        getActivePages () {
            this.chargedRemotePages = false;
            FacebookPages.index()
                .then(request => this.showActivePage(request));
        },

        /**
         * Show active page
         *
         * @param    {object}   request   The api request
         *
         */
        showActivePage (request) {
            if (request.data.data.length > 0) {
                this.pagesTable.push(request.data.data[0]);
            }
            this.chargedRemotePages = true;
        },

        /**
         * Show confirm dialog alert
         *
         * @param    {string}   pageId    The page id
         *
         */
        confirmAlert (pageId) {
            Alert.confirmation(this.$i18n.t('disable-page-confirm-message'), this.$i18n.t('deactivate-page'))
                .then((request) => this.disablePage(request, pageId));
        },

        /**
         * Disable the active page
         *
         * @param    {object}   request   The alert request
         * @param    {string}   pageId    The page id
         *
         * Request.value = true if user confirm
         */
        disablePage (request, pageId) {
            if (request.value) {
                this.loading = true;
                FacebookPages.delete(pageId)
                    .then(() => {
                        this.setActivePage({ activePage: null });
                        this.setHasActivePage({ hasActivePage: false });
                        this.loading = false;
                        this.pagesTable = [];
                        this.getActivePages();

                        EventBus.$emit('deactivate-page');
                    });
            }
        }
    }
};
</script>
