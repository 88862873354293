<template>
    <button @click="continueWithFB()" class="btn fb-btn btn-lg d-flex">
        <font-awesome-icon class="icon text-white fb-icon" :icon="['fab', 'facebook-square']" />
        <p class="fb-text">{{$t('continue-with-facebook')}}</p>
    </button>
</template>
<script>

// Internal services js
import { RepositoryFactory } from '../../../repositories/repositoryFactory.js';
import Alert                 from '../../../helpers/Alert.js';

const FacebookAuth = RepositoryFactory.get('FacebookAuth');
const FacebookPages = RepositoryFactory.get('FacebookPages');

import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions('core/facebook', [
            'setIsValidAdminToken',
            'setActivePage',
            'setHasActivePage'
        ]),

        /**
         * Continue with facebook
         * - Check if user is logged in to facebook
         * - Else show a popup to login to facebook
         * - Sent the access token to the api
         */
        continueWithFB () {
            window.FB.getLoginStatus((response) => {
                if (response.status === 'connected') {
                    this.sendToken(response.authResponse.accessToken);
                } else {
                    this.loginFacebook();
                }
            });
        },

        /**
         * Open pop up login Facebook
         */
        loginFacebook () {
            window.FB.login((response) => {
                if (response.status === 'connected') {
                    this.sendToken(response.authResponse.accessToken);
                } else {
                    Alert.fail(this.$i18n.t('must-login-to-facebook'));
                }
            }, {
                scope: process.env.VUE_APP_FACEBOOK_CONNECTOR_LOGIN_SCOPE
            });
        },

        /**
         * Send access token to api
         *
         * @param    {string}   accessToke    The user's access_token
         *
         */
        sendToken (accessToken) {
            FacebookAuth.post(accessToken)
                .then(() => {
                    FacebookPages.index()
                        .then((request) => {
                            if (request.data.data.length !== 0) {
                                this.setIsValidAdminToken({ isValid: request.data.data.attributes['is-remote-access-token-valid'] });
                                this.setActivePage({ activePage: request.data.data[0] });
                                this.setHasActivePage({ hasActivePage: true });
                                this.$router.push({
                                    name: `facebook-connector:${this.$i18n.locale}`
                                });
                                return;
                            }

                            // If we don't have an active page
                            this.setHasActivePage({ hasActivePage: false });

                            // Force refresh the page
                            this.$router.go(this.$router.currentRoute);
                        });
                })
                .catch(() => {
                    window.FB.logout(() => {
                        this.loginFacebook();
                    });
                });
        }
    }
};
</script>
