import Api       from '@/helpers/Api.js';
import normalize from 'json-api-normalizer';

// Import mutations
import {
    SET_CONNECTORS,
    SET_CONNECTORS_CONNECTION_STATUS
} from './mutation-types.js';

const resource = '/internal/v1/connectors';

// Initial state
const state = () => ({
    connectors: [],
    facebook:   {
        isConnected:   false,
        isEstablished: false
    },
    sms: {
        isConnected:   false,
        isEstablished: false
    }
});

// Getters
const getters = {};

// Actions
const actions = {
    /**
     * Get a list of connectors.
     *
     * @param   {Object}  context  The context
     *
     * @return  {Promise}
     */
    index: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}`)
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_CONNECTORS, normalizedData.connectors);
                    context.commit(SET_CONNECTORS_CONNECTION_STATUS);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {
    [SET_CONNECTORS]: (state, payload) => {
        state.connectors = payload;
    },
    [SET_CONNECTORS_CONNECTION_STATUS]: (state) => {
        Object.values(state.connectors).forEach(connector => {
            const connectorName = connector.attributes.name;
            state[connectorName].isConnected   = connector.attributes['is-connected'];
            state[connectorName].isEstablished = connector.attributes.status === 'established';
        });
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
