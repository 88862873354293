<template>
    <div id="fb-messagebox">
        <div class="container-fluid card-info message-card mb-3 facebook-card-info">
            <div class="row">
                <div class="col-sm-2 col-md-3 col-lg-2 col-xl-1">
                    <div class="connector-icon facebook-connector image-icon position-absolute">
                        <font-awesome-icon class="icon text-white" :icon="['fab', 'facebook-f']" />
                    </div>
                    <contact-avatar :contact="interaction.author" :profilePicture="profilePicture" ref="contactAvatar"></contact-avatar>
                </div>
                <div class="col-sm-10 col-md-9 col-lg-10 col-xl-11 facebook-pill-content">
                    <div class="row">
                        <div class="col-sm col-xl-9 text-left">
                            <router-link @click.native="showContact()" :to="{ name: '' }" class="contact-link">
                                <h6 class="message-title">
                                    {{ interaction.author.attributes['facebook-name'] }}
                                    <small v-if="interaction.author.attributes['facebook-name'] !== interaction.author.attributes.name">
                                        ({{ interaction.author.attributes.name }})
                                    </small>
                                </h6>
                            </router-link>

                        </div>

                        <div class="col-sm custom-checkbox text-right">
                            <input type="checkbox" class="custom-control-input" :id="interaction.id" v-on:click="readInteraction($event)" ref="readToggle" v-model="read" true-value="read" false-value="unread">
                            <label class="custom-control-label read-bloc" :for="interaction.id"><h6 class="read-text" :class="read === 'read' ? 'text-primary' : ''">{{$t('read')}}</h6></label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm text-left date-post-bloc">
                            <h6 class="text-primary mb-0 notification-date" :class="$router.name=`contact:${this.$i18n.locale}` ? 'facebook-notification-date' : 'notification-date'"> {{ moment(interaction.attributes.date).format('YYYY-MM-DD H:mm:ss') }} </h6>
                        </div>
                    </div>
                    <div class="row" v-if="haveParent === false">
                        <div class="col-sm text-left ">
                            <small
                                v-if="interaction.attributes.category === 'post'"
                                class="message-history">
                                <a :href="this.interaction.attributes['url']" class="text-left" target="_blank">{{ $t('new-post') }}</a>
                            </small>
                            <small
                                v-else
                                class="message-history">
                                {{ interaction.attributes.category === 'comment' ? $t('commented-on') : $t('reacted-on') }}
                                <a :href="this.interaction.attributes['url'] " class="text-left" target="_blank">{{ $t('new-post') }}</a>
                                <span class="publication ml-1" v-if="interaction.post.attributes.message !== null && interaction.post.attributes.message !== ''">"{{ shortenedPreview(interaction.post.attributes.message) }}"</span>
                            </small>
                        </div>
                    </div>
                    <div class="row" v-if="haveParent === true">
                        <div class="col-sm text-left ">
                            <small class="message-history text-black-80">{{ $t('replied-on') }} {{ interaction.authorParent.attributes.name }} <span class="publication"> "{{ interaction.parent.attributes.message }}" </span></small><br>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm text-left mt-2" v-if="interaction.attributes.content">
                            <p class="comment-content">{{ interaction.attributes.content }}</p>
                        </div>
                    </div>
                    <facebook-reactions-font
                        v-bind:interaction="interaction">
                    </facebook-reactions-font>
                    <div>
                        <transition name="slide-up">
                            <div class="row" v-if="interaction.attributes.content">
                                <div class="col-sm text-left d-flex">
                                    <div class="reaction-wrapper d-flex">
                                        <font-awesome-icon class="fb-reaction mr-2" :class="isReacted ? '' : 'text-grey'" :icon="['fas', 'thumbs-up']" @click="reactionsController()"/>
                                        <transition name="slide-right">
                                            <div v-if="showReactions === true">
                                                <font-awesome-icon class="fb-reaction mr-2" :icon="reactions.heart" />
                                                <font-awesome-icon class="fb-reaction mr-2" :icon="reactions.haha" />
                                                <font-awesome-icon class="fb-reaction mr-2" :icon="reactions.surprise" />
                                                <font-awesome-icon class="fb-reaction mr-2" :icon="reactions.sad" />
                                                <font-awesome-icon class="fb-reaction mr-2" :icon="reactions.angry" />
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="reaction-wrapper d-flex">
                                        <a href="#" v-if="showReplyButton === true" @click.stop="startReply()">
                                            <font-awesome-icon class="text-grey" :icon="['fas', 'reply']" />
                                        </a>
                                    </div>
                                    <div class="reaction-wrapper d-flex">
                                        <a href="#" v-if="showReplyButton === false && replied === false" @click="disableComment()">
                                            <font-awesome-icon class="text-grey" :icon="['fas', 'xmark']" />
                                        </a>
                                    </div>
                                    <div :class="{ 'col-sm': true, 'd-none': !showReplyInput }">
                                        <div class="row">
                                            <div class="col-sm">
                                                <form class="form-inline align-items-baseline" @submit.prevent>
                                                    <div class="form-group w-50 mb-2" v-if="!showGabarit && !showTemplateMessage" tabindex="0">
                                                        <input
                                                            type="text"
                                                            class="form-control w-100"
                                                            name="comment"
                                                            v-model="comment"
                                                            v-validate="{max:8000}"
                                                            :class="{ 'is-invalid': submitted && errors.has('comment') }"
                                                            :data-vv-as="$tc('comment', 1)"
                                                            v-bind:placeholder="$t('answer-comments')"
                                                            aria-describedby="comment-reply-btn"
                                                            @keyup="disabled = activeBtn">
                                                    </div>
                                                    <div class="form-group w-50 mb-2" v-if="showGabarit && !showTemplateMessage" @click="disabled = false" tabindex="0">
                                                        <select-dropdown
                                                            :options="templates"
                                                            :selected="selectedTemplate"
                                                            v-on:selectTemplate="chooseTemplate($event)"
                                                            :showLabel="false"
                                                            :type="'template'">
                                                        </select-dropdown>
                                                    </div>
                                                    <div class="form-group w-50 mb-2" v-if="showGabarit && showTemplateMessage" tabindex="0">
                                                        <textarea class="w-100 templateTextarea" v-model="comment" @keyup="disabled = activeBtn"></textarea>
                                                    </div>
                                                    <div class="form-group mb-2 mx-3" tabindex="0">
                                                        <a href="#" @click="confirmShowGabarit()">
                                                            <img src="/images/gabarit-icon.png" class="img-icon gabarit-icon" alt="template-icon">
                                                        </a>
                                                        <button type="button" class="btn text-btn" @click="cancelTemplate()" v-if="showGabarit">
                                                            {{ $t('cancel') }}
                                                        </button>
                                                    </div>
                                                    <button type="submit" class="btn btn-primary mb-2 reply-btn" id="comment-reply-btn" @click="commentReply()" :disabled="disabled">{{ $t('answer') }}</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <div class="row reply-message ml-2" v-if="replied === true && interaction.attributes.content">
                            <div class="col-12 d-flex">
                                <div class="text-left mt-2">
                                    <small class=""> {{ $t('your-reply') }} </small><p class="reply-text"> {{ comment }} </p>
                                </div>
                                <div class="row edit-reply">
                                    <div><a href="#" @click="startModification()"><font-awesome-icon class="text-grey ml-4 mt-2 edit-btn" :icon="['fas', 'pencil-alt']" /> </a></div>
                                    <div><a href="#" @click="confirmDelete()"><font-awesome-icon class="text-grey ml-4 mt-2 edit-btn" :icon="['fas', 'times']" /> </a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <facebook-original-post v-bind:postId="interaction.post.id" v-if="this.interaction.attributes.category === this.$config.interactions.POST"></facebook-original-post>
    </div>
</template>
<script>

// Components
import FacebookOriginalPost  from './FacebookOriginalPost.vue';
import FacebookReactionsFont from './FacebookReactionsFont.vue';
import ContactAvatar         from '../../../core/components/contacts/ContactAvatar.vue';
import SelectDropdown        from '../../../core/components/SelectDropdown.vue';

// Internal services js
import { RepositoryFactory }  from '../../../repositories/repositoryFactory.js';
import Alert                  from '../../../helpers/Alert.js';
import { EventBus }           from '../../../helpers/EventBus.js';

// External libraries
import moment from 'moment';

const FacebookComments   = RepositoryFactory.get('FacebookComments');
const FacebookReactions  = RepositoryFactory.get('FacebookReactions');
const FacebookPosts      = RepositoryFactory.get('FacebookPosts');

export default {
    components: {
        FacebookOriginalPost,
        FacebookReactionsFont,
        ContactAvatar,
        SelectDropdown
    },

    props: ['interaction', 'activePageId', 'templates'],

    /**
     * Initialise data using props interaction.
     */
    data () {
        return {
            read:           '',
            comment:        '',
            replied:        false,
            haveParent:     false,
            profilePicture: null,
            showGabarit:    false,
            reply:
            {
                comment:    '',
                parentId:   this.interaction.id,
                postId:     this.interaction.post.id,
                pageId:     null,
                parentType: this.interaction.attributes.category
            },
            replyId:             null,
            submitted:           false,
            showReactions:       false,
            showReplyButton:     true,
            showReplyInput:      false,
            selected:            false,
            isReacted:           false,
            passive:             true,
            reactions:           [],
            loading:             false,
            selectedTemplate:    this.$i18n.t('choose-template'),
            showTemplateMessage: false,
            templateContent:     '',
            disabled:            true,
        };
    },
    mounted () {
        // Check if the interaction is reacted by page or not.
        if (typeof this.interaction['page-reaction'] !== 'undefined') {
            this.isReacted    = true;
            this.reactions[0] = this.interaction['page-reaction'];
        }

        // If user have a profile picture
        if (this.interaction.author.attributes['profile-picture']) {
            this.profilePicture = this.interaction.author.attributes['profile-picture'];
        }

        // If this interaction have an admin reply
        if (typeof this.interaction.reply !== 'undefined') {
            this.replied         = true;
            this.showReplyButton = false;
            this.showReplyInput  = false;
            this.comment         = this.interaction.reply.attributes.message;
            this.replyId         = this.interaction.reply.id;
        }

        // If this interaction is an reply
        if (typeof this.interaction.parent !== 'undefined') {
            this.haveParent = true;
        }
        this.read = this.interaction.attributes['is-read'] === false ? 'unread' : 'read';
    },

    computed: {
        activeBtn () {
            return this.comment === '';
        }
    },

    methods: {
        /**
         * initilise the reply form and disable button once lose focus
         */
        disableComment () {
            this.showReplyInput = false;

            // Cancel template
            this.selectedTemplate = this.$i18n.t('choose-template');
            this.showGabarit = false;
            this.showTemplateMessage = false;

            this.showReplyButton = true;

            // Erase the comment entred
            this.comment = '';

            // Disable button
            this.disabled = true;
        },

        /**
         * Ask user if he want to use Gabarits if have already typed something in reply
         *
         * @return    void
         */
        confirmShowGabarit () {
            if (this.comment !== "") {
                Alert.confirmation(this.$i18n.t('this-will-replace-message'), this.$i18n.t('replace'))
                    .then((response) => {
                        if (response.value) {
                            this.showGabarit = true;
                            this.showTemplateMessage = false;
                        }
                    });
            } else {
                this.showGabarit = true;
            }
        },

        /**
         * Update selected option
         *
         * @return    void
         */
        chooseTemplate (option) {
            this.selectedTemplate = option.name;
            this.showTemplateMessage = true;
            this.comment = option.content;
        },

        /**
         * Cancel template response
         *
         */
        cancelTemplate () {
            if (this.comment !== "") {
                Alert.confirmation(this.$i18n.t('this-will-erase-message'), this.$i18n.t('erase'))
                    .then((response) => {
                        if (response.isConfirmed) {
                            this.selectedTemplate = this.$i18n.t('choose-template');
                            this.showGabarit = false;
                            this.showTemplateMessage = false;
                            this.comment = '';
                            this.disabled = true;
                        }
                    });
            } else if (this.comment === "") {
                this.selectedTemplate = this.$i18n.t('choose-template');
                this.showGabarit = false;
                this.showTemplateMessage = false;
            }
        },

        /**
         * Define moment to use it in template
         */
        moment,

        /**
         * Start the reply operation
         *
         * @return    void
         */
        startReply () {
            this.showReplyInput  = true;
            this.showReplyButton = false;
        },

        /**
         * Start the reply modification
         *
         * @return    void
         */
        startModification () {
            this.replied        = false;
            this.showReplyInput = true;
        },

        /**
         * Show confirm alert and if confirmed, delete the reply comment
         *
         * @param    {object}   event   the checked event
         *
         * @return    void
         */
        confirmDelete () {
            Alert.deleteConfirmation(this.$i18n.t('delete-reply-confirm-message'))
                .then((request) => {
                    if (request.value) {
                        FacebookComments.delete(this.replyId)
                            .then(() => {
                                // Update the popup of the original post
                                EventBus.$emit('update-post');
                            });

                        this.comment         = '';
                        this.replyId         = null;
                        this.replied         = false;
                        this.showReplyButton = true;
                    }
                });
        },

        /**
         * Read or not an interaction
         *
         * @param    {object}   event   the checked event
         *
         * @return    void
         */
        readInteraction (event) {
            let repository;
            switch (this.interaction.attributes.category) {
                case 'comment':
                    repository = FacebookComments;
                    break;
                case 'post':
                    repository = FacebookPosts;
                    break;
                default:
                    repository = FacebookReactions;
                    break;
            }

            // Send request to the api to update the interaction
            repository.update(this.interaction.id, {
                'is-read': event.target.checked
            });
        },

        /**
         * Show the single contact page
         *
         * @return    void
         */
        showContact () {
            this.$refs.contactAvatar.redirectToSingleContact();
        },

        /**
         * Make or cancel a reactions.
         *
         * @return    void
         */
        reactionsController () {
            if (this.loading === false) {
                // Test if this interaction is reacted.
                if (this.isReacted) {
                    // Delete reaction.
                    this.cancelReaction();
                    return;
                }

                // Make a new reaction.
                this.makeReaction();
            }
            return;
        },

        /**
         * Make a new reaction for this interaction (only post and comment).
         *
         * @return    void
         */
        makeReaction () {
            this.loading = true;
            this.isReacted = true;

            /**
             * Send the request to the API to create new reaction.
             * this.interaction.attributes.category = post or comment.
             */
            let relationships = {
                [this.interaction.attributes.category]: {
                    'data': {
                        'id':   this.interaction.id,
                        'type': `${this.interaction.attributes.category}s`
                    }
                }
            };

            // Create the reaction.
            FacebookReactions.create(relationships)
                .then((response) => {
                    this.reactions[0] = response.data.data;
                    this.loading = false;
                });
        },

        /**
         * Cancel a reaction for this interaction (only posts and comments).
         *
         * @return    void
         */
        cancelReaction () {
            this.loading = true;
            this.isReacted = false;

            // Delete the reaction.
            FacebookReactions.delete(this.reactions[0].id)
                .then(() => {
                    this.reactions = [];
                    this.loading = false;
                });
        },

        /**
         * Send the reply comment to the repository
         *
         * @return    void
         */
        commentReply () {
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (!valid) {
                    return;
                }

                // If user want to update the reply
                if (this.replyId !== null) {
                    FacebookComments.update(this.replyId, { 'message': this.comment })
                        .then(() => {
                            // Update the popup of the original post
                            EventBus.$emit('update-post');
                        });

                    this.showReplyInput = false;
                    this.replied        = true;
                    return;
                }

                // If user want to create reply
                this.reply.comment = this.comment;
                this.reply.pageId  = this.activePageId;

                FacebookComments.create(this.reply)
                    .then((request) => {
                        this.replyId = request.data.data.id;

                        // Update the popup of the original post
                        EventBus.$emit('update-post');
                    });

                this.showReplyInput = false;
                this.replied        = true;
            });
        },

        /**
         * Shorten the preview text of the fb message
         *
         * @return    {string}    The shorthened preview
         */
        shortenedPreview (postPreview) {
            const previewMaxLength = 25;
            if (postPreview === null) {
                return '';
            }
            return postPreview.length > previewMaxLength ? postPreview.slice(0, previewMaxLength) + '...' : postPreview;
        }
    }
};
</script>
