<template>
    <div class="facebook-page card-info">
        <div class="text-left">
            <h5>{{$t('page-available')}}</h5>
            <p>{{$t('active-page')}}</p>
        </div>
        <div class="spinner-border" role="status" v-if="!chargedRemotePages">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="page-table" v-if="chargedRemotePages">
            <div class="box-wrap-remote overflow-auto px-2 note-history">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                        <th scope="col" class="text-left">{{$t('page')}}</th>
                        <th scope="col" class="text-left">{{$t('category')}}</th>
                        <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="page in pagesTable" :key="page.id">
                        <td class="text-left">
                            <p class="text-table">{{page.attributes.name}} <br> {{page.id}} </p>
                        </td>
                        <td class="text-left">
                            {{page.attributes.category}}
                        </td>
                        <td>
                            <button class="btn btn-primary btn-active" @click="activateRemotePage(page.id)" :disabled="loading || haveActivePages">{{$t('activate')}}</button>
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
   </div>
</template>
<script>

// Internal services js
import { RepositoryFactory } from '../../../repositories/repositoryFactory.js';
import { EventBus }          from '../../../helpers/EventBus.js';
import Alert                 from '../../../helpers/Alert.js';

// External libraries
import HttpStatus  from 'http-status-codes';
import { mapActions } from 'vuex';

const FacebookPages       = RepositoryFactory.get('FacebookPages');
const FacebookRemotePages = RepositoryFactory.get('FacebookRemotePages');

export default {
    props: ['facebookUsers'],

    mounted () {
        // Get active pages.
        this.getActivePages();

        // Get remote pages.
        this.getRemotePages();

        // Listen for the deactivate page event.
        EventBus.$on('deactivate-page', () => {
            this.getActivePages();
            this.getRemotePages();
            this.getConnectors();
        });

        // Set first config via the route
        this.firstConfiguration = this.$route.meta.first;
    },

    data () {
        return {
            chargedRemotePages: false,
            pagesTable:         [],
            haveActivePages:    true,
            loading:            false,
            firstConfiguration: false
        };
    },

    methods: {
        ...mapActions('core/facebook', [
            'setIsValidAdminToken',
            'setActivePage',
            'setHasActivePage'
        ]),

        /**
         * Methods to get connectors linked to the account
         *
         * getConnectors get connectors linked to account
         */
        ...mapActions('core/connectors', {
            getConnectors: 'index'
        }),

        /**
         * Get all active pages.
         *
         * @returns    void
         */
        getActivePages () {
            FacebookPages.index()
                .then((response) => {
                    this.showActivePage(response);
                });
        },

        /**
         * Get all remote pages.
         *
         * @returns    void
         */
        getRemotePages () {
            if (this.facebookUsers.length === 0) {
                this.chargedRemotePages = true;
                return;
            }

            this.chargedRemotePages = false;
            FacebookRemotePages.index()
                .then((response) => {
                    this.showRemotePages(response);
                });
        },

        /**
         * Show all remote pages
         *
         * @return    void
         */
        showRemotePages (request) {
            this.pagesTable = request.data.data;
            this.chargedRemotePages = true;
        },

        /**
         * Check if we have an active facebook page, so we disable the active button
         * of remote page table
         *
         * @param  {object}  request    The api request
         */
        showActivePage (request) {
            request.data.data.length > 0 ? this.haveActivePages = true : this.haveActivePages = false;
        },

        /**
         * Active remote page
         *
         * @param    {string}    pageId    The id of remote page
         *
         * the api don't have yet a function to get the adminId.
         */
        activateRemotePage (pageId) {
            this.loading = true;
            FacebookPages.create(pageId)
                .then((request) => {
                    this.setIsValidAdminToken({ isValid: request.data.data.attributes['is-remote-access-token-valid'] });
                    this.setActivePage({ activePage: request.data.data });
                    this.setHasActivePage({ hasActivePage: true });
                    this.loading = false;
                    if (this.$router.currentRoute.name.includes('facebook-pages')) {
                        this.$router.push({
                            name: `facebook-connector:${this.$i18n.locale}`
                        });
                    }
                    this.pagesTable = [];
                    this.getRemotePages();

                    EventBus.$emit('activate-page');
                })
                .catch((error) => {
                    if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                        Alert.fail(this.$i18n.t(error.response.data.errors[0].detail));
                        this.loading = false;
                    }
                });
        }
    }
};
</script>
