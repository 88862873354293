<template>
    <div id="sms-page" class="d-flex align-items-stretch">
        <navigation></navigation>
        <div class="container-fluid main-content" v-if="loading">
            <preloader-placeholder :loading="loading" :type="'connector'"></preloader-placeholder>
        </div>
        <template v-else>
            <!-- As simple user section -->
            <sms-conversation v-if="!this.user.attributes['is-admin']"></sms-conversation>

            <!-- Admin user section -->
            <sms-admin-conversations v-else></sms-admin-conversations>
        </template>
    </div>
</template>
<script>

// Components
import smsConversation       from '../components/SmsConversation.vue';
import smsAdminConversations from '../components/adminConversation/SmsAdminConversations.vue';
import PreloaderPlaceholder  from '@/core/components/PreloaderPlaceholder.vue';

// Import external libraries
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        smsConversation,
        smsAdminConversations,
        PreloaderPlaceholder
    },
    data () {
        return {
            notesNumber: 0,
            loading:     true
        };
    },
    computed: {
        ...mapState('core/contacts', [
            'singleNotes'
        ]),
        ...mapState('core/auth', [
            'user'
        ])
    },
    created () {
        this.getProviders().finally(() => {
            this.loading = false;
        });

        for (const note in this.singleNotes) {
            if (this.singleNotes.hasOwnProperty(note)) {
                this.notesNumber++;
            }
        }
    },

    methods: {
        /**
         * Methods for Providers API request
         */
        ...mapActions('core/providers', { getProviders: 'index' })
    }
};
</script>

