<template>
    <div id="user-box">
        <div class="container-fluid card-info mb-3" v-bind:class="{suspended: user.attributes['is-enabled'] === false}">
            <div class="row align-items-center" v-if="user">
                <div class="avatar img-box col-sm-2 col-md-2 col-lg-1 text-center p-2">
                    <router-link :to="{ name: '' }">
                        <label class="label-avatar" @click="$refs.editButton.click()">
                            <model-short-name v-if="user.attributes['profile-picture'] === null" :model="user"></model-short-name>
                            <img class="user-img" :src="`${user.attributes['profile-picture']}?${timeStamp}`" alt="" v-else>
                        </label>
                    </router-link>
                </div>
                <div class="col-lg-2 col-sm text-left user-info">
                    <p class="m-0">{{user.attributes['first-name']}}</p>
                    <small class="text-primary">{{ $t('first-name') }}</small>
                </div>
                <div class="col-lg-2 col-sm text-left user-info">
                    <p class="m-0">{{user.attributes['last-name']}}</p>
                    <small class="text-primary">{{ $t('last-name') }}</small>
                </div>
                <div class="col-lg-3 col-sm text-left user-info">
                    <p class="m-0">{{user.attributes['email']}}</p>
                    <small class="text-primary">{{ $t('email') }}</small>
                </div>
                <div class="col-lg-2 col-sm text-left user-info-important">
                    <p class="m-0" v-if="user.attributes['is-admin'] === true">{{ $t('admin') }}</p>
                    <p class="m-0" v-else>{{ $t('user') }}</p>
                    <small class="text-primary">{{ $t('role') }}</small>
                </div>
                <div class="col-sm text-left user-info-important">
                    <p class="m-0" v-if="user.attributes['is-invited'] === true">{{ $t('invited') }}</p>
                    <p class="m-0" v-else-if="user.attributes['is-enabled'] === true">{{ $t('active') }}</p>
                    <p class="m-0" v-else>{{ $t('suspended') }}</p>
                    <small class="text-primary">{{ $t('status') }}</small>
                </div>
                <div class="col-sm-1" v-if="this.user.id !== this.authUser.id">
                    <a class="header-icon user-icon " href="#" role="button" id="userManagment" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <font-awesome-icon class="text-grey edit-btn ellipsis" :icon="['fas', 'ellipsis-v']" />
                    </a>
                    <div class="dropdown-menu managment-menu" aria-labelledby="userManagment">
                        <button
                            type="button"
                            class="dropdown-item"
                            data-toggle="modal"
                            ref="editButton"
                            :data-target="`#edit-modal-user-${user.id}`">
                            {{ $t('edit') }}
                        </button>
                        <button
                            type="button"
                            class="dropdown-item"
                            :disabled="user.attributes['is-invited']"
                            @click="changeStatus()">
                            {{ user.attributes['is-enabled'] ? $t('suspend') : $t('activate') }}
                        </button>
                        <button
                            type="button"
                            class="dropdown-item"
                            :disabled="!authUser.attributes['is-super-admin'] && !authUser.attributes['is-admin']"
                            @click="confirmAlert()">
                            {{ $t('delete') }}
                        </button>
                    </div>
                </div>
                <!-- Edit user Modal -->
                <div class="modal" :id="`edit-modal-user-${user.id}`">
                    <div class="modal-dialog">
                        <div class="modal-content px-5 py-3">
                            <div class="modal-header justify-content-center border-0">
                                <h3 class="modal-title text-primary">{{ $t('edit-user') }}</h3>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group text-left">
                                        <label class="col-form-label">{{ $t('email-adress') }}</label>
                                        <input type="email" class="form-control" v-model="user.attributes['email']" disabled>
                                    </div>
                                    <div class="form-group text-left">
                                        <label class="col-form-label">{{ $t('first-name') }} <strong class="obligatory">*</strong></label>
                                        <input
                                            name="first-name"
                                            type="text"
                                            class="form-control"
                                            v-model="editableAttributes['first-name']"
                                            v-validate="'required'"
                                            :class="{ 'is-invalid': submitted && errors.has('first-name') }"
                                            :data-vv-as="$t('first-name')"
                                            @change="disabled = false"
                                        >
                                        <div class="invalid-feedback" v-if="submitted">
                                            {{ errors.first('first-name') }}
                                        </div>
                                    </div>
                                    <div class="form-group text-left">
                                        <label class="col-form-label">{{ $t('last-name') }} <strong class="obligatory">*</strong></label>
                                        <input
                                            name="last-name"
                                            type="text"
                                            class="form-control"
                                            v-model="editableAttributes['last-name']"
                                            v-validate="'required'"
                                            :class="{ 'is-invalid': submitted && errors.has('last-name') }"
                                            :data-vv-as="$t('last-name')"
                                            @change="disabled = false"
                                        >
                                        <div class="invalid-feedback" v-if="submitted">
                                            {{ errors.first('last-name') }}
                                        </div>
                                    </div>
                                    <div class="form-group text-left">
                                        <label>{{ $t('role') }}</label>
                                        <select
                                            class="form-control"
                                            v-model="editableAttributes['is-admin']"
                                            @change="disabled = false"
                                            :disabled="this.user.attributes['is-saml']"
                                        >
                                            <option :value="true">{{ $t('admin') }}</option>
                                            <option :value="false">{{ $t('user') }}</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer border-0">
                                <button type="button" class="btn text-btn style-btn" data-dismiss="modal" ref="closeButton" @click="cancelChanges()">{{ $t('cancel') }}</button>
                                <button type="button" class="btn btn-primary rounded-pill style-btn" :disabled="disabled" @click="updateUserinformations(editableAttributes)">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" :disabled="disabled" v-if="loading"></span>
                                    {{ $t('save') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal components
import ModelShortName from '@/core/components/ModelShortName.vue';

// Internal services js
import Alert from '@/helpers/Alert.js';

// Import external libraries
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        ModelShortName
    },

    data () {
        return {
            submitted:          false,
            loading:            false,
            editableAttributes: {},
            timeStamp:          0,
            disabled:           true
        };
    },

    props: [
        'user'
    ],

    computed: {
        ...mapState('core/tenants', [
            'selectedTenant'
        ]),

        ...mapState('core/auth', {
            'authUser': 'user'
        })
    },

    /**
     * Component created handler
     */
    created () {
        this.editableAttributes = {
            'first-name': this.user.attributes['first-name'],
            'last-name':  this.user.attributes['last-name'],
            'is-admin':   this.user.attributes['is-admin']
        };

        // Generate a time stamp that will be added to avatar urls to make sure we are not loading the avatar in the browser's cache
        this.timeStamp = Date.now();
    },

    methods: {
        ...mapActions('core/users', [
            'patch'
        ]),
        ...mapActions('core/tenant-users', {
            deleteTenantUser: 'delete'
        }),

        /**
         * Show confirm dialog alert
         */
        confirmAlert () {
            Alert.userDeleteConfirmation('confirm-user-delete', this.user.attributes.email)
                .then((response) => {
                    if (response.value) {
                        // Create the relationshipId (the concatenation of the Tenant-id and the user-id (Tenant-id_user-id)),
                        const relationshipId = `${this.selectedTenant.id}_${this.user.id}`;

                        // Send the request to delete the Tenant-User relationship.
                        this.deleteTenantUser({
                            user:           this.user,
                            relationshipId: relationshipId
                        });
                    }
                });
        },

        /**
         * Save the new user informations
         *
         * @param    {object}    editableAttributes    the user's new informaions
         */
        updateUserinformations (editableAttributes) {
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (!valid) {
                    return;
                }

                // Start loading and disable button
                this.loading  = true;
                this.disabled = true;

                // Keep the used SAML role for the saml user
                if (this.user.attributes['is-saml']) {
                    editableAttributes['is-admin'] = this.user.attributes['is-admin'];
                }

                // Send the new informations to the api
                this.patch({
                    id:         this.user.id,
                    attributes: editableAttributes
                })
                    .then(() => {
                        this.loading   = false;
                        this.submitted = false;

                        // Show the success alert and close the model
                        Alert.success(this.$i18n.t('information-updated'));
                        this.$refs.closeButton.click();
                    });
            });
        },

        /**
         * Cancel all changes
         */
        cancelChanges () {
            this.editableAttributes['first-name'] = this.user.attributes['first-name'];
            this.editableAttributes['last-name']  = this.user.attributes['last-name'];
            this.editableAttributes['is-admin']   = this.user.attributes['is-admin'];

            this.submitted = false;
        },

        /**
         * Change the status of user
         */
        changeStatus () {
            // Reverse the current status and Send it to the api
            this.patch({
                id:         this.user.id,
                attributes: {
                    'is-enabled': !this.user.attributes['is-enabled']
                }
            });
        }
    }
};
</script>
