<template>
    <div id="fb-sidebar-bottom" class="white-box new-contact" v-if="listContacts">
        <p class="sidebar-title text-center mb-0">
            {{ $t('new-contact') }} ({{ Object.keys(listContacts).length }})
        </p>
        <p class="sidebar-date">
            <small class="text-grey">
                {{ $t('since-the') }} {{ fromDate }}
            </small>
        </p>
        <ul class="nav fa-ul flex-column mt-3 " v-if="contacts">
           <li class="nav-item menu-item text-left"
                v-for="contact in listContacts" :key="contact.id">
                <router-link @click.native="showContact(contact.id)" :to="{ name: '' }" class="contact-link">
                    <span v-if="(contact.attributes['facebook-id'] !== undefined && contact.attributes['facebook-id'] !== null)">
                        <font-awesome-icon class="fa-li text-primary" :icon="['fab', 'facebook-f']" />
                    </span>
                    <span class="text-primary" v-else>
                        <font-awesome-icon class="fa-li text-primary" :icon="['fas', 'mobile-alt']"/>
                    </span>
                    {{ contact.attributes['facebook-id'] !== null ? shortenedName(contact.attributes['facebook-name']) : shortenedName(contact.attributes.name) }}
                </router-link>
            </li>
        </ul>
        <div class="new-contact-list mt-3 mx-5" v-if="contacts && contacts.length > 10">
            <a href="#" data-toggle="modal" data-target="#new-contact-modal"><p class="pt-2">{{ $t('see-more') }}</p></a>
        </div>
        <div class="modal" tabindex="-1" role="dialog" id="new-contact-modal">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="w-100">
                            <h5 class="modal-title text-primary">{{ $t('new-contact') }}</h5>
                            <small class="text-grey">{{ $t('7days-new-contact') }} ( {{ contacts != null ? contacts.length : '' }} )</small>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body px-5">
                        <div class="d-flex flex-wrap flex-column modal-list" >
                            <router-link
                                v-for="contact in contacts"
                                :key="contact.id"
                                @click.native="showContact(contact.id)"
                                :to="{ name: '' }"
                                class="contact-link d-flex"
                                data-dismiss="modal">
                                <font-awesome-icon class="text-primary" :icon="['fab', 'facebook-f']" />
                                <p class="ml-3">{{ contact.attributes.name }}</p>
                            </router-link>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <a href="/contacts"><p>{{ $t('see-all-contacts') }}</p></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// External external libraries
import moment                   from 'moment';
import { mapState, mapActions } from 'vuex';
import _                        from 'underscore';
import { EventBus }             from '@/helpers/EventBus.js';

// Number of days to subtract
const daysNumber = 7;

export default {
    mounted () {
        this.indexNew();

        // Update the contact list after importing contacts
        EventBus.$on('contact-imported', () => {
            this.indexNew();
        });
    },

    data () {
        return {
            contactName: null,
            fromDate:    moment()
                .locale('fr-ca')
                .subtract(daysNumber, 'days')
                .format('LL')
        };
    },

    computed: {
        ...mapState('core/contacts', {
            'contacts': 'newContacts'
        }),

        /**
         *  Get Facebook contacts from the newContacts list
         */
        listContacts () {
            if (this.$router.currentRoute.name.includes('facebook-connector')) {
                return _.filter(this.contacts, (contact) => (contact.attributes['facebook-id'] !== null || typeof contact.attributes['facebook-id'] === 'undefined'));
            }
            return this.contacts;
        }
    },

    methods: {
        ...mapActions('core/contacts', [
            'indexNew',
            'getSingle'
        ]),

        /**
         * Set the contact id to the session and redirect to single contact page
         *
         * @param   {String}    contactId   The contact id
         */
        showContact (contactId) {
            this.getSingle({
                id: contactId
            }).then(() => {
                this.$router.push({
                    name: `contact:${this.$i18n.locale}`
                });
            });
        },

        /**
         * Shorten the preview name of the contact
         *
         * @param     {string}    name    The contact name
         *
         * @return    {string}    The shortened name
         */
        shortenedName (contactName) {
            const nameMaxLength = 19;
            return contactName.length > nameMaxLength ? contactName.slice(0, nameMaxLength) + '...' : contactName;
        }
    }
};
</script>
