import Api from '@/helpers/Api.js';

const resource = '/connectors/facebook/v1/comments';

export default {
    /**
     * Send a new comment to the api
     *
     * @param    {object}    commentData    The data of the new comment
     *
     * @return    {object}    jsonapi response
     */
    create (commentData) {
        const relationships = {
            'page': {
                'data': {
                    'id':   commentData.pageId,
                    'type': 'pages'
                }
            },
            'post': {
                'data': {
                    'id':   commentData.postId,
                    'type': 'posts'
                }
            }
        };

        if (commentData.parentType === 'comment') {
            relationships.parent = {
                'data': {
                    'id':   commentData.parentId,
                    'type': 'comments'
                }
            };
        }

        return Api.post(`${resource}`, {
            'data': {
                'type':       'comments',
                'attributes': {
                    'message': commentData.comment
                },
                relationships
            }
        });
    },

    /**
     * Update comments
     *
     * @param   {string}    commentId   The comment id
     * @param   {object}    attributes   The comment new attributes
     *
     * @return    {object}    ApiJson response
     */
    update (commentId, attributes) {
        return Api.patch(`${`${resource}/${commentId}`}`, {
            'data': {
                'type': 'comments',
                'id':   commentId,
                attributes
            }
        });
    },

    /**
     * Delete comments
     *
     * @param   {string}    commentId   The comment id
     *
     * @return    {object}    ApiJson response
     */
    delete (commentId) {
        return Api.delete(`${resource}/${commentId}`);
    },

    /**
     * Get reactions for comment
     *
     * @param   {string}    commentId   The comment id
     *
     * @return    {object}    ApiJson response
     */
    getReactions (commentId) {
        return Api.get(`${resource}/${commentId}/reactions`);
    }
};
