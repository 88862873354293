<template>
    <div class="d-flex align-items-stretch side-menu" :class="{'no-notes' : this.notesNumber === 0, 'one-note' : this.notesNumber === 1, 'two-notes' : this.notesNumber === 2, 'more-notes' : this.notesNumber >= 3}">
        <badge></badge>
        <mainmenu></mainmenu>
        <topheader
            v-if="user !== null"
        ></topheader>
        <useredit
            v-if="user !== null">
        </useredit>
    </div>
</template>
<script>

// Import external libraries
import { mapState } from 'vuex';

export default {
    data () {
        return {
            notesNumber: 0
        };
    },
    computed: {
        ...mapState('core/auth', [
            'user'
        ]),

        ...mapState('core/contacts', [
            'singleNotes'
        ])
    },
    created () {
        for (let note in this.singleNotes) {
            if (this.singleNotes.hasOwnProperty(note)) {
                this.notesNumber++;
            }
        }
    }
};
</script>
