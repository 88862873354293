<template>
    <div id="home-page" class="d-flex align-items-stretch"
        v-if="sessionValid">
        <div class="connectors-content container-fluid main-content">
            <div class="mb-5">
                <h3>{{ $i18n.t('redirecting-to-your-connector') }}</h3>
            </div>
            <preloader-placeholder :loading="loading" :type="'connector'"></preloader-placeholder>
        </div>
    </div>
</template>
<script>

// Internal components
import PreloaderPlaceholder from '@/core/components/PreloaderPlaceholder.vue';

// Import external libraries
import { mapState, mapActions } from 'vuex';

// External libraries
import HttpStatus  from 'http-status-codes';

export default {
    components: { PreloaderPlaceholder },

    computed: {
        ...mapState('core/connectors', [
            'connectors',
            'sms',
            'facebook'
        ]),

        /**
         * State with the user
         *
         * List of user {object}
         *
         */
        ...mapState('core/auth', [
            'user'
        ])
    },

    data () {
        return {
            loading:      true,
            sessionValid: true
        };
    },

    /**
     * Check function
     * Before create this page check session is undefined or false
     */
    beforeCreate () {
        if (typeof this.sessionExists === 'undefined' || this.sessionExists === false) {
            this.sessionValid = false;
            this.$router.push({ name: 'login' });
        }
    },

    /**
     * Check function
     * After login the user, check the status of the connectors
     */
    created () {
        this.getConnectorsList();
    },

    methods: {
        /**
         * Methods to get connectors linked to the account
         *
         * getConnectors get connectors linked to account
         */
        ...mapActions('core/connectors', { getConnectors: 'index' }),

        ...mapActions('core/session', ['destroySession']),

        /**
         * Update the list of connectors then redirect to the right one.
         */
        getConnectorsList () {
            this.getConnectors().then(() => {
                this.loading             = false;
                const currentUserIsAdmin = this.user.attributes['is-admin'] || this.user.attributes['is-super-admin'];
                const allConnectors      = Object.values(this.connectors);
                const nexts              = [
                    { name: 'connectors', next: `connectors-page:${this.$i18n.locale}`    },
                    { name: 'contacts',   next: `contact-list:${this.$i18n.locale}`       },
                    { name: 'sms',        next: `sms-connector:${this.$i18n.locale}`      },
                    { name: 'facebook',   next: `facebook-connector:${this.$i18n.locale}` },
                ];

                // Check if any connected connector not established exist
                const notAllConnectedAndEstablished            = allConnectors.some(connector => (this[connector.attributes.name].isConnected && !this[connector.attributes.name].isEstablished));
                const noConnectedAndEstablishedConnectorExists = allConnectors.every(connector =>
                    (!this[connector.attributes.name].isConnected && !this[connector.attributes.name].isEstablished) ||
                    (this[connector.attributes.name].isConnected && !this[connector.attributes.name].isEstablished)
                );

                if (noConnectedAndEstablishedConnectorExists && currentUserIsAdmin) {
                    this.$router.push({ name: nexts.find(page => page.name === 'connectors').next, params: { welcome: true } });
                }
                else if (noConnectedAndEstablishedConnectorExists && !currentUserIsAdmin) {
                    this.$router.push({ name: nexts.find(page => page.name === 'contacts').next });
                }
                else if (notAllConnectedAndEstablished && currentUserIsAdmin) {
                    this.$router.push({ name: nexts.find(page => page.name === 'connectors').next, params: { welcome: false } });
                }
                else {
                    const connectorConnected = allConnectors.find(connector => this[connector.attributes.name].isConnected && this[connector.attributes.name].isEstablished);
                    this.$router.push({ name: nexts.find(page => page.name === connectorConnected.attributes.name).next });
                }
            })
                .catch((error) => {
                    if (error.response.status === HttpStatus.UNAUTHORIZED) {
                        this.destroySession();
                        this.loading = false;
                        this.sessionValid = false;
                    }
                });
        }
    }
};
</script>
