import ImagesConfig from './ImagesConfig.js';
import Vue          from 'vue';

Vue.prototype.$config = {
    'images':       ImagesConfig,
    'interactions': {
        'COMMENT': 'comment',
        'POST':    'post',
        'LIKE':    'like',
        'LAUGHT':  'haha',
        'SAD':     'sad',
        'LOVE':    'love',
        'ANGRY':   'angry',
        'WOW':     'wow',
        'CARE':    'CARE'
    },
    'connectorsStatus': {
        'ESTABLISHED':   'established',
        'EXPIRED':       'expired',
        'UNESTABLISHED': 'unestablished'
    }
};
