<template>
    <div class="align-items-stretch">
        <badge></badge>
        <div id="user-login" class="login-interface">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="login-box p-5">
                        <div class="row">
                            <div class="col-sm mb-4"><img class="img-logo text-center" src="../../../assets/images/logo.png" alt=""></div>
                        </div>
                        <form class="text-left">
                            <div class="form-group">
                                <label for="userName">{{ $t('email') }} <strong class="obligatory">*</strong></label>
                                <input type="text" name="userName" class="form-control" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('userName') }" id="userName" :data-vv-as="$t('email')" v-model="username">
                                <div class="invalid-feedback" v-if="submitted">
                                    {{ errors.first('userName') }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="password">{{ $t('password') }} <strong class="obligatory">*</strong></label>
                                <input type="password" name="password" class="form-control" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('password') }"  id="password" :data-vv-as="$t('password')" v-model="password">
                                <div class="invalid-feedback" v-if="submitted">
                                    {{ errors.first('password') }}
                                </div>
                                <small id="forgot-password" class="form-text text-primary"><router-link :to="{ name: `forgot:${$i18n.locale}` }">{{ $t('password-forgot') }}</router-link></small>
                            </div>
                            <router-link :to="{ name: '' }" class="button-link">
                                <button type="submit" class="btn btn-primary rounded-pill" @click="login()" :disabled="loading">{{ $t('sign-in') }}
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                                </button>
                            </router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal services js
import Alert from '@/helpers/Alert.js';
import LoginMixin from '@/core/mixins/LoginMixin';

// External libraries
import moment                   from 'moment';
import HttpStatus               from 'http-status-codes';
import { mapActions, mapState } from 'vuex';

export default {
    mixins: [LoginMixin],
    data () {
        return {
            username:  '',
            password:  '',
            submitted: false,
            loading:   false
        };
    },

    beforeCreate () {
        // If locale is set in the local storage, use it
        this.$i18n.locale = localStorage.getItem('locale')
            ?? process.env.VUE_APP_I18N_DEFAULT_LOCALE;

        moment.locale(localStorage.getItem('locale') ?? process.env.VUE_APP_I18N_DEFAULT_LOCALE);

        // Check if user already logged in and has a tenant selected
        if (this.sessionExists === true) {
            if (this.sessionUser !== null && this.sessionSelectedTenant !== null) {
                this.tenantsTemp = this.sessionSelectedTenant;
                this.$router.replace({ name: `connectors-page:${this.$i18n.locale}` });
            }
        }
    },

    created () {
        // Check if user has tenants assigned to the user and get that tenants information
        this.tenantsTemp = this.sessionTenants;
    },

    computed: {
        ...mapState('core/session', [
            'sessionExists',
            'sessionSelectedTenant',
            'sessionTenants',
            'sessionUser'
        ]),

        ...mapState('core/auth', [
            'isTwoFactorsRequired'
        ]),

        /**
         * State of tenants
         *
         * tenants     State of the tenants linked to the user
         */
        ...mapState('core/tenants', { tenants: 'userTenants' }),

        /**
         * State of connectors
         *
         * connectors   State of the connectors linked to the tenant
         */
        ...mapState('core/connectors', [
            'connectors'
        ])
    },

    methods: {
        /**
         * Methods to login into the application
         *
         * coreAuthLogin         API request to login into the app
         */
        ...mapActions('core/auth', { coreAuthLogin: 'login' }),

        /**
         * Login user with credentials
         *
         * @return void
         */
        login () {
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.loading = true;

                    // Login via the API
                    this.coreAuthLogin({
                        email:    this.username,
                        password: this.password
                    })
                        .then(() => {
                            // If the 2FA is active, we redirect to the second login step
                            if (this.isTwoFactorsRequired) {
                                this.$router.push({
                                    name: `2fa-login:${this.$i18n.locale}`
                                });
                            } else {
                                this.setSessionExists({ sessionExists: true });
                                this.userTenants();
                            }
                        })
                        .catch((error) => {
                            this.loading = false;
                            if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                                Alert.fail(this.$i18n.t('invalid-login'));
                            }
                        });
                }
            });
        }
    }
};
</script>
