<template>
    <div class="modal" id="form-add-contact">
        <div class="modal-dialog">
            <div class="modal-content px-5 py-3">
                <div class="modal-header justify-content-center border-0">
                    <h3 class="modal-title text-primary">{{ `${$t('add-contact')} ${type}` }}</h3>
                </div>
                <div class="modal-body add-contact-body">
                    <transition name="slide-down" mode="out-in">
                        <form v-if="currentStep === STEP_CREATE" key="form">
                            <div class="form-group text-left">
                                <label for="name" class="col-form-label">{{ $t('first-name') }} <strong class="obligatory">*</strong></label>
                                <input
                                    id="first-name"
                                    name="first-name"
                                    type="text"
                                    class="form-control"
                                    v-model="newContact['first-name']"
                                    v-validate="'required'"
                                    :class="{ 'is-invalid': submitted && errors.has('first-name') }"
                                    :data-vv-as="$t('first-name')"
                                >
                                <div class="invalid-feedback" v-if="submitted">
                                    {{ errors.first('first-name') }}
                                </div>
                            </div>
                            <div class="form-group text-left">
                                <label for="name" class="col-form-label">{{ $t('last-name') }} <strong class="obligatory">*</strong></label>
                                <input
                                    id="last-name"
                                    name="last-name"
                                    type="text"
                                    class="form-control"
                                    v-model="newContact['last-name']"
                                    v-validate="'required'"
                                    :class="{ 'is-invalid': submitted && errors.has('last-name') }"
                                    :data-vv-as="$t('last-name')"
                                >
                                <div class="invalid-feedback" v-if="submitted">
                                    {{ errors.first('last-name') }}
                                </div>
                            </div>
                            <div class="form-group text-left">
                                <label for="phone-number" class="col-form-label">{{ $t('phone-number') }} <strong class="obligatory">*</strong></label>
                                <input
                                    id="phone-number"
                                    name="phone-number"
                                    type="text"
                                    class="form-control"
                                    v-model="newContact['phone-number']"
                                    v-validate="'required|valid_phone'"
                                    :class="{ 'is-invalid': submitted && errors.has('phone-number') }"
                                    :data-vv-as="$t('phone-number')"
                                    data-vv-name="phone-number"
                                >
                                <div class="invalid-feedback" v-if="submitted">
                                    {{ errors.first('phone-number') }}
                                </div>
                            </div>
                            <div class="form-group text-left">
                                <label for="email" class="col-form-label">{{ $t('email-adress') }}</label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    class="form-control"
                                    v-validate="'email'"
                                    v-model="newContact.email"
                                    :class="{ 'is-invalid': submitted && errors.has('email') }"
                                    :data-vv-as="$t('email-adress')"
                                >
                                <div class="invalid-feedback" v-if="submitted">
                                    {{ errors.first('email') }}
                                </div>
                            </div>
                        </form>
                        <div class="col-sm-12" v-if="currentStep === STEP_UPLOAD && showUploadPictureStep" key="picture">
                            <div class="avatar form-group">
                                <label class="label-avatar" for="contactAvatar">
                                    <model-short-name v-if="profilePicture === null && previewPicture === null" v-bind:model="contact"></model-short-name>
                                    <img class="contact-img" :src="previewPicture" alt="" v-if="previewPicture !== null">
                                    <img class="contact-img" :src="profilePicture" alt="" v-if="profilePicture !== null && previewPicture === null">
                                </label>
                                <div class="form-group mt-3">
                                    <label class="label-avatar" for="contactAvatar"><h6 class="text-primary">{{ profilePicture === null && previewPicture === null  ? $t('add-picture') : $t('change-picture') }}</h6></label>
                                    <input type="file" class="form-control-file" accept="image/*" ref="file" @change="previewImage()" id="contactAvatar" hidden><br>
                                    <label class="label-avatar" @click="deletePreviewPicture()" v-if="profilePicture !== null || previewPicture != null"><small class="delete-avatar">{{ $t('delete-picture') }}</small></label>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn text-btn" ref="closeButton" data-dismiss="modal" @click="resetAddForm()" id="close" v-show="currentStep === STEP_CREATE">{{ $t('cancel') }}</button>
                    <button type="button" class="btn btn-primary rounded-pill invite-btn" @click="stepsController()">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                        {{ $t('save') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';

// Internal componants
import ModelShortName from '../../components/ModelShortName.vue';

// Internal services js
import Alert from '../../../helpers/Alert.js';

// Import external libraries
import { mapActions } from 'vuex';
import HttpStatus     from 'http-status-codes';
import Format         from '@/helpers/Format.js';

export default {
    props: ['type'],

    components: {
        ModelShortName
    },
    created () {
        this.currentStep = this.STEP_CREATE;
    },
    data () {
        return {
            newContact: {
                'email':        '',
                'name':         '',
                'first-name':   '',
                'last-name':    '',
                'phone-number': ''
            },
            newContactId:   '',
            profilePicture: null,
            previewPicture: null,
            loading:        false,
            submitted:      false,
            contact:        null,
            currentStep:    '',
            STEP_CREATE:    'create_contact',
            STEP_UPLOAD:    'upload_image',

            // We hide this step for future use.
            showUploadPictureStep: false
        };
    },

    methods: {
        ...mapActions('core/contacts', [
            'create',
            'uploadProfilePicture',
            'createSmsContact'
        ]),

        /**
         * Upload image for profile
         */
        uploadImage () {
            if (!this.$refs.file.files[0]) {
                this.afterCreation();
                return;
            }

            // Upload the image selected by user.
            this.uploadProfilePicture({
                id:      this.newContactId,
                picture: this.$refs.file.files[0]
            })
                .then((profilePicture) => {
                    this.profilePicture = profilePicture;
                    this.previewPicture = null;
                    this.contact[this.newContactId].attributes['profile-picture'] = profilePicture;
                    this.afterCreation();
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                        Alert.fail(this.$i18n.t('must-be-image'));
                        this.deletePreviewPicture();
                    }
                });
        },

        /**
         * Steps controller.
         */
        stepsController () {
            switch (this.currentStep) {
                case this.STEP_CREATE:
                    this.createContact();
                    break;
                case this.STEP_UPLOAD:
                    this.uploadImage();
                    break;
            }
        },

        /**
         * Create a new contact
         *
         * @return     void
         */
        createContact () {
            Vue.prototype.$validation = this.$validator;
            this.submitted = true;
            this.loading   = true;
            this.$validator.validate().then((valid) => {
                if (!valid) {
                    this.loading = false;
                    return;
                }

                // Concatenate first-name and last-name to create a full name.
                this.newContact.name = `${this.newContact['first-name']} ${this.newContact['last-name']}`;

                // Normalize mobile phone numbers into E.164 format (USA or CAN)
                this.newContact['phone-number'] = Format.phone(this.newContact['phone-number']).phoneNumber;

                this.create({ attributes: this.newContact })
                    .then((response) => {
                        this.contact = response.contacts;

                        // Get new Contact ID
                        this.newContactId = Object.keys(this.contact)[0];

                        // End loading
                        this.loading   = false;
                        this.submitted = false;

                        // Keep creation step for now, will be STEP_UPLOAD if profile picture is needed.
                        this.currentStep = this.STEP_CREATE;

                        // Finish the creation without profile picutre FLOW
                        this.afterCreation();
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            });
        },

        /**
         * After creation of the contact.
         *
         * @return    void
         */
        afterCreation () {
            // Show the success alert and close the model.
            Alert.success(this.$i18n.t('contact-created'));
            this.$refs.closeButton.click();

            // Back to create step
            this.currentStep = this.STEP_CREATE;
        },

        /**
         * Reset the add form.
         *
         * @return    void
         */
        resetAddForm () {
            this.newContact = {
                'email':        '',
                'name':         '',
                'phone-number': '',
                'first-name':   '',
                'last-name':    ''
            };

            // Reste the validation
            this.$validator.reset();

            this.submitted = false;
            this.loading   = false;
        },

        /**
         * preview an image before it is uploaded
         */
        previewImage () {
            if (this.$refs.file.files[0]) {
                this.previewPicture = URL.createObjectURL(this.$refs.file.files[0]);
            }
        },

        /**
         * Delete preview contact picture
         */
        deletePreviewPicture () {
            this.$refs.file.value = '';
            this.previewPicture   = null;
        },
    }
};
</script>
