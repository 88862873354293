<template>
    <div>
        <button v-if="isConnected" @click="continueWithSms()" class="btn sms-btn btn-lg d-flex align-items-center">
            <p class="fb-text">{{$t('configure')}}</p>
        </button>
        <a  v-else href="javascript:void(0)" @click.prevent="openPortalSession" class="btn sms-btn btn-lg d-flex align-items-center">
            <p class="fb-text">{{$t('configure')}}</p>
        </a>
    </div>
</template>
<script>

// Import external libraries
import {
    mapGetters
} from 'vuex';

// Internal services js
import chargbeePortalMixin from '@/core/mixins/chargbeePortalMixin';

export default {
    mixins: [chargbeePortalMixin],

    props: ['isConnected'],

    computed: {
        ...mapGetters('core/number', ['numberToActivate']),

        ...mapGetters('core/providers', ['connectedProvider'])
    },
    methods: {
        /**
         * Continue with Twilio
         */
        continueWithSms () {
            if (this.numberToActivate) {
                const providerToConfigure = this.connectedProvider.attributes.name.toLowerCase();
                this.$router.push({ name: `${providerToConfigure}-config:${this.$i18n.locale}` });
            }
            else {
                this.$router.push({ name: `config-sms-connectors:${this.$i18n.locale}` });
            }
        }
    }
};
</script>
