/**
 * ImportMixin
 *
 * Add common methods to import resources
 */

export default {
    methods: {
        /**
         * Called on change file upload.
         */
        onChangeFileUpload () {
            if (typeof this.$refs.file.files[0] !== 'undefined') {
                this.fileChosen = this.$refs.file.files[0].name;
                this.fileExists = true;
            } else {
                this.fileChosen = this.$t('no-file-chosen');
                this.fileExists = false;
            }
        },

        /**
         * Download Template excel file for contact import
         */
        downloadFile () {
            this.template()
                .then((url) => {
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'template.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(() => {
                    console.log('error');
                });
        },

        /**
         * Remove the selected files
         */
        removeFile () {
            this.$refs.file.value = null;
            this.fileChosen       = this.$t('no-file-chosen');
            this.fileExists       = false;
        }
    }
};
