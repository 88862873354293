/**
 * MyConversationMixin
 *
 * Add methods and common states for Conversation Sms connector
 */

// Import external libraries
import {
    mapState, mapActions, mapGetters
} from 'vuex';
import Format from '@/helpers/Format.js';

export default {
    data () {
        return {
            smsNumber:      '',
            messageCounter: 0,
            isMobile:       false,

            // Loadings
            openingConversations:       false,
            shownConversation:          false,
            currentUserLoading:         true,
            noUsersLoading:             true,
            loadMoreCurrentUserLoading: false,
            loadMoreNoUsersLoading:     false
        };
    },

    watch: {
        newMessage () {
            this.updateConversations();
        },

        currentContactDisabled () {
            this.openingConversations = true;
            this.shownConversation    = false;
            this.updateConversations();
        }
    },

    computed: {
        ...mapState('core/auth', [
            'user'
        ]),

        ...mapGetters('core/number', ['numberToActivate']),

        /**
         * State of SMS phone Number
         *
         * phoneNumber    Active SMS phoneNumber State
         */
        ...mapState('core/number', [
            'phoneNumber'
        ]),

        /**
         * Getter of SMS conversations
         */
        ...mapGetters('core/conversations', [
            'convoContacts'
        ]),

        /**
         * State of tenants
         *
         * tenants           State with list of all tenants
         * selectedTenant    State of the tenant the user has selected
         */
        ...mapState('core/tenants', { selectedTenant: 'selectedTenant' }),

        ...mapState('core/conversations', [
            'newMessage',
            'currentContactDisabled',
            'activeContactId',
            'contactHasConversations',
            'contactConversations',
            'currentUserConversations',
            'otherUsersConversations',
            'noUsersConversations'
        ])
    },

    mounted () {
        // Get the SMS numbers linked to the tenant
        this.getPhoneNumber({}).then(() => {
            // Get the attributes of the SMS number to
            for (const numberId in this.phoneNumber) {
                this.smsNumber = Format.formatPhoneNumber(this.phoneNumber[numberId].attributes['phone-number']);
            }
        });

        this.loadConversations();

        // Listen to the websocket event
        this.update({ tenantId: this.selectedTenant.id, filters: { 'is-active': 1 } });
    },

    methods: {
        /**
         * Methods to get SMS active Number API request
         *
         * getPhoneNumber     API list sms numbers
         */
        ...mapActions('core/number', { getPhoneNumber: 'index' }),

        // Get conversations for a single contact
        ...mapActions('core/contacts', [
            'getSingle'
        ]),

        /**
         * GetConversations method to get SMS list of conversations
         * update           method to get SMS message real time
         *
         */
        ...mapActions('core/conversations', {
            getConversations:         'index',
            getContactConversations:  'getContactConversations',
            update:                   'update',
            stopListeningToWebsocket: 'stopListeningToWebsocket',
            setActiveContact:         'setActiveContact',
            resetSingleConversation:  'resetSingleConversation'
        }),

        /**
         * Get the conversations from the API and show the first one.
         */
        loadConversations () {
            // Init the conversation board and then load new data
            this.resetSingleConversation();
            this.setActiveContact({ contactId: null });

            // Start loadings
            this.currentUserLoading = true;
            this.noUsersLoading     = true;

            this.updateConversations();
        },

        /**
         * Update the list of current and without users conversations
         */
        updateConversations () {
            this.loadCurrentUserConversations();
            this.loadWithoutUsersConversations();
        },

        /**
         * Load the current user conversations
         */
        loadCurrentUserConversations (append = false, page = 1) {
            this.getConversations({
                filters: {
                    'related-to':              'current-user',
                    'is-active':               1,
                    'with-subscribed-contact': 1
                },
                sort: '-waiting-messages',
                page: {
                    number: page,
                    size:   10
                },
                append
            }).then(() => {
                if (this.currentUserConversations.conversations.length !== 0 && !this.shownConversation) {
                    const contactId = this.convoContacts.find((contact) => contact.id == this.currentUserConversations.conversations[0].relationships.contact.data.id).attributes['parent-id'];
                    this.openConversation(contactId, { 'is-active': 1 });
                } else if (this.openingConversations) {
                    this.shownConversation    = false;
                    this.openingConversations = false;
                }

                this.currentUserLoading = false;
                this.loadMoreCurrentUserLoading = false;
            });
        },

        /**
         * Load the conversations without users
         */
        loadWithoutUsersConversations (append = false, page = 1) {
            this.getConversations({
                filters: {
                    'related-to':              'no-users',
                    'with-subscribed-contact': 1,
                    'is-active':               1
                },
                sort: 'waiting-messages',
                page: {
                    number: page,
                    size:   10
                },
                append
            }).then(() => {
                this.noUsersLoading = false;
                this.loadMoreNoUsersLoading = false;
            });
        },

        /**
         * Terminate a conversation
         */
        terminateConversation () {
            this.openingConversations = true;

            const newContactId = this.currentUserConversations.conversations.length !== 0 ?
                this.convoContacts.find((contact) => contact.id == this.currentUserConversations.conversations[0].relationships.contact.data.id).attributes['parent-id'] :
                null;

            this.setActiveContact({ contactId: newContactId });
            if (newContactId === null) {
                this.shownConversation    = false;
                this.openingConversations = false;
            } else {
                this.openConversation(newContactId, { 'is-active': 1 });
            }
        },

        /**
         * Send event to open conversation in the right panel
         *
         * @param  {string}   contactId
         * @param  {object}   filters   like is-active filter
         * @return  void
         */
        openConversation (contactId, filters) {
            this.openingConversations = true;

            this.getSingle({ id: contactId }).then(() => {
                this.getContactConversations({
                    id: contactId,
                    filters
                }).then(() => {
                    this.setActiveContact({ contactId });
                    this.shownConversation    = true;
                    this.openingConversations = false;
                });
            });
        },

        /**
         * Infinite scroll end reached callback from infinite scroll mixin.
         */
        infiniteScrollEndReached (page) {
            // Disable the infinite scroll when the user reaches the end
            this.disableInfiniteScroll();

            switch (this.infiniteScrollTarget) {
                case 'active-sms':
                    // Do not load more if the user is already on the last page
                    if (this.currentUserConversations.meta.page['current-page'] >= this.currentUserConversations.meta.page['last-page']) {
                        return;
                    }

                    this.loadMoreCurrentUserLoading = true;
                    this.loadCurrentUserConversations(true, page);
                    break;

                case 'no-users-conversation':
                    // Do not load more if the user is already on the last page
                    if (this.noUsersConversations.meta.page['current-page'] >= this.noUsersConversations.meta.page['last-page']) {
                        return;
                    }

                    this.loadMoreNoUsersLoading = true;
                    this.loadWithoutUsersConversations(true, page);
                    break;
            }
        }
    },

    // Destroy Event Listener
    beforeDestroy () {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize, { passive: true });
            return false;
        }
    },

    destroyed () {
        this.stopListeningToWebsocket(this.selectedTenant.id);
    }
};
