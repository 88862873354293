var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal",attrs:{"id":"modal-result-config"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content px-5 py-3"},[_c('div',{staticClass:"modal-body body-result-config"},[_c('div',{staticClass:"row",attrs:{"id":"content-pageResult-setup-connector"}},[_c('div',{staticClass:"text-right",class:{
                            'col-4 ml-3 ': _vm.result.status === '200',
                            'col-3': _vm.result.status !== '200'
                        }},[_c('img',{class:{'img-oups': _vm.result.status !== '200'},attrs:{"src":_vm.getImgUrl(_vm.result.status !== '200' ? 'oups-pink' : 'celebration')}})]),_c('div',{class:{
                            'col-7 ml-3': _vm.result.status === '200',
                            'col-9': _vm.result.status !== '200',
                        }},[_c('div',{staticClass:"paragr-result-of-config",class:{
                                'paragr-result-success': _vm.result.status === '200',
                                'paragr-result-fail': _vm.result.status !== '200'
                            }},[_vm._v(" "+_vm._s(_vm.result.status=== '200' ? _vm.$i18n.t('Congrats') : _vm.$i18n.t('fail'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.getMsgResultConfig())+" "),(_vm.result.status !== '200')?_c('p',{staticClass:"msg-error"},[_vm._v(" "+_vm._s(_vm.result.code === '403' ? _vm.$i18n.t("error-api-key") : _vm.$i18n.t("error-config-sms"))+" ")]):_vm._e()]),_c('div',{staticClass:"bloc-btn-page-result pt-2t text-left",class:{
                                'pt-3': _vm.result.status === '200'
                            },attrs:{"data-dismiss":"modal"}},[_c('button',{staticClass:"btn btn-primary rounded-pill btn-close-afterConfig",class:{
                                    'ml-5': _vm.result.status !== '200'
                                },on:{"click":function($event){$event.preventDefault();return _vm.onClickBtnResult.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.getNameBtn())+" ")])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }