export const SET_AUTH_USER           = 'SET_AUTH_USER';
export const SET_AUTH_USER_LINK      = 'SET_AUTH_USER_LINK';
export const REMOVE_AUTH_USER        = 'REMOVE_AUTH_USER';
export const REMOVE_AUTH_USER_LINK   = 'REMOVE_AUTH_USER_LINK';
export const UPDATE_USER_PICTURE     = 'UPDATE_USER_PICTURE';
export const DELETE_USER_PICTURE     = 'DELETE_USER_PICTURE';
export const UPDATE_IDP_ID           = 'UPDATE_IDP_ID';
export const SET_QR_AND_SECRET_CODES = 'SET_QR_AND_SECRET_CODES';
export const SET_USER_ACTIVE_2FA     = 'SET_USER_ACTIVE_2FA';
export const SET_2FA_RECOVERY_CODES  = 'SET_2FA_RECOVERY_CODES';
