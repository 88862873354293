export default {
    methods: {
        /**
         * Receives a JSON:API link and parse it
         *
         * @param      {string}  link    The link
         * @return     {string}  The parsed link
         */
        receiveApiLink: (link) => {
            const NOT_FOUND = -1;
            let parsedLink = link;

            /**
             * Make sure the resource link returned by the API is HTTPS
             * Happens depending on the environment
             */
            if (parsedLink.indexOf('https') === NOT_FOUND) {
                parsedLink = parsedLink.replace('http', 'https');
            }

            return parsedLink;
        }
    }
};
