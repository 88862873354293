<template>
    <div id="account-parameters" class="d-flex align-items-stretch">
        <navigation></navigation>
        <div class="account-container container-fluid">
            <div class="text-left">
                <h3 class="mb-4">{{$t('workspace-settings')}}</h3>
            </div>
            <div class="card-info text-left">
                <div class="tenant-avatar mb-4 pl-2 pr-2">
                    <label class="mb-0 p-2">
                        <div class="position-relative">
                            <label class="label-tenant" :style="'--tenant-color:' + tenantColor" v-if="workspaceImage === null">
                                <model-short-name class="text-center" v-bind:model="tenant"></model-short-name>
                            </label>
                            <img class="tenant-img img-fluid" :src="workspaceImage" alt="" v-if="workspaceImage !== null">
                            <font-awesome-icon class="delete-icon" :icon="['fas', 'times-circle']" @click="deleteImage()" v-if="workspaceImage !== null"/>
                        </div>
                    </label><br/>
                    <input type="file" class="form-control-file" accept="image/*" @change="uploadImage()" id="tenantImage" ref="file" hidden>
                    <label for="tenantImage" class="label-avatar text-primary ml-2">{{ $t('change') }}</label>
                </div>
                <div class="tenant-name mb-4 pl-3 pr-3">
                    <p>{{ $t('tenant-name') }}</p>
                    <input type="text" class="form-control" :placeholder="$t('tenant-name')" name="" id="" v-model="workspaceName">
                </div>
                <div class="d-flex flex-row-reverse mb-4 pl-3 pr-3">
                    <button
                        class="btn btn-primary rounded-pill d-flex"
                        @click.prevent="changeWorkSpaceName()"
                        :disabled="workspaceNameChanged"
                    >
                        {{ $t('submit') }}
                    </button>
                </div>
                <hr class="ml-3 mt-5 mb-5 mr-3">
                <div class="plan-info mb-5 pl-3 pr-3">
                    <p>{{ $t('your-plan') }}: {{ userPlan }} {{ userAddons }}</p>
                    <p v-if="user.attributes['is-admin'] || user.attributes['is-super-admin']">
                        {{ $t('payment-info') }}
                        <a href="javascript:void(0)" @click.prevent="openPortalSession">
                            {{ $t('click-here') }}
                        </a>
                    </p>
                </div>
                <div class="footer mt-5 pl-3 pr-3">
                    <div class="footer-info">
                        <p class="text-center">{{ $t('more-options') }}</p>
                        <p class="text-center">
                            <router-link :to="{ name: `cocoapp-wordpress-packages:${this.$i18n.locale}` }" target="_blank">
                                {{ $t('consult-plans') }}
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal components
import ModelShortName      from './ModelShortName.vue';
import chargbeePortalMixin from '@/core/mixins/chargbeePortalMixin';

// Internal services js
import Alert        from '@/helpers/Alert.js';
import { EventBus } from '@/helpers/EventBus.js';

// Import external libraries
import { mapState, mapActions } from 'vuex';

export default {
    components: { ModelShortName },

    mixins: [chargbeePortalMixin],

    data () {
        return {
            updateTenants:  false,
            tenantColor:    '',
            workspaceName:  '',
            workspaceImage: null
        };
    },

    computed: {
        // State with the user
        ...mapState('core/auth', [
            'user'
        ]),

        // State with the tenant
        ...mapState('core/tenants', { tenant: 'selectedTenant' }),

        /**
         * Decide when submit button must be disabled
         * Submit button must be disabled when workspace name does not change or is empty.
         */
        workspaceNameChanged () {
            return this.workspaceName == this.tenant.attributes.name || this.workspaceName == '';
        },

        /**
         * Return the user's plan based on the remote plan id
         */
        userPlan () {
            return this.$i18n.t(this.tenant.attributes['remote-plan-id']);
        },

        /**
         * Return the user addons based on the remote plan addons
         */
        userAddons () {
            if (this.tenant && this.tenant.attributes['remote-plan-addons'] !== null) {
                const addonsArray = this.tenant.attributes['remote-plan-addons'].map((item) => this.$i18n.t(`addon-${item.id}`));
                const addonsString = addonsArray.join(', ');
                return `(${addonsString})`;
            }
            return '';
        }
    },

    mounted () {
        // Set color for tenant when no image represent the tenant avatar
        this.tenantColor = this.tenant.attributes['default-color'] || '#FFDEEB';

        this.workspaceName = this.tenant.attributes.name;
        this.workspaceImage = this.tenant.attributes['profile-picture'];
    },

    methods: {
        /**
         * Methods for Tenants API request
         *
         * updloadTenantPicture     API request to upload avatar image for tenant
         * deleteTenantPicture      API request to remove avatar image for tenant
         */
        ...mapActions('core/tenants', [
            'updloadTenantPicture',
            'deleteTenantPicture',
            'update'
        ]),

        /**
         * Upload image for tenant avatar
         */
        uploadImage () {
            if (!this.$refs.file.files[0]) {
                return;
            }

            this.updloadTenantPicture({
                id:    this.tenant.id,
                image: this.$refs.file.files[0]
            })
                .then(() => {
                    // Using timestamp (Date.now()) to update the new image.
                    this.workspaceImage = `${this.tenant.attributes['profile-picture']}?${Date.now()}`;
                    Alert.success(this.$i18n.t('workspace-image-modified'));

                    EventBus.$emit('tenant-image-updated');
                })
                .catch(() => {
                    Alert.fail(this.$i18n.t('default-error'));
                });
        },

        /**
         * Remove image for tenant avatar
         */
        deleteImage () {
            Alert.deleteConfirmation(this.$i18n.t('confirm-delete-picture'))
                .then((response) => {
                    if (!response.value) {
                        return;
                    }

                    this.deleteTenantPicture({ id: this.tenant.id }).then(() => {
                        this.workspaceImage = null;
                        EventBus.$emit('tenant-image-updated');
                    });
                });
        },

        /**
         * Update workspace name
         */
        changeWorkSpaceName () {
            this.update({
                id:   this.tenant.id,
                name: this.workspaceName
            })
                .then(() => {
                    Alert.success(this.$i18n.t('workspace-name-modified'));
                })
                .catch(() => {
                    Alert.fail(this.$i18n.t('default-error'));
                });
        }
    }
};
</script>
