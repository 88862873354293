import Api from '@/helpers/Api.js';
import qs from 'qs';

const resource = '/connectors/facebook/v1/posts';

export default {
    /**
     * Get single post
     *
     * @param    {string}    postId    The post id
     *
     * @return    {object}    jsonapi response
     */
    get (postId) {
        const urlParams = {
            include: 'author,comments,comments.author'
        };

        return Api.get(`${resource}/${postId}`, {
            params:           urlParams,
            paramsSerializer: params => qs.stringify(params, {
                encode: false
            })
        });
    },

    /**
     * Get reactions for post
     *
     * @param   {string}    postId   The post id
     *
     * @return    {object}    ApiJson response
     */
    getReactions (postId) {
        return Api.get(`${resource}/${postId}/reactions`);
    },

    /**
     * Update posts
     *
     * @param   {string}    postId       The post id
     * @param   {object}    attributes   The post new attributes
     *
     * @return    {object}    ApiJson response
     */
    update (postId, attributes) {
        return Api.patch(`${`${resource}/${postId}`}`, {
            'data': {
                'type': 'posts',
                'id':   postId,
                attributes
            }
        });
    }
};
