<template>
    <div :id="`main-menu${menuIdSuffix}`" class="position-relative" v-if="this.$route.params.welcome !== true">
        <tenant-bar></tenant-bar>
        <transition name="collapsemenu" v-if="collapsedMenu">
            <menu-collapse></menu-collapse>
        </transition>
        <transition name="expandmenu" v-else>
            <Menu></Menu>
        </transition>
        <import-contacts></import-contacts>
        <form-add-contact :type="'SMS'"></form-add-contact>
    </div>
</template>
<script>

// Internal componants
import ImportContacts  from '../components/contacts/ImportContacts.vue';
import FormAddContact  from '../components/contacts/FormAddContact.vue';
import TenantBar       from './TenantBar.vue';
import Menu            from './Menu.vue';
import MenuCollapse    from './MenuCollapse.vue';

// Helpers
import { EventBus } from '@/helpers/EventBus.js';

// Internal services js
import MenuMixin from '@/core/mixins/MenuMixin';

export default {
    mixins: [MenuMixin],

    components: {
        TenantBar,
        ImportContacts,
        FormAddContact,
        Menu,
        MenuCollapse
    },

    computed: {
        /**
         * The menu ID suffix
         *
         * @return {string}
         */
        menuIdSuffix () {
            return this.collapsedMenu ? '-collapse' : '';
        }
    },

    watch: {
        collapsedMenu () {
            const time = 300;
            setTimeout(() => {
                EventBus.$emit('tenant-image-updated');
            }, time);
        }
    },

    methods: {
        /**
         *
         * Collapse menu automatically if the width of the window is less than 1080
         */
        updateMenu () {
            if (window.innerWidth < '1080') {
                this.SET_MENU_STATE(true);
            }
        }
    },

    mounted () {
        window.addEventListener('resize', this.updateMenu);
        this.updateMenu();
    }
};
</script>
