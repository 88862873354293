import Api from '@/helpers/Api.js';

// Import mutations
import {
    SET_AUTH_USER,
    SET_AUTH_USER_LINK,
    REMOVE_AUTH_USER,
    REMOVE_AUTH_USER_LINK,
    UPDATE_USER_PICTURE,
    DELETE_USER_PICTURE,
    UPDATE_IDP_ID,
    SET_QR_AND_SECRET_CODES,
    SET_USER_ACTIVE_2FA,
    SET_2FA_RECOVERY_CODES,
} from './mutation-types.js';

const resource = '/internal/v1/auth';

// Initial state
const state = () => ({
    user:     null,
    userLink: null,

    // Identifier Provider ID, useful for SAML authentication
    idProviderId: null,

    // Two factors section
    qrCode:               '',
    secret:               null,
    isTwoFactorsRequired: false,
    recoveryCodes:        []
});

// Getters
const getters = {
};

// Actions
const actions = {
    /**
     * Send user's login information.
     *
     * @param      {Object}  context.commit    The commit
     * @param      {string}  payload.email     The email
     * @param      {string}  payload.password  The password
     *
     * @return     {Promise}
     */
    login: (context, { email, password }) => {
        return Api.post(`${resource}`, {
            'data': {
                'attributes': {
                    email,
                    password
                }
            }
        })
            .then(response => {
                if (response.data.data.attributes && response.data.data.attributes['is-two-factor-required']) {
                    context.commit(SET_USER_ACTIVE_2FA, true);
                } else {
                    context.commit(SET_AUTH_USER_LINK, response.data.data.links.user);
                }
            });
    },

    /**
     * Send SAML user's login request.
     *
     * @param      {Object}  context.commit    The commit
     *
     * @return     {Promise}
     */
    samlLogin: (context, { id, tenantId, token }) => {
        return new Promise((resolve, reject) => {
            Api.post(`/internal/v1/saml-auth`, {
                'data': {
                    'attributes': {
                        'token':     token,
                        'id':        id,
                        'tenant-id': tenantId
                    }
                }
            })
                .then(response => {
                    context.commit(SET_AUTH_USER_LINK, response.data.data.links.user);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Logout user.
     *
     * @param      {Object}  context.commit    The commit
     *
     * @return     {Promise}
     */
    logout: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}/logout`)
                .then((response) => {
                    context.commit(REMOVE_AUTH_USER);
                    context.commit(REMOVE_AUTH_USER_LINK);
                    context.commit(SET_USER_ACTIVE_2FA, false);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Gets the authenticated user.
     *
     * @param      {Object}   context  The context
     *
     * @return     {Promise}  The authenticated user.
     */
    getAuthenticatedUser: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(context.state.userLink)
                .then((response) => {
                    context.commit(SET_AUTH_USER, response.data.data);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Send user email to send reset password link.
     *
     * @param      {Object}  context     The context
     * @param      {string}  payload.email  The email
     *
     * @return     {Promise}
     */
    forgotPassword: (context, { email }) => {
        return Api.post(`${resource}/forgot-password`, {
            'data': {
                'attributes': {
                    email
                }
            }
        });
    },

    /**
     * Send user's information to reset password.
     *
     * @param      {Object}  context                  The context
     * @param      {string}  payload.token            The token
     * @param      {string}  payload.email            The email
     * @param      {number}  payload.password         The password
     * @param      {string}  payload.confirmPassword  The confirm password
     *
     * @return     {Promise}
     */
    resetPassword: (context, { token, email, password, confirmPassword }) => {
        return Api.post(`${resource}/reset-password`, {
            'data': {
                'attributes': {
                    token,
                    email,
                    password,
                    'password-confirmation': confirmPassword
                }
            }
        });
    },

    /**
     * Update the ID provider Id.
     *
     * @param      {Object}  context     The context
     * @param      {string}  payload.id  The id
     *
     * @return     {Promise}
     */
    updateIdPId: (context, { id }) => {
        return new Promise((resolve) => {
            context.commit(UPDATE_IDP_ID, id);
            resolve();
        });
    },

    // Two Factors authentication section

    /**
     * Enable two factor authentication.
     *
     * @param      {Object}  context     The context
     *
     * @return     {Promise}
     */
    enableTwoFactorAuthentication: (context) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/two-factor/enable`)
                .then(response => {
                    context.commit(SET_QR_AND_SECRET_CODES, response.data.data.attributes);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Confirm two factor authentication.
     *
     * @param      {Object}  context     The context
     * @param      {string}  code        The confirmation code
     *
     * @return     {Promise}
     */
    confirmTwoFactorAuthentication: (context, { code }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/two-factor/confirm`, {
                'data': {
                    'attributes': {
                        code
                    }
                }
            })
                .then(response => {
                    context.commit(SET_2FA_RECOVERY_CODES, response.data.data.attributes['recovery-codes']);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Challenge two factor authentication.
     *
     * @param      {Object}  context     The context
     * @param      {string}  code        The confirmation code
     *
     * @return     {Promise}
     */
    challengeTwoFactorAuthentication: (context, { code }) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}/two-factor/challenge`, {
                'data': {
                    'attributes': {
                        code
                    }
                }
            })
                .then(response => {
                    context.commit(SET_AUTH_USER_LINK, response.data.data.links.user);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Disable two factor authentication.
     *
     * @return  {Promise}
     */
    disableTwoFactorAuthentication: () => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/two-factor/disable`)
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {
    [SET_AUTH_USER]: (state, payload) => {
        state.user = payload;
    },
    [SET_AUTH_USER_LINK]: (state, payload) => {
        state.userLink = payload;
    },
    [REMOVE_AUTH_USER]: (state) => {
        state.user = null;
    },
    [REMOVE_AUTH_USER_LINK]: (state) => {
        state.userLink = null;
    },
    [UPDATE_USER_PICTURE]: (state, payload) => {
        state.user.attributes['profile-picture'] = payload.links['profile-picture'];
    },
    [DELETE_USER_PICTURE]: (state) => {
        state.user.attributes['profile-picture'] = null;
    },
    [UPDATE_IDP_ID]: (state, id) => {
        state.idProviderId = id;
    },
    [SET_QR_AND_SECRET_CODES]: (state, payload) => {
        state.qrCode = payload['qr-code'];
        state.secret = payload['secret'];
    },
    [SET_USER_ACTIVE_2FA]: (state, isRequired) => {
        state.isTwoFactorsRequired = isRequired;
    },
    [SET_2FA_RECOVERY_CODES]: (state, codes) => {
        state.recoveryCodes = codes;
    },
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
