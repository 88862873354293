<template>
    <div id="connector-box" class="d-flex container-fluid connector-card-info message-card">
        <div class="row w-100" v-if="smsNotActivated">
            <div class="container-fluid card-info message-card mb-0" id="card-confing-sms">
                <div class="sms-row row d-flex align-items-center align-content-center">
                    <div class="d-flex">
                        <div>
                            <img  src="@/core/assets/images/phone-sms-configuration.png" :alt="$t('sms-configuration')" width="150px" class="connector-sms-img"/>
                        </div>
                        <h1 class="mb-0 d-flex">
                            <span class='connect-system-sms col-sm text-left mb-0'>
                                {{$t('connection-sms')}}<br>{{$t('system')}}
                            </span>
                        </h1>
                    </div>
                    <div class="col-sm pr-5">
                        <div class="pr-5 d-flex justify-content-end" v-if="loading">
                            <span class="spinner spinner-border text-primary"></span>
                        </div>
                        <continue-with-SMS :isConnected="connector.attributes['is-connected']" v-else></continue-with-SMS>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row w-100">
            <div class="col-6 text-left" id="card-configured-sms">
                <div class="d-flex">
                    <div>
                        <img  src="@/core/assets/images/phone-sms-configuration.png" alt="SMS Configuration" width="150px" class="connector-sms-img"/>
                    </div>
                    <h1 class="mb-0 d-flex">
                        <span class='connect-system-sms col-sm text-left mb-0'>
                            {{$t('connection-sms')}}<br>{{$t('system')}}
                        </span>
                    </h1>
                </div>
            </div>
            <div class="col-6" v-if="!loading">
                <div class="connected">
                    <div class="row" v-if="connector.attributes.status === $config.connectorsStatus.ESTABLISHED">
                        <div class="col-12">
                            <p class="connected-p">
                                <font-awesome-icon class="text-primary font-check-circle" :icon="['fas', 'check-circle']" />
                                <strong class="text-primary">{{ $t('connection-established') }}</strong>
                            </p>
                        </div>
                        <div class="col-12 connected-by-message" v-if="connector.attributes['connected-by']">
                            <small class="text-grey small-connected-by" v-if="connector.attributes['connected-by']">
                                {{ $t('established-by') }}: {{ connector.attributes['connected-by'] }}
                            </small><br>
                        </div>
                        <div class="col-12 view-settings-link" v-if="activatedProvider">
                            <router-link
                                :to="{ name: `${activatedProvider.attributes.name.toLowerCase()}-activated:${$i18n.locale}` }"
                                class="text-left text-btn"
                                v-if="user.attributes['is-admin'] || user.attributes['is-super-admin']">
                                {{ $t('view-settings') }}
                            </router-link>
                        </div>
                    </div>
                    <div class="text-left" v-if="connector.attributes.status === $config.connectorsStatus.EXPIRED">
                            <p class="mt-5 ml-4">
                                <font-awesome-icon class="exclamation-triangle" :icon="['fas', 'exclamation-triangle']"/>
                                <router-link
                                    :to="{ name: `${connector.attributes.name}-config:${$i18n.locale}` }"
                                    class="text-left warning-link"
                                    v-if="user.attributes['is-admin'] || user.attributes['is-super-admin']">
                                    <strong class="warning-text">
                                        {{ $t('restore-connection') }}
                                    </strong>
                                </router-link>
                            </p>
                            <div class="connection-info" v-if="connector.attributes['connected-by']">
                                <small class="text-grey ml-5">
                                    {{ $t('last-connection-established') }}:<br>
                                </small>
                                <small class="text-grey ml-5">
                                    {{ connector.attributes['connected-by'] }}
                                </small>
                            </div>
                        <div class="learn-more-link mt-3 ml-5">
                            <router-link :to="{ name: `help:${$i18n.locale}` }" class="text-left more-info connection-info">{{ $t('learn-more') }}</router-link>
                        </div>
                    </div>
                    <div
                        v-for="provider in providers"
                        :key="provider.id">
                        <router-link
                            :to="{ name: `${connector.attributes.name}-config:${$i18n.locale}` }"
                            class="button-link" v-if="connector.attributes.status === $config.connectorsStatus.UNESTABLISHED">
                            <button
                                class="connection-btn btn activateSMS"
                                @click="this.selectProvider({ id: provider.id });">
                                <img class="button-logo" src="/images/logo-twilio-white.png" alt="">
                                {{$t('activate-sms')}}
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-6 text-center d-flex justify-content-center" v-else>
                <span class="spinner spinner-border text-primary spinner align-self-center"></span>
            </div>
            <div class="d-flex justify-content-center w-100">
                <small class="justify-content-center"><em>{{ $t('only-one-provider') }}</em></small>
            </div>
        </div>
    </div>
</template>
<script>

// Internal services js
import ConnectorMixin from '@/core/mixins/ConnectorMixin';

// Import external libraries
import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex';
import ContinueWithSMS from '@/connectors/sms/components/ContinueWithSMS';

// Import Really Fast Deep Copy
const clone = require('rfdc')();

export default {
    mixins: [ConnectorMixin],

    components: { ContinueWithSMS },

    data () {
        return {
            connectorName: 'sms'
        };
    },

    created () {
        if (this.sms.isConnected) {
            this.loading = true;
            this.getProviders()
                .then(() => {
                    this.listNumber().then(() => {
                        this.loading = false;
                    });
                });
        }
    },

    computed: {
        ...mapGetters('core/number', ['numberToActivate']),

        smsNotActivated () {
            return !this.sms.isEstablished || this.numberToActivate !== null;
        },

        /**
         * Get the activated sms provider :
         *
         */
        activatedProvider () {
            const activatedProvider = clone(Object.values(this.providers).filter((provider) => {
                return provider.attributes.isConnected === true;
            }));
            return activatedProvider.length > 0 ? activatedProvider[0] : null;
        },

        /**
         * Compute the logo of the activated provider
         */
        connectorLogo () {
            if (this.activatedProvider) {
                return this.$config.images.connectorsLogo[this.activatedProvider.attributes.name.toLowerCase()];
            }

            return null;
        },

        /**
         * State with to for Providers API request
         *
         * List of providers {object}
         * selectedProvider    {object}
         */
        ...mapState('core/providers', [
            'providers',
            'selectedProvider'
        ]),

        ...mapState('core/connectors', [
            'connectors',
            'sms'
        ])
    },

    methods: {
        /**
         * Methods for Providers API request
         *
         * getProviders     Gets Provider list from API
         * selectProvider   Select a provider from the list, place in State
         */
        ...mapActions('core/providers', {
            getProviders:   'index',
            selectProvider: 'select'
        }),

        ...mapActions('core/number', { listNumber: 'index' })
    }
};
</script>
