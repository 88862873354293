<template>
    <div id="tag-page" class="d-flex align-items-stretch">
        <navigation></navigation>
        <tags-manager></tags-manager>
    </div>
</template>
<script>

// Components
import TagsManager  from './TagsManager.vue';

export default {
    components: {
        TagsManager
    }
};
</script>
