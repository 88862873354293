<template>
    <div id="header">
        <div class="container-fluid">
            <div class="row justify-content-end mt-3">
                <div class="ml-4 align-self-center rounded-circle help-icon">
                    <router-link class="text-white" :to="{ name : `help:${$i18n.locale}` }"><p>?</p></router-link>
                </div>
                <div class="ml-4 dropdown align-self-center">
                    <a class="user-icon" href="#" role="button" id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <model-short-name v-if="profilePicture === null" v-bind:model="user"></model-short-name>
                        <img v-if="profilePicture !== null" class="header-Image mb-0" :src="profilePicture" alt="">
                    </a>
                     <div class="dropdown-menu user-menu select-dropdown" aria-labelledby="userDropdown">
                        <button class="dropdown-item" data-toggle="modal" data-target="#edit-user">
                            {{ $t('profile') }}
                        </button>
                        <button
                            class="dropdown-item"
                            v-for="language in availableLanguages"
                            :key="language.locale"
                            @click="changeLanguage(language.locale)"
                        >
                            {{ language.name }}
                        </button>
                        <button class="dropdown-item" @click="logout()">{{ $t('logout') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// Internal services js
import { EventBus }   from '../../../helpers/EventBus.js';
import ModelShortName from '../../components/ModelShortName.vue';

import { Validator } from 'vee-validate';

// Import external libraries
import { mapState, mapActions } from 'vuex';
import _ from 'underscore';
import moment from 'moment';

export default {
    components: {
        ModelShortName
    },

    data () {
        return {
            role:           '',
            password:       false,
            profilePicture: '',
            languages:      [
                {
                    locale: 'fr',
                    name:   'Français'
                },
                {
                    locale: 'en',
                    name:   'English'
                }
            ]
        };
    },

    computed: {
        ...mapState('core/auth', [
            'user',
            'idProviderId'
        ]),

        ...mapState('core/session', [
            'sessionExists'
        ]),

        /**
         * State of tenants
         *
         * selectedTenant    State of the tenant the user has selected
         */
        ...mapState('core/tenants', {
            selectedTenant: 'selectedTenant'
        }),

        /**
         * Available Languages
         * Removed the currently selected language
         *
         * @return     {array}
         */
        availableLanguages: function () {
            return _.filter(this.languages, (language) => language.locale !== this.$i18n.locale);
        }
    },

    mounted () {
        this.loadProfilePicture();

        // Listen for update profile picture event.
        EventBus.$on('informationsUpdated', () => {
            this.loadProfilePicture();
        });
    },

    /**
     * Check function
     * Before create this page check if user are login and Facebook is connect.
     */
    beforeCreate () {
        if (this.sessionExists === false) {
            this.$router.push({
                name: 'login'
            });
            return;
        }

        // If locale is set in the local storage, use it
        this.$i18n.locale = localStorage.getItem('locale')
            ?? process.env.VUE_APP_I18N_DEFAULT_LOCALE;

        moment.locale(localStorage.getItem('locale') ?? process.env.VUE_APP_I18N_DEFAULT_LOCALE);
    },

    methods: {
        ...mapActions('core/users', [
            'patch'
        ]),

        ...mapActions('core/auth', {
            logoutUser: 'logout'
        }),

        ...mapActions('core/session', [
            'setSessionExists'
        ]),

        /**
         * Get and show the user profile picture
         */
        loadProfilePicture () {
            // If user have a profile picture
            if (this.user.attributes['profile-picture']) {
                // Use timestamp (Date.now()) show the new image.
                this.profilePicture = `${this.user.attributes['profile-picture']}?${Date.now()}`;
                return;
            }

            this.profilePicture = null;
        },

        /**
         * Logout
         * Call logout function in api to destroy user cookies
         */
        logout () {
            let isSamlUser = this.user.attributes['is-saml'];
            this.logoutUser()
                .then(() => {
                    this.setSessionExists({ sessionExists: false });
                    window.localStorage.clear();
                    if (isSamlUser) {
                        // Redirect saml user to the IDP logout link
                        window.location.replace(`${process.env.VUE_APP_APIURL}/saml/${this.selectedTenant.id}/${this.idProviderId}/logout`);
                    } else {
                        this.$router.push({
                            name: 'login'
                        });
                    }
                });
        },

        /**
         * Change the current language
         *
         * @param    {string}    locale    The new locale
         *
         * @return    void
         */
        changeLanguage (locale) {
            if (this.$i18n.locale === locale) {
                return;
            }

            // Change the user locale
            this.patch({
                id:         this.user.id,
                attributes: {
                    language: locale
                }
            })
                .then(() => {
                    // Set the locale into local storage
                    window.localStorage.setItem('locale', locale);

                    // Change the i18n and moment locale
                    this.$i18n.locale = locale;
                    moment.locale(locale);

                    // Change Vee Validator locale
                    Validator.localize(locale);

                    // Go to the translated route
                    if (this.$route.meta.locale !== 'undefined' && this.$route.meta.locale !== locale) {
                        // Change page URL
                        this.$router.push({
                            name: this.$route.name.replace(this.$route.meta.local, locale)
                        });
                    }
                });
        }
    }
};
</script>
