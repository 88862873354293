<template>
    <div class="modal" id="modal-help-get-apiKey" >
        <div class="modal-dialog modal-xl">
            <div class="modal-content px-5 py-3">
                <div class="modal-body help-config-body">
                     <div id="content-page-setup-connector">
                        <div class="row justify-content-center" id="bloc-steps-config">
                            <div class="col-12">
                                <h2 class="title-step">{{$t(helpSteps.name)}}</h2>
                                <p class="descrp-step" v-html="$t(helpSteps.description)" ></p>
                            </div>
                        </div>
                        <div class="row bloc-steps-config-fields"
                            v-for="details in helpSteps.details" v-bind:key="details.img">
                            <div v-if="details.img!=='' " class="bloc-img-step col-12">
                                <template v-if="details.actionImg!=='' && showLightBoxPic">
                                        <VueEasyLightbox
                                            @hide="handleHide"
                                            scrollDisabled escDisabled moveDisabled
                                            :index="0"
                                            :visible="showLightBoxPic"
                                            :ref="details.img"
                                            :class="{
                                                'svg-screenshot-expl-step': details.extention === 'svg',
                                                'img-screenshot-expl-step': details.extention === 'png'
                                            }"
                                            :imgs="[ getImgUrl(details.img, details.extention)]">
                                        </VueEasyLightbox>
                                </template>
                                <template v-else>
                                    <img :class="{'svg-screenshot-expl-step': details.extension ==='svg', 'img-screenshot-expl-step': details.extension ==='png'}"
                                            :src="getImgUrl(details.img, details.extension)"
                                            :alt="details.img"/>
                                </template>
                                <div class="text-right" v-if="details.actionImg!== ''">
                                    <button class="action-img-step" @click="onClickPicBigger()">
                                        {{$t(details.actionImg)}}
                                    </button>
                                </div>
                            </div>
                            <div class="text-left align-self-center bloc-explain-step col-12">
                                <h5 v-if="details.title!== ''"
                                    class="title-explain-step">
                                    {{$i18n.t(details.title)}}
                                </h5>
                                <span v-if="details.description!== ''" class="paragraph-explain-step"
                                    v-html="$t(details.description)">
                                </span>
                                <template v-if="details.fields.length > 0">
                                    <form>
                                        <div class="bloc-inputs" v-for="field in details.fields"
                                            v-bind:key="field.name">
                                            <p class="title-field" v-if="field.name!== ''">{{$i18n.t(field.name)}}</p>
                                            <p class="descrip-field" v-if="field.description!== ''" v-html="$i18n.t(field.description)"></p>
                                            <span class="indice-input" v-if="field.indiceInput!== ''">
                                                {{$t(field.indiceInput)}}
                                            </span>
                                        </div>
                                    </form>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0">
                  <button type="button" class="btn btn-primary rounded-pill close-btn" ref="closeButton" data-dismiss="modal">
                        {{$t('close') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

    name: 'GetApiKeyModal',
    data () {
        return {
            helpSteps: {
                id:          1,
                description: '',
                name:        'how-find-api-key-in-system',
                details:     [
                    {
                        img:         'step-help1',
                        extension:   'png',
                        actionImg:   '',
                        description: 'click-plus-inVudu',
                        title:       'create-new-keyApi-cocoapp',
                        fields:      []
                    },
                    {
                        img:         'step-help2',
                        extension:   'png',
                        actionImg:   '',
                        description: 'name-keyApi-and-copy-it',
                        title:       'name-and-copy',
                        fields:      []
                    }
                ]
            }
        };
    },
    methods: {
        /**
         * Show the VueEasyLightbox to display img (in a bigger way)
         *
         */
        onclickPicBigger () {
            this.showLightBoxPic = true;
        },

        /**
         * Hide the VueEasyLightbox
         *
         */
        handleHide () {
            this.showLightBoxPic = false;
        },

        /**
         * Get the path of pic
         *
         * @param  {string}  pic  name pic
         * @param  {string}  extension  extension pic
         * @return {string}  path img
         */
        getImgUrl (pic, extension = 'png') {
            return require(`@/core/assets/images/img-steps-folio/${pic}.${extension}`);
        },
    }
};
</script>
