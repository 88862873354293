import Api from '@/helpers/Api.js';

const resource = '/connectors/facebook/v1/remote-pages';

export default {
    /**
     * Get all remote pages
     *
     * @return    {object}    jsonapi response
     */
    index () {
        return Api.get(`${resource}`);
    }
};
