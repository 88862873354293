// Initial state
const state = () => ({ collapsedMenu: false });

// Import mutations
import {
    SET_MENU_STATE
} from './mutation-types.js';

// Mutations
const mutations = {
    [SET_MENU_STATE]: (state, payload) => {
        state.collapsedMenu = payload;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    mutations
};
