<template>
    <div class="menu">
        <nav>
            <div class="mb-5">
                <div class="sidebar-header">
                    <div class="w-100 text-right close-menu-btn px-2"
                        @click="SET_MENU_STATE(true)">
                        <a href="#">
                            <font-awesome-icon class="text-btn" :icon="['fas', 'angle-double-left']" />
                        </a>
                    </div>
                    <tenants-avatar :tenant="selectedTenant"></tenants-avatar>
                </div>
                <div v-if="user && (user.attributes['is-admin'] || user.attributes['is-super-admin'])" class="tenant-menu mx-3 dropdown">
                    <a class="d-flex menu-tenant-name justify-content-center align-items-baseline" href="#" role="button" id="tenantDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <h6 class="text-center menu-title text-dark-brown font-nunito-bold fs-14"> {{ selectedTenant.attributes.name }} </h6>
                        <img class="text-center ml-2" src="../assets/images/down-arrow.png" alt="" width="15" height="7"/>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="tenantDropdown">
                        <router-link class="dropdown-item" :to="{ name: `workspace-settings:${$i18n.locale}` }">{{ $t('workspace-settings') }}</router-link>
                        <router-link class="dropdown-item" :to="{ name : `user:${$i18n.locale}` }">{{ $t('user-managment') }}</router-link>
                        <router-link class="dropdown-item" :to="{ name: '' }" data-toggle="modal" data-target="#invite-user">{{ $t('invite-user') }}</router-link>
                        <router-link class="dropdown-item" :to="{ name: `connectors-page:${$i18n.locale}` }">{{ $t('connectors') }}</router-link>
                    </div>
                    <invite-user></invite-user>
                </div>
            </div>
            <div class="list">
                <div class="messages-menu ml-3">
                    <h6 class="text-left menu-title text-black fs-14">
                        {{ $t('messages') }}
                        <font-awesome-icon class="exclamation-triangle" :icon="['fas', 'exclamation-triangle']"
                            v-if="showExclamationTriangle"/>
                    </h6>
                    <p class="m-0 text-left pb-1" v-if="facebook.isConnected">
                        <font-awesome-icon class="mr-2" :icon="['fab', 'facebook-f']"/>
                        <router-link :to="{ name: `facebook-connector:${$i18n.locale}` }" class="text-left menu-link">{{ $t('facebook') }}</router-link>
                    </p>
                    <p class="m-0 text-left pb-1" v-if="sms.isConnected">
                        <font-awesome-icon class="mr-2" :icon="['fas', 'mobile-alt']"/>
                        <span class="text-left fs-12 sms-title">{{ $t('sms') }}</span>
                    </p>
                    <p class="m-0 text-left pb-1 pl-4" v-if="sms.isConnected">
                        <router-link :to="{ name: `flash-send:${$i18n.locale}` }" class="text-left menu-link">-{{ $t('send-sms') }}</router-link>
                    </p>
                    <p class="m-0 text-left pb-1 pl-4" v-if="sms.isConnected">
                        <router-link :to="{ name: `sms-connector:${$i18n.locale}` }" class="text-left menu-link">-{{ $t('conversations') }}</router-link>
                    </p>
                    <p class="m-0 text-left pb-1 pl-4" v-if="sms.isConnected">
                        <router-link :to="{ name: `massive-send:${$i18n.locale}` }" class="text-left menu-link">-{{ $t('massive-send') }}</router-link>
                    </p>
                    <p class="m-0 text-left pb-1">
                        <router-link :to="{ name: `template:${$i18n.locale}` }" class="text-left menu-link">{{ $t('message-templates') }}</router-link>
                    </p>
                </div>
                <div class="contact-menu ml-3 mt-5">
                    <h6 class="text-left menu-title text-black fs-14">{{ $t('contacts') }}</h6>
                    <p class="m-0 text-left pb-1">
                        <router-link :to="{ name: `contact-list:${$i18n.locale}` }" class="text-left menu-link">{{ $t('all-contacts') }}</router-link>
                    </p>
                    <p class="m-0 text-left pb-1" v-if="sms.isConnected">
                        <router-link :to="{ name: '' }" class="text-left menu-link" data-toggle="modal" data-target="#form-add-contact">{{ $t('add-contact') }}</router-link>
                    </p>
                    <p class="m-0 text-left pb-1">
                        <router-link :to="{ name: `tags:${$i18n.locale}` }" class="text-left menu-link">{{ $t('tags') }}</router-link>
                    </p>
                    <p class="m-0 text-left pb-1">
                        <router-link :to="{ name: '' }" class="text-left menu-link" data-toggle="modal" data-target="#import-contact">{{ $t('import') }}</router-link>
                    </p>
                </div>
                <div class="contact-menu ml-3 mt-5">
                    <h6 class="text-left menu-title text-black fs-14">{{ $t('advanced-stocks') }}</h6>
                    <p class="m-0 text-left pb-1">
                        <router-link :to="{ name: `reports:${$i18n.locale}` }" class="text-left menu-link">{{ $t('reports') }}</router-link>
                    </p>
                    <p class="m-0 text-left pb-1">
                        <router-link :to="{ name: `autoResponse:${$i18n.locale}` }" class="text-left menu-link">{{ $t('auto-response') }}</router-link>
                    </p>
                </div>
            </div>
        </nav>
        <div class="sidebar-footer">
            <img class="img-fluid text-center p-3" src="../assets/images/logo.png" alt="">
        </div>
    </div>
</template>

<script>
import InviteUser    from './security/user/InviteUser.vue';
import TenantsAvatar from './TenantsAvatar.vue';

// Internal services js
import MenuMixin from '@/core/mixins/MenuMixin';

export default {
    mixins: [MenuMixin],

    components: {
        InviteUser,
        TenantsAvatar
    }
};
</script>
