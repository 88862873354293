<template>
    <div id="tag-page" class="d-flex align-items-stretch">
        <navigation></navigation>
        <auto-response></auto-response>
    </div>
</template>
<script>

// Components
import AutoResponse  from './AutoResponseManager.vue';

export default {
    components: {
        AutoResponse
    }
};
</script>
