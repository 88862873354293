<template>
    <div id="tenant-bar" class="bg-secondary position-absolute pt-4 d-flex flex-column">
        <tenant-picker
            ref="tenants"
        ></tenant-picker>
        <div class="tenant-list">
            <tenants-menu-list
                v-for="tenant in tenants"
                :key="tenant.id"
                v-bind:tenant="tenant"
                v-on:choose-tenant="$refs.tenants.chooseTenant($event)"
            ></tenants-menu-list>
        </div>
        <div class="add-link mt-4">
            <router-link :to="{ name: `cocoapp-wordpress-packages:${this.$i18n.locale}` }" target="_blank">
                <font-awesome-icon class="plus-square" :icon="['fas', 'plus-square']" />
            </router-link>
        </div>
        <div class="tenant-list-button">
            <router-link class="tenant-page-link small" :to="{ name: `tenant-list:${$i18n.locale}` }">{{ $t('see-all') }}</router-link>
        </div>
    </div>
</template>
<script>

import TenantsMenuList from './TenantsMenuList.vue';
import TenantPicker    from './TenantPicker.vue';
import { mapState }    from 'vuex';

export default {
    components: {
        TenantsMenuList,
        TenantPicker
    },
    computed: {
        /**
         * State of tenants
         *
         * tenants           State with list of all tenants
         */
        ...mapState('core/tenants', { tenants: 'userTenants' })
    }
};
</script>
