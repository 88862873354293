import Api from '@/helpers/Api.js';

const resource = '/connectors/facebook/v1/reactions';

export default {
    /**
     * Update reactions
     *
     * @param   {string}    reactionId   The reaction id
     * @param   {object}    attributes   The reaction new attributes
     *
     * @return    {object}    ApiJson response
     */
    update (reactionId, attributes) {
        return Api.patch(`${`${resource}/${reactionId}`}`, {
            'data': {
                'type': 'reactions',
                'id':   reactionId,
                attributes
            }
        });
    },

    /**
     * Make a new reaction
     *
     * @param   {object}    relationships  The new reactions relationships
     *
     * @return    {object}    ApiJson response
     */
    create (relationships) {
        return Api.post(`${`${resource}`}`, {
            'data': {
                'type':       'reactions',
                'attributes': {
                    'category': 'like'
                },
                relationships
            }
        });
    },

    /**
     * Cancel a reaction
     *
     * @param    {string}    reactionParentId     The reaction Parent ID
     *
     * @return    {object}    ApiJson response
     */
    delete (reactionParentId) {
        return Api.delete(`${resource}/${reactionParentId}`);
    }
};
