<template>
    <div id="single-contact-sidebar" class="sidebar single-contact-sidebar" v-bind:class="{ closeSidebar: !collapseSidebar }">
        <div class="white-box mb-4 mr-2">
            <div class="row">
                <div class="col-6">
                    <div class="d-flex align-items-center">
                        <h5 class="sibebar-title text-left text-black mr-auto">{{ $t('profile') }}</h5>
                    </div>
                </div>
                <div class="col-6">
                    <router-link :to="{ name: '' }" class="text-left edit-profil" data-toggle="modal" data-target="#form-edit-contact">{{ $t('edit') }}</router-link>
                    <form-edit-contact v-bind:contact="contact"></form-edit-contact>
                </div>
            </div>
            <div v-if="contact !== null">
                <div class="d-flex mb-3" v-if="contact.attributes['facebook-id'] !== undefined && contact.attributes['facebook-id'] != null">
                    <div class="connector-icon mr-2 facebook-connector">
                        <font-awesome-icon class="text-white" :icon="['fab', 'facebook-f']" />
                    </div>
                    <p class="mr-2 mb-0 profile-name text-left">{{ contact.attributes['facebook-name'] }}</p>
                </div>
                <div class="d-flex mb-3" v-if="contact.attributes.email != null">
                    <div class="connector-icon mr-2 innactive email">
                        <font-awesome-icon class="icon text-white" :icon="['fas', 'envelope']" />
                    </div>
                    <p class="mr-2 mb-0 profile-name text-left"><a class="contact-data" :href="'mailto:'+ contact.attributes.email" target="_blank">{{ contact.attributes.email }}</a></p>
                    <!-- Chose main information if contact has multiple one, intergration
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="facebook-2" name="main-account-fb" class="custom-control-input">
                        <label class="custom-control-label" for="facebook-2">Pricipal</label>
                    </div>-->
                </div>
            </div>
            <div class="d-flex mb-3" v-if="contact !== null && contact.attributes['phone-number'] != null">
                <div class="connector-icon sms-connector mr-2 innactive sms">
                    <font-awesome-icon class="icon text-white" :icon="['fas', 'mobile-alt']" />
                </div>
                <p v-if="contact" class="mr-2 mb-0 profile-name text-left"><a class="contact-data" :href="`tel:${contact.attributes['phone-number']}`">{{ contact.attributes["phone-number"] }}</a></p>
                <!-- Chose main information if contact has multiple one, intergration
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="number-1" name="main-account-number" class="custom-control-input" checked>
                    <label class="custom-control-label" for="number-1">Pricipal</label>
                </div>
                -->
            </div>
        </div>
        <div class="white-box mb-4 tags-sidebar mr-2">
            <contact-tags></contact-tags>
        </div>
        <div class="white-box note-sidebar mr-2">
            <contact-notes></contact-notes>
        </div>
    </div>
</template>
<script>

// Internal componants
import ContactNotes    from './ContactNotes.vue';
import ContactTags     from './ContactTags.vue';
import FormEditContact from '../contacts/FormEditContact.vue';

// External libraries
import {
    mapState
} from 'vuex';

export default {
    components: {
        FormEditContact,
        ContactNotes,
        ContactTags
    },

    props: [
        'collapseSidebar',
        'contactTags'
    ],

    computed: {
        ...mapState('core/contacts', {
            contact: 'single',
        }),
    }
};
</script>
