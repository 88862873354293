<template>
 <!-- v-if="visible" -->
<div class="modal" id="modal-result-config">
    <div class="modal-dialog modal-lg">
        <div class="modal-content px-5 py-3">
            <div class="modal-body body-result-config">
                <div id="content-pageResult-setup-connector" class="row">
                    <div class="text-right"
                        :class="{
                            'col-4 ml-3 ': statusOk(),
                            'col-3': !statusOk()
                        }">
                        <img :class="{'img-oups': !statusOk()}"
                        :src="getImgUrl( !statusOk() ? 'oups-pink' : 'celebration')" />
                    </div>
                    <div :class="{
                            'col-7 ml-3': statusOk(),
                            'col-9': !statusOk(),
                        }">
                        <div class="paragr-result-of-config"
                            :class="{
                                'paragr-result-success': statusOk(),
                                'paragr-result-fail': !statusOk()
                            }">
                                {{ statusOk() ? $i18n.t('Congrats') : $i18n.t('fail') }}
                            <br/>
                                {{getMsgResultConfig()}}
                            <p v-if="!statusOk()" class="msg-error">
                                {{ errorMessage() }}
                            </p>
                        </div>
                        <div class="bloc-btn-page-result pt-2t text-left"
                            data-dismiss="modal"
                            :class="{
                                'pt-3': statusOk()
                            }">
                            <button class="btn btn-primary rounded-pill btn-close-afterConfig"
                                :class="{
                                    'ml-5': !statusOk()
                                }"
                                @click.prevent="onClickBtnResult">
                                {{ getNameBtn()}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        result:  Object,
        visible: Boolean
    },
    emits: ['resultSetupEmit'],

    methods: {
        /**
         * Get the path of pic
         *
         * @param  {string}  pic  name pic
         * @param  {string}  extension  extension pic
         * @return {string}  path img
         */
        getImgUrl (pic, extension = 'png') {
            return require(`@/core/assets/images/${pic}.${extension}`);
        },

        /**
         * Get the path of pic
         *
         * @return {string}  name btn
         */
        getNameBtn () {
            switch (this.result.status) {
                case '403':
                    return `${this.$i18n.t('jump-to-step')} 2`;
                case '401':
                    return `${this.$i18n.t('jump-to-step')} 1`;
                default:
                    return this.$i18n.t('finish');
            }
        },

        /**
         * Emit event to parent
         *
         * @return {string}  name btn
         */
        onClickBtnResult () {
            this.$bvModal.hide('modal-result-config');
            this.$emit('resultSetupEmit');
        },

        /**
         * Return error message for configuration
         * based on status returned
         *
         * @return {string}  errorMessage
         */
        errorMessage () {
            if (this.result.status === '403') {
                return this.$i18n.t("error-api-key");
            }
            else if (this.result.status === '422') {
                return this.$i18n.t("error-config-voip-credentials");
            }
            return this.$i18n.t("error-config-sms");
        },

        /**
         * Return if the status of the configuration
         * is Ok
         *
         * @return {Boolean}  statusOk
         */
        statusOk () {
            return this.result.status === '200';
        },

        /**
         * Get Msg to inform the user of the config result
         * @return {string}  paragraph of result
         */
        getMsgResultConfig () {
            switch (this.result.status) {
                case '200':
                    return `${this.$i18n.t('Account')} ${this.result.account} ${this.$i18n.t('valid')}.`;
                case '401':
                    return `${this.$i18n.t('incSms-config-incorrectly')}`;
                case '403':
                    return `${this.$i18n.t('cannot-communicate-API')}`;
                case '422':
                    return `${this.result.errors[0].title}`;
                default:
                    if (this.result.errors) {
                        return this.result.errors[0].title;
                    }
            }
        }
    }
};
</script>
