import Api             from '@/helpers/Api.js';
import { REMOVE_USER } from '../users/mutation-types.js';

const resource = '/internal/v1/tenant-users';

// Initial state
const state = () => ({
});

// Getters
const getters = {
};

// Actions
const actions = {
    /**
     * Deleting a specific Tenant-User relationship.
     *
     * @param      {Object}  context                 The context
     * @param      {Object}  payload.user            The user
     * @param      {string}  payload.relationshipId  The relationship identifier
     * @return     {Promise}
     */
    delete: (context, { user, relationshipId }) => {
        return new Promise((resolve, reject) => {
            Api.delete(`${resource}/${relationshipId}`)
                .then(() => {
                    context.commit(`core/users/${REMOVE_USER}`, user, { root: true });
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
